export const COLOR_SCHEMES = {
  PRIMARY_GRADIENT: {
    BLUE: "#606AC1",
    PURPLE: "#E300B1",
    GREY: "#6673A5",
    PURPLE_SECONDARY: "#A131C7",
    BUTTON_DEACTIVATED: "#94A1AB",
  },
  DASHBOARD: {
    BACKGROUND_COLOR: "#fbfbfb",
  },
  GREY: "#94a1ab",
  WHITE: "#ffffff",
  BLACK: "#000000",
  SUCCESS: "#16A34A",
  ERROR: "#DC2626",
  GREYS: {
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827",
  },
  MOBILE_COLORS: {
    GREY: "#94a1ab",
  },
};
