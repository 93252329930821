import _ from "lodash";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { setLoading, updateAgreements } from "../actions";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import qs from "qs";
import history from "../history";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 *  @desc Fetch agreements
 * @return {*}  {Generator}
 */
export function* fetchAgreementsWorker(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `facts/?type="agreements"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      let fnResult: any = JSON.parse(jsonResponse.data.fnResult);
      return yield put(updateAgreements(JSON.parse(fnResult.data)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Set modal
 * @param {*} action
 */
export function* setModalWorker(action: any) {
  const {
    data: { modal },
  }: any = action;
  const parsed: any = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const modalSearch: any = qs.parse(modal.searchQuery, {
    ignoreQueryPrefix: true,
  });

  const newSearchQuery: any = new URLSearchParams(parsed);
  const updatedSearch: any = new URLSearchParams(history.location.search);

  try {
    if (!modal.isOpen) {
      newSearchQuery.delete("gmodal");
      newSearchQuery.delete("gref");

      history.push({
        pathname: history.location.pathname,
        search: newSearchQuery.toString(),
      });
    } else {
      updatedSearch.set("gref", modalSearch.gref);
      updatedSearch.set("gmodal", modalSearch.gmodal);

      history.push({
        pathname: history.location.pathname,
        search: updatedSearch.toString(),
      });
    }
  } catch (err) {
    yield put(setLoading({ errorMessage: err }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchAgreementsWatcher(): Generator {
  yield takeLatest("FETCH_AGREEMENTS", fetchAgreementsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* setModalWatcher(): Generator {
  yield takeLatest("SET_MODAL", setModalWorker);
}

export { fetchAgreementsWatcher, setModalWatcher };
