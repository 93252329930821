import * as React from "react";

const ArrowIconRight: React.FunctionComponent = (props) => (
  <svg width={50} height={25} viewBox="27 20 10 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.63 32.412a1.31 1.31 0 000-1.824l-8.039-8.21a1.245 1.245 0 00-1.786 0 1.31 1.31 0 000 1.824l5.882 6.008H19v2.58h19.687l-5.882 6.008a1.31 1.31 0 000 1.824 1.245 1.245 0 001.786 0l8.039-8.21z"
      fill="#94A1AB"
    />
  </svg>
);

export default ArrowIconRight;
