import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 15%);
  z-index: 2;
  width: 100%;
`;

export const ContainerInner = styled.ul`
  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const FooterItem = styled.a`
  list-style-type: none;
  cursor: pointer;
  color: #374151;
  font-family: Montserrat;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    color: #374151;
  }

  @media (max-width: 600px) {
    min-width: 100%;
    text-align: center;
  }
`;

export const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;
