// Types
import { Action } from "../actions/types";

// State types
type State = {
  cartaReports: any;
  carta04: any;
  reportContents: any;
  reportDetails: any;
  count: number;
};

// Initial state
const INITIAL_STATE: State = {
  cartaReports: {},
  carta04: {},
  count: 0,
  reportContents: "",
  reportDetails: "",
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "FETCH_CARTA_REPORTS":
      return { ...state };
    case "UPDATE_CARTA_REPORTS":
      return { ...state, cartaReports: action.data };
    case "UPDATE_REPORT_CONTENTS":
      return { ...state, reportContents: action.data };
    case "UPDATE_REPORT_DETAILS":
      return { ...state, reportDetails: action.data };
    case "FETCH_CARTA_04":
      return { ...state };
    case "UPDATE_CARTA_04":
      return { ...state, carta04: action.data};
    default:
      return state;
  }
};
