import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { fetchEmployeeCardDetails } from "../../../actions/employee";
import { COLOR_SCHEMES } from "../../../designSystem/colors";
import { getUserRole } from "../../../selectors";
import MenuOptionItem from "./components/MenuOptionsItem";
import PCOOptions from "./components/PCOOptions";
import PlanAdminOptions from "./components/PlanAdminOptions";
import {
  MenuOptions,
  MenuOptionsBarContainer,
} from "./MenuOptionsBar.styled.components";

const MenuOptionsBar: React.FunctionComponent = () => {
  const [whiteLabel, dependentsAllowed, userRole] = useSelector(
    (state: any) => [
      state.authReducer.preAuthDetails.whiteLabel,
      state.employeeReducer?.planDetails?.summary?.[0]?.dependentsAllowed,
      getUserRole(state),
    ]
  );
  const primaryColor =
    whiteLabel?.primarycolor ?? COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE;
  const secondaryColor =
    whiteLabel?.secondarycolor ?? COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE;

  const dispatch = useDispatch();

  useEffect(() => {
    const getState = JSON.parse(localStorage.getItem("state"));
    const personGuid = getState?.authReducer?.userClaims?.personGuid;
    dispatch(fetchEmployeeCardDetails(personGuid));
  }, []);

  let renderedList = null;

  switch (userRole) {
    case "PCO":
      renderedList = <PCOOptions dependentsAllowed={dependentsAllowed} />;
      break;
    case "planSponsorContact":
      renderedList = <PlanAdminOptions />;
      break;
    case "planSponsorAdministrator":
      renderedList = <PlanAdminOptions />;
      break;
    case "planSponsorAdministratorPrimary":
      renderedList = <PlanAdminOptions />;
      break;
    default:
      break;
  }

  return (
    <MenuOptionsBarContainer data-testid={"menuOptionsBar"}>
      <MenuOptions>{renderedList}</MenuOptions>
    </MenuOptionsBarContainer>
  );
};

export default withRouter(MenuOptionsBar);
