import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import {
  editBrokerageHouse,
  setLoading,
  updateBanks,
  updateBrokerageHouse,
  updateBrokerageHouses,
} from "../actions";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/bhaNavigation";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;
// Create brokerage house
const selectBrokerageHouse = (state: any) => state.brokerageHouseReducer;

/**
 * @desc Fetch broker house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchBrokerageHousesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let userRole: any = selectedAuthReducer.userClaims.userRole;
  let queryStringRole: any = roleBasedQueryResolver(userRole);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (userRole == "superAdmin") {
    params.path = `lists/?type="${queryStringRole}bhviewlist"&limit=${
      _.get(action, "data.limit") || 20000
    }&offset=${_.get(action, "data.offset") || 0}&at=${
      selectedAuthReducer.accessToken
    }`;
  } else {
    params.path = `businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="brokerageHouse"&at=${selectedAuthReducer.accessToken}`;
    // params.path = 'businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="brokerageHouse"';
  }
  try {
    apikeys = yield Auth.getAuthTokens();
    // params.data.accessToken = apikey.
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false, pageLoading: false }));
      return yield put(
        updateBrokerageHouses(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc Fetch bank
 * @return {*}  {Generator}
 */
function* fetchBanksWorker(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `facts/?type="banks"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(updateBanks(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Get brokerage house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getBrokerageHouseWorker(action: any): Generator {
  let jsonResponse: any = null;
  try {
    jsonResponse = yield axios(action, Auth);
    yield put(updateBrokerageHouse(jsonResponse));
  } catch (e) {}
  return;
}

/**
 * @desc Create brokerage house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createBrokerageHouseWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make a create request
    invite = yield call(axios, action.data, apikeys);
    if (invite.data.fnResult) {
      invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
    }

    if (invite.data && invite.data.errorType === "Error") {
      if (
        invite.data.errorMessage.toLowerCase().includes("businesslegalname")
      ) {
        return yield put(
          setLoading({
            loading: false,
            alertType: "error",
            errorMessage: "Business already exists",
          })
        );
      }
      if (invite.data.errorMessage.toLowerCase().includes("email")) {
        return yield put(
          setLoading({
            loading: false,
            alertType: "error",
            errorMessage: "Email address already exists",
          })
        );
      }
    }

    params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

    //after retrieving invite, make a call to invite api to retrive invite payload for redux store
    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnResult) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
    }

    yield put(editBrokerageHouse(parsedJsonResponse));
    let selectedBrokerageHouse: any = yield select(selectBrokerageHouse);
    let route: any = getRoute(selectedBrokerageHouse.data);

    let routeData: any = getRouteData(selectedBrokerageHouse.data);

    yield put(setLoading({ loading: false, ...routeData }));
    // return yield history.push(route, routeData);
    return yield history.push(route);
  } catch (ex) {
    console.log("CREATE ERR: ", ex);
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: "Uh oh, looks like something went wrong!",
      })
    );
    return ex;
  }
}

/**
 * @desc Update brokerage house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateBrokerageHouseWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (jsonResponse.data && jsonResponse.data.errorMessage) {
      yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: "Uh oh, looks like something went wrong!",
        })
      );
    }

    if (jsonResponse.data.fnMessage) {
      yield put(editBrokerageHouse(action.data.data));
      let parsedJsonResponse: any = action.data.data;
      let route: any = getRoute(parsedJsonResponse);

      let routeData: any = getRouteData(parsedJsonResponse);
      yield put(
        setLoading({
          loading: false,
          ...routeData,
          ...(action.flag && { alertType: "success" }),
          ...(action.flag && {
            successMessage: "Brokerage house information successfully saved",
          }),
        })
      );

      return yield history.push(route);
    } else {
      yield put(setLoading({ loading: false }));
    }
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: "Uh oh, looks like something went wrong!",
      })
    );
    return ex;
  }
}

/**
 * @desc Fetch brokerage house info
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchBrokerageHouseInfoWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons/${action.data}?type="includeInvites"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      let parsedJsonResponse: any = JSON.parse(jsonResponse.data.fnResult);
      yield put(editBrokerageHouse(parsedJsonResponse));
      let route: any = getRoute(parsedJsonResponse);
      let routeData: any = getRouteData(parsedJsonResponse);

      yield put(setLoading({ loading: false, ...routeData }));

      return yield history.push(route);
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Fetch brokerage houses
 * @return {*}  {Generator}
 */
function* fetchBrokerageHousesWatcher(): Generator {
  yield takeLatest("FETCH_BROKERAGE_HOUSES", fetchBrokerageHousesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchBanksWatcher(): Generator {
  yield takeLatest("FETCH_BANKS", fetchBanksWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getBrokerageHouseWatcher(): Generator {
  yield takeLatest("GET_BROKERAGE_HOUSE", getBrokerageHouseWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createBrokerageHouseWatcher(): Generator {
  yield takeLatest("CREATE_BROKERAGE_HOUSE", createBrokerageHouseWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateBrokerageHouseWatcher(): Generator {
  yield takeLatest("UPDATE_BROKERAGE_HOUSE", updateBrokerageHouseWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchBrokerageHouseInfoWatcher(): Generator {
  yield takeLatest("FETCH_BROKERAGE_HOUSE_DATA", fetchBrokerageHouseInfoWorker);
}

export {
  fetchBanksWatcher,
  fetchBrokerageHousesWatcher,
  createBrokerageHouseWatcher,
  updateBrokerageHouseWatcher,
  fetchBrokerageHouseInfoWatcher,
};
