import React from 'react';
import styles from './views.module.scss';

const TermsOfService = () => {
	return (
		<div className={ styles.terms }>
			<p><strong><br></br>
</strong></p>
<p><strong>AYA CARE</strong><br></br>
<strong>Health and Wellness Account Terms of Service</strong></p>
<p>Welcome to the Aya Care Health and Wellness Account program, operated and provided by Aya Payments, Incorporated. (“<strong>Aya Care</strong>”, “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”). The Aya Care Health and Wellness Account program enables employers to provide employees with health and wellness spending accounts (the “<strong>Service</strong>”). The Service is made available through Aya Care’s health payments mobile applications or web-based platform, which is accessible through a web-based portal at https://app.ayacare.com (the “<strong>Platform</strong>”).</p>
<p>Please read these terms and conditions carefully. These Health and Wellness Account Terms of Service (the “<strong>Terms</strong>”) are entered into on the date specified in the invoice (the “<strong>Effective Date</strong>”) provided to you (“<strong>Customer</strong>” “<strong>you</strong>” or “<strong>your</strong>”) by Aya Care. By electronically executing or clicking to agree to these Terms or otherwise using the Platform or Services, you hereby agree to these Terms and represent that you (i) are duly qualiﬁed and in good standing to do business in all jurisdictions where you conduct business; (ii) have all necessary organizational power and authority to use the Services, and to perform all of the obligations to be performed under these Terms; and (iii) have the requisite corporate authority to accept and agree to the Terms on behalf of Customer.</p>
<ol>
<li>Interpretation.
<ul>
<li>the division of the Terms into Sections and the insertion of headings are for convenience of reference only and will not affect the construction or interpretation;</li>
<li>“including” means “including without limitation”, and “includes” means “includes, without limitation”;</li>
<li>unless the context requires otherwise, words importing the singular include the plural and vice versa and words importing gender include all genders;</li>
<li>all Aya Care stated rights and remedies are not exclusive and are in addition to any other available rights and remedies; and</li>
<li>all references to “days” are business days.</li>
</ul>
</li>
<li>Definitions. Capitalized words not otherwise defined in these Terms have the following meanings:</li>
</ol>
<p>“<strong>Access Information</strong>” collectively a PIN, online username, password, and any other security information used to access an Employee Account or Card;</p>
<p>“<strong>Authorized Employee</strong>(<strong>s</strong>)” means the designated employee(s) authorized by you to use the Employee Account;</p>
<p>“<strong>Card</strong>” means the Aya Care prepaid card issued by Issuer, through which any Authorized Employees can pay for Eligible Health Services using funds in the Employee Account. Each reference to “Card” shall also include all “Cards” provided to Authorized Employees;</p>
<p>“<strong>Census</strong>” means the form Aya Care requires Customer to populate with certain information, including the number of Authorized Employees, the full names of Authorized Employees and the specific funds to be deposited into the Employer Account and the Employee Account.</p>
<p>“<strong>Confidential Information</strong>” means all information, data, or materials, whether written or oral, including, but not limited to, personal and financial information, relating to a party or its business, strategies, pricing, personnel, suppliers, technology, products or services, but excludes information that the party receiving Confidential Information (“Recipient”) can demonstrate: (a) was lawfully in its possession before receiving it from the disclosing party (“Discloser”); (b) was provided in good faith to it by a third-party without breaching any of Discloser’s rights or any rights of a third-party; (c) is or becomes generally available to the public through no fault of the Recipient; or (d) was independently developed or discovered by Recipient without use of or reference to Discloser’s Confidential Information;</p>
<p>“<strong>Election Period</strong>” means the first four weeks of the plan year, during which time Customer may select and/or revise the Health Plans corresponding to each Authorized Employee;</p>
<p>“<strong>Eligible Health Service</strong>” means a health service that you permit Authorized Employees to expense or pay for in accordance with a Health Plan;</p>
<p>“<strong>Employee Account</strong>” means the employee account used either as (i) a sub-account of the Employer Account for Pre-paid Plans; or (ii) an expense account for Post-paid Plans;</p>
<p>“<strong>Employer Account</strong>” means the records we maintain to account for the value for the funds available for either: (i) allocating funds at your request to Employee Accounts for Pre-paid Plans; or (ii) paying expenses for Post-paid Plans;</p>
<p>“<strong>Health Plan</strong>” means a Post-paid Plan or a Pre-paid Plan, as the context requires;</p>
<p>“<strong>HSA</strong>” means the Aya Care health spending account that Authorized Employees can use to pay for non-taxable Eligible Health Services;</p>
<p>“<strong>Issuer</strong>” means Peoples Trust Company;</p>
<p>“<strong>Post-paid Plan</strong>” means an HSA and/or WSA for which Customer facilitates payment after Authorized Employees receive Eligible Health Services;</p>
<p>“<strong>Pre-paid Plan</strong>” means an HSA and/or WSA for which Authorized Employees are provided with a Card with pre-loaded funds to facilitate payment of their Eligible Health Services;</p>
<p>“<strong>Third-Party Service</strong>(<strong>s</strong>)” means a product or service offered and/or provided by a third-party service provider;</p>
<p>“<strong>Third Party Terms</strong>” means any additional terms and conditions that govern the provision of a specific Third-Party Service to you or an Authorized Employee;</p>
<p>“<strong>Unauthorized Transaction</strong>” means: (1) a transaction initiated by a person other than an Authorized Employee; (2) a transaction for which funds would be incorrectly paid into either an Employer Account or an Employee Account; (3) a transaction that exceeds the amount of available funds in an Employer Account or Employee Account; (4) a fraudulent transaction; or (5) any transaction for services that are not Eligible Health Service.</p>
<p>“<strong>WSA</strong>” means the Aya Care wellness spending account that Authorized Employees can use to pay for taxable Eligible Health Services; and</p>
<p>“<strong>you</strong>” means the Customer or the individual with the requisite corporate authority to bind the Customer, as applicable.</p>
<ol start={3}>
<li>Platform.
<ul>
<li>Subject to these Terms, we hereby authorize you and your Authorized Employees to access and use the Platform solely for the purposes of using the Service.</li>
<li>The Platform is our exclusive property and is protected by copyright, trademark and other intellectual property laws. Any rights not expressly granted by us to you are reserved by us. We disclaim any implied rights and licenses.</li>
<li>You acknowledge and agree that except for the limited rights expressly provided in these Terms, nothing in these Terms will, or will be deemed or construed or implied to, assign, transfer, or convey to or vest in You any title, rights, or interest in or to any intellectual property, including in or to the Platform, the Services or any related documentation.</li>
<li>You will promptly provide us with written notice of any unauthorized use of, access to, disclosure of, reproduction, or transmission of any element of the Platform that is in violation of these Terms.</li>
</ul>
</li>
<li>Accounts.
<ul>
<li><strong>Employer Account</strong>. You must register for an Employer Account in order to use the Service. As part of the registration process, you will provide us with: (i) the contact information of a company contact; (ii) the contact information of one or more plan administrators responsible for facilitating your Employer Account; (iii) billing information; and (iv) any additional details that Aya Care reasonably requests (“<strong>Employer Registration Information</strong>”). You agree to: (i) provide us with true, accurate, correct and current Employer Registration Information; and (ii) immediately notify us of any change in Employer Registration Information. Aya Care will allocate the funds you identify in the Census to your Employer Account.</li>
<li><strong>Employee Account</strong>. You will be required to invite Authorized Employees to the Platform, select a Health Plan for each Authorized Employee and submit certain registration details regarding such Authorized Employee (“<strong>Employee Registration Information</strong>”, together with Employer Registration Information, the “<strong>Registration Information</strong>”) in order to register Authorized Employees for Employee Accounts. You agree that you are solely responsible for: (i) ensuring that all Authorized Employees are the age of majority in the jurisdiction in which they work; and (ii) entering accurate Employee Registration Information in your Employee Accounts.</li>
<li><strong>Administration of Accounts</strong>. You shall be responsible for administering the Employer Account and each Employee Account in accordance with these Terms. You may make changes to the Employee Account with or without the consent of any Authorized Employee, including changes to define Eligible Health Services, and allocate additional funds to Employee Accounts. The Employer Account and the corresponding Employee Accounts do not constitute checking or savings accounts. You will not receive any interest on the funds in the Employer Accounts or Employee Accounts.</li>
</ul>
</li>
<li>Services
<ul>
<li><strong>Health Plans</strong>. Authorized Employees must be registered for a Health Plan. Authorized Employees registered for a Post-paid Plan will submit receipts for Eligible Health Services received to the Platform. You will be responsible for directly reimbursing Authorized Employees for such Eligible Health Services. Authorized Employees registered for a Pre-paid Plan will receive a Card providing them with access to the funds that you allocate to such Authorized Employees during the Election Period and will be required to submit a receipt of any transactions for Eligible Health Services to the Platform within thirty (30) days of such transaction. In the event that you become aware that an Authorized Employee is or will be terminated, suspended or otherwise no longer requires the use of the Services, you will promptly notify us. Such notice will include details such as the name of the Authorized Employee, the last date on which such Authorized Employee can submit a claim and the date of such Authorized Employee’s termination.</li>
<li><strong>Transferring Funds</strong>. Aya Care will allocate the funds identified in the Census or such other amounts directed by a plan administrator during the Election Period to the corresponding Employer Accounts and Employee Accounts. Aya Care reserves the right to refuse to transfer any funds to an Employee Account in payment of services other than Eligible Health Services or that contravene applicable laws. The amounts available for funding Employee Accounts are limited to those amounts designated in the Census and allocated by Aya Care in accordance with these Terms. If you make an error in your Census regarding the funds to be transferred, you agree to promptly notify us of such error but in no event no less than thirty (30) days after the date the error occurred. Such notice will include details pertaining to the dollar amount of the error and when the error took place.</li>
<li><strong>Unauthorized Transactions</strong>. In the event of an Unauthorized Transaction you acknowledge and agree that we may:
<ul>
<li>refuse to process such Unauthorized Transaction;</li>
<li>hold funds from future deposits to reconcile incorrectly paid amounts or any amounts exceeding the funds in the corresponding Employee Account or Employer Account;</li>
<li>adjust the transaction to the extent of the unauthorized action, including to reallocate funds between a WSA or HSA or an Employee Account and Employer Account; and/or</li>
<li>temporarily suspend the corresponding Employer Account or Employee Account.</li>
</ul>
</li>
</ul>
</li>
</ol>
<p>If we process an Unauthorized Transaction and no future funds are added to the Employer Account or Employee Account, you agree to pay to us an amount equal to the amounts incorrectly paid and/or in excess of the available funds, within seven (7) days of being notified of such Unauthorized Transaction. We will notify you in the event of any such Unauthorized Transaction with particulars as to the actions we are taking.</p>
<ul>
<li>
<ul>
<li><strong>Suspicious Activity. </strong>Subject to any applicable law, in the event we, in our sole discretion, suspect that any suspicious activity is taking place on an Employee Account or an Employer Account, we reserve the right to: (1) limit the dollar amount of transactions that can be made on a Card; (2) place a hold on certain types of transfers or transactions; and (3) limit, suspend, or block transfers from particular persons, entities, or Employee</li>
<li><strong>Transaction Limits</strong>. The maximum amount that can be spent using the Employee Account is limited to the lower of: (a) the limits set by you; (b) the limits set forth by Aya Care; or (c) the limits set by the Issuer. Such limits include any amounts subject to a hold.</li>
<li><strong>Third-Party Services</strong>. You acknowledge that the Platform may include links to, or contain functionality provided by, certain Third-Party Services. You acknowledge and agree that any Third-Party Service is provided solely by the applicable third-party service provider, and not Aya Care, and is provided subject to any additional and applicable Third-Party Terms. We will have no liability, obligation, responsibility or duty for any such Third-Party Service, including any purchase or promotion between you, an Authorized Employee and any such third-party. Use of any Third-Party Service is solely at your risk.</li>
<li>Card Services.
<ul>
<li><strong>Issuing Cards</strong>. Cards can be linked to Employee Accounts by your plan administrator. The Card allows Authorized Employees to access funds that you allocate to their Employee Account. Each Employee Account is a sub-account of the Employer Account that will enable you to track the balance of funds allocated to the Card linked to such Employee Account.</li>
<li><strong>Activating Cards</strong>. An Authorized Employee cannot use a Card to perform transactions until you have activated the Card, selected a Personal Identification Number (“<strong>PIN</strong>”) and allocated funds to the Card. Authorized Employees may be required to provide additional Access Information or Employee Registration Information in order to use an activated Card. By activating or otherwise using a Card, you represent that you have read and understand any Issuer terms and conditions, and agree to be bound by them and acknowledge that we are not responsible for managing the payment network through which Cards operate. You further agree that you will not disclose any Access Information for any Card to any person other than the Authorized Employee permitted to use such Card or the business administrator administering such Employee Account.</li>
<li><strong>Cancelling or Suspending Cards</strong>. Subject to these Terms, your plan administrator may suspend or cancel an Employee Account at any time by providing Aya Care with appropriate notice. Upon the suspension or cancellation of an Employee Account, the Authorized Employee will no longer be able to use the Employee Account or the associated Card. We may suspend an Employee Account in the event an Authorized Employee: (1) fails to provide a receipt for an Eligible Health Service expense within thirty (30) days of such transaction; or (2) initiates a transaction for which there are insufficient funds in the Employee Account.</li>
<li><strong>Administration of Cards</strong>. You may limit or restrict the activity on all Cards with or without the consent of any Authorized Employee. You acknowledge and agree that the amount of funds available in the Employer Account and Employee Account is limited to the funds that have been deposited into the Employer Account or Employee Account pursuant to these Terms.</li>
<li><strong>Replacement Card</strong>. Upon expiration of a Card, if your Employer Account and the corresponding Employee Account is in good standing, we will provide the Authorized Employee registered to the expired Card with a new Card.</li>
<li><strong>Holds</strong>. When Authorized Employee use a Card to initiate a transaction and the ﬁnal purchase amount has not been finalized at the time of authorization, a hold may be placed on the available funds in the Employee Account for an amount equal to or in excess of the ﬁnal transaction amount. The funds subject to the hold will not be available to you or the Authorized Employee until we receive the ﬁnal transaction amount. Once we receive the ﬁnal transaction amount, it may take up to seven (7) days for the hold to be removed. During that period, you and Authorized Employees will not have access to the funds subject to the hold.</li>
<li><strong>Restrictions and Requirements</strong>. Authorized Employees may only use the Card to pay for Eligible Health Services at health service providers accepting the Card. You acknowledge and agree that the Card: (1) is not a credit card; (2) will remain the property of Aya Care and Aya Care’s licensors and must be surrendered upon expiration of these Terms, suspension of Services or upon demand by Aya Care; (3) cannot be redeemed for cash; (4) is non-transferable and (5) may be canceled or revoked at any time without prior notice except as required by applicable law. We may, in our sole discretion, limit the number of Cards that may be issued on your behalf.</li>
</ul>
</li>
</ul>
</li>
</ul>
<ol start={6}>
<li>Responsibilities and Requirements.</li>
</ol>
<p>You will:</p>
<ul>
<li>use Services only in accordance with these Terms, the Privacy Policy, applicable laws and government regulations.</li>
<li>be responsible for the accuracy, quality and legality of Registration Information, Access Information, and any other information submitted to Aya Care;</li>
<li>use commercially reasonable efforts to prevent unauthorized access to the Platform or use of Services by employees, contractors or agents, and notify us promptly upon becoming aware of any such unauthorized access or use;</li>
<li>be responsible for all transactions and fees incurred on Cards used by Authorized Employees, even if such transactions are processed in error or exceed the authorized limit granted by you;</li>
<li>be responsible for providing, at your cost, all equipment and communications capabilities necessary to establish a connection to the Platform and access and use Services;</li>
<li>make commercially reasonable efforts to ensure that Authorized Employees:
<ul>
<li>comply with these Terms, the Privacy Policy and all applicable laws;</li>
<li>submit accurate receipts of any Eligible Health Service expenditure made by such Authorized Employee to the Platform within thirty (30) days of receiving such Eligible Health Service receipt;</li>
<li>immediately notify us if: (1) a Card is suspected to be or actually is lost or stolen; (2) there is unauthorized access to the Registration Information; or (3) an unauthorized transfer of funds to or from an Employee Account;</li>
<li>do not make any Service available to anyone other than Authorized Employees;</li>
<li>do not sell, resell, license, sublicense, distribute, make available, rent or lease any Service;</li>
<li>do not use the Platform to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights;</li>
<li>do not use a the Platform to store or transmit Malicious Code;</li>
<li>do not interfere with or disrupt the integrity or performance of any Service or third-party data contained therein;</li>
<li>do not attempt to gain unauthorized access to the Platform or its related systems or networks;</li>
<li>do not permit direct or indirect access to or use the Platform or Service in a way that circumvents a contractual usage limit, or to access or use any of our intellectual property except as permitted under these Terms; or</li>
<li>do not disassemble, reverse engineer, or decompile the Platform or access it to: (1) build a competitive product or service; (2) build a product or service using similar ideas, features, functions or graphics of the Service; (3) copy any ideas, features, functions or graphics of the Service; or (4) determine whether the Services are within the scope of any patent.</li>
</ul>
</li>
</ul>
<ol start={7}>
<li>Fees and Payment.
<ul>
<li><strong>Payment of Fees</strong>. You agree to pay all undisputed service fees, administration fees, platform fees and any ancillary expenses (the “<strong>Fees</strong>”) which will be invoiced to you on a monthly, quarterly or annual basis, as selected by you. Invoices are due thirty (30) days after they are received. Fee payment obligations are non-cancelable, and Fees paid are non-refundable. Aya Care reserves the right to change the Fees upon thirty (30) days’ notice.</li>
<li><strong>Overdue Amounts</strong>. You will be responsible for paying all amounts as invoiced for the Services. If there is a delay in payment of any such amounts, any delayed payments will be subject to a 1.5% interest of the outstanding balance per month or the maximum rate permitted by law, whichever is lower.</li>
<li><strong>Currency</strong>. Unless otherwise specified references to amounts in this Agreement shall be in Canadian Dollars.</li>
<li><strong>Taxes</strong>. Any fees we invoice you do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “<strong>Taxes</strong>”). You are responsible for paying all Taxes associated with Your use of the Services and Platform hereunder. If we have the legal obligation to pay or collect Taxes for which you are responsible, we will invoice you and you will pay that amount unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing authority.</li>
<li><strong>Payment Disputes</strong>. Upon receipt of an invoice, you will have ninety (90) days to detail in writing to us any issues or inaccuracies regarding such invoices (a “<strong>Payment Claim</strong>”). We will provide a response to any Payment Claim within fifteen (15) days of receipt of such Payment Claim, which response will include either: (i) documentation supporting the disputed details of the applicable invoice; or (ii) a revised invoice reflecting any corrections necessary in respect of the Payment Claim. For greater certainty, no interest will be charged on any amounts subject to a Payment Claim, regardless of the final determination. If you fail to make a Payment Claim within ninety (90) days, you shall be deemed have accepted the information set out in the statement or invoice, and you shall have no right to claim, take action, or set off any amount of funds related to transactions or the The foregoing shall not apply in the event that the relevant issue or inaccuracy is reasonably attributable to us. We may suspend your access to the Platform and Service if you fail to pay any amount specified in an invoice within ninety (90) days, and such amount is not subject to a Payment Claim.</li>
</ul>
</li>
<li>Privacy Policy.</li>
</ol>
<p>Where personal information is collected in connection with an employee’s administration, registration and/or use of the Platform and/or Services, the collection, use, and disclosure of personal information will be in accordance with Aya Care’s privacy policy available at <a href="https://ayacare.com/privacy-policy/">here</a> (the “<strong>Aya Care Privacy Policy</strong>”). You are responsible for your compliance and your Authorized Employee’s compliance with all applicable privacy laws, and you covenant that all personal information provided to us or uploaded to the Platform or any Services is provided in compliance with all applicable laws. The Aya Care Privacy Policy forms part of these Terms.</p>
<ol start={9}>
<li>Security and Confidentiality.
<ul>
<li><strong>Security</strong>. We have implemented security policies and practices that are designed to protect the security and integrity of the Platform. However, we cannot guarantee the security of the Platform or the security or integrity of any communications between you or any Authorized Employee and the Platform. You acknowledge that it is your responsibility to use a secure connection to access the Platform and that you are solely responsible for implementing security safeguards to protect you and your Authorized Employees when accessing and using the Platform, including to take precautions against viruses, worms, trojan horses, and other items of a disabling or destructive nature.</li>
<li><strong>Confidentiality</strong>. Throughout the term of these Terms and following termination by either party, the Recipient of Confidential Information will: (i) hold the Discloser’s Confidential Information in strict confidence and use the same degree of care as it uses to avoid the unauthorized disclosure of its Confidential Information of a similar nature, but not less than commercially reasonable care; and (ii) not use the Confidential Information for any purpose except as contemplated in these Terms. The Receiving Party will restrict the possession and use of Confidential Information to its partners, directors, officers, managers, employees, contractors, shareholders and agents who have a need to know and are bound by confidentiality obligations no less stringent than those contained in these Terms.</li>
</ul>
</li>
<li>Indemnity.</li>
</ol>
<p>You agree to indemnify, release and forever hold harmless Aya Care and Aya Care’s officers, directors, employees, agents, and any other parties working for or engaged by Aya Care or otherwise involved in connection with the administration of the Platform (the “<strong>Aya Care Parties</strong>”), from any and all claims, actions, demands, causes of action, suits, debts, damages (including, without limitation, direct, indirect, special, incidental, exemplary, punitive, third-party, or consequential damages) or any other losses, expenses or liabilities of whatever nature or kind sustained by any of the Aya Care Parties arising out of, or in connection with, you or your Authorized Employees’ improper use of the Platform and/or Services, including for transactions for ineligible products or services, breach of these Terms, willful misconduct or gross negligence.</p>
<ol start={11}>
<li>DISCLAIMER.</li>
</ol>
<p>THE PLATFORM AND SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS AND AT YOUR OWN RISK. OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS, AYA CARE MAKES NO REPRESENTATIONS AND WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS OR IMPLIED, AND EXPRESSLY DISCLAIMS TO THE FULL EXTENT PERMISSIBLE UNDER APPLICABLE LAW ANY AND ALL OTHER WARRANTIES OR CONDITIONS OF ANY KIND, INCLUDING: (A) FITNESS FOR A PARTICULAR PURPOSE; (B) MERCHANTIBILITY; (C) NON-INFRINGEMENT OF THIRD-PARTY RIGHTS; (D) THAT THE PLATFORM WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, FREE OF MALICIOUS CODE OR VIRUS OR OPERATE WITHOUT ERROR; AND (E) THAT ANY ERRORS CAN OR WILL BE CORRECTED.</p>
<ol start={12}>
<li>Limitation and Exclusions.
<ul>
<li><strong>Exclusion o</strong><strong>f Consequential and</strong><strong> Related Damages</strong>. To the fullest extent permissible under applicable law, in no event will the Aya Care Parties or their affiliates have any liability arising out of or related to this agreement for any losses or expenses (including lost profits, revenues, or goodwill, loss of or damage to data or other intangible or economic losses), or direct, indirect, special, incidental, consequential, cover, business interruption or punitive damages, whether an action is in contract or tort and regardless of the theory of liability, even if a party or its affiliates have been advised of the possibility of such damages, including any liability for: (a) interruptions and communication line failures; (b) your inability to access and/or use, at any time, any part of the Platform or Services; (c) the malfunctioning or unavailability of the Platform, in whole or in part; (d) your (or any other person’s) use of or reliance on, the Platform or Services; (e) any harm or loss to your computing systems or records or data howsoever caused including caused by viruses, “worms”, “Trojan horses” or other similar intrusive, disruptive, or destructive programs or files of that nature; (f) security breaches or the interception or disclosure of confidential or sensitive information transmitted over the Internet; (g) the infringement of third-party rights; and/or (h) the lack of accuracy, suitability, reliability, timeliness, or availability of Service, or the Platform.</li>
<li><strong>Limitation of Liability</strong>. If and to the extent any of the foregoing limitations or exclusions of liability are determined by a court of competent jurisdiction to not apply or be enforceable for any reason, Aya Care’s total aggregate liability to you under these Terms in respect of any and all claims related to or arising out of the Platform or any Service will not exceed the fees or charges paid by you for the Platform or Service or other Platform element that is the subject of the claim in the twelve (12) month period prior to the date of the claim even if Aya Care has been advised of the possibility of such damages.</li>
<li><strong>Acknowledgement</strong>. You agree that the limitations and exclusions contained in these Terms are reasonable based upon the commercial circumstances, and that we would not have entered into these Terms and authorized your access to and use of the Platform and Services but for your agreement to, and acceptance of, such limitations and exclusions.</li>
</ul>
</li>
<li>Term and Termination.
<ul>
<li><strong>Term</strong>. These Terms will commence on the Effective Date (as set out above) and shall continue for until the date set out in your Invoice (the “<strong>Initial Term</strong>”).</li>
<li><strong>Renewal</strong>. At the end of the Initial Term these Terms will automatically renew for an additional twelve (12) month term (each, a “<strong>Renewal Term</strong>”, and the Initial Term together with all Renewal Terms, the “<strong>Term</strong>”) and every subsequent Renewal Term, unless you provide Aya Care with sixty (60) days written notice prior to the end of the Initial Term by providing us written notice of Your intention to renew no later than thirty (30) days prior to the expiration of the Term.</li>
<li><strong>Termination for Convenience</strong>. Either Party may terminate these Terms and any Services by providing at least ninety (90) days’ advance written notice of termination to the other Party, provided that upon any such termination during the Initial Term an early termination fee equal to the amount of Fees as defined in the invoice.</li>
<li><strong>Termination for Cause</strong>. If you breach any of these Terms or are engaging in other misconduct or acts that Aya Care determines are harmful to Aya Care or the Platform, Aya Care may, without limiting its rights and remedies, terminate Your access to the Platform and/or any Services or suspend your access to and use of the Platform and any Services for any period of time.</li>
</ul>
</li>
</ol>
<p>In the event of a termination pursuant to this Section 13, we shall return to you, any unearned, prepaid defined contribution amounts for the terminated Service and any amounts outstanding in an Employer Account or a corresponding Employee Account.</p>
<ol start={14}>
<li>General.
<ul>
<li><strong>Notices</strong>. All notices or approvals required or permitted under the Terms will be in writing and delivered by email transmission, and in each instance will be deemed given upon receipt. All notices or approvals will be sent: (i) to Aya Care at <a href="mailto:cm@ayacare.com">cm@ayacare.com</a> and (ii) to you at the electronic email address provided in your Registration Information. For notices sent by email the date of receipt will be deemed the date on which such notice is transmitted.</li>
<li><strong>Consent to Use Electronic Signatures and Communications</strong>. You hereby consent to use electronic signatures and to electronically receive all records, notices, statements, communications, and other items for all Services provided to you and your Authorized Employees under these Terms and in connection with your relationship with us (collectively, “<strong>Communications</strong>”) that we may otherwise be required to send or provide you in paper form. By accepting and agreeing to these Terms electronically, you: (1) you have read and understand this consent to use electronic signatures and to receive Communications electronically; and (2) your consent will remain in effect until you withdraw your consent. You may withdraw your consent to receive further Communications at any time by contacting us at the address provided above. If you withdraw your consent to receive Communications electronically, we reserve the right to either: (a) charge you a fee for paper copies of Communications; or (b) terminate these Terms in accordance with section 13.</li>
<li><strong>Entire Agreement</strong>. These Terms constitute the entire agreement between you and Aya Care pertaining to all the matters herein and supersedes all other agreements, understandings and discussions between the parties hereto, whether oral or written. In the event of any conflict between these Terms in relation to the subject matter hereof and any other agreement between You and Aya Care, these Terms will prevail.</li>
<li><strong>Amendment</strong>. We may revise the Terms at any time with or without notice to you. The most current version of these Terms will always be posted on the Platform. If these Terms are revised, you will be notified of the updated Terms by way of email. If you do not agree to the revised Terms, you will not be permitted to continue accessing the Platform and use the Services. These Terms cannot otherwise be amended or modified.</li>
<li><strong>Assignment</strong>. These Terms will be binding upon and will enure to the benefit of and be enforceable by each of the parties hereto, their respective successors and permitted assigns. You may not assign, delegate or transfer all or any part of the Terms (or any of Your rights) without our prior written We may assign these Terms without Your consent at any time.</li>
<li><strong>Independent Contractor</strong>. It is expressly understood and agreed that Aya Care is an independent contractor in performing its obligations hereunder and will not be considered or deemed to be an agent, employee, joint venturer or partner of You. No party by virtue of these Terms will have any right, power or authority, express or implied, to act on behalf of or enter into any undertaking binding another party.</li>
<li><strong>Waiver</strong>. No delay or omission by a party to exercise any right or power it has under these Terms or to object to the failure of any covenant of another party to be performed in a timely and complete manner, will impair any such right or power or be construed as a waiver of any succeeding breach or any other covenant. All waivers must be in writing and signed by the party waiving its rights.</li>
<li><strong>Severability</strong>. If any provision of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable in any respect, then the remaining provisions, or the application of such provisions to persons or circumstances other than those as to which it is invalid or unenforceable will not be affected thereby, and each such provision of the Terms will be valid and enforceable to the extent granted by law.</li>
<li><strong>No Third-Party Beneficiaries</strong>. These Terms are solely for the benefit of you, your Authorized Employees, and Aya Care, and nothing in these Terms will be deemed to create any third-party beneficiary rights in any other person or entity.</li>
<li><strong>Force Majeure</strong>. Aya Care will not be responsible or liable for delays in or for failures in the availability of the Platform due to causes beyond its reasonable control, including without limitation, acts of God, the acts or omissions of the Authorized Employees, health service providers, third-party product or service failures, Internet or telecommunications outages, including outages of any payment network service provider, acts of civil or military authorities, fire, strikes, power surges or outages, pandemics, epidemics, flood, earthquakes, riot, or war.</li>
<li><strong>Jurisdiction</strong>. These Terms will be exclusively governed by, construed and interpreted in accordance with the laws of the Province of Ontario, Canada, without regard to any conflict of law principles which would cause the application of any other law. The parties hereto irrevocably attorn to the exclusive jurisdiction of the Courts of the Province of Ontario in respect of all matters and disputes arising hereunder.</li>
<li><strong>Language</strong>. It is the express wish of the parties hereto that the Terms be drawn up in English. La volonté expresse des parties aux présentes est que ce “Terms” soit rédigé en anglais. The parties hereto hereby waive any right to use and rely upon any other language.</li>
</ul>
</li>
</ol>
		</div>
	);
};

export default TermsOfService;
