import { call, put, select, takeLatest } from "redux-saga/effects";
import { editDependent, setLoading, updateFetchedDependent } from "../actions";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/dependentNavigation";

// create dependent
const selectDependent = (state: any) => state.dependentReducer;
// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Create Dependent
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createDependentWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    invite = yield call(axios, action.data, apikeys);
    if (invite.data.fnResult) {
      invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
    }
    params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnResult) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
    }

    yield put(editDependent(parsedJsonResponse));
    let selectedDependent: any = yield select(selectDependent);
    let route: any = getRoute(selectedDependent.data);
    let routeData: any = getRouteData(selectedDependent.data);
    yield put(setLoading({ loading: false, ...routeData }));
    // return yield history.push(route, routeData);
    return yield history.push(route);
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @desc Update dependent house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateDependentsWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (jsonResponse.data.fnMessage) {
      yield put(editDependent(action.data.data));
      let parsedJsonResponse: any = action.data.data;
      let route: any = getRoute(parsedJsonResponse);
      let routeData: any = getRouteData(parsedJsonResponse);
      yield put(setLoading({ loading: false, ...routeData }));
      // return yield history.push(route, routeData);
      return yield history.push(route);
    } else {
      yield put(setLoading({ loading: false }));
    }
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

// fetchDependentWorker

function* fetchDependentWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params = {
    method: "GET",
    path: `persons/dependents?at=${selectedAuthReducer.accessToken}`,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnStatusCode === 200) {
      yield put(setLoading({ loading: false }));
      // return yield put(editDependent(jsonResponse.data.data));
      return yield put(updateFetchedDependent(jsonResponse.data.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setLoading({ loading: false }));
  }
}


/**
 * @return {*}  {Generator}
 */
function* createDependentWatcher(): Generator {
  yield takeLatest("CREATE_DEPENDENT", createDependentWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateDependentsWatcher(): Generator {
  yield takeLatest("UPDATE_DEPENDENTS", updateDependentsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchDependentWatcher(): Generator {
  yield takeLatest("FETCH_DEPENDENTS", fetchDependentWorker);
}


export { createDependentWatcher, updateDependentsWatcher, fetchDependentWatcher };
