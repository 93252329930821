/** @type {*} */
const userRoleMapper: { [index: string]: string } = {
  superAdmin: "SA",
  juniorAdmin: "JA",
  brokerageHouseAdministratorPrimary: "BHAP",
  brokerageHouseAdministrator: "BHAP",
  broker: "BROKER",
  tpaAdministrator: "TPAP",
  tpaAdministratorPrimary: "TPAP",
  tpaServiceRepresentative: "TPA_SR",
  PCO: "PCO",
  dependent: "DEPENDENT",
  planSponsorAdministrator: "PSAP",
  planSponsorAdministratorPrimary: "PSAP",
  planSponsorContact: "PSC",
  finance: "FINANCE",
  claimEditor: "CLAIM_EDITOR",
};

// Export
export default userRoleMapper;
