// Types
import { Action } from "../actions/types";

// State types
type State = {
  tpaSrs: Array<any>;
  tpaSrsFull: Array<any>;
  data: any;
};

// Initial state
const INITIAL_STATE: State = {
  tpaSrs: [],
  tpaSrsFull: [],
  data: {
    inviteState: {
      step: "PRELIMINARY-STEP_TWO-TPA_SR",
      status: "active",
    },
    sendNotification: true,
    requestedForBusinessId: "",
    person: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      smsNumber: "",
      homeNumber: "",
      workNumber: "",
      workNumberExt: "",
      faxNumber: "",
      faxNumberExt: "",
      email: "",
      postalCode: "",
      countryCode: "CA",
      languageCode: "en",
      governmentBirthID: "",
      personStatus: "PA00S",
      title: "",
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      userRole: {
        label: "tpaServiceRepresentative",
        name: "tpaServiceRepresentative",
      },
      verification: {
        documentType: "",
        documentNumber: "",
        expiryData: "",
        provinceOfInsurance: "",
      },
      metaInformation: {
        dateOfBirth: "",
        familyStatus: "",
        gender: "",
        disabled: "",
        smoker: "",
        hsaClassType: "",
        hsaStartDate: "",
        officeLocation: "",
        officeCostCenter: "",
        termsConditions: "",
        dependantType: "",
        provincialHealthInfo: {
          coveredPHP: "",
          coreHealthInsuranceCoverage: "",
          insuranceCompany: {
            name: "",
            policyNumber: "",
          },
        },
        studentEligibility: {
          schoolAttendanceName: "",
          schoolStartDate: "",
          schoolEndDate: "",
        },
      },
    },
    business: {
      businessLegalName: "",
      businessOperatedName: "",
      businessShortName: "",
      businessDbaLicNumber: "",
      numberOfBrokers: "",
      isAddressSameAsMailing: false,
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      businessGenericEmail: "",
      businessTelephoneNumber: "",
      businessTelephoneNumberExtension: "",
      businessPreferredLanguage: "en",
      logo: "",
      institutionType: "thirdPartyAdministratorPayor",
      businessType: "",
      corporateNumber: "",
      businessNumber: "",
      provinceRegisteredOffice: "",
      programDefinedVariables: {
        termYear: "",
        programStartDate: "",
        programEndDate: "",
        revenueShare: [
          {
            name: "administrationFee",
            percentage: "",
            type: "percentage",
          },
          {
            name: "interchange",
            percentage: "",
            type: "money",
          },
          {
            name: "programFees",
            percentage: "",
            type: "number",
          },
        ],
        paymentTerms: "",
        brokerageCommitment: {
          numberOfBusinesses: "",
          adoptionTargets: "",
          averageNumberEmployeesPerBusiness: "",
          averageSpendPerEmployeePerYear: "",
          averageSpendPerEmployeePerMonth: "",
          averageNumberTransactionsPerMonthPerEmployee: "",
          numberOfHsaTransactions: "",
          hsaSpend: "",
        },
        fees: {
          setupFee: "",
          employeeMonthlyFee: "",
          earlyTerminationFee: "",
          administrationFee: "",
        },
        billables: {
          preAuthorizedDebitFormReceived: "",
          invoicingPeriod: "",
          distributionMethod: "",
        },
        employeeSelectionClassType: [
          {
            name: "common",
            value: "",
          },
          {
            name: "preferred",
            value: "",
          },
          {
            name: "executive",
            value: "",
          },
          {
            name: "manual",
            value: "",
          },
        ],
        allowDependants: "",
        claimRules: {
          intervalSubmissionTransactionReceipts: "",
          claimViolationRule: "",
        },
      },
      tpaInformation: {
        type: [""],
        tpaBusinessId: "",
      },
    },
    bankAccountDetails: {
      bankName: "",
      bankBranchCity: "",
      bankBranchProvince: "",
      bankBranchPostalCode: "",
      bankBranchStreetOne: "",
      bankBranchStreetTwo: "",
      country: "CA",
      bankAccountDesignation: "checking",
      bankBranchNumber: "",
      bankNumber: "",
      bankAccountNumber: "",
      bankAccountType: "business",
      submissionPreAuthorizedDebitForm: "false",
      agreeTermsConditions: "false",
    },
    referral: {
      id: "",
      type: "business",
    },
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_TPA_SR":
      return { ...state, data: { ...state.data, ...action.data } };
    case "UPDATE_TPA_SRS":
      return { ...state, tpaSrs: { ...action.data } };
    case "UPDATE_FULL_TPA_SRS":
      return { ...state, tpaSrsFull: { ...action.data } };
    case "CLEAR_TPA_SR":
      return { ...state, data: INITIAL_STATE.data };
    default:
      return state;
  }
};