// Type
import { Action } from "./types";

/**
 * @param {*} params
 * @param {*} history
 * @param {boolean} [isSetNewPassword=false]
 * @return {*}  {Action}
 */
export const signIn = (
  params: any,
  history?: any,
  isSetNewPassword: boolean = false
): Action => ({
  type: "SIGN_IN",
  data: {
    eventName: "SIGN_IN",
    params,
  },
  history,
  isSetNewPassword,
});

/**
 * @param {*} params
 * @param {*} history
 * @return {*}  {Action}
 */
export const renewVerificationCode = (params: any, history: any): Action => ({
  type: "RENEW_VERIFICATION_CODE",
  data: {
    eventName: "RENEW_VERIFICATION_CODE",
    params,
  },
  history,
});

/**
 * @param {*} params
 * @param {*} history
 * @return {*}  {Action}
 */
export const respondToAuthChallenge = (params: any, history: any): Action => ({
  type: "RESPOND_TO_AUTH_CHALLENGE",
  data: {
    eventName: "RESPOND_TO_AUTH_CHALLENGE",
    params,
  },
  history,
});

/**
 * @param {*} params
 * @param {*} history
 * @param {*} authTokens
 * @return {*}  {Action}
 */
export const changePassword = (
  params: any,
  history?: any,
  authTokens?: any
): Action => ({
  type: "CHANGE_PASSWORD",
  data: {
    eventName: "CHANGE_PASSWORD",
    params,
  },
  history,
  authTokens,
});

/**
 * @param {*} params
 * @param {*} history
 * @param {*} authTokens
 * @return {*}  {Action}
 */
export const changeRole = (
  params: any,
  history: any,
  authTokens: any
): Action => ({
  type: "CHANGE_ROLE",
  data: {
    eventName: "SELECT_ROLE",
    params,
  },
  history,
  authTokens,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const setRole = (data: any): Action => ({
  type: "SET_ROLE",
  data,
});

/**
 * @param {*} params
 * @param {*} history
 * @return {*}  {Action}
 */
export const forgotPassword = (params: any, history?: any): Action => ({
  type: "FORGOT_PASSWORD",
  data: {
    eventName: "FORGOT_PASSWORD",
    params,
  },
  history,
});

/**
 * @param {*} params
 * @param {*} history
 * @return {*}  {Action}
 */
export const confirmForgotPassword = (params: any, history?: any): Action => ({
  type: "CONFIRM_FORGOT_PASSWORD",
  data: {
    eventName: "CONFIRM_FORGOT_PASSWORD",
    params,
  },
  history,
});

/**
 * @param {*} params
 * @param {*} history
 * @param {*} authTokens
 * @return {*}  {Action}
 */
export const signout = (
  params: any,
  history: any,
  authTokens: any
): Action => ({
  type: "SIGNOUT",
  data: {
    eventName: "SIGNOUT",
    params,
  },
  history,
  authTokens,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateAuth = (data: any): Action => ({
  type: "UPDATE_AUTH",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearAuth = (): Action => ({
  type: "SIGN_OUT",
});

/**
 * @param {any} payload
 * @return {*}  {Action}
 */
export const authError = (payload?: any): Action => ({
  type: "AUTH_ERROR",
  payload,
});

/**
 * @param {any} payload
 * @return {*}  {Action}
 */
export const changeSubView = (payload?: any): Action => ({
  type: "CHANGE_SUB_VIEW",
  payload,
});

/**
 * @param {any} payload
 * @return {*}  {Action}
 */
export const checkEmail = (data?: any): Action => ({
  type: "CHECK_EMAIL",
  data,
});

/**
 * @param {any} payload
 * @return {*}  {Action}
 */
export const updatePreAuth = (data?: any): Action => ({
  type: "UPDATE_PREAUTH",
  data,
});

export const resendVerification = (data?: any): Action => ({
  type: "RESEND_VERIFICATION",
  data,
});

export const confirmVerification = (data?: any): Action => ({
  type: "CONFIRM_VERIFICATION",
  data,
});

export const setNewPassword = (data?: any): Action => ({
  type: "SET_NEW_PASSWORD",
  data,
});
export const setNewEmail = (payload?: any): Action => ({
  type: "SET_NEW_EMAIL",
  payload,
});

/**
 * @return {*}  {Action}
 */
export const fetchTerminateUser = (): Action => ({
  type: "FETCH_TERMINATE_USER",
});

/**
 * @param {*} payload
 * @return {*}  {Action}
 */
export const storeTerminateUser = (payload: any): Action => ({
  type: "STORE_TERMINATE_USER",
  payload,
});

export const confirmForgotPasswordVerification = (data?: any): Action => ({
  type: "CONFIRM_FORGOT_PASSWORD_VERIFICATION",
  data,
});

export const triggerSelfEnrolmentVerificationCode = (data?: any): Action => ({
  type: "TRIGGER_SELF_ENROLMENT_VERIFICATION_CODE",
  data,
});
