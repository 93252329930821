// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchDependents = (): Action => ({
  type: "FETCH_DEPENDENTS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createDependent = (data: any): Action => ({
  type: "CREATE_DEPENDENT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editDependent = (data: any): Action => ({
  type: "EDIT_DEPENDENT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateDependent = (data: any): Action => ({
  type: "UPDATE_DEPENDENTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
 export const updateFetchedDependent = (data: any): Action => ({
  type: "UPDATE_FETCHED_DEPENDENTS",
  data,
});

/**
 * @return {*}  {Action}
 */
export const getDependent = (): Action => ({
  type: "GET_DEPENDENT",
});

/**
 * @return {*}  {Action}
 */
export const clearDependent = (): Action => ({
  type: "CLEAR_DEPENDENT",
});
