// Types
import type { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchCartaReports = (): Action => ({
  type: "FETCH_CARTA_REPORTS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCartaReports = (data: any): Action => ({
  type: "UPDATE_CARTA_REPORTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchReportContents = (data: any): Action => ({
  type: "FETCH_REPORT_CONTENTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateReportContents = (data: any): Action => ({
  type: "UPDATE_REPORT_CONTENTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchReportDetails = (data: any): Action => ({
  type: "FETCH_REPORT_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchCarta04 = (data: any): Action => ({
  type: "FETCH_CARTA_04",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCarta04 = (data: any): Action => ({
  type: "UPDATE_CARTA_04",
  data,
});