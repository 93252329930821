// Npm modules
import { FC, ReactElement, memo } from "react";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
// Shared components

// Selectors
import styled from "styled-components";
import ArrowIconRight from "../icons/ArrorIconRight";
import ArrowIconGradientRight from "../icons/ArrowIconRightGradient";
import { getWhiteLabel } from "../../../selectors";
import GradientText from "../GradientText/GradientText";
import { COLOR_SCHEMES } from "../../../styles/colors/colors";

/**
 * @interface IGradientButton
 */
interface IGradientButton {
  handleClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean | undefined;
  width?: string;
  text: string;
  fontSize?: string;
  name?: string;
}

/**
 * @param {*} { handleClick, isLoading, disabled }
 * @return {*}  {ReactElement}
 */
const GradientTextButton: FC<IGradientButton> = ({
  handleClick,
  isLoading,
  text,
  disabled,
  width = "10rem",
  fontSize = "1.75rem",
  name = "gradientText",
}: IGradientButton): ReactElement => {
  // constant
  const defaultPrimary = COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE;
  const defaultSecondary = COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE;
  const cursorPointer = "pointer";

  // selectors
  const [whiteLabel] = useSelector((store) => [getWhiteLabel(store)]);

  return (
    <StyledGradientButton
      onClick={(e: any) => {
        e.preventDefault();
        handleClick && !disabled && handleClick();
      }}
      primaryColor={whiteLabel?.primarycolor ?? defaultPrimary}
      secondaryColor={whiteLabel?.secondarycolor ?? defaultSecondary}
      disabled={disabled}
      width={width}
      data-testid={`${name}-button`}
    >
      {isLoading ? (
        <Loader active inline="centered" size={"small"} />
      ) : disabled ? (
        <DisabledText style={{ fontSize: fontSize }}>{text}</DisabledText>
      ) : (
        <GradientText
          string={text}
          fontSize={fontSize}
          cursor={cursorPointer}
        />
      )}
    </StyledGradientButton>
  );
};

// Default export
export default memo(GradientTextButton);

const StyledGradientButton = styled.button`
  & {
    color: #313149;
    padding: 0.75rem 2rem;
    display: inline-block;
    position: relative;
    z-index: 0;
    min-width: ${(props: any) => props.width};
    text-align: center;
    cursor: pointer;
    min-height: 3.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    /* margin: 0 auto; */
    white-space: nowrap;
    font-family: Montserrat;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 35px;
    border: solid 2px transparent;
    background-image: none,
      linear-gradient(
        to right,
        ${(props: any) => (props.disabled ? "#94A1AB" : props.primaryColor)},
        ${(props: any) => (props.disabled ? "#94A1AB" : props.secondaryColor)}
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }
  @media (max-width: 900px) {
    min-width: 100%;
  }
`;

const DisabledText = styled.div`
  color: ${COLOR_SCHEMES.PRIMARY_GRADIENT.BUTTON_DEACTIVATED};
  font-weight: 500;
  font-family: Montserrat;
`;
