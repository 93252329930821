// Npm dependencies
import { createSelector } from "reselect";

// States
const sls = (store: any) => store.slsReducer;

// Get error message
export const getSlsErrorMessage = createSelector(
  sls,
  (sls) => sls.errorMessage
);

// Get success message
export const getSlsSuccessMessage = createSelector(
  sls,
  (sls) => sls.successMessage
);

// Get row clicked
export const getSlsIsRowClicked = createSelector(
  sls,
  (sls) => sls.isRowClicked
);

// Get selected row
export const getSlsSelectedRow = createSelector(sls, (sls) => sls.selectedRow);

// Get active index
export const getSlsActiveIndex = createSelector(sls, (sls) => sls.activeIndex);

// Get pagination server
export const getSlsPaginationServer = createSelector(
  sls,
  (sls) => sls.paginationServer
);

// Get rows details
export const getSlsRowsDetails = createSelector(
  (store: any) => store.slsReducer.rowsDetails.loading,
  (store: any) => store.slsReducer.rowsDetails.limit,
  (store: any) => store.slsReducer.rowsDetails.offset,
  (store: any) => store.slsReducer.rowsDetails.search,
  (store: any) => store.slsReducer.rowsDetails.rowsPerPage,
  (store: any) => store.slsReducer.rowsDetails.rows,
  (store: any) => store.slsReducer.rowsDetails.count,
  (loading, limit, offset, search, rowsPerPage, rows, count) => ({
    loading,
    limit,
    offset,
    search,
    rowsPerPage,
    rows,
    count,
  })
);

// Get rows by id details
export const getSlsRowsByIdDetails = createSelector(
  (store: any) => store.slsReducer.rowsByIdDetails.loading,
  (store: any) => store.slsReducer.rowsByIdDetails.limit,
  (store: any) => store.slsReducer.rowsByIdDetails.offset,
  (store: any) => store.slsReducer.rowsByIdDetails.wallets,
  (store: any) => store.slsReducer.rowsByIdDetails.plan,
  (store: any) => store.slsReducer.rowsByIdDetails.bankInfo,
  (store: any) => store.slsReducer.rowsByIdDetails.rowsPerPage,
  (store: any) => store.slsReducer.rowsByIdDetails.rows,
  (store: any) => store.slsReducer.rowsByIdDetails.count,
  (
    loading,
    limit,
    offset,
    wallets,
    plan,
    bankInfo,
    rowsPerPage,
    rows,
    count
  ) => ({
    loading,
    limit,
    offset,
    wallets,
    plan,
    bankInfo,
    rowsPerPage,
    rows,
    count,
  })
);

// Get edit rows
export const getSlsEditRows = createSelector(
  (store: any) => store.slsReducer.editRow.loading,
  (loading) => ({ loading })
);
