import TermsOfService from './views/termsOfService';

type titleOptions = {
    [key: string]: string
};

export const getTitle: titleOptions = {
    keyof: 'any',
    'terms-of-service': 'Terms of Services', 
    'pre-authorized-debit': 'Authorization Debit Agreement',
    'card-member-agreement': 'Card Cardholder Agreement',
    'esign-agreement': 'E-Sign Agreement',
    'privacy-policy': 'Privacy Policy'
};
