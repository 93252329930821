import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  setLoading,
  updateCompanyStats,
  updateDashboardStats,
} from "../actions";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";

//fetch General stats
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch Dashboard Stats
 * @return {*}  {Generator}
 */
function* fetchDashboardStatsWorker(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path:
      _.get(selectedAuthReducer, "userClaims.userRole") === "superAdmin"
        ? `reports/?type=dashboardStats&at=${selectedAuthReducer.accessToken}`
        : `reports/${selectedAuthReducer.userClaims.businessGuid}?type=dashboardStats&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      // return yield put(
      //   updateDashboardStats(JSON.parse(jsonResponse.data.fnResult))
      // );
    }
  } catch (e) {
    console.log("error", e);
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Fetch PlanAdmin (Company) Stats
 * @return {*}  {Generator}
 */
function* fetchCompanyStatsWorker(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `reports/?type=planadminstats&at=${selectedAuthReducer.accessToken}`;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateCompanyStats(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    console.log("error", e);
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchDashboardStatsWatcher(): Generator {
  yield takeLatest("FETCH_DASHBOARD_STATS", fetchDashboardStatsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchCompanyStatsWatcher(): Generator {
  yield takeLatest("FETCH_COMPANY_STATS", fetchCompanyStatsWorker);
}

export { fetchDashboardStatsWatcher, fetchCompanyStatsWatcher };
