// Reselect
import { createSelector } from "reselect";

// States
const general = (state: any) => state.generalReducer;

// Menu
export const getIsMenuOpen = createSelector(
  general,
  (general) => general.isMenuOpen
);

// Page loading
export const getPageLoading = createSelector(
  general,
  (general) => general.pageLoading
);

// Loading
export const getLoading = createSelector(general, (general) => general.loading);

export const getCraLoading = createSelector(
  general,
  (general) => general.craLoading
);

export const getPinVerificationCodeLoading = createSelector(
  general,
  (general) => general.pinVerificationCodeLoading
);

// Next action
export const getNextAction = createSelector(
  general,
  (general) => general.nextAction
);

// Error message
export const getErrorMessage = createSelector(
  general,
  (general) => general.errorMessage
);

// Success message
export const getSuccessMessage = createSelector(
  general,
  (general) => general.successMessage
);

// Email confirmed
export const getEmailConfirmed = createSelector(
  general,
  (general) => general.emailConfirmed
);

// Claim Submitted Successfully
export const getClaimSubmitted = createSelector(
  general,
  (general) => general.claimSubmittedSuccessfully
);
