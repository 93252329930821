import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { COLOR_SCHEMES } from "../../../../styles/colors/colors";
import {
  getLoading,
  getPersonGuid,
  getWhiteLabel,
} from "../../../../selectors";
import { triggerAccountDeletionVerificationCode } from "../../../../actions";
import GradientText from "../../../shared/GradientText/GradientText";
import moment from "moment";
import { ImgHeader, Container } from "../accountDeletion.styled.components";

const styleClasses = {
  container: {
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "0 30px",
  },
  imageContainer: {
    // background: "linear-gradient(45deg,  #606AC1, #A131C7, #E300B1)",
    background: `linear-gradient(45deg,  
      ${COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE}, 
      ${COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE_SECONDARY}, 
      ${COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE})`,
    width: "72px",
    height: "72px",
    padding: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

interface IDeletionInProgressContent {
  // proceed: () => void;
}

const DeletionInProgressContent = (props: IDeletionInProgressContent) => {
  const [isLoading, personGuid, whiteLabel, deletionDate] = useSelector(
    (state: any) => [
      getLoading(state),
      getPersonGuid(state),
      getWhiteLabel(state),
      state?.employeeReducer?.accountDeletion?.deletionDate,
    ]
  );
  const dispatch = useDispatch();

  // const onProceedHandler = () => {
  //   dispatch(triggerAccountDeletionVerificationCode({ personGuid }));
  // };

  return (
    <Container>
      <div style={styleClasses.container}>
        <ImgHeader>
          <div style={styleClasses.imageContainer}>
            <FontAwesomeIcon
              icon={faUserClock}
              style={{ padding: 10, color: `${COLOR_SCHEMES.WHITE}` }}
              size="3x"
            />
          </div>
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bolder",
              marginTop: 15,
            }}
          >
            Deletion Scheduled!
          </h3>
        </ImgHeader>

        <p>
          Just a heads up that your account deletion is scheduled for <br />
          <GradientText
            string={moment.utc(deletionDate).format("LL")}
            fontSize={"1.15rem"}
            extraStyles={{ display: "inline-block" }}
          />{" "}
        </p>
        <p>
          We'd hate to see you go, but the choice is yours! If you'd like to
          keep your account, just click on{" "}
          <GradientText
            string={"Cancel Deletion"}
            fontSize={"1.15rem"}
            extraStyles={{ display: "inline-block" }}
          />{" "}
          and you're good to go
        </p>
      </div>
    </Container>
  );
};

export default DeletionInProgressContent;
