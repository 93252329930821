// Types
import { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const filterApprovedUnapproved = (data: any): Action => ({
  type: "FILTER_APPROVED_UNAPPROVED",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchInvoices = (): Action => ({
  type: "FETCH_INVOICES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateInvoiceList = (data: any): Action => ({
  type: "UPDATE_INVOICE_LIST",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchInvoiceDetails = (data: any): Action => ({
  type: "FETCH_INVOICE_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const sendInvoice = (data: any): Action => ({
  type: "SEND_INVOICE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createJournal = (data: any): Action => ({
  type: "CREATE_JOURNAL",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateInvoiceSearch = (data: any): Action => ({
  type: "UPDATE_INVOICE_SEARCH",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearInvoiceSearch = (): Action => ({
  type: "CLEAR_INVOICE_SEARCH",
});
