// React
import { FC, ReactElement } from "react";
// Route
import { Route } from "react-router-dom";
// Interface
import { IRoute } from "./routes.interface";

/**
 * @param {IRoute} {
 *   children,
 *   layout,
 *   layoutProps,
 *   ...rest
 * }
 * @return {*}  {ReactElement}
 */
export const PublicRoute: FC<IRoute> = ({
  children,
  layout,
  layoutProps,
  ...rest
}: IRoute): ReactElement => {
  const Layout: any = layout;
  return (
    <Route
      {...rest}
      render={() =>
        Layout ? (
          <Layout layoutProps={layoutProps}>{children}</Layout>
        ) : (
          children
        )
      }
    />
  );
};
