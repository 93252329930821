// Types
import data from "../components/dashboard/users/data";
import type { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const setLoading = (data: any): Action => ({
  type: "LOADING",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchAgreements = (data: any): Action => ({
  type: "FETCH_AGREEMENTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateAgreements = (data: any): Action => ({
  type: "UPDATE_AGREEMENTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchReports = (data: any): Action => ({
  type: "FETCH_DASHBOARD_STATS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateReports = (data: any): Action => ({
  type: "UPDATE_STATS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const setErrors = (data: any): Action => ({
  type: "ERRORS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const clearGeneral = (data?: any): Action => ({
  type: "CLEAR_GENERAL",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const setModal = (data: any): Action => ({
  type: "SET_MODAL",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const isMenuOpen = (data?: any): Action => ({
  type: "IS_MENU_OPEN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const craLoading = (data?: any): Action => ({
  type: "CRA_LOADING",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const setPinVerificationCodeLoading = (data?: any): Action => ({
  type: "PIN_VERIFICATION_CODE_LOADING",
  data,
});
