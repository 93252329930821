// Types
import { Action } from "../actions/types";
// Lodash
import { has } from "lodash";

// State type
type State = {
  loading: boolean;
  alertType: any;
  errorMessage: any;
  successMessage: any;
  infoMessage: any;
  largeHeader: any;
  largeSubHeader: any;
  imageUploading: any;
  pageLoading: any;
  claimsLoading: any;
  reviewLastPageEnrolment: boolean;
  claimGuids: Array<any>;
  claimSubmittedSuccessfully: boolean;
  failedToSubmitClaim: boolean;
  isMenuBarOpen: boolean;
  agreements: any;
  isMenuOpen: Boolean;
  nextAction: string;
  emailConfirmed: boolean;
  claimSubmitting: boolean;
  craLoading: boolean;
  pinVerificationCodeLoading: boolean;
};

// Initial state
const INITIAL_STATE: State = {
  loading: false,
  alertType: "",
  errorMessage: null,
  successMessage: null,
  infoMessage: null,
  largeHeader: null,
  largeSubHeader: null,
  imageUploading: null,
  pageLoading: null,
  claimsLoading: false,
  reviewLastPageEnrolment: false,
  claimGuids: [],
  claimSubmittedSuccessfully: false,
  failedToSubmitClaim: false,
  isMenuBarOpen: true,
  agreements: "",
  isMenuOpen: true,
  nextAction: "",
  emailConfirmed: false,
  claimSubmitting: false,
  craLoading: false,
  pinVerificationCodeLoading: false,
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "LOADING":
      return { ...state, ...action.data };
    case "UPDATE_AGREEMENTS":
      return { ...state, agreements: { ...action.data } };
    case "ERRORS":
      return { ...state, ...action.data };
    case "CLEAR_GENERAL":
      return INITIAL_STATE;
    case "SET_MODAL":
      return { ...state, ...action.data };
    case "IS_MENU_OPEN":
      const { data }: any = action;
      return {
        ...state,
        isMenuOpen: has(data, "override") ? data.override : !state.isMenuOpen,
      };
    case "CRA_LOADING":
      return { ...state, ...action.data };
    case "PIN_VERIFICATION_CODE_LOADING":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
