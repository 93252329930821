import {
  IPcoDataList,
  IPcoPayloadObject,
  IPcoObject,
} from "./pcoEnrolment-interfaces";
import { ENROLMENT_STATUSES } from "./planAdminPcoEnrolment.constants";

export const uploadedFileMapper = (data: any): IPcoDataList[] => {
  let mappedData: IPcoDataList[] = [];
  data.forEach((row: any) => {
    //This order should be maintaied as per the order of the data in the excel sheet
    let i = 2;

    const firstName = row[i++]?.toString() ?? "";
    const lastName = row[i++]?.toString() ?? "";
    const email = row[i++]?.toString() ?? "";
    const dateOfBirth = row[i++]?.toString() ?? "";
    const dateOfHire = row[i++]?.toString() ?? "";
    const firstDayToSubmitClaims = row[i++]?.toString() ?? "";
    const familyStatus = row[i++]?.toString() ?? "";
    const className = row[i++]?.toString() ?? "";
    const addressLine1 = row[i++]?.toString() ?? "";
    const addressLine2 = row[i++]?.toString() ?? "";
    const city = row[i++]?.toString() ?? "";
    const province = row[i++]?.toString() ?? "";
    const postalCode = row[i++]?.toString() ?? "";
    // const flexYearlyContributionAmount = row[i++] ?? 0;
    // const hsaYearlyContributionAmount = row[i++] ?? 0;
    // const wsaYearlyContributionAmount = row[i++] ?? 0;
    const spouseFirstName = row[i++]?.toString() ?? "";
    const spouseLastName = row[i++]?.toString() ?? "";
    const spouseDateOfBirth = row[i++]?.toString() ?? "";

    let spouse = null;
    if (
      spouseFirstName !== "" ||
      spouseLastName !== "" ||
      spouseDateOfBirth !== ""
    ) {
      spouse = {
        firstName: spouseFirstName ?? "",
        lastName: spouseLastName ?? "",
        dateOfBirth: spouseDateOfBirth ?? "",
      };
    }

    let children = [];
    let k = 18;
    while (row[k] !== undefined) {
      children.push({
        firstName: row[k++]?.toString() ?? "",
        lastName: row[k++]?.toString() ?? "",
        dateOfBirth: row[k++]?.toString() ?? "",
        isDisabled: row[k++]?.toLowerCase() === "yes" ? true : false,
        isFullTimeStudent: row[k++]?.toLowerCase() === "yes" ? true : false,
      });
    }

    children.map((child: any) => {
      if (child.isDisabled === false) {
        if (
          child.firstName === "" &&
          child.lastName === "" &&
          child.dateOfBirth === ""
        ) {
          child.isDisabled = "";
        }
      }
      if (child.isFullTimeStudent === false) {
        if (
          child.firstName === "" &&
          child.lastName === "" &&
          child.dateOfBirth === ""
        ) {
          child.isFullTimeStudent = "";
        }
      }
    });

    const nonEmptyChildren: any = children.filter((child: any) => {
      const values = Object.values(child);
      const isEmpty = values.every((value) => value === "");
      return !isEmpty;
    });

    mappedData.push({
      payload: {
        firstName,
        lastName,
        email,
        dateOfBirth,
        dateOfHire,
        firstDayToSubmitClaims: firstDayToSubmitClaims,
        familyStatus,
        className,
        address: {
          addressLine1,
          addressLine2,
          city,
          province,
          postalCode,
          country: "CA",
        },
        // contributions: {
        //   hsa: {
        //     yearlyContributionAmount:
        //       hsaYearlyContributionAmount !== ""
        //         ? hsaYearlyContributionAmount
        //         : 0,
        //   },
        //   wsa: {
        //     yearlyContributionAmount:
        //       wsaYearlyContributionAmount !== ""
        //         ? wsaYearlyContributionAmount
        //         : 0,
        //   },
        //   flex: {
        //     yearlyContributionAmount:
        //       flexYearlyContributionAmount !== ""
        //         ? flexYearlyContributionAmount
        //         : 0,
        //   },
        // },
        spouse,
        children: nonEmptyChildren,
      },
      status: ENROLMENT_STATUSES.notEnroled.key,
      email,
      errors: [],
    });
  });

  return mappedData;
};

export const validationRules: any = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  dateOfBirth: {
    required: true,
    format: "YYYY-MM-DD",
  },
  dateOfHire: {
    required: true,
    format: "YYYY-MM-DD",
  },
  email: {
    required: true,
    email: true,
  },
  familyStatus: {
    required: true,
    range: ["Single", "Single+1", "Single+2"],
  },
  className: {
    required: true,
  },
  firstDayToSubmitClaims: {
    format: "YYYY-MM-DD",
    required: true,
  },
  address: {
    required: true,
    address: {
      addressLine1: {
        required: true,
      },
      addressLine2: {},
      city: {
        required: true,
      },
      province: {
        required: true,
      },
      postalCode: {
        required: true,
        postalCode: true,
        noSpaces: true,
        location: "CA",
      },
    },
  },
  spouse: {
    spouseDetails: {
      firstName: {
        requiredIfAnyOtherInfoPresent: ["lastName", "dateOfBirth"],
      },
      lastName: {
        requiredIfAnyOtherInfoPresent: ["firstName", "dateOfBirth"],
      },
      dateOfBirth: {
        requiredIfAnyOtherInfoPresent: ["firstName", "lastName"],
        format: "YYYY-MM-DD",
      },
    },
  },
  children: {
    children: false,
    child: {
      firstName: {
        requiredIfAnyOtherInfoPresent: ["lastName", "dateOfBirth"],
      },
      lastName: {
        requiredIfAnyOtherInfoPresent: ["firstName", "dateOfBirth"],
      },
      dateOfBirth: {
        requiredIfAnyOtherInfoPresent: ["firstName", "lastName"],
        format: "YYYY-MM-DD",
      },
    },
  },
  contributions: {
    required: true,
    contribution: {
      nonZeroContributionTypesPresent: true,
      nonZeroCheckFields: ["yearlyContributionAmount"],
      custom: ["mutualExclusion"],
      hsa: {
        yearlyContributionAmount: {},
      },
      wsa: {
        yearlyContributionAmount: {},
      },
    },
  },
};

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateDate = (date: string, format: string): boolean => {
  const re = /\d{4}-\d{2}-\d{2}/;
  return re.test(date);
};

export const validatePostalCode = (
  postalCode: string,
  location: string
): boolean => {
  if (location === "CA") {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(postalCode);
  }
  return false;
};

export const keyToStringMapper = (key: string): string => {
  switch (key) {
    case "firstName":
      return "First Name";
    case "lastName":
      return "Last Name";
    case "dateOfBirth":
      return "Date of Birth";
    case "dateOfHire":
      return "Hire Date";
    case "addressLine1":
      return "Address";
    case "addressLine2":
      return "Address";
    case "city":
      return "City";
    case "province":
      return "Province";
    case "postalCode":
      return "Postal Code";
    case "email":
      return "Email";
    case "familyStatus":
      return "Single\nSingle+1\nSingle+2";
    case "className":
      return "Benefit Class Name";
    // case "yearlyContributionAmount":
    //   return "Yearly Contribution Amount";
    case "firstSubmissionClaimDate":
      return "First Claim Submission Date";
    case "hsaCurrentYear":
      return "HSA Current Year";
    case "wsaCurrentYear":
      return "WSA Current Year";
    case "hsaCFYear1":
      return "HSA Carried Forward (Year1)";
    case "wsaCFYear1":
      return "WSA Carried Forward (Year1)";
    case "spouseFirstName":
      return "First Name";
    case "spouseLastName":
      return "Spouse Last Name";
    case "spouseEmail":
      return "Spouse Email";
    case "spouseDateOfBirth":
      return "Spouse Date of Birth";
    case "dependent":
      return "Dependent";
    case "hsa":
      return "HSA";
    case "wsa":
      return "WSA";
    default:
      return key;
  }
};

export const validateColumns = (data: IPcoDataList[]): string[] => {
  let errors: string[] = [];

  if (data.length === 0) {
    errors.push("No data found in the file");
    return errors;
  }

  let rowIndex = 3;
  data.forEach((row, index) => {
    rowIndex = rowIndex + 1;
    const keys = Object.keys(row.payload);
    keys.forEach((key: string) => {
      if (validationRules[key]) {
        if (validationRules[key].required) {
          if (row.payload[key] === "") {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(key)} is required`
            );
          }
        }
        if (validationRules[key].maxLength) {
          if (row.payload[key].length > validationRules[key].maxLength) {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(key)} should not exceed ${
                validationRules[key].maxLength
              } characters`
            );
          }
        }
        if (validationRules[key].email) {
          if (!validateEmail(row.payload[key])) {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(key)} is not a valid email`
            );
          }
        }
        if (validationRules[key].format) {
          if (!validateDate(row.payload[key], validationRules[key].format)) {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(key)} should be in ${
                validationRules[key].format
              }`
            );
          }
        }
        if (validationRules[key].range) {
          if (!validationRules[key].range.includes(row.payload[key])) {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(
                key
              )} should be one of ${validationRules[key].range.join(", ")}`
            );
          }
        }
        if (validationRules[key].requiredIfAnyOtherInfoPresent) {
          const requiredKeys =
            validationRules[key].requiredIfAnyOtherInfoPresent;
          let isAnyOtherInfoPresent = false;
          requiredKeys.forEach((requiredKey: string) => {
            if (row.payload[requiredKey] !== "") {
              isAnyOtherInfoPresent = true;
            }
          });
          if (isAnyOtherInfoPresent && row.payload[key] === "") {
            errors.push(
              `Row ${rowIndex}: ${keyToStringMapper(key)} is required`
            );
          }
        }

        if (validationRules[key].spouseDetails) {
          const spouseKeys = Object.keys(row.payload.spouse ?? []);
          spouseKeys?.forEach((spouseKey: string) => {
            if (validationRules[key].spouseDetails[spouseKey]) {
              if (validationRules[key].spouseDetails[spouseKey].required) {
                if (row.payload.spouse[spouseKey] === "") {
                  errors.push(
                    `Row ${rowIndex}: Spouse: ${keyToStringMapper(
                      spouseKey
                    )} is required`
                  );
                }
              }
              if (
                validationRules[key].spouseDetails[spouseKey].maxLength &&
                row.payload.spouse[spouseKey].length >
                  validationRules[key].spouseDetails[spouseKey].maxLength
              ) {
                errors.push(
                  `Row ${rowIndex}: Spouse: ${keyToStringMapper(
                    spouseKey
                  )} should not exceed ${
                    validationRules[key].spouseDetails[spouseKey].maxLength
                  } characters`
                );
              }
              if (
                validationRules[key].spouseDetails[spouseKey].format &&
                !validateDate(
                  row.payload.spouse[spouseKey],
                  validationRules[key].spouseDetails[spouseKey].format
                )
              ) {
                errors.push(
                  `Row ${rowIndex}: Spouse: ${keyToStringMapper(
                    spouseKey
                  )} should be in ${
                    validationRules[key].spouseDetails[spouseKey].format
                  }`
                );
              }
              if (
                validationRules[key].spouseDetails[spouseKey]
                  .requiredIfAnyOtherInfoPresent
              ) {
                const requiredKeys =
                  validationRules[key].spouseDetails[spouseKey]
                    .requiredIfAnyOtherInfoPresent;
                let isAnyOtherInfoPresent = false;
                requiredKeys.forEach((requiredKey: string) => {
                  if (row.payload.spouse[requiredKey] !== "") {
                    isAnyOtherInfoPresent = true;
                  }
                });
                if (
                  isAnyOtherInfoPresent &&
                  row.payload.spouse[spouseKey] === ""
                ) {
                  errors.push(
                    `Row ${rowIndex}: Spouse: ${keyToStringMapper(
                      spouseKey
                    )} is required`
                  );
                }
              }
            }
          });
        }

        if (validationRules[key].address) {
          const addressKeys = Object.keys(row.payload.address);
          addressKeys.forEach((addressKey: string) => {
            if (validationRules[key].address[addressKey]) {
              if (validationRules[key].address[addressKey].required) {
                if (row.payload.address[addressKey] === "") {
                  errors.push(
                    `Row ${rowIndex}: ${keyToStringMapper(
                      addressKey
                    )} of address is required`
                  );
                }
              }
              if (
                validationRules[key].address[addressKey].maxLength &&
                row.payload.address[addressKey].length >
                  validationRules[key].address[addressKey].maxLength
              ) {
                errors.push(
                  `Row ${rowIndex}: ${keyToStringMapper(
                    addressKey
                  )} should not exceed ${
                    validationRules[key].address[addressKey].maxLength
                  } characters`
                );
              }
              if (
                validationRules[key].address[addressKey].postalCode &&
                !validatePostalCode(
                  row.payload.address[addressKey],
                  validationRules[key].address[addressKey].location
                )
              ) {
                errors.push(
                  `Row ${rowIndex}: ${keyToStringMapper(
                    addressKey
                  )} of address is not a valid postal code. It should be in the format XXXXXX`
                );
              }
            }
          });
        }

        if (validationRules[key].contribution) {
          const contributionKeys = Object.keys(row.payload.contributions);

          if (
            validationRules[key].contribution
              ?.nonZeroContributionTypesPresent &&
            validationRules[key].contribution?.nonZeroCheckFields?.length > 0
          ) {
            let contributionSumMap: any = {};

            validationRules[key].contribution.nonZeroCheckFields.forEach(
              (contributionTypeSumKey: string) => {
                contributionSumMap[contributionTypeSumKey] = 0;

                contributionKeys.forEach((contributionKey: string) => {
                  let contributionTypeKeys = Object.keys(
                    row.payload.contributions[contributionKey]
                  );

                  contributionTypeKeys.forEach(
                    (contributionTypeKey: string) => {
                      if (contributionTypeKey === contributionTypeSumKey) {
                        contributionSumMap[contributionTypeSumKey] +=
                          row.payload.contributions[contributionKey][
                            contributionTypeKey
                          ];
                      }
                    }
                  );
                });

                if (contributionSumMap[contributionTypeSumKey] === 0) {
                  errors.push(
                    `Row ${rowIndex}: The total ${keyToStringMapper(
                      contributionTypeSumKey
                    )} for a user cannot be 0`
                  );
                }
              }
            );
          }

          if (
            validationRules[key].contribution?.custom &&
            validationRules[key].contribution?.custom?.length > 0
          ) {
            if (
              validationRules[key].contribution.custom.includes(
                "mutualExclusion"
              )
            ) {
              let mutualExclusionKeys: string[] = [];

              contributionKeys.forEach((contributionKey: string) => {
                const contributionTypeKeys = Object.keys(
                  row.payload.contributions[contributionKey]
                );

                contributionTypeKeys.forEach((contributionTypeKey: string) => {
                  if (
                    row.payload.contributions[contributionKey][
                      contributionTypeKey
                    ] > 0
                  ) {
                    mutualExclusionKeys.push(contributionKey);
                  }
                });
              });

              if (
                (mutualExclusionKeys.indexOf("hsa") > -1 ||
                  mutualExclusionKeys.indexOf("wsa") > -1) &&
                mutualExclusionKeys.indexOf("flex") > -1
              ) {
                errors.push(
                  `Row ${rowIndex}: You must choose only one contribution type: Flexible Balance or a combination of HSA and WSA Balances.`
                );
              }
            }
          }

          contributionKeys.forEach((contributionKey: string) => {
            if (validationRules[key].contribution[contributionKey]) {
              const contributionTypeKeys = Object.keys(
                row.payload.contributions[contributionKey]
              );

              contributionTypeKeys.forEach((contributionTypeKey: string) => {
                if (
                  validationRules[key].contribution[contributionKey][
                    contributionTypeKey
                  ].required
                ) {
                  if (
                    row.payload.contributions[contributionKey][
                      contributionTypeKey
                    ] === 0
                  ) {
                    errors.push(
                      `Row ${rowIndex}: ${keyToStringMapper(
                        contributionTypeKey
                      )} for ${keyToStringMapper(contributionKey)} is required`
                    );
                  }
                }
              });
            }
          });
        }

        if (validationRules[key].child) {
          let index = 1;

          row.payload.children.forEach((child) => {
            const childKeys = Object.keys(child);
            childKeys.forEach((childKey: string) => {
              if (validationRules[key].child[childKey]) {
                if (validationRules[key].child[childKey].required) {
                  if (child[childKey] === "") {
                    errors.push(
                      `Row ${rowIndex}: Dependent-Child ${index}: ${keyToStringMapper(
                        childKey
                      )} is required`
                    );
                  }
                }
                if (validationRules[key].child[childKey].maxLength) {
                  if (
                    child[childKey].length >
                    validationRules[key].child[childKey].maxLength
                  ) {
                    errors.push(
                      `Row ${rowIndex}: Dependent-Child ${index}: ${keyToStringMapper(
                        childKey
                      )} should not exceed ${
                        validationRules[key].child[childKey].maxLength
                      } characters`
                    );
                  }
                }
                if (validationRules[key].child[childKey].email) {
                  if (!validateEmail(child[childKey])) {
                    errors.push(
                      `Row ${rowIndex}: Dependent-Child ${index}: ${keyToStringMapper(
                        childKey
                      )} is not a valid email`
                    );
                  }
                }
                if (
                  child[childKey] &&
                  validationRules[key].child[childKey].format
                ) {
                  if (
                    !validateDate(
                      child[childKey],
                      validationRules[key].child[childKey].format
                    )
                  ) {
                    errors.push(
                      `Row ${rowIndex}: Dependent-Child ${index}: ${keyToStringMapper(
                        childKey
                      )} should be in ${validationRules[childKey].format}`
                    );
                  }
                }
                if (
                  validationRules[key].child[childKey]
                    .requiredIfAnyOtherInfoPresent
                ) {
                  const requiredKeys =
                    validationRules[key].child[childKey]
                      .requiredIfAnyOtherInfoPresent;
                  let isAnyOtherInfoPresent = false;
                  requiredKeys.forEach((requiredKey: string) => {
                    if (child[requiredKey] !== "") {
                      isAnyOtherInfoPresent = true;
                    }
                  });
                  if (isAnyOtherInfoPresent && child[childKey] === "") {
                    errors.push(
                      `Row ${rowIndex}: Dependent-Child ${index}: ${keyToStringMapper(
                        childKey
                      )} is required`
                    );
                  }
                }
              }
            });
            index++;
          });
        }
      }
    });
  });

  return errors;
};

export const personToPayloadMapper = (data: IPcoObject): IPcoPayloadObject => {
  return {
    person: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      dateOfBirth: data.dateOfBirth,
      dateOfHire: data.dateOfHire,
      firstDayToSubmitClaims: data.firstDayToSubmitClaims,
      familyStatus: data.familyStatus,
      className: data.className,
      address: data.address,
      contributions: data.contributions,
    },
    spouse: data.spouse,
    children: data.children,
  };
};
