// Npm dependencies
import { takeLatest, call, put, all, delay, select } from "redux-saga/effects";
import _ from "lodash";
// Local dependencies
import axios from "../utilities/axiosWrapper";
import { Auth } from "../utilities/auth";
import { updateSls } from "../actions";
import { getSlsRowsByIdDetails, getSlsRowsDetails } from "../selectors";

/**
 * @param {{
 *   data: {
 *     accessToken: string;
 *   };
 * }} payload
 * @return {*}  {Generator}
 */
function* getSls(payload: {
  data: {
    accessToken: string;
    path: string;
    limit: number | string;
    offset: number | string;
    search: string;
    rowsPerPage: number | string;
    activeIndex: number;
  };
}): Generator {
  // destructuring
  const {
    data: {
      accessToken,
      path,
      limit,
      offset,
      search,
      rowsPerPage,
      activeIndex,
    },
  } = payload;
  // get the row details
  const rowDetails: any = yield select(getSlsRowsDetails);
  // start loading
  yield put(
    updateSls({ ...rowDetails, loading: true }, "UPDATE_SLS_ROWS_DETAILS")
  );
  // update index
  yield put(updateSls({ activeIndex }));
  // create param
  const params: any = {
    method: "GET",
    path: `${path}?at=${accessToken}&limit=${limit}&offset=${offset}&searchTerm=${search}`,
  };
  // api Keys
  const apiKeys: any = yield Auth.getAuthTokens();

  try {
    // call the claim api
    const slsResponse: any = yield call(axios, params, apiKeys);
    // get the response
    const getSlsResponse: any = slsResponse?.data ?? {};
    // get the status code
    const status: number = getSlsResponse?.fnStatusCode ?? 500;
    // check the status
    if (status === 200) {
      yield put(
        updateSls(
          {
            ...rowDetails,
            loading: false,
            rows: getSlsResponse?.data ?? [],
            count: getSlsResponse?.count ?? 0,
            offset,
            search,
            rowsPerPage,
          },
          "UPDATE_SLS_ROWS_DETAILS"
        )
      );
    } else {
      throw {
        message:
          getSlsResponse?.fnMessage ||
          getSlsResponse?.errorMessage ||
          "Server Error",
      };
    }
  } catch (e) {
    // stop loading
    yield put(
      updateSls({ ...rowDetails, loading: false }, "UPDATE_SLS_ROWS_DETAILS")
    );
    // add error message
    yield put(updateSls({ errorMessage: e.message }));
    yield delay(5000);
    // remove error message
    yield put(updateSls({ errorMessage: "" }));
  }
}

/**
 * @param {{
 *   data: {
 *     accessToken: string;
 *   };
 * }} payload
 * @return {*}  {Generator}
 */
function* getSlsById(payload: {
  data: {
    accessToken: string;
    path: string;
    row: any;
    rowClicked: boolean;
    limit: number;
    offset: number;
  };
}): Generator {
  // destructuring
  const {
    data: { accessToken, path, limit, offset, rowClicked, row },
  } = payload;
  // get the rows details from reducer
  const rowsByIdDetails: any = yield select(getSlsRowsByIdDetails);
  // start loading
  yield put(
    updateSls(
      { ...rowsByIdDetails, loading: true },
      "UPDATE_SLS_ROWS_BY_ID_DETAILS"
    )
  );
  // create param
  const params: any = {
    method: "GET",
    path: `${path}?at=${accessToken}&limit=${limit}&offset=${offset}`,
  };
  // api Keys
  const apiKeys: any = yield Auth.getAuthTokens();
  try {
    // call the claim api
    const slsByIdResponse: any = yield call(axios, params, apiKeys);
    // get the response
    const getSlsByIdResponse: any = slsByIdResponse?.data?.data ?? {};
    // get the status code
    const status: number = slsByIdResponse?.data?.fnStatusCode ?? 500;
    // check the status
    if (status === 200) {
      const transactions: any = getSlsByIdResponse?.transactions;
      let modifiedTransactions: any;
      if (transactions?.data && Array.isArray(transactions.data)) {
        modifiedTransactions = transactions.data.map((transaction: any) => ({
          ...transaction,
          firstName: getSlsByIdResponse?.employee?.firstName ?? "",
          lastName: getSlsByIdResponse?.employee?.lastName ?? "",
        }));
      } else {
        modifiedTransactions = [];
      }

      // if (transactions?.count ?? 0 > 0) {
      yield put(
        updateSls({
          selectedRow: row,
          isRowClicked: rowClicked,
        })
      );
      yield put(
        updateSls(
          {
            ...rowsByIdDetails,
            loading: false,
            limit,
            offset,
            rows: modifiedTransactions,
            count: transactions?.count ?? 0,
            wallets: getSlsByIdResponse?.wallets ?? {},
            plan: {
              programType: getSlsByIdResponse?.programType ?? "",
              planYearStartDate: row?.planYearStartDate ?? "",
              planYearEndDate: row?.planYearEndDate ?? "",
              ...(getSlsByIdResponse?.employee ?? {}),
              primaryDependent: getSlsByIdResponse?.primaryDependent ?? "",
              children: getSlsByIdResponse?.children ?? [],
            },
            bankInfo: getSlsByIdResponse?.bankInfo ?? {},
          },
          "UPDATE_SLS_ROWS_BY_ID_DETAILS"
        )
      );
      // } else {
      //   throw {
      //     message: "Transactions not found",
      //   };
      // }
    } else {
      throw {
        message:
          getSlsByIdResponse?.fnMessage ||
          getSlsByIdResponse?.errorMessage ||
          "Server Error",
      };
    }
  } catch (e) {
    // stop loading
    yield put(
      updateSls(
        { ...rowsByIdDetails, loading: false },
        "UPDATE_SLS_ROWS_BY_ID_DETAILS"
      )
    );
    // add error message
    yield put(updateSls({ errorMessage: e.message }));
    yield delay(5000);
    // remove error message
    yield put(updateSls({ errorMessage: "" }));
  }
}

function* editSlsById(payload: {
  data: {
    accessToken: string;
    path: string;
    payload: any;
  };
}): Generator {
  // destructuring
  const {
    data: { accessToken, path },
  } = payload;
  // get the rows
  const rowsByIdDetails: any = yield select(getSlsRowsByIdDetails);
  // start loading
  yield put(updateSls({ loading: true }, "EDIT_SLS_ROWS"));
  // create param
  const params: any = {
    method: "PUT",
    path,
    data: { ...payload.data.payload, accessToken },
  };
  // api Keys
  const apiKeys: any = yield Auth.getAuthTokens();
  try {
    // call the claim api
    const slsEditResponse: any = yield call(axios, params, apiKeys);
    // get the response
    const getSlsResponse: any = slsEditResponse?.data ?? {};
    // get the status code
    const status: number = getSlsResponse?.fnStatusCode ?? 500;
    if (status === 200) {
      yield put(updateSls({ loading: false }, "EDIT_SLS_ROWS"));
      yield put(
        updateSls(
          {
            ...rowsByIdDetails,
            rows: rowsByIdDetails.rows.map((rowById: any) =>
              rowById.referenceNumber === payload.data.payload.referenceNumber
                ? {
                    ...rowById,
                    invoice: payload.data.payload.invoice,
                    comment: payload.data.payload.comment,
                  }
                : rowById
            ),
          },
          "UPDATE_SLS_ROWS_BY_ID_DETAILS"
        )
      );
      yield put(
        updateSls({
          successMessage:
            getSlsResponse?.fnMessage ?? "Row updated successfully",
        })
      );
      yield delay(5000);
      // remove error message
      yield put(updateSls({ successMessage: "" }));
    } else {
      throw {
        message:
          getSlsResponse?.fnMessage ||
          getSlsResponse?.errorMessage ||
          "Server Error",
      };
    }
  } catch (e) {
    // stop loading
    yield put(updateSls({ loading: false }, "EDIT_SLS_ROWS"));
    // add error message
    yield put(updateSls({ errorMessage: e.message }));
    yield delay(5000);
    // remove error message
    yield put(updateSls({ errorMessage: "" }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* getSlsWatcher(): Generator {
  yield takeLatest("GET_SLS", getSls);
}

/**
 * @return {*}  {Generator}
 */
function* getSlsByIdWatcher(): Generator {
  yield takeLatest("GET_SLS_BY_ID", getSlsById);
}

/**
 * @return {*}  {Generator}
 */
function* editSlsByIdWatcher(): Generator {
  yield takeLatest("EDIT_SLS", editSlsById);
}

// export sagas
export { getSlsWatcher, getSlsByIdWatcher, editSlsByIdWatcher };
