// Types
import { Action } from "../actions/types";

// State types
type State = {
  paymentsList: Array<any>;
  searchQuery: any;
  results: Array<any>;
  bankInfo: any;
};

// Initial state
const INITIAL_STATE: State = {
  paymentsList: [],
  searchQuery: "",
  results: [],
  bankInfo: {},
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "UPDATE_SEARCH_QUERY":
      const newResults = state.paymentsList.filter((payment) => {
        const { businessName, referenceNumber } = payment;
        if (
          businessName.toLowerCase().includes(action.data.toLowerCase()) ||
          referenceNumber.toString().includes(action.data)
        ) {
          return payment;
        }
      });
      return {
        ...state,
        results: [...newResults],
      };
    case "CLEAR_SEARCH_QUERY":
      return {
        ...state,
        results: [],
      };
    case "FETCH_PAYMENTS_LIST":
      return { ...state };
    case "EDIT_BANK_INFO":
      return { ...state, bankInfo: { ...state.bankInfo, ...action.data } };
    case "FETCH_PAYMENT":
      return state;
    case "UPDATE_PAYMENTS_LIST":
      return { ...state, paymentsList: [...action.data] };
    case "POST_NEW_PAYMENT":
      const { amount, date, currency, source, reference, disperse, method } =
        action.data;

      const newPayment = {
        company: source,
        date: date,
        reference: reference,
        method: method,
        origin: "Komal",
        amount: amount,
        action: "complete",
        currency: currency,
        paymentStatus: "Refunded",
      };

      return {
        ...state,
      };
    case 'CLEAR_BANK_INFO':
      return {
        ...INITIAL_STATE
      }
    default:
      return state;
  }
};
