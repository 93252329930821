/** @type {*} */
const userRoleMapper: { [index: string]: string } = {
  superAdmin: "Super Admin",
  juniorAdmin: "Junior Admin",
  brokerageHouseAdministratorPrimary: "BH Primary Admin",
  brokerageHouseAdministrator: "BH Admin",
  broker: "BROKER",
  tpaAdministrator: "TPA",
  tpaAdministratorPrimary: "TPAP",
  tpaServiceRepresentative: "TPA_SR",
  PCO: "PCO",
  dependent: "DEPENDENT",
  planSponsorAdministrator: "PSA",
  planSponsorAdministratorPrimary: "PLAN ADMINISTRATOR",
  planSponsorContact: "PLAN CONTACT",
  finance: "FINANCE",
  claimEditor: "CLAIM EDITOR",
  externalUser: "EXTERNAL USER",
};

// Export
export default userRoleMapper;
