import { stepList } from "../../components/shared/stepper/stepList";

/**
 * @desc parse indicator
 * @param {*} data
 * @param {*} stepList
 * @return {*}  {object}
 */
const parseIndicator = (data: any, stepList: any): object => {
  const splitStep: any = data && data.split("-");
  const enrolmentType: any = splitStep && splitStep[0].toLowerCase();
  const stepObj: any =
    stepList[enrolmentType] !== undefined
      ? Object.assign(
          {},
          ...stepList[enrolmentType].filter((item: any) =>
            item.step.includes(data)
          )
        )
      : {};
  return stepObj.indicator;
};

/**
 * @desc get route
 * @param {*} data
 * @param {*} direct
 * @return {*}  {*}
 */
export const getRouteData = (data: any, direct?: any): any => {
  let step: any;
  let stepIndicator: any;
  let directIndicator: any;

  if (direct) {
    step = direct;
    directIndicator = parseIndicator(direct, stepList);
  } else {
    step =
      data.inviteState && data.inviteState.step
        ? data.inviteState.step
        : data.inviteStep;
    stepIndicator = parseIndicator(step, stepList);
  }

  switch (step) {
    case "FULL-STEP_ZERO-BHA":
      return {
        view: "STEP_ZERO",
        largeHeader: "Add a New Brokerage House",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_ONE-BHA":
      return {
        view: "STEP_ONE",
        largeHeader: "Add a New Brokerage House",
        largeSubHeader: data && data.business.businessOperatedName,
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_TWO-BHA":
      return {
        view: "STEP_TWO",
        largeHeader: "Add a New Brokerage House",
        largeSubHeader: data && data.business.businessOperatedName,
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_THREE-BHA":
      return {
        view: "STEP_THREE",
        largeHeader: "Add a New Brokerage House",
        largeSubHeader: data && data.business.businessOperatedName,
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_FOUR-BHA":
      return {
        view: "STEP_FOUR",
        largeHeader: "Add a New Brokerage House",
        largeSubHeader: data && data.business.businessOperatedName,
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_FIVE-BHA":
      return {
        view: "STEP_FIVE",
        largeHeader: "Review Brokerage House Information",
        largeSubHeader: data && data.business.businessOperatedName,
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "FULL-STEP_SIX-BHA":
      return {
        view: "STEP_SIX",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "QUICK-STEP-ZERO-BHA":
      return {
        view: "STEP_ZERO",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "QUICK-STEP_ONE-BHA":
      return {
        view: "STEP_ONE",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "QUICK-STEP_TWO-BHA":
      return {
        view: "STEP_TWO",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "QUICK-STEP_THREE-BHA":
      return {
        view: "STEP_THREE",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_TWO-BHA":
      return {
        view: "STEP_TWO",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_THREE-BHA":
      return {
        view: "STEP_THREE",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_FOUR-BHA":
      return {
        view: "STEP_FOUR",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_FIVE-BHA":
      return {
        view: "STEP_FIVE",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_SIX-BHA":
      return {
        view: "STEP_SIX",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "ONBOARDING-STEP_SEVEN-BHA":
      return {
        view: "STEP_SEVEN",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    case "COMEPLTE-STEP_SEVEN-BHA":
      return {
        view: "STEP_SEVEN",
        largeHeader: "",
        largeSubHeader: "",
        ...(data && { stepIndicator }),
        directIndicator: directIndicator,
      };
    default:
      break;
  }
};

/**
 * @desc get route
 * @param {*} data
 * @return {*}  {*}
 */
export const getRoute = (data: any): any => {
  let route: string =
    data.inviteState && data.inviteState.step
      ? data.inviteState.step.split("-")[0]
      : data.inviteStep.split("-")[0];

  switch (route) {
    case "QUICK":
      return "/brokerage-houses/add/quick";
    case "FULL":
      return "/brokerage-houses/add/full";
    case "ONBOARDING":
      return "/brokerage-houses/onboarding";
    case "COMPLETE":
      return "/dashboard";
    default:
      break;
  }
};
