import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
	editBroker,
	setLoading,
	updateBhaBrokers,
	updateFullBrokers,
} from "../actions";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/brokerNavigation";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;
//create broker
export const selectBroker = (state: any) => state.brokerReducer;

/**
 * @desc Create broker
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* createBrokerWorker(action: any): Generator {
	yield put(setLoading({ loading: true, errorMessage: null }));
	let selectedAuthReducer: any = yield select(selectAuthReducer);
	let params: any = {
		method: "get",
		path: "",
		data: {},
	};
	let apikeys: any = null;
	let invite: any = null;
	let jsonResponse: any = null;
	let parsedJsonResponse: any = null;
	try {
		apikeys = yield Auth.getAuthTokens();
		invite = yield call(axios, action.data, apikeys);
		if (
			invite?.data?.fnError?.toLowerCase().includes("email") ||
			invite?.data?.errorMessage?.toLowerCase().includes("email")
		) {
			return yield put(
				setLoading({
					loading: false,
					alertType: "error",
					errorMessage: "Email address already exists",
				})
			);
		}

		if (invite.data.fnResult) {
			invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
		}
		params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

		jsonResponse = yield call(axios, params, apikeys);
		if (jsonResponse.data.fnResult) {
			parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
		}

		yield put(editBroker(parsedJsonResponse));
		let selectedBroker: any = yield select(selectBroker);
		let route: any = getRoute(selectedBroker.data);
		let routeData: any = getRouteData(selectedBroker.data);
		yield put(setLoading({ loading: false, ...routeData }));
		// return yield history.push(route, routeData);
		return yield history.push(route);
	} catch (ex) {
		yield put(setLoading({ loading: false }));
		return ex;
	}
}

/**
 * @desc fetch list of brokers Based on their Brokerage House
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchBhaBrokersWorker(action: any): Generator {
	yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
	let selectedAuthReducer: any = yield select(selectAuthReducer);
	let jsonResponse: any = null;
	let apikeys: any = null;
	let params: any = {
		method: "get",
		data: {},
	};
	params.path = `persons/?type="broker"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`;
	try {
		apikeys = yield Auth.getAuthTokens();
		jsonResponse = yield call(axios, params, apikeys);
		if (_.has(jsonResponse, "data.fnResult")) {
			yield put(setLoading({ loading: false, pageLoading: false }));
			return yield put(
				updateBhaBrokers(JSON.parse(jsonResponse.data.fnResult))
			);
		}
	} catch (e) {
		yield put(setLoading({ loading: false, pageLoading: false }));
	}
}

/**
 * @desc fetch full list of brokers subDashboards
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchFullBrokersWorker(action: any): Generator {
	yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
	let selectedAuthReducer: any = yield select(selectAuthReducer);
	let userRole: any = selectedAuthReducer.userClaims.userRole;
	let queryStringRole: any = roleBasedQueryResolver(userRole);
	let jsonResponse: any = null;
	let apikeys: any = null;
	let params: any = {
		method: "get",
		data: {},
	};
	if (
		userRole === "brokerageHouseAdministratorPrimary" ||
		userRole === "brokerageHouseAdministrator" ||
		userRole === "superAdmin"
	) {
		params.path = `lists/?type="${queryStringRole}brviewlist"&limit=${
			_.get(action, "data.limit") || 20000
		}&offset=${_.get(action, "data.offset") || 0}&at=${
			selectedAuthReducer.accessToken
		}`;
	} else {
		params.path = `persons?type="includeOvw"&referenceType="broker"&limit="100000"&at=${selectedAuthReducer.accessToken}`;
	}
	try {
		apikeys = yield Auth.getAuthTokens();
		jsonResponse = yield call(axios, params, apikeys);
		if (_.has(jsonResponse, "data.fnResult")) {
			yield put(setLoading({ loading: false, pageLoading: false }));
			return yield put(
				updateFullBrokers(JSON.parse(jsonResponse.data.fnResult))
			);
		}
	} catch (e) {
		yield put(setLoading({ loading: false, pageLoading: false }));
	}
}

/**
 * @desc Update brokerage house
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* updateBrokerWorker(action: any): Generator {
	yield put(setLoading({ loading: true }));
	let apikeys: any = null;
	let jsonResponse: any = null;
	try {
		apikeys = yield Auth.getAuthTokens();
		jsonResponse = yield call(axios, action.data, apikeys);

		if (jsonResponse.data.fnMessage) {
			yield put(editBroker(action.data.data));
			let parsedJsonResponse: any = action.data.data;
			let route: any = getRoute(parsedJsonResponse);
			let routeData: any = getRouteData(parsedJsonResponse);
			yield put(setLoading({ loading: false, ...routeData }));
			// return yield history.push(route, routeData);
			return yield history.push(route);
		} else {
			yield put(setLoading({ loading: false }));
		}
	} catch (ex) {
		yield put(setLoading({ loading: false }));
		return ex;
	}
}

/**
 * @return {*}  {Generator}
 */
function* fetchBhaBrokersWatcher(): Generator {
	yield takeLatest("FETCH_BHA_BROKERS", fetchBhaBrokersWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createBrokerWatcher(): Generator {
	yield takeLatest("CREATE_BROKER", createBrokerWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateBrokerWatcher(): Generator {
	yield takeLatest("UPDATE_BROKER", updateBrokerWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchFullBrokersWatcher(): Generator {
	yield takeLatest("FETCH_FULL_BROKERS", fetchFullBrokersWorker);
}

export {
	createBrokerWatcher,
	updateBrokerWatcher,
	fetchFullBrokersWatcher,
	fetchBhaBrokersWatcher,
};
