// Types
import { Action } from "../actions/types";

// State types
type State = {
  tpas: Array<any>;
  data: any;
};

// Initial state
const INITIAL_STATE: State = {
  tpas: [],
  data: {
    inviteState: {
      step: "PRELIMINARY-STEP_TWO-TPA",
      status: "",
    },
    sendNotification: false,
    requestedForBusinessId: "",
    requestedPersonParentId: "",
    person: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      smsNumber: "",
      homeNumber: "",
      workNumber: "",
      workNumberExt: "",
      faxNumber: "",
      faxNumberExt: "",
      email: "",
      postalCode: "",
      countryCode: "CA",
      languageCode: "en",
      governmentBirthID: "",
      personStatus: "PA00S",
      title: "",
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      userRole: {
        label: "tpaAdministratorPrimary",
        name: "tpaAdministratorPrimary",
      },
      verification: {
        documentType: "",
        documentNumber: "",
        expiryDate: "",
        jurisdictionOfInsurance: "",
      },
      metaInformation: {
        dateOfBirth: "12",
        familyStatus: "",
        gender: "",
        diagnosedPermanentImpairment: "",
        hsaClassType: "",
        hsaStartDate: "",
        officeLocation: "",
        officeCostCenter: "",
        termsConditions: "",
        dependents: [
          {
            dependentType: "",
            provincialHealthInfo: {
              coveredProvincial: false,
              coreHealthInsuranceCoverage: "",
              type: "",
              insuranceCompany: {
                name: "",
                otherName: "",
                policyNumber: "",
              },
              primaryCertificateFirstName: "",
              primaryCertificateLastName: "",
            },
          },
          {
            dependentType: "",
            provincialHealthInfo: {
              coveredProvincial: false,
              coreHealthInsuranceCoverage: "",
              type: "",
              insuranceCompany: {
                name: "",
                otherName: "",
                policyNumber: "",
              },
              primaryCertificateFirstName: "",
              primaryCertificateLastName: "",
            },
          },
        ],
        provincialHealthInfo: {
          isCovered: "",
          coreHealthInsuranceCoverage: "",
          insuranceCompany: {
            name: "",
            policyNumber: "",
          },
        },
        studentEligibility: {
          schoolAttendanceName: "",
          schoolStartDate: "",
          schoolEndDate: "",
        },
      },
    },
    business: {
      businessLegalName: "",
      businessOperatedName: "",
      businessShortName: "",
      businessDbaLicNumber: "",
      isAddressSameAsMailing: false,
      businessGenericEmail: "",
      businessTelephoneNumber: "",
      businessTelephoneNumberExtension: "",
      businessPreferredLanguage: "",
      businessLogoURL: "",
      institutionType: "thirdPartyAdministratorPayor",
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      tpaInformation: {
        type: "",
        tpaBusinessId: "",
      },
      metaInformation: {
        companyWebsite: "M",
        corporateNumber: "",
        businessNumber: "",
        businessType: "",
        numberOfBrokers: "",
        numberOfEmployees: "",
        numberofServiceRepresentatives: "",
        estimatedAnnualCompanyHsaAccount: "",
        jurisdictionOfInsurance: "",
        isCompanyPubliclyTraded: "",
        trading: {
          stockSymbol: "",
          stockExchangeName: "",
        },
        industry: {
          industryType: "",
          industryClassificationType: "",
        },
        dateOfRegistration: "",
        sourceDocument: {
          name: "",
          number: "",
        },
      },
      programDefinedVariables: {
        contractTerm: {
          term: "",
          startDate: "",
          endDate: "",
          revenueShareInterchangeFee: "",
          revenueShareProgramFeesSetup: "",
        },
        statementDelivery: {
          paymentTerm: "",
        },
        processVolumeForecast: {
          hsaProcessedYearly: "",
        },
        administratorFee: {
          optionAFixedPercentagePercentage: "",
          optionAFixedPercentageDollars: "",
          optionAVariablePercentage: "",
          optionBVariablePercentagePercentage: "",
          optionBVariablePercentageDollars: "",
          optionCVairablePercentagePercentage: "",
        },
      },
    },
    bankAccountDetails: {
      bankName: "",
      bankBranchCity: "",
      bankBranchProvince: "",
      bankBranchPostalCode: "",
      bankBranchStreetOne: "",
      bankBranchStreetTwo: "",
      country: "CA",
      bankAccountDesignation: "checking",
      bankBranchNumber: "",
      bankNumber: "",
      bankAccountNumber: "",
      bankAccountType: "business",
      submissionPreAuthorizedDebitForm: "false",
      agreeTermsConditions: "false",
    },
    referral: {
      id: "",
      type: "business",
    },
    accessToken: "",
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_TPA":
      return { ...state, data: { ...state.data, ...action.data } };
    case "UPDATE_TPAS":
      return { ...state, tpas: { ...action.data } };
    case "CLEAR_TPA":
      return { ...state, data: INITIAL_STATE.data };
    default:
      return state;
  }
};
