import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import styles from './views.module.scss';
import { setModal } from '../../../../actions';

const PADAgreement = (props) => {
	let file = '';
	const [html, setHtml] = useState('')
	const getFile= ()=>{
		// try{
		// 	let file = await fetch('https://phatdev-cdn-public.s3.ca-central-1.amazonaws.com/agreement/agreement-1-0-0.html',{
		// 		method: 'GET',
		// 		mode: 'no-cors',
		// 		accept: '*/*'				
		// 	})
		// 	setHtml(file)			
		// 	console.log('view file',file)
		// }catch(e){
		// }
		// var requestOptions = {
		// 	method: 'GET',
		// 	redirect: 'follow'
		// };

		// fetch("https://phatdev-cdn-public.s3.ca-central-1.amazonaws.com/agreement/agreement-1-0-0.html", requestOptions)
		// 	.then(response => response.text())
		// 	.then(result => console.log('result',result))
		// 	.catch(error => console.log('error', error));
	}
	useEffect(() => {
		getFile()
	}, [])
	if(html){
		return <div dangerouslySetInnerHTML={{__html: html}}/>
	}
	return (
		<div className={ styles.terms }>
			<h2><strong>Aya Payments, Inc. Pre Authorization Debit Agreement </strong></h2>
			<p><strong>General Terms </strong></p>
			<p>Aya Prepaid Mastercard &reg; (herein referred to Aya) is issued by Peoples Trust Company under license from Mastercard International Incorporated. Aya Payments Inc., is the Program Manager and Administrator (hereafter referred to as "Administrator&rsquo;) agrees to provide web and mobile application services, account fulfillment and reconciliation services to you and your Company. Aya Payment uses Third Party Adjudicators as Claims Adjudicator of the Health Spending Account. The Administrator, Adjudicator and the Company expressly understand and agree that nothing in this agreement shall constitute the Administrator as an Adjudicator, and both parties shall act in a manner consistent with this understanding. The terms and conditions of the contracts with Third Party Adjudicator are not impacted by the Aya Terms and Conditions of the Administrative Agreement, Cardholder Agreement, Privacy Policy and Aya Terms of Service.</p>
	 		<p>The Administrator shall be compensated by the Company with an administration fee associated with the Health Spending Account. Additional administrative fees may be paid by employees submitting claims for adjudication. All fees to be paid by employees will be denoted in the Aya Prepaid Mastercard &reg; Cardholder Agreement.</p>
			<p><strong>Pre Authorization Debit Agreement</strong></p>
			<ol>
			<li>We have provided our Corporate Financial Information (Financial Institution Number, Branch Transit Number, and Account Number) in the section titled &ldquo;Pre Authorized Debit Payment&rdquo;.</li>
			<li>We will inform the Program Administrator (Aya Payments), in writing, of any change in the information provided in this section of the Authorization prior to the next due date of the PAD at the address below: Aya Payments, Customer Service P.O. Box 60014 (Thorncrest PO), Etobicoke, ON., M9A 3L0 .</li>
			<li>We acknowledge that the Authorization is provided for the benefit of the Administrator and the Processing Institution and is provided in consideration of the Processing Institution agreeing to process debits against our account, as listed above, (the &ldquo;Account&rdquo;) in accordance with the Rules of the Canadian Payments Association.</li>
			<li>We (The Company) warrant and guarantee that all persons whose signatures are required to authorize withdrawals from the Account have signed the Authorization and that all persons signing this Authorization are our authorized signing officers and are empowered to enter into this agreement.</li>
			<li>We hereby authorize the Administrator to issue Pre-Authorized Debits (PAD), as defined in Rule H4 of the Rules of the Canadian Payments Association, drawn on the Account, for the purpose of contributing to the Private Health Services Plan</li>
			<li>We may cancel the Authorization at any time upon providing written notice to the Administrator</li>
			<li>We acknowledge that provision and delivery of the Authorization to the Administrator constitutes delivery by us to the Processing Institution. Any delivery of the Authorization to the Administrator, regardless of the method of delivery, constitutes delivery by us.</li>
			<li>We acknowledge that the Processing Institution is not required to verify that a PAD has been issued in accordance with the particulars of the Authorization including, but not limited to, the amount, or that any purpose of payment for which the PAD was issued has been fulfilled by the Administrator as a condition to honouring a PAD issued by the Administrator on the Account.</li>
			<li>Revocation of the Authorization does not terminate any contract for goods or services that exists between us and the Administrator. The Authorization applies only to the method of payment and does not otherwise have any bearing on the contract for goods or services exchanged.</li>
			<li>We may dispute a PAD only under the following conditions: (i)The PAD was not drawn in accordance with the Authorization. (ii)The Authorization was revoked.We acknowledge that in order to be reimbursed, a declaration to the effect that either (i),or (ii ) took place, must be completed and presented to the branch of the Processing Institution holding the Account up to and including 10 business days after the date on which the PAD in dispute was posted to the Account. We acknowledge that when disputing any PAD beyond the time allowed in this section, it is a matter to be resolved solely between us and the Administrator, outside the payment system.</li>
			<li>We agree that the information contained in the Authorization may be disclosed to Desjardins as required to complete any PAD transaction.</li>
			<li>We understand and accept the terms of participating in this PAD plan.</li>
			</ol>
		</div>);

};


export default connect(null, { setModal })(withRouter(PADAgreement));
