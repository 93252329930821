// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchProductCodes = (): Action => ({
  type: "FETCH_PRODUCT_CODES",
});
/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateProductCodes = (data: any): Action => ({
  type: "UPDATE_PRODUCT_CODES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getCompanyGeneralInformation = (data: any): Action => ({
  type: "GET_COMPANY_GENERAL_INFORMATION",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanyGeneralInformation = (data: any): Action => ({
  type: "UPDATE_COMPANY_GENERAL_INFORMATION",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchFullCompanies = (data: any): Action => ({
  type: "FETCH_FULL_COMPANIES",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchCompanies = (): Action => ({
  type: "FETCH_COMPANIES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanies = (data: any): Action => ({
  type: "UPDATE_COMPANIES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateFullCompanies = (data: any): Action => ({
  type: "UPDATE_FULL_COMPANIES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createCompany = (data: any): Action => ({
  type: "CREATE_COMPANY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchPlanSponsorContact = (data: any): Action => ({
  type: "FETCH_PLAN_SPONSOR_CONTACT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePlanSponsorContact = (data: any): Action => ({
  type: "UPDATE_PLAN_SPONSOR_CONTACT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchPlanSponsorAdministratorPrimary = (data: any): Action => ({
  type: "FETCH_PLAN_SPONSOR_ADMIN_PRIMARY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePlanSponsorAdministratorPrimary = (data: any): Action => ({
  type: "UPDATE_PLAN_SPONSOR_ADMIN_PRIMARY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchPlanSponsorAdministrator = (data: any): Action => ({
  type: "FETCH_PLAN_SPONSOR_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePlanSponsorAdministrator = (data: any): Action => ({
  type: "UPDATE_PLAN_SPONSOR_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchAuthorizedSigners = (data: any): Action => ({
  type: "FETCH_AUTHORIZED_SIGNERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateAuthorizedSigners = (data: any): Action => ({
  type: "UPDATE_AUTHORIZED_SIGNERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchDirectors = (data: any): Action => ({
  type: "FETCH_DIRECTORS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateDirectors = (data: any): Action => ({
  type: "UPDATE_DIRECTORS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchBeneficiaryOwners = (data: any): Action => ({
  type: "FETCH_BENEFICIARY_OWNERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBeneficiaryOwners = (data: any): Action => ({
  type: "UPDATE_BENEFICIARY_OWNERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchAccountants = (data: any): Action => ({
  type: "FETCH_ACCOUNTANTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateAccountants = (data: any): Action => ({
  type: "UPDATE_ACCOUNTANTS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchAyaSalesReps = (data: any): Action => ({
  type: "FETCH_AYA_SALES_REPS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateAyaSalesReps = (data: any): Action => ({
  type: "UPDATE_AYA_SALES_REPS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createNewPlanAdmin = (data: any): Action => ({
  type: "CREATE_NEW_PLAN_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateNewPlanAdmin = (data: any): Action => ({
  type: "UPDATE_NEW_PLAN_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchCompanyAllInfo = (data: any): Action => ({
  type: "FETCH_COMPANY_ALL_INFO",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanyAllInfo = (data: any): Action => ({
  type: "UPDATE_COMPANY_ALL_INFO",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editNewPlanAdmin = (data: any): Action => ({
  type: "EDIT_NEW_PLAN_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getNewPlanAdminInvite = (data: any): Action => ({
  type: "GET_NEW_PLAN_ADMIN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editCompany = (data: any): Action => ({
  type: "EDIT_COMPANY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompany = (data: any): Action => ({
  type: "UPDATE_COMPANY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getCompany = (data: any): Action => ({
  type: "GET_COMPANY",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearCompany = (): Action => ({
  type: "CLEAR_COMPANY",
});

/**
 * @return {*}  {Action}
 */
export const fetchIndustries = (): Action => ({
  type: "FETCH_INDUSTRIES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateIndustries = (data: any): Action => ({
  type: "UPDATE_INDUSTRIES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchPcoTpa = (data: any): Action => ({
  type: "FETCH_PCO_TPA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePcoTpa = (data: any): Action => ({
  type: "UPDATE_PCO_TPA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const increaseLoggedIn = (data: any): Action => ({
  type: "INCREASE_LOGGED_IN",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const decreaseLoggedIn = (data: any): Action => ({
  type: "DECREASE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getCompanyInvoiceFile = (data: any): Action => ({
  type: "GET_COMPANY_INVOICE_FILE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanyInvoiceFile = (data: any): Action => ({
  type: "UPDATE_COMPANY_INVOICE_FILE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const clearCompanyInvoiceFile = (): Action => ({
  type: "CLEAR_COMPANY_INVOICE_FILE",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getInvoiceList = (data: any): Action => ({
  type: "GET_INVOICE_LIST",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanyInvoiceList = (data: any): Action => ({
  type: "UPDATE_COMPANY_INVOICE_LIST",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getReport = (data: any): Action => ({
  type: "GET_REPORT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateReport = (data: any): Action => ({
  type: "UPDATE_REPORT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const clearReport = (): Action => ({
  type: "CLEAR_REPORT",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateRedirectedCompanyReport = (data: any): Action => ({
  type: "UPDATE_REDIRECTED_COMPANY_REPORT",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearRedirectedCompanyReport = (): Action => ({
  type: "CLEAR_REDIRECTED_COMPANY_REPORT",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getBalances = (data: any): Action => ({
  type: "GET_BALANCES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBalances = (data: any): Action => ({
  type: "UPDATE_BALANCES",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearBalances = (): Action => ({
  type: "CLEAR_BALANCES",
});

export const getSelfEnrolmentUserData = (data?: any): Action => ({
  type: "GET_SELF_ENROLMENT_USER_DATA",
  data,
});

export const getSelfEnrolmentCompanyData = (data?: any): Action => ({
  type: "GET_SELF_ENROLMENT_COMPANY_DATA",
  data,
});

export const setSelfEnrolmentDetails = (data?: any): Action => ({
  type: "SET_SELF_ENROLMENT_DETAILS",
  data,
});

export const saveSelfEnrolmentCompanyData = (data?: any): Action => ({
  type: "SAVE_SELF_ENROLMENT_COMPANY_DATA",
  data,
});

export const createSelfEnrolmentCompany = (data?: any): Action => ({
  type: "CREATE_SELF_ENROLMENT_COMPANY",
  data,
});

export const submitSelfEnrolmentCompanyData = (data?: any): Action => ({
  type: "SUBMIT_SELF_ENROLMENT_COMPANY_DATA",
  data,
});

export const getAllSelfEnrolmentCompanies = (data?: any): Action => ({
  type: "GET_ALL_SELF_ENROLLED_COMPANIES",
  data,
});

export const getSelfEnroledCompanyDetails = (data?: any): Action => ({
  type: "GET_SELF_ENROLLED_COMPANY_DETAILS",
  data,
});

export const approveSelfEnroledCompany = (data?: any): Action => ({
  type: "SET_APPROVE_SELF_ENROLLED_COMPANY",
  data,
});

export const declineSelfEnroledCompany = (data?: any): Action => ({
  type: "SET_DECLINE_SELF_ENROLLED_COMPANY",
  data,
});

export const saveReviewedSelfEnroledCompanyData = (data?: any): Action => ({
  type: "SAVE_REVIEWED_SELF_ENROLMENT_COMPANY_DATA",
  data,
});

export const setPcoEnrolmentDetails = (data?: any): Action => ({
  type: "SET_PCO_ENROLMENT_DETAILS",
  data,
});

export const triggerPcoEnrolment = (data?: any): Action => ({
  type: "TRIGGER_PCO_ENROLMENT",
  data,
});

export const updatePcoEnrolmentDetails = (data?: any): Action => ({
  type: "UPDATE_PCO_ENROLMENT_DETAILS",
  data,
});

export const clearPcoEnrolmentDetails = (): Action => ({
  type: "CLEAR_PCO_ENROLMENT_DETAILS",
});

export const addClass = (data?: any): Action => ({
  type: "ADD_CLASS",
  data,
});

export const updateClass = (data?: any): Action => ({
  type: "UPDATE_CLASS",
  data,
});

export const fetchClasses = (data?: any): Action => ({
  type: "FETCH_CLASSES",
  data,
});

export const clearClasses = (): Action => ({
  type: "CLEAR_CLASSES",
});

export const clearClassResponse = (): Action => ({
  type: "CLEAR_CLASS_RESPONSE",
});

export const updateClassInStore = (data?: any): Action => ({
  type: "UPDATE_CLASS_IN_STORE",
  data,
});
