import _, { get, has } from "lodash";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  editClaim,
  editNotes,
  getClaim,
  setLoading,
  updateClaimCategories,
  updateClaims,
  updateClaimUsageStats,
  updateMastercardClaim,
  updateSequentialClaims,
} from "../actions";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { SENTRY_ERRORS, SENTRY_PAGES } from "./utilities/sentry-error.constant";
import { transformReceivedClaim } from "./utilities/transformReceivedClaim";
import { claimStatusMapper } from "../reducers/claims";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;
// Update Claim
const selectClaimReducer = (state: any) => state.claimsReducer;
// Claims per request
const maxClaimsPerRequest = 4000;
const CHUNK_SIZE = 2;

/**
 * @desc fetch claim categories
 * @return {*}  {Generator}
 */
function* fetchClaimCategoriesWorker(): Generator {
  // yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `facts/?type="claimCategories"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(updateClaimCategories(JSON.parse(jsonResponse.data.fnResult)));
      // yield put(setLoading({ loading: false }));
      return;
    }
  } catch (e) {
    // yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Fetch Notes
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchNotesWorker(action: any): Generator {
  yield put(setLoading({ pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `claims/${action.data}/notes/?type="all"&referenceType="claims"&at="${selectedAuthReducer.accessToken}"`;

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      let notesParsed: any = JSON.parse(jsonResponse.data.fnResult);
      yield put(editNotes(notesParsed.summary));
      yield put(setLoading({ pageLoading: false }));
      return;
    }
  } catch (e) {
    yield put(setLoading({ pageLoading: false }));
  }
}

/**
 * @desc Update notes
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateNotesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let jsonFetchResponse: any = null;
  let apikeys: any = null;
  //params for fetching notes
  let fetchParams: any = {
    method: "get",
    path: "",
    data: {},
  };
  fetchParams.path = `claims/${action.data.id}/notes/?type="all"&referenceType="claims"&at="${selectedAuthReducer.accessToken}"`;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data.params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      //fetch notes
      jsonFetchResponse = yield call(axios, fetchParams, apikeys);
      if (_.has(jsonResponse, "data.fnResult")) {
        let notesParsed: any = JSON.parse(jsonFetchResponse.data.fnResult);
        yield put(editNotes(notesParsed.summary));
        return yield put(setLoading({ loading: false, pageLoading: false }));
      }
    } else {
      yield put(setLoading({ loading: false, pageLoading: false }));
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc fetch claim usage stats
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchClaimUsageStatsWorker(): Generator {
  yield put(setLoading({ loading: true, pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  //params for fetching claim usage stats
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `claims?type=claimUsageStats&limit=100000&at="${selectedAuthReducer.accessToken}"`;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.claimUsageStats")) {
      yield put(updateClaimUsageStats(jsonResponse?.data?.claimUsageStats));
      return yield put(setLoading({ loading: false }));
    } else {
      yield put(setLoading({ loading: false }));
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc Fetch claims
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchClaimsWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, pageLoading: true, claimsLoading: true })
  );
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let selectedClaimReducer: any = yield select(selectClaimReducer);
  let isFetchApiForGroupCalled = false;

  let newAxiosGetClaimsRequestParam = function (
    limit: number,
    offset: number,
    accessToken: string
  ) {
    return {
      method: "get",
      path: `claims/?limit=${limit}&offset=${offset}&at="${accessToken}"`,
      data: {},
    };
  };

  let getClaimsRequestsParams: any[] = [];
  let responses = [];

  if (action?.data?.mode == "serverPagination") {
    let limit = action?.data?.recordsPerPage;
    let offset = action?.data?.page ? action?.data?.page * 10 - 10 : 0;
    let request = newAxiosGetClaimsRequestParam(
      limit,
      offset,
      selectedAuthReducer.accessToken
    );
    getClaimsRequestsParams.push(request);
  } else if (action?.data?.mode == "newest2000") {
    if (
      selectedClaimReducer.cached2kClaims &&
      selectedClaimReducer.cached2kClaims?.length !== 0
    ) {
      yield put(
        updateClaims({
          claims: selectedClaimReducer.cached2kClaims,
          loadFromCache: true,
        })
      );
      yield put(
        setLoading({ loading: false, pageLoading: false, claimsLoading: false })
      );
      return;
    }
    let limit = 2000;
    let offset = 0;
    let request = newAxiosGetClaimsRequestParam(
      limit,
      offset,
      selectedAuthReducer.accessToken
    );
    getClaimsRequestsParams.push(request);
  } else if (action?.data?.mode == "pco") {
    let limit = 3000;
    let offset = 0;
    let request = newAxiosGetClaimsRequestParam(
      limit,
      offset,
      selectedAuthReducer.accessToken
    );
    getClaimsRequestsParams.push(request);
  } else {
    if (
      selectedClaimReducer.cachedAllClaims &&
      selectedClaimReducer.cachedAllClaims?.length !== 0 &&
      selectedClaimReducer.cachedAllClaims?.length ===
        selectedClaimReducer.allClaimsCount
    ) {
      yield put(
        updateClaims({
          claims: selectedClaimReducer.cachedAllClaims,
          loadFromCache: true,
        })
      );
      if (action?.data?.statusFilter) {
        const statusFilter = action?.data?.statusFilter;
        let filteredClaims = selectedClaimReducer.cachedAllClaims.filter(
          (item: any) => item.status === statusFilter
        );
        yield put(
          updateClaims({
            claims: filteredClaims,
            loadFromCache: true,
          })
        );
      }
      yield put(
        setLoading({ loading: false, pageLoading: false, claimsLoading: false })
      );
      return;
    }

    try {
      let claimsCount: number = selectedClaimReducer.allClaimsCount;
      while (claimsCount > 0) {
        let offset = getClaimsRequestsParams.length * maxClaimsPerRequest;
        let request = newAxiosGetClaimsRequestParam(
          maxClaimsPerRequest,
          offset,
          selectedAuthReducer.accessToken
        );
        getClaimsRequestsParams.push(request);
        claimsCount -= maxClaimsPerRequest;

        // call api in groups
        if (
          getClaimsRequestsParams.length % CHUNK_SIZE === 0 ||
          claimsCount <= 0
        ) {
          let apikeys = yield Auth.getAuthTokens();
          let itemsToPick =
            claimsCount <= 0
              ? getClaimsRequestsParams.length % CHUNK_SIZE || CHUNK_SIZE
              : CHUNK_SIZE;
          let chunkResponse: any[] = yield all(
            getClaimsRequestsParams
              .slice(itemsToPick * -1)
              .map((params) => call(axios, params, apikeys))
          );
          responses.push(...chunkResponse);
          isFetchApiForGroupCalled = true;
        }
      }
    } catch (e) {
      yield put(
        setLoading({ loading: false, pageLoading: false, claimsLoading: false })
      );
    }
  }

  try {
    let apikeys = yield Auth.getAuthTokens();
    if (!isFetchApiForGroupCalled) {
      responses = yield all(
        getClaimsRequestsParams.map((params) => call(axios, params, apikeys))
      );
    }

    let claims: any[] = [];
    let count: number = 0;
    responses.map((response, index) => {
      if (_.has(response, "data.fnResult")) {
        claims.push(...JSON.parse(response.data.fnResult));
        count = Number(response?.data?.fnMeta?.type ?? 0);
      }
    });

    if (
      action?.data?.mode !== "newest2000" &&
      action.data?.mode !== "serverPagination" &&
      claims.length > 0 &&
      (!selectedClaimReducer.cachedAllClaims ||
        selectedClaimReducer.cachedAllClaims.length === 0)
    ) {
      yield put(
        updateClaims({
          claims,
          isAllCache: true,
          allClaimsCount: count,
        })
      );
    }
    if (action?.data?.mode === "newest2000") {
      yield put(
        updateClaims({ claims, is2kCache: true, allClaimsCount: count })
      );
    }

    if (action?.data?.statusFilter) {
      const statusFilter = action?.data?.statusFilter;
      claims = claims.filter((item) => item.claimStatus === statusFilter);
      yield put(updateClaims({ claims, count }));
    } else {
      yield put(updateClaims({ claims, count }));
    }
    yield put(
      setLoading({ loading: false, pageLoading: false, claimsLoading: false })
    );
    return;
  } catch (e) {
    yield put(
      setLoading({ loading: false, pageLoading: false, claimsLoading: false })
    );
  }
}

/**
 * @desc Get claim
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getClaimWorker(action: any): Generator {
  yield put(setLoading({ pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `claims/${action.data}?type="includeAll"&at="${selectedAuthReducer.accessToken}"`;

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(editClaim(JSON.parse(jsonResponse.data.fnResult)));
      yield put(setLoading({ pageLoading: false }));
      return;
    } else if (_.has(jsonResponse, "data.errorMessage")) {
      throw new Error(jsonResponse.data.errorMessage);
    }
  } catch (e) {
    let selectedAuthReducer: any = yield select(selectAuthReducer);
    let {
      preAuthDetails: { email },
    } = selectedAuthReducer;
    Sentry.captureException(e.message, {
      tags: {
        page: SENTRY_PAGES.claimDetails,
        type: SENTRY_ERRORS.claimLoadFailed,
        email,
      },
    });
    yield put(setLoading({ pageLoading: false }));
  }
}

/**
 * @desc Update claim
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateClaimWorker(action: any): Generator {
  console.log(".........", action);
  let selectedClaimReducer: any = yield select(selectClaimReducer);
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let setSeq: string = "";
  if (has(action, "data.setSeq")) {
    setSeq = get(action, "data.setSeq");
    delete action.data.setSeq;
  }
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data, apikeys);

    // check set seq available then update the sequential string
    if (setSeq) yield put(updateSequentialClaims(setSeq));

    if (
      get(jsonResponse.data, "fnError") ||
      get(jsonResponse.data, "errorMessage")
    ) {
      // check set seq available then update the sequential string and the last items
      if (setSeq) yield put(updateSequentialClaims("doneClaims"));
      history.goBack();
      throw {
        message:
          get(jsonResponse.data, "fnError") ||
          get(jsonResponse.data, "errorMessage") ||
          "Oops something went wrong, try later",
      };
    }

    //TODO: should update the backup as well
    if (
      jsonResponse.data?.fnStatusCode === 200 &&
      jsonResponse.data?.fnMessage === "assign.successful.current_session_user"
    ) {
      const desiredId = action?.data?.path.split("/")[1];
      const newClaimList = [...selectedClaimReducer.claims];
      let selectedAuthReducer: any = yield select(selectAuthReducer);

      newClaimList.forEach((item) => {
        if (item.id === desiredId) {
          item.assignedTo =
            selectedAuthReducer.userClaims.firstName +
            " " +
            selectedAuthReducer.userClaims.lastName;
          item.status =
            item.claimFlag === "submitted" &&
            action?.data?.data?.inContextOfPov === "intermediary"
              ? claimStatusMapper.ireview
              : claimStatusMapper.treview;
        }
        return item;
      });

      if (
        newClaimList.length === 2000 &&
        selectedClaimReducer.cachedAllClaims.length > 0
      ) {
        //call to update the cachedAllClaims list
        yield put(
          updateClaims({
            cachedAllClaims: updateBackupCacheAssignedTo(
              desiredId,
              selectedAuthReducer.userClaims.firstName,
              selectedAuthReducer.userClaims.lastName,
              action?.data?.data?.inContextOfPov,
              selectedClaimReducer,
              true
            ),
            updateCache: true,
          })
        );
      } else if (
        newClaimList.length !== selectedClaimReducer.recordsPerPage &&
        selectedClaimReducer.cached2kClaims.length > 0
      ) {
        //call to update the cached2kClaims list
        yield put(
          updateClaims({
            cached2kClaims: updateBackupCacheAssignedTo(
              desiredId,
              selectedAuthReducer.userClaims.firstName,
              selectedAuthReducer.userClaims.lastName,
              action?.data?.data?.inContextOfPov,
              selectedClaimReducer
            ),
            updateCache: true,
          })
        );
      } else {
        yield put(
          updateClaims({
            cached2kClaims: updateBackupCacheAssignedTo(
              desiredId,
              selectedAuthReducer.userClaims.firstName,
              selectedAuthReducer.userClaims.lastName,
              action?.data?.data?.inContextOfPov,
              selectedClaimReducer
            ),
            cachedAllClaims: updateBackupCacheAssignedTo(
              desiredId,
              selectedAuthReducer.userClaims.firstName,
              selectedAuthReducer.userClaims.lastName,
              action?.data?.data?.inContextOfPov,
              selectedClaimReducer,
              true
            ),
            updateCache: true,
          })
        );
      }

      yield put(updateClaims({ claims: newClaimList, localUpdate: true }));
    }

    // if claim was adjudicated successfully, set claim status to appropraite status
    if (action?.data?.data?.claimAction === "adjudicate") {
      const desiredId = action?.data?.path.split("/")[1];
      const newClaimList = [...selectedClaimReducer.claims];
      newClaimList.forEach((item) => {
        if (item.id === desiredId) {
          item.status =
            claimStatusMapper[action?.data?.data?.claimStatus?.toLowerCase()];
        }
        return item;
      });

      if (
        newClaimList.length === 2000 &&
        selectedClaimReducer.cachedAllClaims.length > 0
      ) {
        //call to update the cachedAllClaims list
        yield put(
          updateClaims({
            cachedAllClaims: updateBackupCacheStatus(
              desiredId,
              action?.data?.data?.claimStatus,
              selectedClaimReducer,
              true
            ),
            updateCache: true,
          })
        );
      } else if (
        newClaimList.length !== selectedClaimReducer.recordsPerPage &&
        selectedClaimReducer.cached2kClaims.length > 0
      ) {
        yield put(
          updateClaims({
            cached2kClaims: updateBackupCacheStatus(
              desiredId,
              action?.data?.data?.claimStatus,
              selectedClaimReducer
            ),
            updateCache: true,
          })
        );
      } else {
        yield put(
          updateClaims({
            cached2kClaims: updateBackupCacheStatus(
              desiredId,
              action?.data?.data?.claimStatus,
              selectedClaimReducer
            ),
            cachedAllClaims: updateBackupCacheStatus(
              desiredId,
              action?.data?.data?.claimStatus,
              selectedClaimReducer,
              true
            ),
            updateCache: true,
          })
        );
      }

      yield put(updateClaims({ claims: newClaimList, localUpdate: true }));
    }

    if (
      get(action, "data.data.claimAction") &&
      get(action, "data.data.claimStatus") !== "submittedToTpa"
    ) {
      history.push({
        pathname: "/hsa-adjudication",
      });
      // yield call(getClaimWorker, {
      //   data: selectedClaimReducer.data.claimHealthGUID,
      // });

      return yield put(
        setLoading({
          loading: false,
          alertType: "success",
          successMessage: "Claim Adjudicated Successfully",
        })
      );
    }

    if (get(action, "data.data.paymentDate")) {
      history.push({
        pathname: "/hsa-adjudication",
      });
      yield call(getClaimWorker, {
        data: selectedClaimReducer.data.claimHealthGUID,
      });

      return yield put(
        setLoading({
          loading: false,
          alertType: "success",
          successMessage: "Claim Recorded Successfully",
        })
      );
    }
    if (
      get(action, "data.data.claimAction") &&
      get(action, "data.data.claimStatus") === "submittedToTpa"
    ) {
      history.push({
        pathname: "/intermediaries",
      });
      return yield put(
        setLoading({
          loading: false,
          alertType: "success",
          successMessage: "Submitted to TPA Successfully",
        })
      );
    }
    return yield put(setLoading({ loading: false }));
    // return yield put(setLoading({ loading: false,  }));
    // yield put(editClaim(action.data.data));
  } catch (ex) {
    // check set seq available then update the sequential string and the last items
    if (setSeq) yield put(updateSequentialClaims("doneClaims"));

    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: ex.message,
      })
    );
    return ex;
  }
}

function updateBackupCacheStatus(
  desiredId: any,
  claimStatus: any,
  selectedClaimReducer: any,
  is2KClaims = false
) {
  const cachedClaimsToUpdate = is2KClaims
    ? [...selectedClaimReducer.cachedAllClaims]
    : [...selectedClaimReducer.cached2kClaims];

  cachedClaimsToUpdate.forEach((item) => {
    if (item.id === desiredId) {
      item.status = claimStatusMapper[claimStatus.toLowerCase()];
    }
    return item;
  });

  return cachedClaimsToUpdate;
}

function updateBackupCacheAssignedTo(
  desiredId: any,
  firstName: any,
  lastName: any,
  inContextOfPov: any,
  selectedClaimReducer: any,
  is2KClaims = false
) {
  const cachedClaimsToUpdate = is2KClaims
    ? [...selectedClaimReducer.cachedAllClaims]
    : [...selectedClaimReducer.cached2kClaims];

  cachedClaimsToUpdate.forEach((item) => {
    if (item.id === desiredId) {
      item.assignedTo = firstName + " " + lastName;
      item.status =
        item.claimFlag === "submitted" && inContextOfPov === "intermediary"
          ? claimStatusMapper.ireview
          : claimStatusMapper.treview;
    }
    return item;
  });

  return cachedClaimsToUpdate;
}

/**
 * @desc Update claim item
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateClaimItemWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let setSeq: string = "";
  let isLast: boolean = false;
  if (has(action, "data.setSeq")) {
    setSeq = get(action, "data.setSeq");
    delete action.data.setSeq;
  }
  if (has(action, "data.isLast")) {
    isLast = get(action, "data.isLast");
    delete action.data.isLast;
  }
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data, apikeys);

    // check set seq available then update the sequential string and the last items
    if (setSeq && isLast) yield put(updateSequentialClaims(setSeq));

    if (jsonResponse.data.fnMessage) {
      // yield put(editClaim(action.data.data));
      return yield put(setLoading({ loading: false }));

      // return yield history.push('/intermediary');
    } else {
      // check set seq available then update the sequential string and the last items
      if (setSeq && isLast) yield put(updateSequentialClaims("doneClaims"));

      yield put(setLoading({ loading: false }));
    }
  } catch (ex) {
    // check set seq available then update the sequential string and the last items
    if (setSeq && isLast) yield put(updateSequentialClaims("doneClaims"));

    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @desc Upload claim
 * @param {*} action
 * @return {*}  {Generator}
 */
function* uploadClaimImage(action: any): Generator {
  yield put(setLoading({ imageUploading: true }));

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  // let claimType = action.isOOP ? 'claims-oop' : 'claims-card';
  let jsonResponse: any = null;
  let params: any = {
    method: "put",
    data:
      action.data.mimeType !== "application/pdf"
        ? action.data.image
        : action.data.rawFile,
    ...(action.data.mimeType === "application/pdf"
      ? {
          mimeType: "application/pdf",
        }
      : { mimeType: action.data.mimeType }),
  };

  if (action.data.imageType === "claimImage") {
    params.path = `claim-image/receipt_${action.data.claimGuid}_${action.data.imageName}?at=${selectedAuthReducer.accessToken}`;
  }
  if (action.data.imageType === "claimItemImage") {
    params.path = `claim-image/claimitem_${action.data.claimHealthItemGUID}_${action.data.imageName}?at=${selectedAuthReducer.accessToken}`;
  }

  try {
    let apikeys: any = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    yield put(getClaim(action.data.claimGuid));
    return yield put(
      setLoading({
        imageUploading: false,
        alertType: "success",
        successMessage: "Uploaded Image Successfully",
      })
    );
  } catch (e) {
    console.log("upload claim image err: ", e);
    return yield put(
      setLoading({
        imageUploading: false,
        alertType: "error",
        errorMessage: "Failed to Upload Image",
      })
    );
  }
}

/**
 * @desc Create claim
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createClaimWorker(action: any): Generator {
  yield put(setLoading({ loading: true, claimSubmitting: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;

  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data.payload, apikeys);
    yield batchClaimImageUploadWorker({
      bankInfo: action.data.bankInfo,
      payload: action.data.payload,
      claimGuids: get(jsonResponse, "data.fnResult")
        ? JSON.parse(jsonResponse.data.fnResult)
        : "",
      receiptImages: action.data.receiptImages,
      claimHealthItems: action.data.claimHealthItems,
    });
  } catch (e) {
    console.log("failed to create claim: ", e);
    return yield put(
      setLoading({
        imageUploading: false,
        errorMessage: "Failed to Upload Image",
      })
    );
  }
}

/**
 * @desc Submit claim worker
 * @param {*} {payload: params, bankInfo, claimGuid}
 * @return {*}  {Generator}
 */
function* submitClaimWorker({
  payload: params,
  bankInfo,
  claimGuid,
}: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  params.method = "put";
  params.path = `claims-oop/${claimGuid}`;
  params.data["action"] = "submit";
  params.data["bankAccountInformation"] = bankInfo;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, params, apikeys);
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") !== 200
    ) {
      throw { message: jsonResponse.data.fnMessage };
    }
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") === 200
    ) {
      return yield put(
        setLoading({
          loading: false,
          claimSubmittedSuccessfully:
            "Claim successfully submitted. Our team is going to adjudicate shortly and you will be updated in the next couple of days.",
          claimSubmitting: false,
        })
      );
    }
  } catch (e) {
    console.log("failed to submit claim: ", e);
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: e.message,
        failedToSubmitClaim: "Failed to submit claim, try again later.",
        claimSubmitting: false,
      })
    );
    return;
  }
}

/**
 * @desc Upload single claim image
 * @param {*} {data, index}
 * @return {*}  {Generator}
 */
function* uploadSingleClaimImageWorker({ data, index }: any): Generator {
  // yield put(setLoading({ imageUploading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  // let claimType = action.isOOP ? 'claims-oop' : 'claims-card';
  let jsonResponse: any = null;
  let params: any = {
    method: "put",
    data:
      data.image.fileName !== "pdf" ? data.image.fullImage : data.image.rawFile,
    ...(data.image.fileName === "pdf"
      ? { mimeType: "application/pdf" }
      : { mimeType: data.image.mimeType }),
  };

  if (data.type === "claimImage") {
    params.path = `claim-image/receipt_${
      data.guid
    }_${`${index}.${data.image.fileName}`}?at=${
      selectedAuthReducer.accessToken
    }`;
  }
  if (data.type === "claimItemImage") {
    params.path = `claim-image/claimitem_${
      data.guid
    }_${`${index}.${data.image.fileName}`}?at=${
      selectedAuthReducer.accessToken
    }`;
  }

  try {
    let apikeys: any = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    return;
  } catch (e) {
    console.log("upload image err: ", e);
    return;
  }
}

/**
 * @desc Batch claim image upload
 * @param {*} data
 * @return {*}  {Generator}
 */
function* batchClaimImageUploadWorker(data: any): Generator {
  let {
    payload,
    claimGuids,
    receiptImages = [],
    claimHealthItems = {},
    bankInfo = {},
  }: any = data;

  //build single list containing items with respective guids.
  let list: any = [];
  if (claimGuids.claim) {
    for (let i: number = 0; i < receiptImages.length; i++) {
      list.push({
        guid: claimGuids.claim,
        image: receiptImages[i],
        type: "claimImage",
      });
    }
  }
  if (claimGuids.items && claimGuids.items.length > 0) {
    for (let i: number = 0; i < claimGuids.items.length; i++) {
      if (
        claimHealthItems.length > 0 &&
        has(claimHealthItems[i], "claimItemImages")
      ) {
        let myCurrentItem: any = get(claimHealthItems[i], "claimItemImages");
        for (let j: number = 0; j < myCurrentItem.length; j++) {
          list.push({
            guid: claimGuids.items[i],
            image: myCurrentItem[j],
            type: "claimItemImage",
          });
        }
      }
    }
  }
  if (list.length > 0) {
    yield all(
      list.map((data: any, index: number) => {
        return uploadSingleClaimImageWorker({ data, index });
      })
    );
  }
  yield submitClaimWorker({
    payload,
    bankInfo,
    claimGuid: get(claimGuids, "claim"),
  });
}

function* createAndSubmitClaimWorker(action: any): Generator {
  // local state
  const {
    data: { payload, values, userClaimsDetails, history, url },
  } = action;
  // get the claim items images
  const claimItemsImages: Array<any> = values?.claimItems ?? [];
  // get the receipt images
  const receiptImages: Array<any> = userClaimsDetails?.receipt ?? [];
  //get api keys/tokens
  const apikeys: any = yield Auth.getAuthTokens();

  // params
  const claimParams: any = {
    method: "POST",
    path: "claims-oop",
    data: payload,
  };
  // update the loading state
  yield put(
    setLoading({
      loading: true,
    })
  );
  try {
    // call the claim api
    const claimResponse: any = yield call(axios, claimParams, apikeys);
    // get the result
    const { fnStatusCode, fnResult, fnMessage } = claimResponse?.data;
    // check result 200
    if (fnStatusCode === 200) {
      // prepare image to upload const
      const preparedImages: Array<{
        image: any;
        path: string;
        uploadData: any;
      }> = [];
      // get the result & convert into json parse
      const parsedFnResult: any = JSON.parse(fnResult);
      // images to be uploaded
      // prepare receipt images
      if (receiptImages && receiptImages.length > 0) {
        receiptImages.forEach((image: any, index: number) => {
          preparedImages.push({
            image,
            path: `claim-image/receipt_${
              parsedFnResult?.claim
            }_${`${index}.${image.nameWithExtension}`}?at=${
              payload?.accessToken
            }`,
            uploadData:
              image.type === "application/pdf" ? image : image.base64WithBuffer,
          });
        });
      }
      // retrieve claim Item Images
      const itemImages: { [key: number]: any } = {};
      // prepare the claim items
      claimItemsImages.forEach((item: any, index: number) => {
        if (item.prescriptionImages) {
          itemImages[index] = [...item.prescriptionImages];
        }
      });
      // item images
      // loop through item guids returned from response
      if (parsedFnResult.items[0] !== "") {
        for (let i = 0; i < parsedFnResult.items.length; i += 1) {
          // check each index in the {itemImages object}, if it exist (this means there are images associated to that item);
          if (itemImages[i] && itemImages[i].length > 0) {
            // add images to upload queue by looping through each.
            itemImages[i].forEach((image: any, index: number) => {
              preparedImages.push({
                image,
                path: `claim-image/claimitem_${
                  parsedFnResult?.items[i]
                }_${`${index}.${image.nameWithExtension}`}?at=${
                  payload?.accessToken
                }`,
                uploadData:
                  image.type === "application/pdf"
                    ? image
                    : image.base64WithBuffer,
              });
            });
          }
        }
      }
      // upload prepared Images
      if (preparedImages.length > 0) {
        yield all(
          preparedImages.map((data: any) => {
            const imageParams: any = {
              method: "PUT",
              path: data.path,
              data: data.uploadData,
              ...(data.image.type === "application/pdf"
                ? {
                    mimeType: "application/pdf",
                  }
                : { mimeType: data.image.type }),
            };
            return call(axios, imageParams, apikeys);
          })
        );
      }

      // Add guids to claim item
      for (let i = 0; i < parsedFnResult.items.length; i += 1) {
        payload.claimItems[i].claimHealthItemGUID = parsedFnResult.items[i];
      }

      // set payload action to submit
      payload.action = "submit";

      // prepare submit clai
      const updateClaimParams: any = {
        method: "PUT",
        path: `claims-oop/${parsedFnResult?.claim}`,
        data: payload,
      };

      // call the claims
      const claimSubmissionResponse: any = yield call(
        axios,
        updateClaimParams,
        apikeys
      );

      if (
        _.get(claimSubmissionResponse.data, "fnStatusCode") &&
        _.get(claimSubmissionResponse.data, "fnStatusCode") === 200
      ) {
        history.push(url);
        return yield put(
          setLoading({
            loading: false,
            claimSubmittedSuccessfully: true,
          })
        );
      } else {
        // throw the error
        throw {
          message: claimSubmissionResponse?.data?.fnMessage ?? "Server Error",
        };
      }
    } else {
      // throw the error
      throw new Error(fnMessage);
    }
  } catch (e) {
    let selectedAuthReducer: any = yield select(selectAuthReducer);
    let {
      preAuthDetails: { email },
    } = selectedAuthReducer;
    Sentry.captureException(e.message, {
      tags: {
        page: SENTRY_PAGES.claimDetails,
        type: SENTRY_ERRORS.claimSubmissionFailed,
        email,
      },
    });
    yield put(setLoading({ loading: false, errorMessage: e.message }));
    yield delay(5000);
    yield put(setLoading({ errorMessage: "" }));
  }
}

function* submitMastercardClaimWorker(action: any): Generator {
  // local state
  const {
    data: {
      payload,
      values,
      userClaimsDetails,
      history,
      url,
      type,
      claimHealthGUID,
    },
  } = action;

  // get the claim items images
  const claimItemsImages: Array<any> = values?.claimItems ?? [];
  // get the receipt images
  const receiptImages: Array<any> = userClaimsDetails?.receipt ?? [];
  //get api keys/tokens
  const apikeys: any = yield Auth.getAuthTokens();
  // params
  const claimParams: any = {
    method: "PUT",
    path: `claims-card/${claimHealthGUID}`,
    data: payload,
  };
  // update the loading state
  yield put(
    setLoading({
      loading: true,
    })
  );
  try {
    // call the claim api
    const claimResponse: any = yield call(axios, claimParams, apikeys);

    // get the result
    const { fnStatusCode, fnResult, fnMessage } = claimResponse?.data;
    // check result 200
    if (fnStatusCode === 200) {
      // prepare image to upload const
      const preparedImages: Array<{
        image: any;
        path: string;
        uploadData: any;
      }> = [];
      // get the result & convert into json parse
      // images to be uploaded

      // prepare receipt images
      if (receiptImages && receiptImages.length > 0) {
        receiptImages.forEach((image: any, index: number) => {
          preparedImages.push({
            image,
            path: `claim-image/receipt_${claimHealthGUID}_${`${index}.${image.nameWithExtension}`}?at=${
              payload?.accessToken
            }`,
            uploadData:
              image.type === "application/pdf" ? image : image.base64WithBuffer,
          });
        });
      }

      if (preparedImages.length > 0) {
        yield all(
          preparedImages.map((data: any) => {
            const imageParams: any = {
              method: "PUT",
              path: data.path,
              data: data.uploadData,
              ...(data.image.type === "application/pdf"
                ? {
                    mimeType: "application/pdf",
                  }
                : { mimeType: data.image.type }),
            };
            return call(axios, imageParams, apikeys);
          })
        );
      }

      // retrieve claim Item Images
      const itemImages: { [key: number]: any } = {};
      // prepare the claim items
      claimItemsImages.forEach((item: any, index: number) => {
        if (item.prescriptionImages) {
          itemImages[index] = [...item.prescriptionImages];
        }
      });

      // item images
      // loop through item guids returned from response
      if (fnResult.items[0] !== "") {
        for (let i = 0; i < fnResult.items.length; i += 1) {
          // check each index in the {itemImages object}, if it exist (this means there are images associated to that item);
          if (itemImages[i] && itemImages[i].length > 0) {
            // add images to upload queue by looping through each.
            itemImages[i].forEach((image: any, index: number) => {
              preparedImages.push({
                image,
                path: `claim-image/claimitem_${
                  fnResult?.items[i]
                }_${`${index}.${image.nameWithExtension}`}?at=${
                  payload?.accessToken
                }`,
                uploadData:
                  image.type === "application/pdf"
                    ? image
                    : image.base64WithBuffer,
              });
            });
          }
        }
      }

      for (let i = 0; i < fnResult.items.length; i += 1) {
        if (payload.claimItems[i]) {
          payload.claimItems[i].claimHealthItemGUID = fnResult.items[i];
        }
      }

      // set payload action to submit
      payload.action = "submit";

      // prepare submit clai
      const updateClaimParams: any = {
        method: "PUT",
        path: `claims-card/${claimHealthGUID}`,
        data: payload,
      };

      // call the claims
      const finalSubmissionResponse: any = yield call(
        axios,
        updateClaimParams,
        apikeys
      );

      if (
        _.get(finalSubmissionResponse.data, "fnStatusCode") &&
        _.get(finalSubmissionResponse.data, "fnStatusCode") === 200
      ) {
        history.push(url);
        return yield put(
          setLoading({
            loading: false,
            claimSubmittedSuccessfully: true,
          })
        );
      } else {
        // throw the error
        throw {
          message: finalSubmissionResponse?.data?.fnMessage ?? "Server Error",
        };
      }
    }
  } catch (e) {
    yield put(setLoading({ loading: false, errorMessage: e.message }));
    yield delay(5000);
    yield put(setLoading({ errorMessage: "" }));
  }
}

function* fetchMastercardClaimWorker(action: any): Generator {
  yield put(setLoading({ pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `claims/${action.data.id}?type="includeAll"&at="${selectedAuthReducer.accessToken}"`;
  let selectedClaimReducer: any = yield select(selectClaimReducer);

  const categorylist = has(selectedClaimReducer.claimCategories, "data")
    ? JSON.parse(selectedClaimReducer.claimCategories.data)
    : [];

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    const rawClaim = JSON.parse(jsonResponse.data.fnResult);
    const transformedClaim = yield call(transformReceivedClaim, {
      rawClaim,
      categorylist,
      tokens: {
        accessToken: selectedAuthReducer.accessToken,
        idToken: selectedAuthReducer.idToken,
        refreshToken: selectedAuthReducer.refreshToken,
      },
      dispatch: action.data.dispatch,
    });

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(updateMastercardClaim(transformedClaim));

      yield put(setLoading({ pageLoading: false }));
      return;
    }
  } catch (e) {
    yield put(setLoading({ pageLoading: false }));
  }
}

function* fetchClaimImagesWorker(action: any): Generator {
  yield put(setLoading({ pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys = yield Auth.getAuthTokens();
  let params: any = {
    method: "get",
    path: `claim-image/${action.data}?at=${selectedAuthReducer.accessToken}&authorization=${selectedAuthReducer.idToken}`,
    data: {},
  };

  try {
    jsonResponse = yield call(axios, params, apikeys);
  } catch (error) {
    console.log("fetchClaimImagesError: ", error);
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchClaimCategoriesWatcher(): Generator {
  yield takeLatest("FETCH_CLAIM_CATEGORIES", fetchClaimCategoriesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchNotesWatcher(): Generator {
  yield takeLatest("FETCH_NOTES", fetchNotesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateNotesWatcher(): Generator {
  yield takeLatest("UPDATE_NOTES", updateNotesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchClaimsWatcher(): Generator {
  yield takeLatest("FETCH_CLAIMS", fetchClaimsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getClaimWatcher(): Generator {
  yield takeLatest("GET_CLAIM", getClaimWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateClaimWatcher(): Generator {
  yield takeEvery("UPDATE_CLAIM", updateClaimWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateClaimItemWatcher(): Generator {
  yield takeEvery("UPDATE_CLAIM_ITEM", updateClaimItemWorker);
}

/**
 * @return {*}  {Generator}
 */
function* uploadClaimImageWatcher(): Generator {
  yield takeEvery("UPLOAD_CLAIM_IMAGE", uploadClaimImage);
}

/**
 * @return {*}  {Generator}
 */
function* createClaimWatcher(): Generator {
  yield takeEvery("CREATE_CLAIM", createClaimWorker);
}

/**
 * @return {*}  {Generator}
 */
function* submitClaimWatcher(): Generator {
  yield takeEvery("SUBMIT_CLAIM", createClaimWorker);
}

/**
 * @return {*}  {Generator}
 */
function* batchClaimImageUploadWatcher(): Generator {
  yield takeEvery("BATCH_CLAIM_IMAGE_UPLOADS", batchClaimImageUploadWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createAndSubmitClaimWatcher(): Generator {
  yield takeEvery("CREATE_AND_SUBMIT_CLAIM", createAndSubmitClaimWorker);
}

function* fetchMastercardClaimWatcher(): Generator {
  yield takeEvery("FETCH_MASTERCARD_CLAIM", fetchMastercardClaimWorker);
}

function* fetchClaimImagesWatcher(): Generator {
  yield takeEvery("FETCH_CLAIM_IMAGES", fetchClaimImagesWorker);
}

function* submitMastercardClaimWatcher(): Generator {
  yield takeEvery("SUBMIT_MASTERCARD_CLAIM", submitMastercardClaimWorker);
}

function* fetchClaimUsageStatsWatcher(): Generator {
  yield takeEvery("FETCH_CLAIM_USAGE_STATS", fetchClaimUsageStatsWorker);
}

export {
  fetchClaimsWatcher,
  fetchClaimCategoriesWatcher,
  getClaimWatcher,
  updateClaimWatcher,
  updateClaimItemWatcher,
  fetchNotesWatcher,
  updateNotesWatcher,
  uploadClaimImageWatcher,
  createClaimWatcher,
  submitClaimWatcher,
  batchClaimImageUploadWatcher,
  createAndSubmitClaimWatcher,
  fetchMastercardClaimWatcher,
  fetchClaimImagesWatcher,
  submitMastercardClaimWatcher,
  fetchClaimUsageStatsWatcher,
};
