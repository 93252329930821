// Reselect
import { createSelector } from "reselect";

// States
const sensiBill = (state: any) => state.sensiBillReducer;

// Loading
export const loadingSensiBill = createSelector(
  sensiBill,
  (sensiBill) => sensiBill.loading
);

// Register sensi bill
export const registerSensiBill = createSelector(
  sensiBill,
  (sensiBill) => sensiBill
);

// Register sensi bill
export const loginSensiBill = createSelector(
  sensiBill,
  (sensiBill) => sensiBill
);

// Register sensi bill
export const createReceipt = createSelector(
  sensiBill,
  (sensiBill) => sensiBill
);

// Register sensi bill
export const polling = createSelector(sensiBill, (sensiBill) => sensiBill);

// Register sensi bill
export const getReceipt = createSelector(sensiBill, (sensiBill) => sensiBill);

// Get the sensi bill token
export const getSensiBillToken = createSelector(
  sensiBill,
  (sensiBill) => sensiBill.sensiBillAccessToken
);

// Has error
export const getSensiBillHasErrors = createSelector(
  sensiBill,
  (sensiBill) => sensiBill.hasErrors
);

// Error
export const getSensiBillError = createSelector(
  sensiBill,
  (sensiBill) => sensiBill.error
);
