import React from 'react';
import styles from './views.module.scss';

const ESignAgreement = () => {
	return (
		<div className={ styles.terms }>
			<h2>Aya Prepaid Mastercard&reg; &ndash; Aya Mastercard Account E-Sign Agreement</h2>
			<p>Note: This E-Communication Disclosure (&ldquo;Disclosure&rdquo;) applies to any and all communications or disclosures that we are legally required to provide to you in writing in connection with your Aya Prepaid Mastercard&reg; (&ldquo;Aya Mastercard&rdquo;) and any related products and services (&ldquo;E-Communications&rdquo;). This Disclosure supplements and is to be construed in accordance with the terms contained in the Cardholder Agreement (&ldquo;Agreement&rdquo;) The words &ldquo;we,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo; refer to Aya Payments, Inc. The words &ldquo;you&rdquo; and &ldquo;your&rdquo; mean you, the individual(s) identified on the Card Account. As used in this Disclosure, &ldquo;Card Account&rdquo; means the Aya Mastercard Account you have with us.</p>
			<h3>1. Scope of Communications to Be Provided in Electronic Form</h3>
			<p>When you use a product or service to which this Disclosure applies, you agree that we will provide you with any communications or disclosures in electronic format only, and that we may discontinue sending paper communications and disclosures to you, unless and until you withdraw your consent to E-Communications as described below. Your consent to receive E-Communications includes, but is not limited to:</p>
			<ul>
			<li>All legal and regulatory disclosures and communications associated with AyaMastercard and any related products and services.</li>
			<li>Your Cardholder Agreement and any Notices about a change in the terms of your Cardholder Agreement.</li>
			<li>Privacy policies and notices.</li>
			<li>Error Resolution policies and notices.</li>
			<li>Responses to claims filed in connection with your Card Account.</li>
			<li>Notices regarding insufficient funds or negative balances.</li>
			</ul>
			<h3>2. Method of Providing Communications to You in Electronic Form</h3>
			<p>All E-Communications that we provide to you will be provided by posting such E-Communications on our website at <a href="http://www.ayacare.com">www.ayacare.com</a></p>
			<h3>3. How to Withdraw Consent</h3>
			<p>You may withdraw your consent to receive E-Communications at any time by sending us an email at <a href="mailto:support@ayacare.com">support@ayacare.com</a> or sending a letter to: Aya Payments, Inc.,&nbsp;</p>
			<p>P.O. Box 60014, Thorncrest PO, Etobicoke, ON., M9A 3L0. If you do withdraw your consent, we will send subsequent Communications to you in writing to the most current mailing address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive E-Communications will be effective only after we have a reasonable period of time to process your withdrawal of consent. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.</p>
			<h3>4. How to Update Your Records</h3>
			<p>It is your responsibility to provide us with a true, accurate and complete e-mail address, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information (such as your e-mail address) by: 1) Logging into your account at <a href="http://www.ayacare.com">www.ayacare.com</a> ; 2) Calling the toll-free number, 1-(888) 427-6682; or 3) By sending us an email at <a href="mailto:support@ayacare.com">support@ayacare.com</a>.&nbsp;</p>
			<h3>5. Hardware and Software Requirements</h3>
			<p>In order to access, view, and retain Electronic Communications that we make available to you, you must have an Internet connection. You will need to use an Internet web browser which is capable of supporting 128-bit SSL encrypted communications, which requires a minimum web browser version of either Microsoft&reg; Internet Explorer version 11.0, Mozilla Firefox, Chrome, or Safari. Your system or device must have 128-bit SSL encryption software. Your access to this page verifies that your browser and encryption software/device meet these requirements. Javascript and cookies must also be enabled. You will also need a printer capable of printing text screens or a hard drive capable of storing data.</p>
			<h3>6. Requesting Paper Copies&nbsp;</h3>
			<p>We will not send you a paper copy of any Communication unless you request it, or we otherwise deem it appropriate to do so. You can obtain a paper copy of an ElectronicCommunication by printing it yourself from <a href="http://www.ayacare.com">www.ayacare.com</a> or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the E-Communication to you. To request a paper copy, you may: 1) Log into your account at <a href="http://www.ayacare.com">www.ayacare.com</a>&nbsp;2) Call the toll-free number, 1 (888) 427-6682 or 3) Send us an email at <a href="mailto:support@ayacare.com">support@ayacare.com</a> . We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any required disclosure or communication that you have authorized us to provide electronically.</p>
			<h3>7. Communications in Writing</h3>
			<p>All Communications in either electronic or paper format from us to you will be considered &ldquo;in writing.&rdquo; You should print or download for your records a copy of this Disclosure and any other E-Communication that is important to you.</p>
			<h3>8. Federal Law</h3>
			<p>You acknowledge and agree that your consent to E-Communications is being provided in connection with the federal Personal Information Protection and Electronic Documents Act (PIPEDA) This Agreement will be governed by and interpreted in accordance with Canadian law. The courts in the Canadian province or territory in which you reside will have exclusive jurisdiction over any disputes arising in connection with the Account or this Agreement.</p>
			<ol start="9">
			<li>Termination/Changes</li>
			</ol>
			<p>We reserve the right, in our sole discretion, to discontinue the provision of your E-Communications, or to terminate or change the terms and conditions on which we provide E-Communications. We will provide you with notice of any such termination or change as required by law.</p>
			<h3>Customer Service</h3>
			<p>For all customer service information regarding the Aya Prepaid Mastercard, please contact:</p>
			<p>1 (888) 427-6682</p>
			<p>or</p>
			<p>Aya Payments, Inc.,</p>
			<p>P.O. Box 60014</p>
			<p>Thorncrest PO,&nbsp;</p>
			<p>Etobicoke, ON., M9A 3L0</p>
			<p>This Agreement is effective September 25, 2020 (PCS ID 10435) <em>Aya Prepaid Mastercard card is issued by Peoples Trust Company under licence from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.&nbsp;</em></p>
			<h2><br /><br /></h2>
		</div>
	);
};

export default ESignAgreement;
