import _ from "lodash";
import { call, put, select, takeLatest, all } from "redux-saga/effects";
import {
  editCompany,
  editNewPlanAdmin,
  setLoading,
  updateAccountants,
  updateAuthorizedSigners,
  updateAyaSalesReps,
  updateBeneficiaryOwners,
  updateCompanies,
  updateCompanyGeneralInformation,
  updateDirectors,
  updateFullCompanies,
  updateIndustries,
  updatePcoTpa,
  updatePlanSponsorAdministrator,
  updatePlanSponsorAdministratorPrimary,
  updatePlanSponsorContact,
  updateProductCodes,
  updateCompanyAllInfo,
  updateCompanyInvoiceFile,
  clearCompanyInvoiceFile,
  updateCompanyInvoiceList,
  updateReport,
  clearReport,
  updateReportsCache,
  clearBalances,
  updateBalances,
  setSelfEnrolmentDetails,
  submitSelfEnrolmentCompanyData,
  saveSelfEnrolmentCompanyData,
  setPcoEnrolmentDetails,
  updatePcoEnrolmentDetails,
  clearClasses,
  updateClassInStore,
} from "../actions";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/companyNavigation";
import { AYA_GUID } from "./utilities/constants";
import externalAxios from "axios";
import { personToPayloadMapper } from "../components/dashboard/planAdminPcoEnrolment/utilities/pcoEnrolment-helpers";
import { classList } from "../components/dashboard/selfEnrolment/sampleData";

const MAX_RETRIES = 3;

//Self enrolment
const BASE_URL = `${process.env.REACT_APP_API_URL}/v1/`;
const HEADERS = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_API_KEY,
};

// Company reducer
const selectCompany = (state: any) => state.companyReducer;
// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @return {*}  {Generator}
 */
function* fetchProductCodesWorker(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `facts/?type="banks"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateProductCodes(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchIndustriesWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `facts/?type="industries"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateIndustries(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchCompaniesWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (selectedAuthReducer.userClaims.userRole == "superAdmin") {
    params.path = `businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="planSponsor"&at=${selectedAuthReducer.accessToken}`;
  } else {
    params.path = `businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="planSponsor"&at=${selectedAuthReducer.accessToken}`;
    // params.path = 'businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="planSponsor"';
  }
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(updateCompanies(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchFullCompaniesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
  yield put(updateFullCompanies([]));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let userRole: any = selectedAuthReducer.userClaims.userRole;
  let queryStringRole: any = roleBasedQueryResolver(userRole);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (
    userRole === "brokerageHouseAdministratorPrimary" ||
    userRole === "brokerageHouseAdministrator" ||
    userRole === "broker" ||
    userRole === "tpaAdministrator" ||
    userRole === "tpaAdministratorPrimary" ||
    userRole === "tpaServiceRepresentative" ||
    userRole === "superAdmin"
  ) {
    params.path = `lists/?type="${queryStringRole}cyviewlist"&limit=${
      _.get(action, "data.limit") || 20000
    }&offset=${_.get(action, "data.offset") || 0}&at=${
      selectedAuthReducer.accessToken
    }`;
  } else {
    params.path = `businesses?type="includeOvw"&referenceType="planSponsor"&limit="1000"&at=${selectedAuthReducer.accessToken}`;
  }
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false, pageLoading: false }));
      return yield put(
        updateFullCompanies(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getCompanyDetailsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `businesses/${action.data}/?info=withFullBusinessInfo&at=${selectedAuthReducer.accessToken}`;

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false, pageLoading: false }));
      return yield put(
        updateCompanyGeneralInformation(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc fetch PCO TPA
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchPcoTpaWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  params.path = `businesses/${action.data}/?type="listSlim"&referenceType="hierarchy"&referenceValue="thirdPartyAdministratorPayor"&at=${selectedAuthReducer.accessToken}`;

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(updatePcoTpa(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Get company house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/${action.data}?at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);

      yield put(editCompany(parsedJsonResponse));
    }
    yield put(setLoading({ loading: false }));
    return;
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
  return "okay";
}

/**
 * @desc Get New Plan Admin Invite
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getNewPlanAdminWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `invites/${action.data}?at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnResult) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
      return yield put(editNewPlanAdmin(parsedJsonResponse));
    }
    yield put(setLoading({ loading: false }));
    return;
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
  return "okay";
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorAdministratorWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorAdministrator"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updatePlanSponsorAdministrator(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorAdministratorPrimaryWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorAdministratorPrimary"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updatePlanSponsorAdministratorPrimary(
          JSON.parse(jsonResponse.data.fnResult)
        )
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorContactWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorContact"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updatePlanSponsorContact(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchAuthorizedSignersWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorAuthorizedSigner"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateAuthorizedSigners(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchDirectorsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorDirector"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(updateDirectors(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchBeneficiaryOwnersWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorBeneficiary"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      //update each beneficiary owner to include the percentage of ownership
      let beneficialOwnersList: any = JSON.parse(jsonResponse.data.fnResult);
      for (let owner of beneficialOwnersList) {
        let ownersParams: any = {
          method: "get",
          path: `persons/${owner.personGuid}`,
          data: {},
        };
        let ownersJsonResponse: any = yield call(axios, ownersParams, apikeys);

        if (ownersJsonResponse.data.fnResult) {
          let parsedJsonResponse: any = JSON.parse(
            ownersJsonResponse.data.fnResult
          );

          if (
            _.has(parsedJsonResponse, "metaInformation") &&
            typeof parsedJsonResponse.metaInformation !== "undefined" &&
            typeof parsedJsonResponse.metaInformation.forEach === "function"
          ) {
            parsedJsonResponse.metaInformation.forEach((item: any) => {
              if (item.metaKey === "amountOfOwnership") {
                owner[item.metaKey] = item.metaValue;
              }
              if (item.metaKey === "isDirector") {
                owner[item.metaKey] = item.metaValue;
              }
            });
          }
        }
      }
      yield put(setLoading({ loading: false }));
      return yield put(updateBeneficiaryOwners(beneficialOwnersList));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchAccountantsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="planSponsorAccountant"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateAccountants(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchAyaSalesRepsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="Sales"&referenceType="business"&referenceValue=${AYA_GUID}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateAyaSalesReps(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc create company house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    //get api keys/tokens
    //added new comment
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make a create request
    invite = yield call(axios, action.data, apikeys);
    //check for error
    if (_.has(invite.data, "errorMessage")) {
      throw { message: invite.data.errorMessage };
    } else {
      if (invite.data.fnResult) {
        invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
      }
      params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

      //after retrieving invite, make a call to invite api to retrive invite payload for redux store
      jsonResponse = yield call(axios, params, apikeys);
      if (jsonResponse.data.fnResult) {
        parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
      }

      yield put(updateCompanyAllInfo(parsedJsonResponse));
      yield put(editCompany(parsedJsonResponse));
      let selectedCompany: any = yield select(selectCompany);
      let route: any = getRoute(selectedCompany.data);
      let routeData: any = getRouteData(selectedCompany.data);
      yield put(setLoading({ loading: false, error: "", ...routeData }));
      // return yield history.push(route, routeData);
      return yield history.push(route);
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc create new plan admin
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createNewPlanAdminWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    //get api keys/tokens
    //added new comment
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make a create request
    invite = yield call(axios, action.data, apikeys);
    if (_.has(invite.data, "errorMessage")) {
      throw { message: invite.data.errorMessage };
    } else {
      if (invite.data.fnResult) {
        invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
      }
      params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

      //after retrieving invite, make a call to invite api to retrive invite payload for redux store
      jsonResponse = yield call(axios, params, apikeys);
      if (jsonResponse.data.fnResult) {
        parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
      }
      return yield put(editNewPlanAdmin(parsedJsonResponse));
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc Update company house
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data, apikeys);

    if (
      _.has(jsonResponse.data, "errorMessage") ||
      jsonResponse?.data?.fnError
    ) {
      throw {
        message: jsonResponse.data.errorMessage ?? jsonResponse?.data?.fnError,
      };
    } else if (
      _.get(jsonResponse, "data.fnStatusCode") !== 200 &&
      _.get(jsonResponse, "data.fnStatusCode") !== 201
    ) {
      throw {
        message: _.get(jsonResponse, "data.fnMessage"),
      };
    } else {
      if (jsonResponse.data.fnMessage) {
        yield put(updateCompanyAllInfo(action.data.data));

        yield put(editCompany(action.data.data));
        let parsedJsonResponse: any = action.data.data;

        let route: any = getRoute(parsedJsonResponse);
        let routeData: any = getRouteData(parsedJsonResponse);

        yield put(setLoading({ loading: false, ...routeData }));
        return yield history.push(route);
      }
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return { feErrorMessage: ex.message, error: ex };
  }
}

/**
 * @desc Update new plan admin
 * @param {*} action
 * @return {*}  {*}
 */
function* updateNewPlanAdminWorker(action: any): any {
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    //get api keys/tokens
    apikeys = yield Auth.getAuthTokens();
    //using keys and params, make an update request
    jsonResponse = yield call(axios, action.data, apikeys);
    if (_.has(jsonResponse.data, "errorMessage")) {
      throw { message: jsonResponse.data.errorMessage };
    } else {
      if (jsonResponse.data.fnMessage) {
        return yield put(editNewPlanAdmin(action.data.data));
      }
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return { feErrorMessage: ex.message, error: ex };
  }
}

/**
 * @desc Fetch company info
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchCompanyAllInfoWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons/${action.data}?type="includeInvites"&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      let parsedJsonResponse: any = JSON.parse(jsonResponse.data.fnResult);
      yield put(updateCompanyAllInfo(JSON.parse(jsonResponse.data.fnResult)));
      let route: any = getRoute(parsedJsonResponse);
      let routeData: any = getRouteData(parsedJsonResponse);

      //To fetch addresses
      // let invite = JSON.parse(jsonResponse.data.fnResult).inviteGuid;
      // invite = invite.replace(/^"(.*)"$/, '$1');
      // params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;
      // jsonResponse = yield call(axios, params, apikeys);
      // if (jsonResponse.data.fnResult) {
      //   parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
      // }
      // yield put(editCompany(parsedJsonResponse));

      yield put(
        setLoading({ loading: false, pageLoading: false, ...routeData })
      );

      return yield history.push(route);
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

function* getCompanyInvoiceFileWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(clearCompanyInvoiceFile());
  let selectedAuthReducer: any = yield select(selectAuthReducer);

  let fileResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/${action.data.businessGuid}/invoices/${action.data.invoiceNumber}/download`,
    responseType: "arraybuffer",
    acceptType: "application/pdf",
  };

  try {
    apikeys = yield Auth.getAuthTokens();

    fileResponse = yield call(axios, params, apikeys);

    if (fileResponse && fileResponse.data) {
      const dataBlob = fileResponse.data;
      yield put(updateCompanyInvoiceFile({ dataBlob }));
      yield put(setLoading({ loading: false, pageLoading: false }));
    } else {
      yield put(
        setLoading({
          loading: false,
          pageLoading: false,
          errorMessage: `Could not fetch invoice file ${action.data.invoiceNumber}. Please try again later or contact support@ayacare.com`,
        })
      );
    }
  } catch (e) {
    yield put(
      setLoading({
        loading: false,
        pageLoading: false,
        errorMessage: `Could not fetch invoice file ${action.data.invoiceNumber}. Please try again later or contact support@ayacare.com`,
      })
    );
  }
}

function* getInvoiceListWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/${action.data.businessGuid}/invoices?at=${selectedAuthReducer.accessToken}`,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      (jsonResponse.data?.fnStatusCode === 200 ||
        jsonResponse.data?.fnStatusCode === 201) &&
      jsonResponse.data?.invoices
    ) {
      yield put(updateCompanyInvoiceList(jsonResponse.data.invoices));
    } else {
      throw {
        message: _.get(jsonResponse, "data.fnMessage"),
      };
    }

    yield put(updateCompanyInvoiceList(jsonResponse.data.invoices));
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          e.message ??
          "Could not fetch your invoices. Please try again later or contact support@ayacare.com",
      })
    );
    return e;
  }
}

function* getReportWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(clearReport());
  const recentDownloadItem = action.data.payload;
  const reportType = action.data.reportType.split(" ").join("").toLowerCase();

  let url = `businesses/${action.data.businessGuid}/reports/${reportType}/download?startDate=${action.data.payload.startDate}&endDate=${action.data.payload.endDate}`;
  if (action.data.payload.startDate && !action.data.payload.endDate) {
    url = `businesses/${action.data.businessGuid}/reports/${reportType}/download?startDate=${action.data.payload.startDate}`;
  }
  if (!action.data.payload.startDate && !action.data.payload.endDate) {
    url = `businesses/${action.data.businessGuid}/reports/${reportType}/download`;
  }

  let fileResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: url,
    responseType: "blob",
    acceptType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    fileResponse = yield call(axios, params, apikeys);

    if (fileResponse && fileResponse.data) {
      const dataBlob = fileResponse.data;
      yield put(
        updateReport({
          fileData: dataBlob,
          reportType: action.data.payload.reportType,
        })
      );
      yield put(
        updateReportsCache({ recentReports: new Array(recentDownloadItem) })
      );
      yield put(setLoading({ loading: false, pageLoading: false }));
    } else {
      yield put(
        setLoading({
          loading: false,
          pageLoading: false,
          errorMessage: `Could not generate report. Please try again later or contact support@ayacare.com`,
        })
      );
    }
  } catch (e) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          e.message ??
          "Could not generate report. Please try again later or contact support@ayacare.com",
      })
    );
    return e;
  }
}

function* getBalancesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(clearBalances());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/${action.data.businessGuid}/balances`,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (jsonResponse && !jsonResponse.data?.isSuccess) {
      throw {
        message: _.get(jsonResponse, "data.error.message"),
      };
    }

    yield put(updateBalances(jsonResponse.data.data));
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          e.message ??
          "Could not fetch your balances. Please try again later or contact support@ayacare.com",
      })
    );
    return e;
  }
}

//Self Enrolment

export function* getSelfEnrolmentUserData(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(setSelfEnrolmentDetails({ gettingSelfEnrolmentUserData: true }));
  let jsonResponse: any = null;
  let params: any = {
    method: "get",
    path:
      `${BASE_URL}businesses/selfEnrollment?email=` +
      encodeURIComponent(action.data.email),
    options: {
      headers: {
        ...HEADERS,
        ...{
          "verification-code": action.data.verificationCode,
        },
      },
    },
  };

  try {
    jsonResponse = yield externalAxios.get(params.path, params.options);

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw jsonResponse.data.fnError ?? "";
    }

    yield put(
      setSelfEnrolmentDetails({
        getSelfEnrolmentUserDataSuccess: true,
        getSelfEnrolmentUserDataFailed: false,
        userData: jsonResponse.data.data ?? "[]",
        selectedCompanyGuid: jsonResponse.data.data
          ? jsonResponse.data.data[0]?.guid
          : "",
        verificationCode: action.data.verificationCode,
        email: action.data.email,
        gettingSelfEnrolmentUserData: false,
        businessLegalName: jsonResponse.data.data[0]?.businessName,
        enrolmentStatus: jsonResponse.data.data[0]?.status,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        error: true,
        errorMessage: ex.response?.data?.fnMessage ?? "Unknown",
      })
    );
    yield put(
      setSelfEnrolmentDetails({
        getSelfEnrolmentUserDataSuccess: false,
        getSelfEnrolmentUserDataFailed: true,
        gettingSelfEnrolmentUserData: false,
      })
    );
    return ex;
  }
}

export function* getSelfEnrolmentCompanyData(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(setSelfEnrolmentDetails({ gettingSelfEnrolmentCompanyData: true }));
  let jsonResponse: any = null;
  let params: any = {
    method: "get",
    path: `${BASE_URL}businesses/selfEnrollment/${action.data.guid}`,
    options: {
      headers: {
        ...HEADERS,
        ...{
          "verification-code": action.data.verificationCode,
        },
      },
    },
  };

  try {
    jsonResponse = yield externalAxios.get(params.path, params.options);

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw jsonResponse.data.fnError ?? "";
    }

    let routeData: any = getRouteData(jsonResponse.data.data.data);

    yield put(
      setSelfEnrolmentDetails({
        getSelfEnrolmentCompanyDataSuccess: true,
        getSelfEnrolmentCompanyDataFailed: false,
        companyData: jsonResponse.data.data.data,
        gettingSelfEnrolmentCompanyData: false,
        routeData: routeData,
        businessLegalName: jsonResponse.data.data.businessName,
        enrolmentStatus: jsonResponse.data.data.status,
      })
    );

    return yield put(setLoading({ loading: false, ...routeData }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        getSelfEnrolmentCompanyDataSuccess: false,
        getSelfEnrolmentCompanyDataFailed: true,
        gettingSelfEnrolmentCompanyData: false,
      })
    );
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

export function* createSelfEnrolmentCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(
    setSelfEnrolmentDetails({ creatingSelfEnrolmentCompanyData: true })
  );
  let jsonResponse: any = null;
  let params: any = {
    method: "post",
    path: `${BASE_URL}businesses/selfEnrollment`,
    options: {
      headers: {
        ...HEADERS,
        ...{
          "verification-code": action.data.verificationCode,
        },
      },
    },
    data: {
      businessName: action.data.businessName,
      data: action.data.companyData,
      email: action.data.email,
    },
  };

  try {
    jsonResponse = yield externalAxios.post(
      params.path,
      params.data,
      params.options
    );

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw jsonResponse.data.fnError ?? "";
    }

    let routeData: any = getRouteData(JSON.parse(action.data.companyData));

    yield put(
      setSelfEnrolmentDetails({
        createSelfEnrolmentCompanyDataSuccess: true,
        createSelfEnrolmentCompanyDataFailed: false,
        selectedCompanyGuid: jsonResponse.data.data,
        businessLegalName: action.data.businessName,
        creatingSelfEnrolmentCompanyData: false,
        companyData: JSON.parse(action.data.companyData),
        routeData: routeData,
      })
    );

    return yield put(setLoading({ loading: false, ...routeData }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        saveSelfEnrolmentCompanyDataSuccess: false,
        saveSelfEnrolmentCompanyDataFailed: true,
        selectedCompanyGuid: "",
        businessLegalName: "",
        creatingSelfEnrolmentCompanyData: false,
      })
    );
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

export function* saveSelfEnrolmentCompanyDataWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(setSelfEnrolmentDetails({ savingSelfEnrolmentCompanyData: true }));
  let jsonResponse: any = null;
  let params: any = {
    method: "post",
    path: `${BASE_URL}businesses/selfEnrollment/${action.data.guid}/save`,
    options: {
      headers: {
        ...HEADERS,
        ...{
          "verification-code": action.data.verificationCode,
        },
      },
    },
    data: {
      businessName: action.data.businessName,
      data: action.data.companyData,
    },
  };

  try {
    jsonResponse = yield externalAxios.post(
      params.path,
      params.data,
      params.options
    );

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw new Error(jsonResponse);
    }

    let routeData: any = getRouteData(JSON.parse(action.data.companyData));

    yield put(
      setSelfEnrolmentDetails({
        saveSelfEnrolmentCompanyDataSuccess: true,
        saveSelfEnrolmentCompanyDataFailed: false,
        companyData: JSON.parse(action.data.companyData),
        savingSelfEnrolmentCompanyData: false,
        // routeData: routeData,
      })
    );

    if (routeData.view !== "STEP_SEVEN") {
      yield put(
        setSelfEnrolmentDetails({
          routeData: routeData,
        })
      );
    } else {
      return yield put(
        submitSelfEnrolmentCompanyData({
          guid: action.data.guid,
          verificationCode: action.data.verificationCode,
          companyData: action.data.companyData,
          businessName: action.data.businessName,
        })
      );
    }

    return yield put(setLoading({ loading: false, ...routeData }));
  } catch (error) {
    console.log(error?.response?.data?.fnMessage);
    yield put(
      setLoading({
        loading: false,
        error: true,
        errorMessage: error?.response?.data?.fnMessage,
      })
    );
    yield put(
      setSelfEnrolmentDetails({
        saveSelfEnrolmentCompanyDataSuccess: false,
        saveSelfEnrolmentCompanyDataFailed: true,
        savingSelfEnrolmentCompanyData: false,
      })
    );
    return error;
  }
}

export function* submitSelfEnrolmentCompanyDataWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(
    setSelfEnrolmentDetails({ submittingSelfEnrolmentCompanyData: true })
  );
  let jsonResponse: any = null;
  let params: any = {
    method: "post",
    path: `${BASE_URL}businesses/selfEnrollment/${action.data.guid}/submit`,
    options: {
      headers: {
        ...HEADERS,
        ...{
          "verification-code": action.data.verificationCode,
        },
      },
    },
  };

  try {
    jsonResponse = yield externalAxios.post(params.path, null, params.options);

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw new Error(jsonResponse);
    }

    let routeData: any = getRouteData(JSON.parse(action.data.companyData));

    yield put(
      setSelfEnrolmentDetails({
        submitSelfEnrolmentCompanyDataSuccess: true,
        submitSelfEnrolmentCompanyDataFailed: false,
        submittingSelfEnrolmentCompanyData: false,
        enrolmentStatus: "Submitted",
        routeData: routeData,
      })
    );

    return yield put(setLoading({ loading: false, ...routeData }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        submitSelfEnrolmentCompanyDataSuccess: false,
        submitSelfEnrolmentCompanyDataFailed: true,
        submittingSelfEnrolmentCompanyData: false,
      })
    );
    yield put(
      saveSelfEnrolmentCompanyData({
        verificationCode: action.data.verificationCode,
        companyData: action.data.companyData
          .toString()
          .replace("FULL-STEP_SEVEN-COMPANY", "FULL-STEP_SIX-COMPANY"),
        guid: action.data.guid,
        businessName: action.data.businessName,
      })
    );
    yield put(
      setLoading({
        loading: false,
        error: true,
      })
    );
    return ex;
  }
}

export function* getAllSelfEnroledCompaniesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(setSelfEnrolmentDetails({ gettingSelfEnrolmentCompanies: true }));

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/selfEnrollment`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.data?.fnStatusCode !== 200 &&
      jsonResponse.data?.fnStatusCode !== 201
    ) {
      throw {
        message: _.get(jsonResponse, "data.fnMessage"),
      };
    }

    yield put(
      setSelfEnrolmentDetails({
        getSelfEnroledCompaniesSuccess: true,
        gettingSelfEnrolmentCompanies: false,
        getSelfEnroledCompaniesFailed: false,
        selfEnroledCompanies: jsonResponse.data.data,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        getSelfEnroledCompaniesSuccess: false,
        getSelfEnroledCompaniesFailed: true,
        gettingSelfEnrolmentCompanies: false,
      })
    );
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

export function* getSelfEnroledCompanyDetailsWorker(action: any): Generator {
  yield put(
    setLoading({
      loading: true,
      errorMessage: "",
      getSelEnrolmentDetailsSuccess: false,
    })
  );

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `businesses/selfEnrollment/${action.data.companyGuid}`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.data?.fnStatusCode !== 200 &&
      jsonResponse.data?.fnStatusCode !== 201
    ) {
      throw {
        message: _.get(jsonResponse, "data.fnMessage"),
      };
    }

    yield put(
      setSelfEnrolmentDetails({
        getSelfEnroledCompanDetailsFailed: false,
        companyData: jsonResponse.data.data.data,
        businessLegalName: jsonResponse.data.data.businessName,
        enrolmentStatus: jsonResponse.data.data.status,
        email: jsonResponse.data.data.email,
        updatedOn: jsonResponse.data.data.updatedAt,
        selectedCompanyGuid: jsonResponse.data?.data?.guid,
        getSelEnrolmentDetailsSuccess: true,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        getSelfEnroledCompanDetailsFailed: true,
        getSelEnrolmentDetailsSuccess: false,
        businessLegalName: "",
        companyData: {},
      })
    );
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

export function* setApproveSelfEnroledCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(
    setSelfEnrolmentDetails({
      companySelfEnrolmentApproved: false,
      companySelfEnrolmentApprovalFailed: false,
    })
  );

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `businesses/selfEnrollment/${action.data.guid}/approve?at=${selectedAuthReducer.accessToken}`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error();
    }

    yield put(
      setSelfEnrolmentDetails({
        companySelfEnrolmentApproved: true,
        companySelfEnrolmentApprovalFailed: false,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        error: true,
        errorMessage:
          jsonResponse?.response?.data?.fnError ??
          jsonResponse?.response?.data?.fnMessage ??
          "Oops something went wrong. Please try again later or contact support.",
      })
    );
    yield put(
      setSelfEnrolmentDetails({
        companySelfEnrolmentApproved: false,
        companySelfEnrolmentApprovalFailed: true,
      })
    );
    return ex;
  }
}

export function* setDeclineSelfEnroledCompanyWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(
    setSelfEnrolmentDetails({
      companySelfEnrolmentDeclined: false,
      companySelfEnrolmentDeclineFailed: false,
    })
  );

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `businesses/selfEnrollment/${action.data.guid}/decline`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error();
    }

    yield put(
      setSelfEnrolmentDetails({
        companySelfEnrolmentDeclined: true,
        companySelfEnrolmentDeclineFailed: false,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        error: true,
        errorMessage:
          jsonResponse?.response?.data?.fnError ??
          "Oops something went wrong. Please try again later or contact support.",
      })
    );
    yield put(
      setSelfEnrolmentDetails({
        companySelfEnrolmentDeclined: false,
        companySelfEnrolmentDeclineFailed: true,
      })
    );
    return ex;
  }
}

export function* saveReviewedSelfEnrolmentCompanyDataWorker(
  action: any
): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(
    setSelfEnrolmentDetails({ savingReviewedSelfEnrolmentCompanyData: true })
  );
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `businesses/selfEnrollment/${action.data.guid}/save`,
    data: {
      businessName: action.data.businessName,
      data: action.data.companyData,
    },
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw new Error(jsonResponse);
    }

    yield put(
      setSelfEnrolmentDetails({
        companyData: JSON.parse(action.data.companyData),
        savingReviewedSelfEnrolmentCompanyData: false,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        savingReviewedSelfEnrolmentCompanyData: false,
      })
    );
    yield put(
      setLoading({
        loading: false,
        error: true,
      })
    );
    return ex;
  }
}

export function* triggerPcoEnrolmentWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "", error: false }));
  yield put(setPcoEnrolmentDetails({ enrolingPcoSingle: true }));

  let statUpdateArr: any = [];
  action.data.personPayloadList.forEach((personPayload: any) => {
    statUpdateArr.push({
      status: "pending",
      email: personPayload.payload?.email,
    });
  });
  yield put(updatePcoEnrolmentDetails(statUpdateArr));
  statUpdateArr = [];

  try {
    let newAxiosPcoEnrolParam = function (personDetails: any) {
      return {
        method: "post",
        path: `persons/enroll`,
        data: {
          businessGuid: action.data?.businessGuid,
          ...personToPayloadMapper(personDetails.payload),
        },
      };
    };
    let postPcoEnrolParams: any[] = [];
    let responses = [];
    let chunkResponse: any = null;
    let apikeys: any = null;
    apikeys = yield Auth.getAuthTokens();

    action.data.personPayloadList.forEach((personDetails: any) => {
      postPcoEnrolParams.push(newAxiosPcoEnrolParam(personDetails));
    });

    chunkResponse = yield all(
      postPcoEnrolParams.map((params) => call(axios, params, apikeys))
    );
    responses = [...chunkResponse];

    responses.map((response, index) => {
      if (response.data) {
        let respData = response.data;
        let metaData = respData.data;
        let responseStatus = respData.isSuccess ? "successful" : "failed";
        if (response.data?.isSuccess) {
          statUpdateArr.push({
            status: "successful",
            email: metaData?.email,
            errors: [],
          });
        }
      } else if (response.response?.data) {
        let respData = response.response?.data;
        let responseStatus = response.response?.data.isSuccess
          ? "successful"
          : "failed";
        statUpdateArr.push({
          status: responseStatus,
          email: JSON.parse(response.config?.data)?.person?.email,
          errors: respData.error?.metadata
            ? respData.error?.metadata
            : { genericMsg: respData.error?.message },
        });
      } else {
        statUpdateArr.push({
          status: "failed",
          email: JSON.parse(response.config?.data)?.person?.email,
          errors: { genericMsg: "Unknown error" },
        });
      }
    });
    yield put(updatePcoEnrolmentDetails(statUpdateArr));

    yield put(
      setPcoEnrolmentDetails({
        enrolingPcoSingle: false,
      })
    );
    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setPcoEnrolmentDetails({
        enrolingPcoSingle: false,
      })
    );
    yield put(
      setLoading({
        loading: false,
        error: true,
      })
    );
    return ex;
  }
}

export function* addClassWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "", successMessage: "" }));
  yield put(clearClasses());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `persons/add/familyclass`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse.status >= 200 &&
      jsonResponse.status < 300 &&
      jsonResponse.data.data[0]?.statusCode >= 200 &&
      jsonResponse.data.data[0]?.statusCode < 300
    ) {
      yield put(
        updateClassInStore({
          responseStatus: {
            classAddedSuccessfully: true,
          },
        })
      );
      const fetchPayload = {
        data: {
          businessGuid: action.data.payload.businessGuid,
        },
      };
      yield call(fetchClassesWorker, fetchPayload);
    } else {
      yield put(
        updateClassInStore({
          responseStatus: {
            classAddedFailed: true,
            response: jsonResponse.response.data.fnMessage ?? "",
          },
        })
      );
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      updateClassInStore({
        responseStatus: {
          classAddedFailed: true,
          response: e.response?.data?.fnMessage ?? {},
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

export function* updateClassWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "", successMessage: "" }));
  yield put(clearClasses());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `business/update/classdetails`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse.status >= 200 &&
      jsonResponse.status < 300 &&
      jsonResponse.data.data[0]?.statusCode >= 200 &&
      jsonResponse.data.data[0]?.statusCode < 300
    ) {
      yield put(
        updateClassInStore({
          responseStatus: {
            classUpdatedSuccessfully: true,
          },
        })
      );
      const fetchPayload = {
        data: {
          businessGuid: action.data.payload.businessGuid,
        },
      };

      yield call(fetchClassesWorker, fetchPayload);
    } else {
      yield put(
        updateClassInStore({
          responseStatus: {
            classUpdatedFailed: true,
            response: jsonResponse.response.data.fnMessage ?? "",
          },
        })
      );
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      updateClassInStore({
        responseStatus: {
          classUpdatedFailed: true,
          response: e.response?.data?.fnMessage ?? {},
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

export function* fetchClassesWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "", successMessage: "" }));
  yield put(clearClasses());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `business/${action.data?.businessGuid}/fetchclasses`,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse.status >= 200 &&
      jsonResponse.status < 300 &&
      jsonResponse.data?.fnStatusCode >= 200 &&
      jsonResponse.data?.fnStatusCode < 300
    ) {
      yield put(
        updateClassInStore({
          classList: jsonResponse.data.data?.data ?? [],
          responseStatus: {
            classFetchedSuccessfully: true,
          },
        })
      );
    } else {
      yield put(
        updateClassInStore({
          responseStatus: {
            classFetchedFailed: true,
            response: jsonResponse?.data?.data[0]?.message ?? "",
          },
        })
      );
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      updateClassInStore({
        responseStatus: {
          classFetchedFailed: true,
          response: e.response?.data?.fnMessage ?? {},
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchProductCodesWatcher(): Generator {
  yield takeLatest("FETCH_PRODUCT_CODES", fetchProductCodesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchIndustriesWatcher(): Generator {
  yield takeLatest("FETCH_INDUSTRIES", fetchIndustriesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchCompaniesWatcher(): Generator {
  yield takeLatest("FETCH_COMPANIES", fetchCompaniesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchFullCompaniesWatcher(): Generator {
  yield takeLatest("FETCH_FULL_COMPANIES", fetchFullCompaniesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getCompanyDetailsWatcher(): Generator {
  yield takeLatest("GET_COMPANY_GENERAL_INFORMATION", getCompanyDetailsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchPcoTpaWatcher(): Generator {
  yield takeLatest("FETCH_PCO_TPA", fetchPcoTpaWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getCompanyWatcher(): Generator {
  yield takeLatest("GET_COMPANY", getCompanyWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getNewPlanAdminWatcher(): Generator {
  yield takeLatest("GET_NEW_PLAN_ADMIN", getNewPlanAdminWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorAdministratorWatcher(): Generator {
  yield takeLatest(
    "FETCH_PLAN_SPONSOR_ADMIN",
    fetchPlanSponsorAdministratorWorker
  );
}

/**
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorAdministratorPrimaryWatcher(): Generator {
  yield takeLatest(
    "FETCH_PLAN_SPONSOR_ADMIN_PRIMARY",
    fetchPlanSponsorAdministratorPrimaryWorker
  );
}

/**
 * @return {*}  {Generator}
 */
function* fetchPlanSponsorContactWatcher(): Generator {
  yield takeLatest("FETCH_PLAN_SPONSOR_CONTACT", fetchPlanSponsorContactWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchAuthorizedSignersWatcher(): Generator {
  yield takeLatest("FETCH_AUTHORIZED_SIGNERS", fetchAuthorizedSignersWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchDirectorsWatcher(): Generator {
  yield takeLatest("FETCH_DIRECTORS", fetchDirectorsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchBeneficiaryOwnersWatcher(): Generator {
  yield takeLatest("FETCH_BENEFICIARY_OWNERS", fetchBeneficiaryOwnersWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchAccountantsWatcher(): Generator {
  yield takeLatest("FETCH_ACCOUNTANTS", fetchAccountantsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchAyaSalesRepsWatcher(): Generator {
  yield takeLatest("FETCH_AYA_SALES_REPS", fetchAyaSalesRepsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createCompanyWatcher(): Generator {
  yield takeLatest("CREATE_COMPANY", createCompanyWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createNewPlanAdminWatcher(): Generator {
  yield takeLatest("CREATE_NEW_PLAN_ADMIN", createNewPlanAdminWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateCompanyWatcher(): Generator {
  yield takeLatest("UPDATE_COMPANY", updateCompanyWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateNewPlanAdminWatcher(): Generator {
  yield takeLatest("UPDATE_NEW_PLAN_ADMIN", updateNewPlanAdminWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchCompanyAllInfoWatcher(): Generator {
  yield takeLatest("FETCH_COMPANY_ALL_INFO", fetchCompanyAllInfoWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getCompanyInvoiceFileWatcher(): Generator {
  yield takeLatest("GET_COMPANY_INVOICE_FILE", getCompanyInvoiceFileWorker);
}

/**
 * @return {*}  {Generator}
 * @desc fetch invoice list
 */
function* getInvoiceListWatcher(): Generator {
  yield takeLatest("GET_INVOICE_LIST", getInvoiceListWorker);
}

/**
 * @return {*}  {Generator}
 * @desc fetch report
 */
function* getReportwatcher(): Generator {
  yield takeLatest("GET_REPORT", getReportWorker);
}

/**
 * @return {*}  {Generator}
 * @desc fetch report
 */
function* getBalanceswatcher(): Generator {
  yield takeLatest("GET_BALANCES", getBalancesWorker);
}

function* getSelfEnrolmentUserDataWatcher(): Generator {
  yield takeLatest("GET_SELF_ENROLMENT_USER_DATA", getSelfEnrolmentUserData);
}

function* getSelfEnrolmentCompanyDataWatcher(): Generator {
  yield takeLatest(
    "GET_SELF_ENROLMENT_COMPANY_DATA",
    getSelfEnrolmentCompanyData
  );
}

function* saveSelfEnrolmentCompanyDataWatcher(): Generator {
  yield takeLatest(
    "SAVE_SELF_ENROLMENT_COMPANY_DATA",
    saveSelfEnrolmentCompanyDataWorker
  );
}

function* createSelfEnrolmentCompanyWatcher(): Generator {
  yield takeLatest(
    "CREATE_SELF_ENROLMENT_COMPANY",
    createSelfEnrolmentCompanyWorker
  );
}

function* submitSelfEnrolmentCompanyDataWatcher(): Generator {
  yield takeLatest(
    "SUBMIT_SELF_ENROLMENT_COMPANY_DATA",
    submitSelfEnrolmentCompanyDataWorker
  );
}

function* getAllSelfEnroledCompaniesWatcher(): Generator {
  yield takeLatest(
    "GET_ALL_SELF_ENROLLED_COMPANIES",
    getAllSelfEnroledCompaniesWorker
  );
}

function* getSelfEnroledCompanyDetailsWatcher(): Generator {
  yield takeLatest(
    "GET_SELF_ENROLLED_COMPANY_DETAILS",
    getSelfEnroledCompanyDetailsWorker
  );
}

function* setApproveSelfEnroledCompanyWatcher(): Generator {
  yield takeLatest(
    "SET_APPROVE_SELF_ENROLLED_COMPANY",
    setApproveSelfEnroledCompanyWorker
  );
}

function* setDeclineSelfEnroledCompanyWatcher(): Generator {
  yield takeLatest(
    "SET_DECLINE_SELF_ENROLLED_COMPANY",
    setDeclineSelfEnroledCompanyWorker
  );
}

function* saveReviewedSelfEnrolmentCompanyDataWatcher(): Generator {
  yield takeLatest(
    "SAVE_REVIEWED_SELF_ENROLMENT_COMPANY_DATA",
    saveReviewedSelfEnrolmentCompanyDataWorker
  );
}

function* triggerPcoEnrolmentWatcher(): Generator {
  yield takeLatest("TRIGGER_PCO_ENROLMENT", triggerPcoEnrolmentWorker);
}

function* addClassWatcher(): Generator {
  yield takeLatest("ADD_CLASS", addClassWorker);
}

function* updateClassWatcher(): Generator {
  yield takeLatest("UPDATE_CLASS", updateClassWorker);
}

function* fetchClassesWatcher(): Generator {
  yield takeLatest("FETCH_CLASSES", fetchClassesWorker);
}

export {
  fetchCompaniesWatcher,
  fetchFullCompaniesWatcher,
  createCompanyWatcher,
  updateCompanyWatcher,
  fetchIndustriesWatcher,
  getCompanyWatcher,
  fetchPcoTpaWatcher,
  createNewPlanAdminWatcher,
  updateNewPlanAdminWatcher,
  getNewPlanAdminWatcher,
  fetchAuthorizedSignersWatcher,
  fetchDirectorsWatcher,
  fetchBeneficiaryOwnersWatcher,
  fetchAccountantsWatcher,
  fetchProductCodesWatcher,
  getCompanyDetailsWatcher,
  fetchPlanSponsorContactWatcher,
  fetchPlanSponsorAdministratorPrimaryWatcher,
  fetchPlanSponsorAdministratorWatcher,
  fetchAyaSalesRepsWatcher,
  fetchCompanyAllInfoWatcher,
  getCompanyInvoiceFileWatcher,
  getInvoiceListWatcher,
  getReportwatcher,
  getBalanceswatcher,
  getSelfEnrolmentUserDataWatcher,
  getSelfEnrolmentCompanyDataWatcher,
  saveSelfEnrolmentCompanyDataWatcher,
  createSelfEnrolmentCompanyWatcher,
  submitSelfEnrolmentCompanyDataWatcher,
  getAllSelfEnroledCompaniesWatcher,
  getSelfEnroledCompanyDetailsWatcher,
  setApproveSelfEnroledCompanyWatcher,
  setDeclineSelfEnroledCompanyWatcher,
  saveReviewedSelfEnrolmentCompanyDataWatcher,
  triggerPcoEnrolmentWatcher,
  addClassWatcher,
  updateClassWatcher,
  fetchClassesWatcher,
};
