/**
 * @desc get route data
 * @param {*} data
 * @return {*}  {*}
 */
export const getRouteData = (data: any): any => {
  // let step = data.inviteState.step;
  let step: string = data.inviteState ? data.inviteState.step : "";
  switch (step) {
    case "PRELIMINARY-STEP_ONE-TPA":
      return {
        view: "STEP_ONE",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: "",
      };
    case "PRELIMINARY-STEP_TWO-TPA":
      return {
        view: "STEP_TWO",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: data.business.businessOperatedName,
      };
    case "FULL-STEP_ONE-TPA":
      return {
        view: "STEP_ONE",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: data.business.businessOperatedName,
      };
    case "FULL-STEP_TWO-TPA":
      return {
        view: "STEP_TWO",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: data.business.businessOperatedName,
      };
    case "FULL-STEP_THREE-TPA":
      return {
        view: "STEP_THREE",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: data.business.businessOperatedName,
      };
    case "FULL-STEP_FOUR-TPA":
      return {
        view: "STEP_FOUR",
        largeHeader: "Add a New Third Party Representative",
        largeSubHeader: data.business.businessOperatedName,
      };
    case "FULL-STEP_FIVE-TPA":
      return {
        view: "STEP_FIVE",
        largeHeader: "",
        largeSubHeader: "",
      };
    case "FULL-STEP_SIX-TPA":
      return {
        view: "STEP_SIX",
        largeHeader: "",
        largeSubHeader: "",
      };
    // case 'COMPLETE-STEP_SEVEN-TPA':
    //   return {
    //     view: 'STEP_SEVEN',
    //     largeHeader: '',
    //     largeSubHeader: ''
    //   };
    default:
      break;
  }
};

/**
 * @desc get route
 * @param {*} data
 * @return {*}  {*}
 */
export const getRoute = (data: any): any => {
  let route: string = data.inviteState
    ? data.inviteState.step.split("-")[0]
    : "";
  if ((data.inviteState.status = {}))
    switch (route) {
      case "PRELIMINARY":
        return "/tpa/add";
      case "FULL":
        return "/tpa/add/full";
      case "COMPLETE":
        return "/dashboard";
      default:
        break;
    }
};
