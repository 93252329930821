import { takeLatest, select, call, put } from "redux-saga/effects";
import axios from "../utilities/axiosWrapper";
import _ from "lodash";
import { updateCartaReports, setLoading, updateCarta04 } from "../actions";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch carta report
 * @return {*}  {Generator}
 */
function* fetchCartaReportsWorker(): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let response: any = null;
  let params: any = {
    method: "GET",
    path: `lists?type=vendorpackagedeliverylist&at=${selectedAuthReducer.accessToken}`,
  };

  try {
    response = yield call(axios, params, apiKeys);
    if (_.has(response, "data.fnResult")) {
      yield put(updateCartaReports(JSON.parse(response.data.fnResult)));
      yield put(setLoading({ pageLoading: false }));
    }
  } catch (err) {
    console.log("fetchCartaReports Err: ", err);
    yield put(setLoading({ pageLoading: false }));
  }
}

/**
 * @desc Fetch carta04
 * @return {*}  {Generator}
 */
function* fetchCarta04Worker(action: any): Generator {
  yield put(setLoading({ pageLoading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let offset: any = action?.data?.page ? action?.data?.page * 10 - 10 : 0;
  let apiKeys: any = null;
  let response: any = null;
  let params: any = {
    method: "GET",
    path: `lists?type=vendorfilerecords&limit=${action?.data?.recordsPerPage}&offset=${offset}&at=${selectedAuthReducer.accessToken}`,
  };

  try {
    response = yield call(axios, params, apiKeys);
    if (_.has(response, "data.fnResult")) {
      yield put(updateCarta04(JSON.parse(response.data.fnResult)));
      yield put(setLoading({ pageLoading: false }));
    }
  } catch (err) {
    console.log("fetchCartaReports Err: ", err);
    yield put(setLoading({ pageLoading: false }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchCartaReportsWatcher(): Generator {
  yield takeLatest("FETCH_CARTA_REPORTS", fetchCartaReportsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchCarta04Watcher(): Generator {
  yield takeLatest("FETCH_CARTA_04", fetchCarta04Worker);
}

export { fetchCartaReportsWatcher, fetchCarta04Watcher };
