export const COLOR_SCHEMES = {
  PRIMARY_GRADIENT: {
    BLUE: "#2D42FF",
    PURPLE: "#E300B1",
    GREY: "#6B7280", // Grey 500
    PURPLE_SECONDARY: "#A131C7",
    BUTTON_DEACTIVATED: "#D1D5DB", // Grey 300
  },
  GREY: "#565862",
  WHITE: "#FFF",
  RED: "red",
  BLACK: "#3A3A3A",
  GREEN: "#4CBEB0",
  AYA_BLACK: {
    BLACK_50: "#3A3A3A",
  },
  AYA_RED: {
    RED_50: "red",
  },
  AYA_GREEN: {
    GREEN_50: "#4CBEB0",
  },
  GREYS: {
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827",
  },
};

export default {
  COLOR_SCHEMES,
};
