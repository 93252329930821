// Type
import { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateMastercardStatus = (data: any): Action => ({
  type: "UPDATE_MASTERCARD_STATUS",
  data,
});
