import moment from "moment";
import React, { useEffect } from "react";
import styled from "styled-components";
import { dateFormat } from "../../../../constants/DateFormat";

import { COLOR_SCHEMES } from "../../../../styles/colors/colors";
import { FONTS } from "../../../../styles/fonts/fonts";
import { BOX_SHADOWS } from "../../../../styles/other/boxShadow";
import CustomHeader from "../../../shared/customHeader/CustomHeader";
import CustomText from "../../../shared/customText/CustomText";
import GradientText from "../../../shared/GradientText/GradientText";

const MCConfirmation: React.FC<{ requestedClaim: any }> = ({
  requestedClaim,
}) => {
  const submissionDeadline = moment(requestedClaim?.activityDateOfSubmission)
    .add(1, "M")
    .format(dateFormat);

  return (
    <StyledContainer>
      <HeaderContainer>
        <CustomHeader fontWeight={"normal"}>Submit Details</CustomHeader>
        <CustomHeader as={"h3"} color={"GREYS.400"} fontWeight={"normal"}>
          Claim Number #{requestedClaim?.claimId}
        </CustomHeader>
      </HeaderContainer>

      <CustomText extraStyles={{ textAlign: "left" }}>
        This is a Card txn that requires supporting information in the form of a
        merchant receipt and claim details. It is mandatory for you to provide
        this information or else your employer may ask you to remit re-payment
        back into your Aya Care Account or may impose this full txn amount to
        your T4 or T4A Income Tax Return as a Taxable Benefit. Your account may
        also be subject to suspension.
        <br />
        <br />
        Your deadline for submission of details is on or before{" "}
        <GradientText
          string={submissionDeadline}
          inlineBlock
          fontSize={"1.15rem"}
        />
        .
      </CustomText>
    </StyledContainer>
  );
};

export default MCConfirmation;

const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const HeaderContainer = styled.div`
  margin-bottom: 1rem;
`;
