// Types
import type { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchBrokerageHouses = (): Action => ({
  type: "FETCH_BROKERAGE_HOUSES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBrokerageHouses = (data: any): Action => ({
  type: "UPDATE_BROKERAGE_HOUSES",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchBanks = (): Action => ({
  type: "FETCH_BANKS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBanks = (data: any): Action => ({
  type: "UPDATE_BANKS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createBrokerageHouse = (data: any): Action => ({
  type: "CREATE_BROKERAGE_HOUSE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const newBrokerageHouse = (data: any): Action => ({
  type: "NEW_BROKERAGE_HOUSE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editBrokerageHouse = (data: any): Action => ({
  type: "EDIT_BROKERAGE_HOUSE",
  data,
});

/**
 * @param {*} data
 * @param {*} flag
 * @return {*}  {Action}
 */
export const updateBrokerageHouse = (data: any, flag?: any): Action => ({
  type: "UPDATE_BROKERAGE_HOUSE",
  data,
  flag,
});

/**
 * @return {*}  {Action}
 */
export const getBrokerageHouse = (): Action => ({
  type: "GET_BROKERAGE_HOUSE",
});

/**
 * @return {*}  {Action}
 */
export const showBrokerageHouse = (): Action => ({
  type: "SHOW_BROKERAGE_HOUSE",
});

/**
 * @return {*}  {Action}
 */
export const clearBrokerageHouse = (): Action => ({
  type: "CLEAR_BROKERAGE_HOUSE",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchBrokerageHouseInfo = (data: any): Action => ({
  type: "FETCH_BROKERAGE_HOUSE_DATA",
  data,
});
