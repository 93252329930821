// Axios
import axios from "axios";
// Lodash
import _ from "lodash";
// History
import history from "../history";
// Store
import store from "../store/configureStore";
// Services
import { deleteState } from "../store/localStorageService";
// Auth
import { checkAuth } from "./auth";

/** @type {*} */
const AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

//current axios implementation
/**
 * @param {*} authToken
 * @param {*} apiParams
 */
const setAuthorizationHeaders = (authToken: any, apiParams: any): void => {
  AxiosInstance.defaults.headers.Authorization = authToken;
};

/**
 * @param {*} accessToken
 * @param {*} apiParams
 */
const setAccessTokenInHeaders = (accessToken: any, apiParams: any): void => {
  AxiosInstance.defaults.headers.common["AccessToken"] = accessToken;
};

const deleteAccessTokenInHeaders = (): void => {
  delete AxiosInstance.defaults.headers.common["AccessToken"];
};

const setResponseType = (type: any): void => {
  AxiosInstance.defaults.responseType = type;
};

const setAcceptType = (type: any): void => {
  AxiosInstance.defaults.headers["Accept"] = type;
};

/**
 * @param {*} type
 */
const setContentTypeInHeaders = (type: any): void => {
  AxiosInstance.defaults.headers.common["Content-Type"] = type;
  AxiosInstance.defaults.headers["Content-Type"] = type;
};

/**
 * @param {*} params
 * @param {object} [authUser={}]
 * @param {boolean} [requiresAccessTokenHeader=false]
 * @return {*}  {Promise<any>}
 */
const axiosWrapper = async (
  params: any,
  authUser: object = {},
  requiresAccessTokenHeader: boolean = false
): Promise<any> => {
  let apiParams: { url: any; data?: any; method?: any } = {
    url: AxiosInstance.defaults.baseURL,
  };

  apiParams.method = params.method;
  apiParams.url += params.path;
  apiParams.data = params.data;

  if (_.has(authUser, "idToken")) {
    setAuthorizationHeaders(_.get(authUser, "idToken", null), apiParams);
  }

  if (params.mimeType) {
    setContentTypeInHeaders(params.mimeType);
  } else {
    setContentTypeInHeaders("application/json");
  }

  if (requiresAccessTokenHeader && _.has(authUser, "accessToken")) {
    setAccessTokenInHeaders(_.get(authUser, "accessToken", null), apiParams);
  } else {
    deleteAccessTokenInHeaders();
  }

  if (params.responseType) {
    setResponseType(params.responseType);
  } else {
    setResponseType("json");
  }

  if (params.acceptType) {
    setAcceptType(params.acceptType);
  } else {
    setAcceptType("application/json");
  }

  try {
    const response: any = await AxiosInstance(apiParams);
    return response;
  } catch (ex) {
    return ex;
  }
};

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  (config) => {
    const authUser: any = store.getState().authReducer;

    if (_.get(authUser, "idToken") && !checkAuth()) {
      deleteState();
      navigateToLogin();
    }
    return config;
  },
  function (error) {
    Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const authUser: any = store.getState().authReducer;
    if (error.response.status === 401 && _.get(authUser, "idToken")) {
      deleteState();
      navigateToLogin();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const navigateToLogin = (): void => {
  history.push("/", { message: "Your session has expired!" });
};

export default axiosWrapper;
