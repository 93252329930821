// React
import { ReactElement, FC } from "react";
// Routes
import { Redirect, Route } from "react-router-dom";
// Utilities
import { checkAuth } from "../utilities/auth";
// Interface
import { IRoute } from "./routes.interface";

/**
 * @param {*} {
 *   children,
 *   layout,
 *   layoutProps,
 *   ...rest
 * }
 * @return {*}  {ReactElement}
 */
export const PrivateRoute: FC<IRoute> = ({
  children,
  layout,
  layoutProps,
  ...rest
}: IRoute): ReactElement => {
  const Layout: any = layout;
  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        checkAuth() ? (
          Layout ? (
            <Layout layoutProps={layoutProps}>{children}</Layout>
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
