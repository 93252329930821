import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { COLOR_SCHEMES } from "../../../designSystem/colors";

const GradientText: React.FunctionComponent<{
  string: string;
  fontSize?: string;
  inlineBlock?: boolean;
  underline?: boolean;
  testid?: string;
  isDisabled?: boolean;
  extraStyles?: any;
  gradientStart?: string;
  fontWeight?: string | number;
  handleClick?: () => void;
  cursor?: string
}> = ({
  string,
  fontSize = "2.5rem",
  inlineBlock = false,
  underline,
  testid,
  isDisabled = false,
  extraStyles,
  gradientStart = "80%",
  fontWeight = 400,
  handleClick,
  cursor = "auto"
}) => {
  const defaultPrimary = COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE;
  const defaultSecondary = COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE;
  const [whiteLabel] = useSelector((state: any) => [
    state.authReducer.preAuthDetails.whiteLabel,
  ]);

  return (
    <StyledGradientText
      primaryColor={whiteLabel?.primarycolor ?? defaultPrimary}
      secondaryColor={whiteLabel?.secondarycolor ?? defaultSecondary}
      fontSize={fontSize}
      cursor={cursor}
      inlineBlock={inlineBlock}
      underline={underline}
      data-testid={testid}
      isDisabled={isDisabled}
      gradientStart={gradientStart}
      style={{ ...extraStyles }}
      fontWeight={fontWeight}
      onClick={() => handleClick && handleClick()}
    >
      {string}
    </StyledGradientText>
  );
};

interface ISCProps {
  primaryColor: string;
  secondaryColor: string;
  fontSize: string;
  inlineBlock: boolean;
  underline: boolean;
  fontWeight: string | number;
  cursor?: string;
}

const StyledGradientText = styled.p<ISCProps>`
  font-family: Montserrat;
  font-weight: 400;
  font-weight: ${(props) => props.fontWeight};
  background: -webkit-linear-gradient(
    45deg,
    ${(props: any) => (props.isDisabled ? "#000000" : props.primaryColor)},
    ${(props: any) => (props.isDisabled ? "#000000" : props.secondaryColor)}
      ${(props: any) => props.gradientStart} 0
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  cursor: ${(props: any) => props.cursor};
  font-size: ${(props: any) => props.fontSize};
  display: ${(props: any) => (props.inlineBlock ? "inline-block" : "block")};
  border-bottom: ${(props) =>
    props.underline ? `2px solid ${props.secondaryColor}` : "none"};

  &::selection {
    color: white;
    background: ${(props) => props.primaryColor};
    -webkit-text-fill-color: black;
  }

  @media (max-width: 700px) {
    font-size: 1.25rem;
  }
`;

export default GradientText;
