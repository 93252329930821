// Npm modules
import { FC, ReactElement, memo } from "react";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
// Shared components
import ArrowIconGradientRight from "../../../shared/icons/ArrowIconRightGradient";
import ArrowIconRight from "../../../shared/icons/ArrorIconRight";
// Style components
import { DisabledText, StyledGradientButton } from "./../styled.component";
// Selectors
import { getWhiteLabel } from "../../../../selectors";
import GradientText from "../../../shared/GradientText/GradientText";
import CustomText from "../../../shared/customText/CustomText";

/**
 * @interface IGradientButton
 */
interface IGradientButton {
  handleClick: () => void;
  isLoading: boolean;
  disabled: boolean | undefined;
  label?: string;
  style?: any;
  width?: string;
  propValues?: any;
  testid?: string;
}

/**
 * @param {*} { handleClick, isLoading, disabled }
 * @return {*}  {ReactElement}
 */
const GradientButton: FC<IGradientButton> = ({
  handleClick,
  isLoading,
  disabled,
  label,
  style,
  width = "10rem",
  propValues,
  testid = "gradientButton",
}: IGradientButton): ReactElement => {
  // constant
  const defaultPrimary = "#606ac1";
  const defaultSecondary = "#e300b1";

  // selectors
  const [whiteLabel] = useSelector((store) => [getWhiteLabel(store)]);

  return (
    <StyledGradientButton
      style={style}
      onClick={(e: any) => {
        e.preventDefault();
        handleClick && !disabled && handleClick();
      }}
      primaryColor={whiteLabel?.primarycolor ?? defaultPrimary}
      secondaryColor={whiteLabel?.secondarycolor ?? defaultSecondary}
      disabled={disabled}
      width={width}
      data-testid={`${testid}`}
      type={propValues?.type && propValues.type}
    >
      {isLoading ? (
        <Loader active inline="centered" size={"small"} />
      ) : (
        <>
          {label ? (
            disabled ? (
              <CustomText>{label}</CustomText>
            ) : (
              <GradientText fontSize={"16px"} string={label} cursor="pointer"/>
            )
          ) : disabled ? (
            <ArrowIconRight />
          ) : (
            <ArrowIconGradientRight />
          )}
        </>
      )}
    </StyledGradientButton>
  );
};

// Default export
export default memo(GradientButton);
