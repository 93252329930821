/**
 * @desc This method accepts a role as a string -
 * @param {string} role
 * @return {*}  {string} returns a string to be used in place of original role for query
 */
export const roleBasedQueryResolver = (role: string): string => {
  if (role) {
    switch (role) {
      case "superAdmin":
        return "sa";
      case "brokerageHouseAdministratorPrimary":
        return "bha";
      case "brokerageHouseAdministrator":
        return "bha";
      case "broker":
        return "br";
      case "tpaAdministrator":
        return "tpadm";
      case "tpaAdministratorPrimary":
        return "tpadm";
      case "tpaServiceRepresentative":
        return "tpasr";
      case "planSponsorAdministrator":
        return "pa";
      case "planSponsorAdministratorPrimary":
        return "pa";
      case "planSponsorContact":
        return "pa";
      case "PCO":
        return "pco";
      default:
        return "roleNotInHierarchy";
    }
  }
  return "UserRoleMissing";
};
