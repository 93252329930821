// React
import { FC, ReactElement, memo, useEffect } from "react";
// Redux
import { connect } from "react-redux";
// Semantic UI
import { Icon } from "semantic-ui-react";
// Redux actions
import { setLoading } from "../../../actions";
// Constant
import { errorMappings } from "../../../constants/ErrorMappings";
// Scss
import styles from "./Alert.module.scss";
import styled from "styled-components";

// Interface
interface IProps {
  message: any;
  rawMessage: any;
  options: any;
  close: any;
}

// Type
type iconMapperType = { [index: string]: ReactElement };

/** @type { success: ReactElement, error: ReactElement, info: ReactElement } */
const iconMapper: iconMapperType = {
  success: <Icon circular inverted color="green" name={"check"} />,
  error: <Icon circular inverted color="red" name={"close"} />,
  info: <Icon circular inverted color="blue" name={"info"} />,
};
//
/**
 * @param {IProps} { message, rawMessage, options, close }
 * @return {*}  {ReactElement}
 */
const Alert: FC<IProps> = ({
  message,
  rawMessage,
  options,
  close,
}: IProps): ReactElement => {
  return (
    <AlertContainer alertType={options.type}>
      {rawMessage ? rawMessage : errorMappings(message)}
    </AlertContainer>
  );
};

// Export with wrap HOC
export default connect(null, { setLoading })(memo(Alert));

const bgColorMapper: Record<string, string> = {
  success: "#dcfce7",
  error: "#F56C6C",
  info: "#409EFF",
};

const fontColorMapper: Record<string, string> = {
  success: "#82bd97",
  error: "#791616",
  info: "#2b74bd",
};

const AlertContainer = styled.div<{ alertType: string }>`
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  border-radius: 35px;
  background-color: ${(props: any) => bgColorMapper[props.alertType]};
  color: #000000;
  font-family: Montserrat;
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
`;
