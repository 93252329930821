import moment from "moment";
import React, { SetStateAction } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { fetchMastercardClaim, getClaim } from "../../../../actions";
import { dateFormat } from "../../../../constants/DateFormat";
import { COLOR_SCHEMES } from "../../../../styles/colors/colors";
import { FONTS } from "../../../../styles/fonts/fonts";
import GradientText from "../../../shared/GradientText/GradientText";

interface IMCClaimItem {
  setRequestedClaim: (claim: any) => void;
  claim: {
    assignedTo: string;
    certificateOwner: string;
    claimAmount: number;
    claimFlag: string;
    claimId: string;
    claimType: string;
    company: string;
    dateOfSubmission: string;
    id: string;
    merchantName: string;
    status: string;
    activityDateOfSubmission: string;
  };
  index: number;
}

const MCClaimListItem: React.FC<IMCClaimItem> = ({
  setRequestedClaim,
  claim,
  index,
}) => {
  const { claimAmount, claimId, activityDateOfSubmission, merchantName, id } =
    claim;
  const dispatch = useDispatch();

  const handleFetchClaim = () => {
    setRequestedClaim(claim);
    dispatch(fetchMastercardClaim({ id, dispatch }));
  };

  return (
    <Container
      onClick={() => handleFetchClaim()}
      data-testid={`mastercardClaim${index}`}
    >
      <RowItem>
        <GradientText string={merchantName} fontSize={"1.25rem"} cursor="pointer"/>

        <NumberFormat
          value={claimAmount}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          renderText={(formattedValue) => (
            <Value data-testid={`claimAmount-${index}-text`}>
              {formattedValue}
            </Value>
          )}
        />
      </RowItem>
      <RowItem>
        <GreyText>{claimId}</GreyText>
        <GreyText>
          {moment(activityDateOfSubmission).format(dateFormat)}
        </GreyText>
      </RowItem>
    </Container>
  );
};

export default MCClaimListItem;

const Container = styled.div`
  margin-bottom: 2rem;
  cursor: pointer;
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    margin-bottom: 0.5rem;
  }
`;

const Value = styled.div`
  color: ${COLOR_SCHEMES.BLACK};
  font-family: ${FONTS.FONT_1};
  font-size: 1.25rem;
`;

const GreyText = styled.div`
  color: ${COLOR_SCHEMES.GREY};
  font-family: ${FONTS.FONT_1};
`;
