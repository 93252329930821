/**
 * @desc get route data
 * @param {*} data
 * @return {*}  {*}
 */
export const getRouteData = (data: any): any => {
  let step: string = data.inviteState ? data.inviteState.step : "";
  switch (step) {
    case "PRELIMINARY-STEP_ONE-TPA_SR":
      return {
        view: "STEP_ONE",
        largeHeader: "Add a New Third Party Administrator",
        largeSubHeader: "",
      };
    case "PRELIMINARY-STEP_TWO-TPA_SR":
      return {
        view: "STEP_TWO",
        largeHeader: "",
        largeSubHeader: "",
      };
    default:
      return {
        view: "STEP_ONE",
        largeHeader: "Add a New Third Party Administrator",
        largeSubHeader: "",
      };
  }
};

/**
 * @desc get route
 * @param {*} data
 * @return {*}  {*}
 */
export const getRoute = (data: any): any => {
  let route: string = data.inviteState
    ? data.inviteState.step.split("-")[0]
    : "";
  switch (route) {
    case "PRELIMINARY":
      return "/tpa-sr/add";
    case "FULL":
      return "/tpa-sr/add/full";
    case "COMPLETE":
      return "/dashboard";
    default:
      break;
  }
};
