import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  ContainerInner,
  FooterItem,
  FlexRowContainer,
} from "./dashboardFooter.styled.components";

const DashboardFooter = () => {
  const [hsaDetails, wsaDetails] = useSelector((store) => [
    store.employeeReducer.hsaDetails,
    store.employeeReducer.wsaDetails,
  ]);

  const isPrepay = hsaDetails?.prepaid ?? wsaDetails?.prepaid;

  return (
    <Container>
      <ContainerInner>
        <FlexRowContainer>
          <FooterItem
            href={"https://ayacare.com/terms-of-services/"}
            target={"_blank"}
          >
            Terms of Service
          </FooterItem>
          <FooterItem
            href={"https://ayacare.com/privacy-policy/"}
            target={"_blank"}
          >
            Privacy Policy
          </FooterItem>
          <FooterItem
            href={"https://ayacare.com/esign-agreement/"}
            target={"_blank"}
          >
            eSign Agreement
          </FooterItem>
          {isPrepay && (
            <FooterItem
              href={"https://ayacare.com/cardholder-agreement/"}
              target={"_blank"}
            >
              Cardholder Agreement
            </FooterItem>
          )}
        </FlexRowContainer>

        <FlexRowContainer>
          <FooterItem>Tel: +1 (888) 427-6682</FooterItem>
          <FooterItem>Fax: +1 (647) 931-9311</FooterItem>
          <FooterItem href={"mailto:support@ayacare.com"} target={"_blank"}>
            support@ayacare.com
          </FooterItem>
        </FlexRowContainer>
      </ContainerInner>
    </Container>
  );
};

export default DashboardFooter;
