import styled from "styled-components";

export const IconContainer = styled.div<any>`
  background: "linear-gradient(45deg, #A131C7, #E300B1)";
  width: "72px";
  height: "72px";
  padding: "10px";
  borderradius: "20px";
  display: "flex";
  justifycontent: "center";
  alignitems: "center";
  boxshadow: "0 4px 6px rgba(0, 0, 0, 0.1)";
`;

export const Container = styled.div`
  fontfamily: "Montserrat" !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImgHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`;
