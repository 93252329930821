// Types
import type { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchBhaBrokers = (data: any): Action => ({
  type: "FETCH_BHA_BROKERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBhaBrokers = (data: any): Action => ({
  type: "UPDATE_BHA_BROKERS",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchFullBrokers = (): Action => ({
  type: "FETCH_FULL_BROKERS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateFullBrokers = (data: any): Action => ({
  type: "UPDATE_FULL_BROKERS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createBroker = (data: any): Action => ({
  type: "CREATE_BROKER",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editBroker = (data: any): Action => ({
  type: "EDIT_BROKER",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBroker = (data: any): Action => ({
  type: "UPDATE_BROKER",
  data,
});

/**
 * @return {*}  {Action}
 */
export const getBroker = (): Action => ({
  type: "GET_BROKER",
});

/**
 * @return {*}  {Action}
 */
export const clearBroker = (): Action => ({
  type: "CLEAR_BROKER",
});
