import { get } from "lodash";
import React, { ReactNode } from "react";
import { Button, ButtonProps, Header } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR_SCHEMES } from "../../../designSystem/colors";
import TYPOGRAPHY from "../../../designSystem/typography";

const { fontFamily, fontWeights } = TYPOGRAPHY;

const CustomHeader: React.FC<{
  children: ReactNode;
  color?: string;
  fontWeight?: string;
  extraStyles?: any;
  as?: string;
}> = ({
  children,
  color = "AYA_BLACK.BLACK_50",
  fontWeight = "medium",
  extraStyles,
  as = "h1",
}) => {
  return (
    <>
      <StyledTextContainer
        as={as}
        color={color}
        fontWeight={fontWeight}
        extraStyles={extraStyles}
      >
        {children}
      </StyledTextContainer>
    </>
  );
};

export default CustomHeader;

const StyledTextContainer: React.FC<ButtonProps> = styled(Header)`
  font-family: ${fontFamily.base};
  color: ${(props) => get(COLOR_SCHEMES, props.color)};
  font-weight: ${(props) => fontWeights[props.fontWeight]};
  margin: 0;
  ${(props) => ({ ...props.extraStyles })}
`;
