import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";

export const MenuOptionsBarContainer = styled.div`
  background-color: #ffffff;
  display: block;
  -webkit-box-shadow: 0px 2px 5px -1px rgb(0 0 0 / 8%);
  box-shadow: 0px 2px 5px -1px rgb(0 0 0 / 8%);
  z-index: 1;
  @media only screen and (max-width: 600px) {
    & {
      display: none;
    }
  }
`;

export const MenuOptions = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
`;

export const OptionItem = styled.li`
  padding: 1.25rem;

  cursor: pointer;
  @media only screen and (max-width: 600px) {
    & {
      margin: 1rem 0;
    }
  }
`;

interface IOptionTextProps {
  primaryColor: string;
  secondaryColor: string;
}

export const OptionText = styled(NavLink)<IOptionTextProps>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1rem;
  color: black;
  &:hover {
    color: black;
  }
  &.active {
    background: -webkit-linear-gradient(
      45deg,
      ${(props: any) => props.primarycolor},
      ${(props: any) => props.secondarycolor} 70% 0
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
