// Types
import { Action } from "../actions/types";

// State type
type State = {
  dashboardStats: Array<any>;
  general: {
    successMessage: string;
    errorMessage: string;
    postTransactionLoading: boolean;
    fetchLedgerListLoading: boolean;
  };
  collectionLedger: any;
};

// Initial state
const INITIAL_STATE: State = {
  dashboardStats: [],
  general: {
    successMessage: "",
    errorMessage: "",
    postTransactionLoading: false,
    fetchLedgerListLoading: false,
  },
  collectionLedger: "",
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "FETCH_LEDGER_STATS":
      return state;
    case "UPDATE_LEDGER_STATS":
      return { ...state, dashboardStats: action.data };
    case "FETCH_COLLECTION_LEDGER":
      return { ...state };
    case "UPDATE_LEDGER":
      return { ...state, [action.data.key]: action.data.data.transactions };
    case "UPDATE_COLLECTION_LEDGER":
      return { ...state, collectionLedger: action.data };
    case "CREATE_MEMO_TRANSACTION":
      return { ...state };
    case "UPDATE_GENERAL":
      return {
        ...state,
        general: { ...state.general, [action.data.type]: action.data.data },
      };
    default:
      return state;
  }
};
