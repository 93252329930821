/**
 * @desc get route data
 * @param {*} data
 * @return {*}  {*}
 */
export const getRouteData = (data: any): any => {
  let step: string = data.inviteState ? data.inviteState.step : data.inviteStep;

  switch (step) {
    case 'QUICK-STEP_ONE-COMPANY':
      return {
        view: 'STEP_ONE',
        largeHeader: 'Add a New Company',
        largeSubHeader: '',
      };
    case 'QUICK-STEP_TWO-COMPANY':
      return {
        view: 'STEP_TWO',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'QUICK-STEP_THREE-COMPANY':
      return {
        view: 'STEP_THREE',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'QUICK-STEP_FOUR-COMPANY':
      return {
        view: 'STEP_FOUR',
        largeHeader: '',
        largeSubHeader: '',
      };
    case 'FULL-STEP_ONE-COMPANY':
      return {
        view: 'STEP_ONE',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_TWO-COMPANY':
      return {
        view: 'STEP_TWO',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_THREE-COMPANY':
      return {
        view: 'STEP_THREE',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_FOUR-COMPANY':
      return {
        view: 'STEP_FOUR',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_FIVE-COMPANY':
      return {
        view: 'STEP_FIVE',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_SIX-COMPANY':
      return {
        view: 'STEP_SIX',
        largeHeader: 'Add a New Company',
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'FULL-STEP_SEVEN-COMPANY':
      return {
        view: 'STEP_SEVEN',
        largeHeader: '',
        largeSubHeader: '',
      };
    case 'ONBOARDING-STEP_ONE-COMPANY':
      return {
        view: 'STEP_ONE',
        largeHeader: `ONBOARDING ${data.business.businessOperatedName}`,
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'ONBOARDING-STEP_TWO-COMPANY':
      return {
        view: 'STEP_TWO',
        largeHeader: `ONBOARDING ${data.business.businessOperatedName}`,
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'ONBOARDING-STEP_THREE-COMPANY':
      return {
        view: 'STEP_THREE',
        largeHeader: `ONBOARDING ${data.business.businessOperatedName}`,
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'ONBOARDING-STEP_FOUR-COMPANY':
      return {
        view: 'STEP_FOUR',
        largeHeader: `ONBOARDING ${data.business.businessOperatedName}`,
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'ONBOARDING-STEP_FIVE-COMPANY':
      return {
        view: 'STEP_FIVE',
        largeHeader: `ONBOARDING ${data.business.businessOperatedName}`,
        largeSubHeader: data.business.businessOperatedName,
      };
    case 'ONBOARDING-STEP_SIX-COMPANY':
      return {
        view: 'STEP_SIX',
        largeHeader: '',
        largeSubHeader: '',
      };
    case 'COMPLETE-STEP_SEVEN-COMPANY':
      return {
        view: 'STEP_SIX',
        largeHeader: '',
        largeSubHeader: '',
      };
    default:
      break;
  }
};

/**
 * @desc get route
 * @param {*} data
 * @return {*}  {*}
 */
export const getRoute = (data: any): any => {
  let route: string = data.inviteState
    ? data.inviteState.step.split('-')[0]
    : data.inviteStep.split('-')[0];
  switch (route) {
    case 'QUICK':
      return '/companies/add/quick';
    case 'FULL':
      return '/companies/add/full';
    case 'ONBOARDING':
      return '/company/onboarding';
    case 'COMPLETE':
      return '/dashboard';
    default:
      break;
  }
};
