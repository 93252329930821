export const tabs = ["Batch", "Single"];

export const TEMPLATE_TYPES = [
  { id: 0, name: "Select Template Type" },
  { id: 1, name: "Prepay" },
  { id: 2, name: "Postpay" },
];

export const ENROLMENT_STATUSES = {
  notEnroled: { key: "notEnroled", value: "Not Enroled" },
  pending: { key: "pending", value: "Pending" },
  successful: { key: "successful", value: "Successful" },
  failed: { key: "failed", value: "Failed" },
};
