import _ from "lodash";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { SENTRY_ERRORS, SENTRY_PAGES } from "./utilities/sentry-error.constant";
import { json } from "msw/lib/types/context";
import { call, put, select, takeLatest, all } from "redux-saga/effects";
import {
  editEmployee,
  fetchFullEmployees,
  setLoading,
  updateEmployees,
  updateFullEmployees,
  updatePlanHSAWSA,
  updatePlanDetails,
  updateSuperAdmins,
  storeEmployeeDetails,
  updateEmployee,
  updateTerminationStats,
  clearTerminationStats,
  updateEmployeeCards,
  clearEmployeeCards,
  updateEmployeeCraHsaInfo,
  craLoading,
  setPinVerificationCodeLoading,
  setPinResetCompleted,
  clearFullEmployees,
  updateAccountDeletionInfo,
  clearAccountDeletionInfo,
  setFlexAllocationAPIResponse,
  clearUpdateEmployeeAllocationData,
  setUpdateEmployeeAllocationResponse,
  updateFullEmployeesAllocationDetails,
  clearContributionData,
  setContributionResponse,
  updateFullEmployeesContribution,
} from "../actions";
import {
  EmployeeOnboardingDetailsModel,
  SkipEnrolmentStepModel,
} from "../components/dashboard/employees/EmployeeOnboarding/EmployeeOnboarding.model";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import externalAxios from "axios";
import { getRoute, getRouteData } from "./navigation/employeeNavigation";
import { response } from "msw";
import { error } from "console";

export const PUBLIC_CRA_HSA_URL =
  "https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/lines-33099-33199-eligible-medical-expenses-you-claim-on-your-tax-return.html";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;
//create employee
export const selectEmployee = (state: any) => state.employeeReducer;
// Claims per request
const maxEmployeesPerRequest = 4000;
const CHUNK_SIZE = 2;

const MAX_RETRIES = 4;
let employeeFetchRetries = 0;

/**
 * @return {*}  {Generator}
 */
export function* fetchPlanDetailsWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    let response: any = yield call(getPlanDetailsSaga, {
      accessToken: selectedAuthReducer.accessToken,
    });

    let balances: any = _.get(response, "planDetails.balances");
    let summary: any = _.get(response, "planDetails.summary");
    yield put(
      updatePlanDetails({
        payload: response,
        balances: balances,
        summary: summary,
      })
    );
    yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

//Helper
/**
 * @param {*} data
 * @return {*}
 */
export async function getPlanDetailsSaga(data: any) {
  const accessToken: any = _.get(data, "accessToken");
  const getParams = (date: any) => {
    return {
      method: "GET",
      path: `person-accounts/?type="ppdve"&referenceType="date"&referenceValue="${date}"&at=${accessToken}`,
    };
  };

  const isPlanYearValid = (data: any) => {
    const { planYearStartDate = null }: any = data;
    return planYearStartDate != null;
  };

  const isResponseValid = (resp: any) =>
    resp && resp.status === 200 && resp.data.fnStatusCode === 200 ? resp : null;
  const queryDates: any = [
    moment.utc().startOf("day").format("YYYY-MM-DD HH:MM:SS").toString(),
  ];

  if (moment.utc(data.startYearDate).isBefore(moment.utc(), "years")) {
    const now: any = moment.utc();
    const end: any = moment.utc(data.startYearDate);
    const dif: any = now.diff(end, "years");

    if (dif >= 2) {
      queryDates.push(
        moment
          .utc()
          .subtract(1, "years")
          .startOf("day")
          .format("YYYY-MM-DD HH:MM:SS")
          .toString()
      );
      queryDates.push(
        moment
          .utc()
          .subtract(2, "years")
          .startOf("day")
          .format("YYYY-MM-DD HH:MM:SS")
          .toString()
      );
    } else if (dif === 1) {
      queryDates.push(
        moment
          .utc()
          .subtract(1, "years")
          .startOf("day")
          .format("YYYY-MM-DD HH:MM:SS")
          .toString()
      );
    }
  }
  const datePromises: any = queryDates.map((d) =>
    axios(getParams(d), data.auth)
  );
  try {
    const [currentYear, previousYear, twoYearsAgo]: any = await Promise.all(
      datePromises
    );
    const yearsSummary: any = {};
    const curYear: any = {};

    put(
      updatePlanDetails({ planDetails: JSON.parse(currentYear.data.fnResult) })
    );

    if (isResponseValid(twoYearsAgo)) {
      const plData: any = JSON.parse(twoYearsAgo.data.fnResult);
      if (isPlanYearValid(plData.summary[0].planYearDetail[0])) {
        yearsSummary["twoYearsAgo"] = plData;
      }
    }

    if (isResponseValid(previousYear)) {
      const plData: any = JSON.parse(previousYear.data.fnResult);
      if (isPlanYearValid(plData.summary[0].planYearDetail[0])) {
        yearsSummary["previousYear"] = plData;
      }
    }

    if (isResponseValid(currentYear)) {
      Object.assign(curYear, JSON.parse(currentYear.data.fnResult));
      const plData: any = JSON.parse(currentYear.data.fnResult);
      if (isPlanYearValid(plData.summary[0].planYearDetail[0])) {
        yearsSummary["currentYear"] = plData;
      }
    }

    return { yearsSummary, planDetails: JSON.parse(currentYear.data.fnResult) };
  } catch (e) {
    // yield put(setError('A problem occurred '));
    return {};
  }
}
//Helper

export function* fetchPlanHSAWSAWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `storedvalueaccounts?at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.data")) {
      yield put(setLoading({ loading: false }));
      return yield put(updatePlanHSAWSA(jsonResponse.data.data));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchEmployeesWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: `persons?type="PCO"&referenceType="business"&referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(updateEmployees(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @export
 * @desc fetch full list of employees
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchFullEmployeesWorker(action: any): Generator {
  yield put(
    setLoading({
      loading: true,
      pageLoading: true,
      errorMessage: "",
      employeeInitialFetchCompleted: false,
      employeesFetched: false,
    })
  );
  yield put(clearTerminationStats());
  yield put(clearFullEmployees());

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let userRole: any = selectedAuthReducer.userClaims.userRole;
  let queryStringRole: any = roleBasedQueryResolver(userRole);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (
    userRole === "brokerageHouseAdministratorPrimary" ||
    userRole === "brokerageHouseAdministrator" ||
    userRole === "broker" ||
    userRole === "tpaAdministrator" ||
    userRole === "tpaAdministratorPrimary" ||
    userRole === "tpaServiceRepresentative" ||
    userRole === "superAdmin"
  ) {
    params.path = `lists/?type="${queryStringRole}eeviewlist"&limit=${
      _.get(action, "data.limit") || 20000
    }&offset=${_.get(action, "data.offset") || 0}&at=${
      selectedAuthReducer.accessToken
    }`;
  } else {
    params.path = `persons?type="includeOvw"&referenceType="PCO"&limit=100000&at=${selectedAuthReducer.accessToken}`;
  }

  let newAxiosGetClaimsRequestParam = function (
    limit: number,
    offset: number,
    accessToken: string
  ) {
    return {
      method: "get",
      path: `lists/?type="${queryStringRole}eeviewlist"&limit=${limit}&offset=${offset}&at=${accessToken}`,
      data: {},
    };
  };
  let getEmployeesRequestsParams: any[] = [];
  let responses = [];

  try {
    if (userRole === "superAdmin") {
      let selectedEmployeeReducer: any = yield select(selectEmployee);
      let employeesCount: number = selectedEmployeeReducer?.fullEmployeeCount;

      if (action.data?.limit !== 1 && employeesCount > 0) {
        while (employeesCount > 0) {
          let offset =
            getEmployeesRequestsParams.length * maxEmployeesPerRequest;
          let request = newAxiosGetClaimsRequestParam(
            maxEmployeesPerRequest,
            offset,
            selectedAuthReducer.accessToken
          );
          getEmployeesRequestsParams.push(request);
          employeesCount -= maxEmployeesPerRequest;

          // call api in groups
          if (
            getEmployeesRequestsParams.length % CHUNK_SIZE === 0 ||
            employeesCount <= 0
          ) {
            let apikeys = yield Auth.getAuthTokens();
            let itemsToPick =
              employeesCount <= 0
                ? getEmployeesRequestsParams.length % CHUNK_SIZE || CHUNK_SIZE
                : CHUNK_SIZE;
            let chunkResponse: any[] = yield all(
              getEmployeesRequestsParams
                .slice(itemsToPick * -1)
                .map((params) => call(axios, params, apikeys))
            );
            responses.push(...chunkResponse);
          }
        }

        let employees: any[] = [];

        if (responses.length > 0) {
          let employeeData = {
            count: JSON.parse(responses[0].data.fnResult).count,
            type: JSON.parse(responses[0].data.fnResult).type,
          };
          responses.map((response, index) => {
            if (_.has(response, "data.fnResult")) {
              employees.push(...JSON.parse(response.data.fnResult).listData);
            }
          });
          yield put(
            setLoading({
              loading: false,
              pageLoading: false,
              employeesFetched: true,
            })
          );
          return yield put(
            updateFullEmployees({
              employeeList: {
                listData: employees,
                count: employeeData.count,
                // count: employeesCount,
                type: employeeData.type,
              },
            })
          );
        }
      } else {
        if (employeeFetchRetries > MAX_RETRIES) {
          yield put(
            setLoading({
              loading: false,
              pageLoading: false,
              employeeInitialFetchCompleted: false,
              employeesFetched: false,
            })
          );
          throw {
            message:
              "Failed to fetch employees. Please reload app and try again.",
          };
        }
        yield put(
          setLoading({
            employeeInitialFetchCompleted: false,
            employeesFetched: false,
          })
        );
        apikeys = yield Auth.getAuthTokens();
        params.path = `lists/?type="${queryStringRole}eeviewlist"&limit=1&offset=${
          _.get(action, "data.offset") || 0
        }&at=${selectedAuthReducer.accessToken}`;
        jsonResponse = yield call(axios, params, apikeys);
        employeeFetchRetries = employeeFetchRetries + 1;
        if (_.has(jsonResponse, "data.fnResult")) {
          if (JSON.parse(jsonResponse.data.fnResult).count > 0) {
            employeeFetchRetries = 0;
          }
          yield put(
            setLoading({
              loading: false,
              pageLoading: false,
              employeeInitialFetchCompleted: true,
              employeesFetched: true,
            })
          );
          return yield put(
            updateFullEmployees({
              employeeList: JSON.parse(jsonResponse.data.fnResult),
              employeeStats: jsonResponse?.data?.summary,
              fullEmployeeCount: JSON.parse(jsonResponse.data.fnResult).count,
            })
          );
        }
      }
    } else {
      apikeys = yield Auth.getAuthTokens();
      jsonResponse = yield call(axios, params, apikeys);
      if (_.has(jsonResponse, "data.fnResult")) {
        yield put(
          setLoading({
            loading: false,
            pageLoading: false,
            employeesFetched: true,
          })
        );
        return yield put(
          updateFullEmployees({
            employeeList: JSON.parse(jsonResponse.data.fnResult),
            employeeStats: jsonResponse?.data?.summary,
          })
        );
      }
    }
  } catch (e) {
    yield put(
      setLoading({
        loading: false,
        pageLoading: false,
        errorMessage: e.message,
        error: true,
        employeesFetched: false,
      })
    );
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* createEmployeeWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    invite = yield call(axios, action.data, apikeys);
    //check for error
    if (_.has(invite.data, "errorMessage")) {
      throw { message: invite.data.errorMessage };
    } else {
      if (invite.data.fnResult) {
        invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
      }
      params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

      jsonResponse = yield call(axios, params, apikeys);
      if (jsonResponse.data.fnResult) {
        parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
      }

      yield put(editEmployee(parsedJsonResponse));
      let selectedEmployee: any = yield select(selectEmployee);
      let route: any = getRoute(selectedEmployee.data);
      let routeData: any = getRouteData(selectedEmployee.data);
      yield put(setLoading({ loading: false, ...routeData }));
      return yield history.push(route);
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc Update Employee
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* updateEmployeeWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (
      _.has(jsonResponse?.data, "errorMessage") ||
      jsonResponse?.data?.fnError
    ) {
      throw {
        message: jsonResponse.data.errorMessage ?? jsonResponse?.data?.fnError,
      };
    } else {
      if (jsonResponse?.data?.fnMessage) {
        yield put(editEmployee(action.data.data));
        let parsedJsonResponse: any = action.data.data;
        let route: any = getRoute(parsedJsonResponse);
        let routeData: any = getRouteData(parsedJsonResponse);
        yield put(setLoading({ loading: false, ...routeData }));
        return yield history.push(route);
      }
    }
    yield put(
      setLoading({
        loading: false,
        errorMessage: "default",
      })
    );
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc Patch Employee
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* patchEmployeeWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "patch",
    path: `persons/${action.data.guid}`,
    data: action.data.body,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") === 400
    ) {
      throw { message: jsonResponse.data.fnMessage };
    }
    yield call(fetchFullEmployeesWorker);
    return yield put(
      setLoading({
        loading: false,
        alertType: "success",
        successMessage: "SuccessFully Updated Employee Status",
      })
    );
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc Patch Terminate Employee
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* terminateEmployeeWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(clearTerminationStats());
  let apikeys: any = null;
  let jsonResponse: any = null;
  const employeeDetails = action.data;
  let params: any = {
    method: employeeDetails.method,
    path: `persons/terminate?at=${employeeDetails.accessToken}`,
    data: employeeDetails.terminationData,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      !jsonResponse ||
      (jsonResponse.status !== 200 && jsonResponse.status !== 201) ||
      (_.get(jsonResponse.data, "fnStatusCode") &&
        _.get(jsonResponse.data, "fnStatusCode") !== 200 &&
        _.get(jsonResponse.data, "fnStatusCode") !== 201)
    ) {
      throw { message: jsonResponse.data.data[0].message };
    }
    yield put(updateTerminationStats(jsonResponse.data.data));
    yield put(
      setLoading({
        loading: false,
        alertType: "success",
        successMessage:
          jsonResponse.data.data[0].message ??
          "SuccessFully Updated Employee Status",
      })
    );
    yield call(fetchFullEmployeesWorker);
  } catch (ex) {
    return yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: ex.message,
      })
    );
  }
}

/**
 * @desc Resend email
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* resendEmployeeEmailWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "post",
    path: `invites?type=${action.data.type}`,
    data: action.data.body,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") === 400
    ) {
      throw { message: jsonResponse.data.fnMessage };
    }
    return yield put(
      setLoading({
        loading: false,
        alertType: "success",
        successMessage: "SuccessFully Updated Employee Status",
      })
    );
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

export function* fetchEmployeeDetailsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  // let jsonResponse2: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: `persons/planmember/${action.data}?at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    // jsonResponse2 = yield call(axios, params2, apikeys);

    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.data.fnError
    ) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: jsonResponse.data.fnError,
        })
      );
    }

    yield put(storeEmployeeDetails(JSON.parse(jsonResponse.data.fnResult)));

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

export function* updateEmployeeDetailsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (jsonResponse.data?.fnError) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: jsonResponse.data.fnError,
        })
      );
    }

    if (jsonResponse.status === 200) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "success",
          successMessage: "Plan member details successfully updated",
          nextAction: "refetchEmployeeList",
        })
      );
    }
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

export function* fetchEmployeeInviteDataWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params = {
    method: "get",
    path: `persons/${action.data}?type="includeInvites"`,
    data: {},
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));

      return yield put(editEmployee(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setLoading({ loading: false }));
  }
}

/**
 * @desc Activate Employee card
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* activateEmployeeCardWorker(action: any): Generator {
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/cards/${action.data.cardId}/activate`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201 &&
      _.has(jsonResponse.data, "fnMessage") &&
      jsonResponse.data?.fnMessage !== ""
    ) {
      throw { message: jsonResponse.data.fnMessage };
    } else {
      if (jsonResponse.data.fnStatusCode === 200) {
        return yield put(
          setLoading({
            loading: false,
            alertType: "success",
            successMessage: "Card has been activated successfully",
          })
        );
      } else {
        return yield put(
          setLoading({
            loading: false,
            alertType: "error",
            errorMessage:
              "Oops, something went wrong. Could not activate card. Please try again or contact support@ayacare.com",
          })
        );
      }
    }
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage:
          "Oops, something went wrong. Could not activate card. Please try again or contact support@ayacare.com",
      })
    );
    return ex;
  }
}

/**
 * @desc Update Employee
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* updateEmployeeCardDetailsWorker(action: any): Generator {
  if (action.data.calledFor === "planadmin") {
    yield put(setLoading({ errorMessage: "" }));
  } else {
    yield put(setLoading({ loading: true, errorMessage: "" }));
  }

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/cards/${action.data.cardId}/status`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201 &&
      _.has(jsonResponse.data, "fnMessage") &&
      jsonResponse.data?.fnMessage !== ""
    ) {
      throw { message: jsonResponse.data.fnMessage };
    } else {
      if (
        jsonResponse.data.fnStatusCode === 200 ||
        jsonResponse.data.fnStatusCode === 201
      ) {
        if (action.data.calledFor !== "pco") {
          yield call(fetchFullEmployeesWorker);
        }
        return yield put(
          setLoading({
            loading: false,
            alertType: "success",
            successMessage: "Card status has been updated successfully",
            nextAction: "refetchEmployeeList",
          })
        );
      } else {
        return yield put(
          setLoading({
            loading: false,
            alertType: "error",
            errorMessage:
              "Oops, something went wrong. Could not update card. Please try again or contact support@ayacare.com",
          })
        );
      }
    }
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage:
          "Oops, something went wrong. Could not update card. Please try again later or contact support@ayacare.com.",
      })
    );
    return ex;
  }
}

/**
 * @desc Update Employee Card Pin
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* updateEmployeeCardPinWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(setPinResetCompleted({ pinResetCompleted: "false" }));

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/cards/${action.data.cardId}/pin`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse?.data?.fnStatusCode !== 200 &&
      jsonResponse?.data?.fnStatusCode !== 201 &&
      _.has(jsonResponse.data, "fnMessage") &&
      jsonResponse?.data?.fnMessage !== ""
    ) {
      throw { message: jsonResponse.data.fnMessage };
    } else {
      if (jsonResponse?.data?.fnStatusCode === 200) {
        yield put(setPinResetCompleted({ pinResetCompleted: "true" }));
        return yield put(
          setLoading({
            loading: false,
            alertType: "success",
            successMessage: "Card pin changed successfully",
          })
        );
      } else {
        return yield put(
          setLoading({
            loading: false,
            alertType: "error",
            errorMessage: jsonResponse?.response?.data?.fnMessage.includes(
              "birth"
            )
              ? "Could not change pin. " + jsonResponse.response.data.fnMessage
              : "Oops, something went wrong. Could not change pin. Please try again or contact support@ayacare.com",
          })
        );
      }
    }
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: ex.message.includes("birth")
          ? ex.message
          : "Oops, something went wrong. Could not change pin. Please try again or contact support@ayacare.com",
      })
    );
    return ex;
  }
}

export function* fetchEmployeeCardsWorker(action: any): Generator {
  if (action.data?.calledFor === "planadmin") {
    yield put(setLoading({ errorMessage: "" }));
  } else {
    yield put(setLoading({ loading: true, errorMessage: "" }));
  }
  let apikeys: any = null;
  let jsonResponse: any = null;

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "GET",
    path: `persons/${action.data.personGuid}/cards`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      yield put(clearEmployeeCards());
      throw {
        message:
          "Could not fetch card details. Please try again later or contact support@ayacare.com",
      };
    }

    if (
      jsonResponse &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201 &&
      _.has(jsonResponse.data, "fnMessage") &&
      jsonResponse.data?.fnMessage !== ""
    ) {
      yield put(clearEmployeeCards());

      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage:
            jsonResponse.data.fnError ??
            "Could not fetch card details. Please try again later or contact support@ayacare.com",
        })
      );
    }

    yield put(updateEmployeeCards(jsonResponse.data.cards));

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          "Could not fetch card details. Please try again later or contact support@ayacare.com",
      })
    );
    return ex;
  }
}

/**
 * @desc Get Pin Verification Code
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* triggerPinVerificationWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "" }));
  yield put(
    setPinVerificationCodeLoading({ pinVerificationCodeLoading: true })
  );

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;

  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/cards/${action.data.cardId}/pin/verify`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201 &&
      _.has(jsonResponse.data, "fnMessage") &&
      jsonResponse.data?.fnMessage !== ""
    ) {
      yield put(
        setPinVerificationCodeLoading({ pinVerificationCodeLoading: false })
      );
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage:
            jsonResponse.data.fnError ??
            "Could not send verification token. Please resend or contact support@ayacare.com",
        })
      );
    }
    return yield put(
      setLoading({ loading: false, pinVerificationCodeLoading: false })
    );
  } catch (ex) {
    yield put(
      setPinVerificationCodeLoading({
        loading: false,
        pinVerificationCodeLoading: false,
      })
    );
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          "Could not send verification token. Please resend or contact support@ayacare.com",
      })
    );
    return ex;
  }
}

/**
 * @desc Fetch CRA Info
 * @return {*}  {Generator}
 */
export function* fetchEmployeeCraHsaInfoWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(craLoading({ craLoading: true }));

  let params: any = {
    path: PUBLIC_CRA_HSA_URL,
  };
  let jsonResponse: any = null;

  try {
    jsonResponse = yield externalAxios.get(params.path);
    yield put(updateEmployeeCraHsaInfo(jsonResponse.data));
    yield put(craLoading({ craLoading: false }));
  } catch (ex) {
    Sentry.captureException(ex.message, {
      tags: {
        page: SENTRY_PAGES.hsaCoverage,
        type: SENTRY_ERRORS.hsaCoverageCraFailed,
      },
    });
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    yield put(craLoading({ craLoading: false }));
    return ex;
  }
}

export function* fetchAccountDeletionInfoWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, errorMessage: "", successMessage: "" })
  );
  yield put(clearAccountDeletionInfo());
  yield put(updateAccountDeletionInfo({ fetchTriggered: true }));

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;

  let params: any = {
    method: "GET",
    path: `persons/${action.data.personGuid}/delete/schedule`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error();
    }

    const { data } = jsonResponse.data;
    data.isTriggered =
      data.initiatedAt !== undefined && data.deletionDate !== undefined
        ? true
        : false;
    yield put(updateAccountDeletionInfo(data));

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(updateAccountDeletionInfo({ fetchDeletionInfoFailed: true }));
    yield put(
      setLoading({
        loading: false,
      })
    );
    return ex;
  }
}

export function* triggerAccountDeletionWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, errorMessage: "", successMessage: "" })
  );

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;

  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/delete/schedule`,
    data: action.data.payload,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error(jsonResponse);
    }

    yield put(updateAccountDeletionInfo({ deletionSuccess: true }));

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          jsonResponse?.response?.data?.fnMessage ??
          "Could not trigger account deletion. Please try again later or contact support.",
      })
    );
    return ex;
  }
}

export function* triggerAccDelVerificationCodeWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, errorMessage: "", successMessage: "" })
  );

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;

  let params: any = {
    method: "POST",
    path: `persons/${action.data.personGuid}/delete/verificationCode`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error();
    }

    yield put(updateAccountDeletionInfo({ verificationCodeSuccess: true }));

    return yield put(
      setLoading({
        loading: false,
        successMessage:
          "Verification Code Sent to your registered email address",
      })
    );
  } catch (ex) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          "Could not trigger verification code generation. Please try again or contact support.",
      })
    );
    return ex;
  }
}

export function* accountDeletionCancellationWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, errorMessage: "", successMessage: "" })
  );
  yield put(
    updateAccountDeletionInfo({
      cancelDeletionTriggered: true,
    })
  );

  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apikeys: any = null;
  let jsonResponse: any = null;

  let params: any = {
    method: "DELETE",
    path: `persons/${action.data.personGuid}/delete/schedule`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.status !== 201
    ) {
      throw new Error(jsonResponse);
    }

    yield put(
      updateAccountDeletionInfo({
        cancelVerificationSuccess: true,
        isTriggered: false,
      })
    );

    return yield put(
      setLoading({
        loading: false,
        successMessage: "Account Deletion has been cancelled",
      })
    );
  } catch (ex) {
    yield put(
      updateAccountDeletionInfo({
        cancelVerificationFailed: true,
      })
    );
    yield put(
      setLoading({
        loading: false,
      })
    );
    return ex;
  }
}

export function* updateFlexAllocationWorker(action: any): Generator {
  yield put(
    setLoading({ loading: true, errorMessage: "", successMessage: "" })
  );
  yield put(setFlexAllocationAPIResponse(null));

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `persons/${action.data.personGuid}/accounts/flex/distribute`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.status >= 200 && jsonResponse.status < 300) {
      yield put(setFlexAllocationAPIResponse(jsonResponse.data));
    } else {
      yield put(setFlexAllocationAPIResponse(jsonResponse.response.data));
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    console.log("execption", e);
    yield put(
      setFlexAllocationAPIResponse({
        isSuccess: false,
        statusCode: 500,
        statusCodeText: "Bad Request",
        error: {
          code: "INVALID_REQUEST",
          message: "Request is invalid. Please check the input and try again.",
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

export function* updateContributionWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "", successMessage: "" }));
  yield put(clearContributionData());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `persons/contribution/update`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse.status >= 200 &&
      jsonResponse.status < 300 &&
      jsonResponse.data.data[0]?.statusCode >= 200 &&
      jsonResponse.data.data[0]?.statusCode < 300
    ) {
      yield put(
        setContributionResponse({
          apiResponse: jsonResponse.data,
          updateStatus: {
            success: true,
            failed: false,
            personGuid: action.data?.payload[0]?.personGuid,
            product: action.data?.payload[0]?.product,
          },
        })
      );
      yield put(
        updateFullEmployeesContribution({
          payload: action.data.payload,
          calledBy: action.data.calledBy,
        })
      );
    } else {
      yield put(
        setContributionResponse({
          apiResponse: jsonResponse?.data?.data[0]?.message ?? "",
          updateStatus: {
            success: false,
            failed: true,
            personGuid: action.data?.payload[0]?.personGuid,
            product: action.data?.payload[0]?.product,
          },
        })
      );
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      setContributionResponse({
        apiResponse: e.response?.data?.fnMessage ?? {},
        updateStatus: {
          success: false,
          failed: true,
          personGuid: action.data?.payload[0]?.personGuid,
          product: action.data?.payload[0]?.product,
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

export function* updateEmployeeAllocationWorker(action: any): Generator {
  yield put(setLoading({ errorMessage: "", successMessage: "" }));
  yield put(clearUpdateEmployeeAllocationData());

  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "post",
    path: `persons/contribution/category`,
    data: action.data.payload,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      jsonResponse.status >= 200 &&
      jsonResponse.status < 300 &&
      jsonResponse.data.data[0]?.statusCode >= 200 &&
      jsonResponse.data.data[0]?.statusCode < 300
    ) {
      yield put(
        setUpdateEmployeeAllocationResponse({
          apiResponse: jsonResponse.data,
          updateStatus: {
            success: true,
            failed: false,
            personGuid: action.data?.payload?.persons[0].personGuid,
            properties: action.data?.payload?.persons[0],
          },
        })
      );
      // yield call(fetchFullEmployeesWorker);
      yield put(
        updateFullEmployeesAllocationDetails({
          payload: {
            ...action.data.payload,
            ...{ allocation: action.data?.allocation },
          },
          calledBy: action.data.calledBy,
        })
      );
    } else {
      yield put(
        setUpdateEmployeeAllocationResponse({
          apiResponse: jsonResponse?.data?.data[0]?.message ?? "",
          updateStatus: {
            success: false,
            failed: true,
            personGuid: action.data?.payload?.persons[0].personGuid,
            properties: action.data?.payload?.persons[0],
          },
        })
      );
    }
    return yield put(setLoading({ loading: false, errorMessage: "" }));
  } catch (e) {
    yield put(
      setUpdateEmployeeAllocationResponse({
        apiResponse: e.response?.data?.fnMessage ?? {},
        updateStatus: {
          success: false,
          failed: true,
          personGuid: action.data?.payload?.persons[0].personGuid,
          properties: action.data?.payload?.persons[0],
        },
      })
    );
    yield put(setLoading({ loading: false }));
    return e;
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchPlanDetailsWatcher(): Generator {
  yield takeLatest("GET_PLAN_DETAILS", fetchPlanDetailsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchPlanHSAWSAWatcher(): Generator {
  yield takeLatest("GET_PLAN_HSA_WSA", fetchPlanHSAWSAWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchEmployeesWatcher(): Generator {
  yield takeLatest("FETCH_EMPLOYEES", fetchEmployeesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchFullEmployeesWatcher(): Generator {
  yield takeLatest("FETCH_FULL_EMPLOYEES", fetchFullEmployeesWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createEmployeeWatcher(): Generator {
  yield takeLatest("CREATE_EMPLOYEE", createEmployeeWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateEmployeeWatcher(): Generator {
  yield takeLatest("UPDATE_EMPLOYEE", updateEmployeeWorker);
}

/**
 * @return {*}  {Generator}
 */
function* patchEmployeeWatcher(): Generator {
  yield takeLatest("PATCH_EMPLOYEE", patchEmployeeWorker);
}

/**
 * @return {*}  {Generator}
 */
function* terminateEmployeeWatcher(): Generator {
  yield takeLatest("TERMINATE_EMPLOYEE", terminateEmployeeWorker);
}

/**
 * @return {*}  {Generator}
 */
function* resendEmployeeEmailWatcher(): Generator {
  yield takeLatest("RESEND_EMPLOYEE_EMAIL", resendEmployeeEmailWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchEmployeeDetailsWatcher(): Generator {
  yield takeLatest("FETCH_EMPLOYEE_DETAILS", fetchEmployeeDetailsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateEmployeeDetailsWatcher(): Generator {
  yield takeLatest("UPDATE_EMPLOYEE_DETAILS", updateEmployeeDetailsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateEmployeeCardDetailsWatcher(): Generator {
  yield takeLatest("UPDATE_CARD_DETAILS", updateEmployeeCardDetailsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateEmployeeCardPinWatcher(): Generator {
  yield takeLatest("UPDATE_EMPLOYEE_CARD_PIN", updateEmployeeCardPinWorker);
}

/**
 * @return {*}  {Generator}
 */
function* triggerPinVerificationWatcher(): Generator {
  yield takeLatest(
    "TRIGGER_EMPLOYEE_CARD_PIN_VERIFICATION_CODE",
    triggerPinVerificationWorker
  );
}

function* fetchEmployeeInviteDataWatcher(): Generator {
  yield takeLatest("FETCH_EMPLOYEE_INVITE", fetchEmployeeInviteDataWorker);
}

function* fetchEmployeeCraHsaInfoWatcher(): Generator {
  yield takeLatest(
    "FETCH_EMPLOYEE_CRA_HSA_INFO",
    fetchEmployeeCraHsaInfoWorker
  );
}

/**
 * @return {*}  {Generator}
 */
function* fetchEmployeeCardsWatcher(): Generator {
  yield takeLatest("FETCH_EMPLOYEE_CARDS", fetchEmployeeCardsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* activateEmployeeCardWatcher(): Generator {
  yield takeLatest("ACTIVATE_EMPLOYEE_CARD", activateEmployeeCardWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchAccountDeletionInfoWatcher(): Generator {
  yield takeLatest("FETCH_ACC_DELETION_INFO", fetchAccountDeletionInfoWorker);
}

/**
 * @return {*}  {Generator}
 */
function* triggerAccountDeletionWatcher(): Generator {
  yield takeLatest("TRIGGER_ACC_DELETION", triggerAccountDeletionWorker);
}

/**
 * @return {*}  {Generator}
 */
function* triggerAccDelVerificationCodeWatcher(): Generator {
  yield takeLatest(
    "TRIGGER_ACC_DELETION_VERIFICATIONCODE",
    triggerAccDelVerificationCodeWorker
  );
}

/**
 * @return {*}  {Generator}
 */
function* accountDeletionCancellationWatcher(): Generator {
  yield takeLatest(
    "TRIGGER_ACC_DELETION_CANCELLATION",
    accountDeletionCancellationWorker
  );
}

function* updateFlexAllocationWatcher(): Generator {
  yield takeLatest(
    "UPDATE_EMPLOYEE_FLEX_ALLOCATION",
    updateFlexAllocationWorker
  );
}

function* updateContributionWatcher(): Generator {
  yield takeLatest("UPDATE_EMPLOYEE_CONTRIBUTION", updateContributionWorker);
}

function* updateEmployeeAllocationWatcher(): Generator {
  yield takeLatest(
    "UPDATE_EMPLOYEE_ALLOCATION_DETAILS",
    updateEmployeeAllocationWorker
  );
}

export {
  fetchEmployeesWatcher,
  createEmployeeWatcher,
  updateEmployeeWatcher,
  fetchFullEmployeesWatcher,
  patchEmployeeWatcher,
  terminateEmployeeWatcher,
  resendEmployeeEmailWatcher,
  fetchPlanHSAWSAWatcher,
  fetchPlanDetailsWatcher,
  fetchEmployeeDetailsWatcher,
  updateEmployeeDetailsWatcher,
  updateEmployeeCardDetailsWatcher,
  fetchEmployeeInviteDataWatcher,
  fetchEmployeeCardsWatcher,
  activateEmployeeCardWatcher,
  fetchEmployeeCraHsaInfoWatcher,
  updateEmployeeCardPinWatcher,
  triggerPinVerificationWatcher,
  fetchAccountDeletionInfoWatcher,
  triggerAccountDeletionWatcher,
  triggerAccDelVerificationCodeWatcher,
  accountDeletionCancellationWatcher,
  updateFlexAllocationWatcher,
  updateContributionWatcher,
  updateEmployeeAllocationWatcher,
};
