// Types
import type { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchClaimUsageStats = (): Action => ({
  type: "FETCH_CLAIM_USAGE_STATS"
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateClaimUsageStats = (data: any): Action => ({
  type: "UPDATE_CLAIM_USAGE_STATS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchNotes = (data: any): Action => ({
  type: "FETCH_NOTES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateNotes = (data: any): Action => ({
  type: "UPDATE_NOTES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editNotes = (data: any): Action => ({
  type: "EDIT_NOTES",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearNotes = (): Action => ({
  type: "CLEAR_NOTES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchClaims = (data: any): Action => ({
  type: "FETCH_CLAIMS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateClaims = (data: any): Action => ({
  type: "UPDATE_CLAIMS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateClaim = (data: any): Action => ({
  type: "UPDATE_CLAIM",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateClaimItem = (data: any): Action => ({
  type: "UPDATE_CLAIM_ITEM",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchClaimCategories = (): Action => ({
  type: "FETCH_CLAIM_CATEGORIES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateClaimCategories = (data: any): Action => ({
  type: "UPDATE_CLAIM_CATEGORIES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editClaim = (data: any): Action => ({
  type: "EDIT_CLAIM",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getClaim = (data: any): Action => ({
  type: "GET_CLAIM",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearClaim = (): Action => ({
  type: "CLEAR_CLAIM",
});

/**
 * @return {*}  {Action}
 */
export const clearClaimList = (): Action => ({
  type: "CLEAR_CLAIMS_LIST",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const uploadClaimImage = (data: any): Action => ({
  type: "UPLOAD_CLAIM_IMAGE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createClaim = (data: any): Action => ({
  type: "CREATE_CLAIM",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const submitClaim = (data: any): Action => ({
  type: "SUBMIT_CLAIM",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const batchClaimImageUpload = (data: any): Action => ({
  type: "BATCH_CLAIM_IMAGE_UPLOADS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateSequentialClaims = (data: any): Action => ({
  type: "SEQUENTIAL_CLAIMS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const userClaimsDetails = (data: any): Action => ({
  type: "USER_CLAIMS_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const clearUserClaimsDetails = (): Action => ({
  type: "CLEAR_USER_CLAIMS_DETAILS",
});

export const clearMasterCardClaimDetails = (): Action => ({
  type: "CLEAR_MASTERCARD_CLAIM",
});
/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createAndSubmitClaim = (data: any): Action => ({
  type: "CREATE_AND_SUBMIT_CLAIM",
  data,
});

export const updateClaimBalance = (data: any): Action => ({
  type: "UPDATE_CLAIM_BALANCE",
  data,
});

export const fetchMastercardClaims = (data: any): Action => ({
  type: "FETCH_MASTERCARD_CLAIMS",
  data,
});

export const fetchMastercardClaim = (data: any): Action => ({
  type: "FETCH_MASTERCARD_CLAIM",
  data,
});

export const updateMastercardClaim = (data: any): Action => ({
  type: "UPDATE_MASTERCARD_CLAIM",
  data,
});

export const fetchClaimImages = (data: any): Action => ({
  type: "FETCH_CLAIM_IMAGES",
  data,
});

export const updateClaimImages = (data: any): Action => ({
  type: "UPDATE_CLAIM_IMAGES",
  data,
});

export const submitMastercardClaim = (data: any): Action => ({
  type: "SUBMIT_MASTERCARD_CLAIM",
  data,
});
