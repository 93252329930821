import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { editTPA, setLoading, updateTPA, updateTPAS } from "../actions";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/tpaNavigation";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;
//create tpa
const selectTPA = (state: any) => state.tpaReducer;

/**
 * @desc Fetch TPAS
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchTPASWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let userRole: any = selectedAuthReducer.userClaims.userRole;
  let queryStringRole: any = roleBasedQueryResolver(userRole);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (userRole == "superAdmin") {
    params.path = `lists/?type="${queryStringRole}tpaviewlist"&limit=${
      _.get(action, "data.limit") || 20000
    }&offset=${_.get(action, "data.offset") || 0}&at=${
      selectedAuthReducer.accessToken
    }`;
  } else {
    params.path = `businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="thirdPartyAdministratorPayor"&at=${selectedAuthReducer.accessToken}`;
    // params.path = 'businesses?type="listSlim"&referenceType="hierarchy"&referenceValue="thirdPartyAdministratorPayor"';
  }
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false, pageLoading: false }));
      return yield put(updateTPAS(JSON.parse(jsonResponse.data.fnResult)));
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc Get TPA
 * @param {*} action
 * @return {*}  {Generator}
 */
function* getTPAWorker(action: any): Generator {
  let jsonResponse: any = null;
  try {
    jsonResponse = yield axios(action, Auth);
    yield put(updateTPA(jsonResponse));
  } catch (e) {}

  return;
}

/**
 * @desc Create TPA
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createTPAWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    invite = yield call(axios, action.data, apikeys);
    if (invite.data.fnResult) {
      invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
    }
    params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnResult) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
    }

    yield put(editTPA(parsedJsonResponse));
    let selectedTPA: any = yield select(selectTPA);
    let route: any = getRoute(selectedTPA.data);
    let routeData: any = getRouteData(selectedTPA.data);
    yield put(setLoading({ loading: false, ...routeData }));
    // return yield history.push(route, routeData);
    return yield history.push(route);
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @desc Update TPA
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateTPAWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (jsonResponse.data.fnMessage) {
      yield put(editTPA(action.data.data));
      let parsedJsonResponse: any = action.data.data;
      let route: any = getRoute(parsedJsonResponse);
      let routeData: any = getRouteData(parsedJsonResponse);
      yield put(setLoading({ loading: false, ...routeData }));
      // return yield history.push(route, routeData);
      return yield history.push(route);
    } else {
      yield put(setLoading({ loading: false }));
    }
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
  yield put(editTPA(jsonResponse));

  return;
}

/**
 * @return {*}  {Generator}
 */
function* updateTPAWatcher(): Generator {
  yield takeLatest("UPDATE_TPA", updateTPAWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchTPASWatcher(): Generator {
  yield takeLatest("FETCH_TPAS", fetchTPASWorker);
}

/**
 * @return {*}  {Generator}
 */
function* getTPAWatcher(): Generator {
  yield takeLatest("GET_TPA", getTPAWorker);
}

/**
 * @return {*}  {Generator}
 */
function* createTPAWatcher(): Generator {
  yield takeLatest("CREATE_TPA", createTPAWorker);
}

export { fetchTPASWatcher, createTPAWatcher, updateTPAWatcher };
