// Redux
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
// Reducers
import rootReducer from "../reducers";
// Sagas
import rootSaga from "../sagas";
// Services
import { loadState, saveState } from "./localStorageService";

const isDebuggingInChrome: boolean = process.env.NODE_ENV !== "production";

const persistedState: any = loadState();

const logger: any = createLogger({
  predicate: () => isDebuggingInChrome,
  collapsed: true,
  duration: true,
});

const sagaMiddleware: any = createSagaMiddleware();
const middleware: any = applyMiddleware(sagaMiddleware, logger);

// const reduxDevTools: any = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store: any = createStore(
  rootReducer,
  persistedState,
  compose(middleware)
);
store.subscribe(() => {
  saveState({
    authReducer: store.getState().authReducer,
    statsReducer: store.getState().statsReducer,
    claimsReducer: store.getState().claimsReducer,
    employeeReducer: store.getState().employeeReducer
  });
});

if (isDebuggingInChrome) {
  window.store = store;
}

sagaMiddleware.run(rootSaga);

export default store;
