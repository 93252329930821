// Reselect
import { createSelector } from "reselect";
import { computeClaimSum } from "../utilities/customFunctions/computeClaimSum";

// Claims
const claims = (state: any) => state.claimsReducer;

// User claims
export const getUserClaimsDetails = createSelector(
  claims,
  (claims) => claims.userClaimsDetails
);

// Get claim categories
export const getClaimsCategories = createSelector(
  claims,
  (claims) => claims.claimCategories
);

export const getBalances = createSelector(
  claims,
  (claims) => claims.userClaimsDetails.balances
);

export const getClaimData = createSelector(
  claims,
  (claims) => claims.data
);

export const getMastercardClaims = createSelector(claims, (claims) => {
  const mastercardClaims = claims?.claims.filter((claim) => {
    if (claim.claimType === "Aya Card" && claim.claimFlag === "authorized") {
      return claim;
    }
  });

  const summedClaims = computeClaimSum(mastercardClaims);

  return {
    claimsCount: mastercardClaims.length,
    claimsList: mastercardClaims,
    summedClaims: summedClaims,
  };
});

export const getMastercardClaimData = createSelector(claims, (claims) => {
  return claims.mastercardClaim;
});

export const getClaimUsageStats = createSelector(claims, (claims)=>{
  return claims.claimUsageStats
})
