// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchPaymentsList = (): Action => ({
  type: "FETCH_PAYMENTS_LIST",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchPayment = (data: any): Action => ({
  type: "FETCH_PAYMENT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const postNewPayment = (data: any): Action => ({
  type: "POST_NEW_PAYMENT",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePaymentsList = (data: any): Action => ({
  type: "UPDATE_PAYMENTS_LIST",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateSearchQuery = (data: any): Action => ({
  type: "UPDATE_SEARCH_QUERY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const clearSearchQuery = (data: any): Action => ({
  type: "CLEAR_SEARCH_QUERY",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchBankInfo = (data: any, isBusiness: boolean = false): Action => ({
  type: "FETCH_BANK_INFO",
  data,
  isBusiness
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateBankInfo = (data: any): Action => ({
  type: "UPDATE_BANK_INFO",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editBankInfo = (data: any): Action => ({
  type: "EDIT_BANK_INFO",
  data,
});

export const fetchEmployeeBankDetails = (data: any): Action => ({
  type: "FETCH_EMPLOYEE_BANK_DETAILS",
  data,
});

export const updateEmployeeBankDetails = (data: any): Action => ({
  type: "UPDATE_EMPLOYEE_BANK_DETAILS",
  data,
});

export const clearBankInfo = (): Action => ({
  type: "CLEAR_BANK_INFO",
});
