import React, { ReactElement, useEffect } from "react";
import MenuOptionItem from "./MenuOptionsItem";
import { useSelector, useDispatch } from "react-redux";
import {
  getEmployeeAccountDeletion,
  getPersonGuid,
} from "../../../../selectors";
import {
  clearAccountDeletionInfo,
  fetchAccountDeletionInfo,
  setLoading,
} from "../../../../actions";
import AccDeletionCancellationModal from "../../../../components/dashboard/accountDeletion/components/AccDeletionCancellationModal";
import { positions, useAlert } from "react-alert";
import { deleteState } from "../../../../store/localStorageService";
import { useHistory } from "react-router";

const PCOOptions: React.FC<{ dependentsAllowed: boolean }> = ({
  dependentsAllowed,
}): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const [
    isDeletionScheduled,
    personGuid,
    fetchDeletionInfoFailed,
    fetchTriggered,
  ] = useSelector((state) => [
    getEmployeeAccountDeletion(state)?.isTriggered ?? false,
    getPersonGuid(state),
    getEmployeeAccountDeletion(state)?.fetchDeletionInfoFailed ?? false,
    getEmployeeAccountDeletion(state)?.fetchTriggered ?? false,
  ]);

  useEffect(() => {
    if (personGuid) {
      dispatch(clearAccountDeletionInfo());
      dispatch(fetchAccountDeletionInfo({ personGuid }));
    }
  }, [personGuid]);

  const cleanLogout = () => {
    setTimeout(() => {
      history.push("/logout", { modal: true });
      alert.show(
        {
          rawMessage:
            "Failed to fetch account validity. Please try again later. ",
        },
        {
          position: positions.TOP_CENTER,
          type: "error",
          timeout: 10000,
          onClose: () => {
            dispatch(
              setLoading({
                error: false,
                errorMessage: "",
                success: false,
                successMessage: null,
              })
            );
          },
        }
      );
    });
    deleteState();
    history.push("/");
  };

  useEffect(() => {
    if (personGuid && fetchDeletionInfoFailed && fetchTriggered) {
      alert.show(
        {
          rawMessage:
            "Failed to fetch account validity. You will be logged out.",
        },
        {
          position: positions.BOTTOM_CENTER,
          type: "error",
          timeout: 10000,
          onClose: () => {
            dispatch(
              setLoading({
                error: false,
                errorMessage: "",
                success: false,
                successMessage: null,
              })
            );
          },
        }
      );
      cleanLogout();
    }
  }, [fetchDeletionInfoFailed]);

  return (
    <>
      {!isDeletionScheduled && (
        <>
          <MenuOptionItem name={"Dashboard"} navLink={"/dashboard"} />
          <MenuOptionItem name={"Activity"} navLink={"/activity"} />
          <MenuOptionItem name={"Plan Info"} navLink={"/plan-info"} />
          {dependentsAllowed && (
            <MenuOptionItem name={"Dependents"} navLink={"/list-dependents"} />
          )}
          <MenuOptionItem name={"What Is Covered?"} navLink={"/hsa-coverage"} />

          <MenuOptionItem name={"Bank Info"} navLink={"/bank-info"} />
          <MenuOptionItem name={"Settings"} navLink={"/settings"} />
        </>
      )}

      {isDeletionScheduled && (
        <AccDeletionCancellationModal
          isOpen={isDeletionScheduled}
          onClose={() => {}}
        />
      )}
    </>
  );
};

export default PCOOptions;
