import { get } from "lodash";
import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { COLOR_SCHEMES } from "../../../designSystem/colors";
import TYPOGRAPHY from "../../../designSystem/typography";

const { fontSizes, fontWeights, lineHeights } = TYPOGRAPHY;

const CustomText: React.FC<{
  children: ReactNode;
  fontSize?: string | undefined;
  color?: string;
  fontWeight?: string;
  lineHeight?: string;
  extraStyles?: any;
  testid?: string;
}> = ({
  children,
  fontSize = "base",
  color = "GREYS.400",
  fontWeight = "normal",
  lineHeight = "base",
  extraStyles,
  testid,
}) => {
  return (
    <StyledTextContainer
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      extraStyles={extraStyles}
      data-testid={testid && `${testid}`}
    >
      {children}
    </StyledTextContainer>
  );
};

export default CustomText;

const StyledTextContainer = styled.div<IStyledTextContainer>`
  font-family: "Montserrat";
  font-size: ${(props) => fontSizes[props.fontSize]};
  color: ${(props) => get(COLOR_SCHEMES, props.color)};
  font-weight: ${(props) => fontWeights[props.fontWeight]};
  line-height: ${(props) => lineHeights[props.lineHeight]};
  ${(props) => ({ ...props.extraStyles })}
`;
