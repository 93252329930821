// Lodash
import { get } from "lodash-es";
// Types
import type { Action } from "../actions/types";
// Custom functions
import { cleanUpMeta } from "../utilities/customFunctions";

// State types
type State = {
  brokerageHouses: Array<any>;
  banks: Array<any>;
  data: any;
};

// Initial state
const INITIAL_STATE: State = {
  brokerageHouses: [],
  banks: [],
  data: {},
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_BROKERAGE_HOUSE":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          business: {
            ...action.data.business,
            businessPreferredLanguage: "",
            metaInformation: cleanUpMeta(
              get(action, "data.business.metaInformation")
            ),
          },
        },
      };
    case "UPDATE_BROKERAGE_HOUSES":
      return { ...state, brokerageHouses: { ...action.data } };
    case "UPDATE_BANKS":
      return { ...state, banks: { ...action.data } };
    case "CLEAR_BROKERAGE_HOUSE":
      return { ...state, data: INITIAL_STATE.data };
    default:
      return state;
  }
};
