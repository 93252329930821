// Npm dependencies
import { takeLatest, call, put, all, delay } from "redux-saga/effects";
import axios from "axios";
import _ from "lodash";
// Actions
import { updateSensiBill } from "../actions";
import history from "../utilities/history";

/** @type {*} */
const SENSI_BILL_AUTH_TYPES: any = {
  REGISTER: "Register",
  LOGIN: "Authenticate",
};
/** @type {*} */
const API_URL_KEY: any = {
  sensiBillApiUrl: process.env.REACT_APP_SENSI_BILL_API_URL,
  sensiBillClientId: process.env.REACT_APP_SENSI_BILL_CLIENT_ID,
};

/**
 * @param {{
 *   data: {
 *     idToken: string;
 *   };
 * }} payload
 * @return {*}  {Generator}
 */
function* authorizationSensiBill(payload: {
  data: {
    idToken: string;
  };
}): Generator {
  yield put(updateSensiBill({ loading: true }));
  const data: any = {
    createTestReceipts: true,
    client: `${API_URL_KEY.sensiBillClientId}`,
  };
  const params: any = {
    method: "post",
    url: `${API_URL_KEY.sensiBillApiUrl}jwt${SENSI_BILL_AUTH_TYPES.REGISTER}`,
    headers: {
      Authorization: `Bearer ${payload.data.idToken}`,
    },
    data,
  };
  try {
    yield call(axios, params);
    try {
      const loginResponse: any = yield call(
        loginSensiBill,
        payload.data.idToken
      );
      const response: any = loginResponse?.data;
      yield put(
        updateSensiBill({
          expiresIn: response?.expire_in ?? "",
          sensiBillAccessToken: response?.access_token,
          tokenId: response?.token_id,
        })
      );
    } catch (e) {
      yield call(
        handleError,
        true,
        e?.response?.data?.message ?? "Server Error"
      );
    }
    yield put(updateSensiBill({ loading: false }));
  } catch (e) {
    yield put(updateSensiBill({ loading: false }));
    if (e.response) {
      const { errors } = e?.response?.data;
      if (
        errors &&
        errors.accessID &&
        errors.accessID === "Access ID is already registered."
      ) {
        try {
          const loginResponse: any = yield call(
            loginSensiBill,
            payload.data.idToken
          );
          const response: any = loginResponse?.data;
          yield put(
            updateSensiBill({
              expiresIn: response?.expire_in ?? "",
              sensiBillAccessToken: response?.access_token,
              tokenId: response?.token_id,
            })
          );
        } catch (e) {
          yield call(
            handleError,
            true,
            e?.response?.data?.message ?? "Server Error"
          );
        }
      }
      if (e.response && e.response.status && e.response.status === 401) {
        yield call(
          handleError,
          true,
          e?.response?.data?.message ?? "Server Error"
        );
      }
    } else {
      yield call(handleError, true, "Server Error");
    }
    yield delay(5000);
    yield call(handleError, false, "");
  }
}

/**
 * @param {string} idToken
 * @return {*}  {Generator}
 */
function* loginSensiBill(idToken: string): Generator {
  const data: any = {
    client: API_URL_KEY.sensiBillClientId,
    createTestReceipts: true,
  };
  const params: any = {
    method: "post",
    url: `${API_URL_KEY.sensiBillApiUrl}jwt${SENSI_BILL_AUTH_TYPES.LOGIN}`,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    data,
  };
  return yield call(axios, params);
}

/**
 * @param {{
 *   data: {
 *     sensiBillAccessToken: string;
 *     imageDetails: string[];
 *     whereRedirect?: string;
 *   };
 * }} payload
 * @return {*}  {Generator}
 */
function* createSensiBillReceipt(payload: {
  data: {
    sensiBillAccessToken: string;
    imageDetails: string[];
    whereRedirect?: string;
  };
}): Generator {
  if (payload.data.sensiBillAccessToken){
    yield put(updateSensiBill({ loading: true }));
    try {
      const uploadSensiBillResponse: any = yield call(
        uploadMultipleSensiBillImage,
        {
          imageData: payload.data.imageDetails,
          sensiBillAccessToken: payload.data.sensiBillAccessToken,
        }
      );
      if (uploadSensiBillResponse && Array.isArray(uploadSensiBillResponse) && uploadSensiBillResponse.length !== 0) {
        const responseReceipt: Array<any> = [];
        uploadSensiBillResponse.map((uploadSensiBill: any) => {
          if (uploadSensiBill.status === 200){
            responseReceipt.push(uploadSensiBill?.data?.receipt)
          }
        });
        yield put(updateSensiBill({ data: { receipts: responseReceipt}}));
      }
      if (payload.data.whereRedirect) {
        history.push(payload.data.whereRedirect);
      }
      yield put(updateSensiBill({ loading: false }));
    } catch (e) {
      if (e.response) {
        const { errors } = e?.response?.data;
        yield call(handleError, true, JSON.stringify(errors || 'Error'))     
      } else {
        yield call(handleError, true, JSON.stringify(e?.message ?? 'Error'))
      }    
      yield delay(5000);
      yield call(handleError, false, "");

    }
  } else {
    yield call(handleError, true, 'Sensi Bill Access Token is missing. Please re login');
    yield delay(5000);
    yield call(handleError, false, "");
  }
}

/**
 * @param {{
 *   imageData: string[];
 *   sensiBillAccessToken: string;
 * }} uploadDetails
 * @return {*}  {Generator}
 */
function* uploadMultipleSensiBillImage(uploadDetails: {
  imageData: string[];
  sensiBillAccessToken: string;
}): Generator {
  return yield all(
    uploadDetails.imageData.map((data: any) =>
      call(uploadSingleSensiBillImage, {
        imageData: data.base64,
        sensiBillAccessToken: uploadDetails.sensiBillAccessToken,
      })
    )
  );
}

/**
 * @param {{
 *   imageData: string;
 *   sensiBillAccessToken: string;
 * }} uploadDetails
 * @return {*}  {Generator}
 */
function* uploadSingleSensiBillImage(uploadDetails: {
  imageData: string;
  sensiBillAccessToken: string;
}): Generator {
  try {
    // Create receipt:
    const createReceiptResponse: any = yield call(createReceipt, {
      sensiBillAccessToken: uploadDetails.sensiBillAccessToken,
      imageData: uploadDetails.imageData,
    });
    const { id, status } = createReceiptResponse.data;
    const transactionID = id;
    // poll
    let pollingResponse: any;
    if (
      (transactionID && status === "initializing") ||
      (transactionID && status === "queued")
    ) {
      pollingResponse = yield call(polling, {
        transactionID,
        sensiBillAccessToken: uploadDetails.sensiBillAccessToken,
      });
    }

    const { status: pollingStatus, receipts } = pollingResponse?.data || {};
    let receiptResponse: any = "";
    if ((receipts && status === "completed") || pollingStatus === "completed") {
      const receiptID = receipts[0];
      receiptResponse = yield call(getReceiptData, {
        receiptID,
        sensiBillAccessToken: uploadDetails.sensiBillAccessToken,
      });
    }
    return receiptResponse;
  } catch (error) {
    return error;
  }
}

/**
 * @param {{
 *   sensiBillAccessToken: string;
 *   imageData: any;
 * }} uploadDetails
 * @return {*}  {Generator}
 */
function* createReceipt(uploadDetails: {
  sensiBillAccessToken: string;
  imageData: any;
}): Generator {
  const params: any = {
    method: "post",
    timeout: 90000,
    url: `${API_URL_KEY.sensiBillApiUrl}receipts/transaction`,
    headers: {
      Authorization: `Bearer ${uploadDetails.sensiBillAccessToken}`,
    },
    data: {
      base64_image1: uploadDetails.imageData,
    },
  };
  return yield call(axios, params);
}

/**
 * @param {{
 *   transactionID: string;
 *   sensiBillAccessToken: string;
 * }} sensiBillDetails
 * @return {*}  {Generator}
 */
function* polling(sensiBillDetails: {
  transactionID: string;
  sensiBillAccessToken: string;
}): Generator {
  const params: any = {
    method: "get",
    timeout: 60000,
    url: `${API_URL_KEY.sensiBillApiUrl}receipts/transaction/${sensiBillDetails.transactionID}/wait`,
    headers: {
      Authorization: `Bearer ${sensiBillDetails.sensiBillAccessToken}`,
    },
  };
  return yield call(axios, params);
}

/**
 * @param {{
 *   receiptID: string;
 *   sensiBillAccessToken: string;
 * }} sensiBillDetails
 * @return {*}  {Generator}
 */
function* getReceiptData(sensiBillDetails: {
  receiptID: string;
  sensiBillAccessToken: string;
}): Generator {
  const params: any = {
    method: "get",
    url: `${API_URL_KEY.sensiBillApiUrl}receipts/${sensiBillDetails.receiptID}?format=data&includeRawOcr=true`,
    headers: {
      Authorization: `Bearer ${sensiBillDetails.sensiBillAccessToken}`,
    },
  };
  return yield call(axios, params);
}

/**
 * @param {boolean} hasErrors
 * @param {string} error
 * @return {*}  {Generator}
 */
function* handleError(hasErrors: boolean, error: string): Generator {
  yield put(updateSensiBill({ hasErrors, error }));
}

/**
 * @return {*}  {Generator}
 */
function* registerSensiBillWatcher(): Generator {
  yield takeLatest("AUTHORIZATION_SENSI_BILL", authorizationSensiBill);
}

/**
 * @return {*}  {Generator}
 */
function* createSensiBillWatcher(): Generator {
  yield takeLatest("CREATE_SENSI_BILL", createSensiBillReceipt);
}

// export sagas
export { registerSensiBillWatcher, createSensiBillWatcher };
