// Types
import { Action } from "../actions/types";

// State types
type State = {
  invoiceList: Array<any>;
  queryResults: Array<any>;
  results: Array<any>;
};

// Initial state
const INITIAL_STATE: State = {
  invoiceList: [],
  queryResults: [],
  results: [],
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "FILTER_APPROVED_UNAPPROVED":
      const updatedList = state.invoiceList.filter((invoice) => {
        if (invoice.invoiceStatus === action.data) {
          return invoice;
        }
      });
      return { ...state, queryResults: updatedList };
    case "FETCH_INVOICES":
      return state;
    case "UPDATE_INVOICE_LIST":
      return { ...state, invoiceList: [...action.data] };
    case "FETCH_INVOICE_DETAILS":
      return state;
    case "SEND_INVOICE":
      return state;
    case "CREATE_JOURNAL":
      return state;
    case "UPDATE_INVOICE_SEARCH":
      const newResults = state.invoiceList.filter((invoice) => {
        const { invoiceNumber } = invoice;
        if (invoiceNumber.includes(action.data)) {
          return invoice;
        }
      });
      return { ...state, queryResults: [...newResults] };
    case "CLEAR_INVOICE_SEARCH":
      return { ...state, results: [] };
    default:
      return state;
  }
};
