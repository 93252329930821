const categoryReverseTypeMapper = (label: string) => {
  switch (label) {
    case "prescription":
      return "Prescription Drugs";
    case "dental":
      return "Dental";
    case "vision":
      return "Vision";
    case "equipment":
      return "Medical supplies and equipment";
    case "professional":
      return "Medical professional services";
    case "other":
      return "Other";
    case "wellness":
      return "Wellness Spending";
    default:
      return "No Mapping Found";
  }
};

const DEFAULT_GUID = "00000000-0000-0000-0000-000000000000";

export const transformClaimItems = async (
  items: any,
  categorylist: any[],
  tokens: any,
  dispatch: any
) => {
  const majorCategoryList: any = {};
  let minorCategoryGuid = "";
  let minorCategorySubGuid = "";

  if (items?.length > 0) {
    return items?.map(async (item: any) => {
      const medicalTypePlaceholder = item.medicalTypePlaceholder[0]?.value
        ? JSON.parse(item.medicalTypePlaceholder[0]?.value)
        : [];

      // Determine claim type
      let claimType = "";
      if (
        item?.medicalTypePlaceholder[0].referenceValue === "Wellness Spending"
      ) {
        claimType = "wellness";
      }
      if (
        item?.medicalTypePlaceholder[0].referenceValue !== "Wellness Spending"
      ) {
        claimType = "health";
      }

      if (claimType) {
        // console.log("claimType: ", claimType);
      }

      let itemImageSet: any[] = [];
      if (item.image && item.image.length > 0) {
        const itemImageSetPromises = item?.image?.map(async (singleImage) => {
          // const uri = await fetchImage(singleImage?.name, tokens);
          const uri = singleImage.name;
          return {
            uri,
            type: "link",
          };
        });
        itemImageSet = await Promise.all(itemImageSetPromises);
      }

      // find category:
      let minorCategoryName = "";
      let minorSubCategoryName = "";
      let prescriptionRequired = "";
      if (item?.claimCategoryGuid) {
        // filter categorylist. find category.
        const chosenCategory = categorylist.filter(
          (category) => category.claimcategoryguid === item?.claimCategoryGuid
        );

        // extract values from the chosen category:
        let chosenCategoryParentName = "";
        const chosenCategoryParentGuid =
          chosenCategory[0]?.parentclaimcategoryguid;
        const chosenCategoryGuid = chosenCategory[0]?.claimcategoryguid;
        const chosenCategoryName = chosenCategory[0]?.categoryname;
        const chosenCategoryPrescriptionRequired =
          chosenCategory[0]?.prescriptionneeded;

        // find out if chosen category is a minor category or a minorSubCategory
        // make a map of parent category guids and find chosen category parent name

        for (
          let parentItem = 0;
          parentItem < categorylist.length;
          parentItem += 1
        ) {
          const itemParentGuid =
            categorylist[parentItem].parentclaimcategoryguid;
          const itemGuid = categorylist[parentItem].claimcategoryguid;
          const itemName = categorylist[parentItem].categoryname;
          if (itemParentGuid === DEFAULT_GUID) {
            majorCategoryList[itemName] = itemGuid;
          }
          if (chosenCategoryParentGuid === itemGuid) {
            chosenCategoryParentName = itemName;
          }
        }

        // check if parentCategories map contains
        if (
          Object.values(majorCategoryList).indexOf(chosenCategoryParentGuid) !==
          -1
        ) {
          minorCategoryName = chosenCategoryName;
          minorCategoryGuid = chosenCategoryGuid;
        } else {
          minorCategoryName = chosenCategoryParentName;
          minorCategoryGuid = chosenCategoryParentGuid;
          minorSubCategoryName = chosenCategoryName;
          minorCategorySubGuid = chosenCategoryGuid;
        }
        prescriptionRequired = chosenCategoryPrescriptionRequired;
      }

      const majorCategoryName =
        claimType === "health"
          ? categoryReverseTypeMapper(
              item?.medicalTypePlaceholder[0].referenceType
            )
          : item?.medicalTypePlaceholder[0].referenceValue;

      return {
        whoIsThisFor: item.whoIsThisItemFor,
        isForDependent: item.isForDependent,
        claimcategoryguid: item?.claimCategoryGuid,
        amountPaid: item?.clientPaid?.toString(),
        amountToClaim: item?.clientPaid?.toString(),
        din: medicalTypePlaceholder.din,
        majorCategory: {
          categoryname: majorCategoryName,
          claimcategoryguid: majorCategoryList[majorCategoryName],
        },
        minorCategory: {
          categoryname: minorCategoryName,
          claimcategoryguid: minorCategoryGuid,
        },
        minorSubCategory: {
          categoryname: minorSubCategoryName,
          claimcategoryguid: minorCategorySubGuid,
        },
        prescriptionImages: itemImageSet,
        prescriptionRequired,
        claimType,
      };
    });
  }
  return [];
};
