// Lodash
import { get, set } from "lodash";

/**
 * @return {*}  {(object | Array<any> | undefined)}
 */
export const loadState = (): object | Array<any> | undefined => {
  try {
    // get the state from local storage
    const serializedState: string | null = localStorage.getItem("state");

    // check serialized state is null
    if (serializedState === null) return undefined;

    // convert into json object
    const serializedStateInJson: any = JSON.parse(serializedState);

    // check per auth details exists on state
    if (
      get(serializedStateInJson, "authReducer") &&
      !get(serializedStateInJson, "authReducer.preAuthDetails")
    ) {
      set(serializedStateInJson.authReducer, "preAuthDetails", {});
    }

    return serializedStateInJson;
  } catch (err) {
    return undefined;
  }
};

/**
 * @return {*}  {(object | Array<any> | undefined | null)}
 */
export const getAuthData = (): object | Array<any> | undefined | null => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    let parsedState = JSON.parse(serializedState);
    return get(parsedState, "authReducer");
  } catch (err) {
    return undefined;
  }
};

/**
 * @param {(object | Array<any> | undefined | null)} state
 */
export const saveState = (
  state: object | Array<any> | undefined | null
): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log("err", err);
  }
};

/**
 */
export const deleteState = (): void => {
  try {
    localStorage.removeItem("state");
  } catch (err) {
    console.log("err", err);
  }
};

/**
 * @param {string} key
 * @param {*} value
 */
export const setItem = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

/**
 * @param {string} key
 * @return {*}  {*}
 */
export const getItem = (key: string): any => localStorage.getItem(key);

/**
 * @param {string} key
 */
export const removeItem = (key: string) => localStorage.removeItem(key);

/**
 * @param {string} key
 * @return {*}  {*}
 */
export const getState = (key: string): any =>
  getItem(key) ? JSON.parse(getItem(key)) : setItem(key, {});
