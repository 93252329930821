import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { setLoading, updateSuperAdmins, updateUsersList } from "../actions";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 *
 * @desc Fetch Super Admins
 * @return {*}  {Generator}
 */
export function* fetchSuperAdminsWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: `persons?type="includeOvw"&referenceType="superAdmin"&limit="100000"&at=${selectedAuthReducer.accessToken}`,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false }));
      return yield put(
        updateSuperAdmins(JSON.parse(jsonResponse.data.fnResult))
      );
    }
    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @desc Fetch Users List
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchUsersListWorker(action: any): Generator {
  yield put(setLoading({ pageLoading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path:
      action.data && action.data.role !== undefined
        ? `lists/?type=sauserviewlist&status=${action.data.role}&limit="20000"&at=${selectedAuthReducer.accessToken}`
        : `lists/?type=sauserviewlist&limit="20000"&at=${selectedAuthReducer.accessToken}`,
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ pageLoading: false }));
      return yield put(updateUsersList(JSON.parse(jsonResponse.data.fnResult)));
    }
    return yield put(setLoading({ pageLoading: false }));
  } catch (ex) {
    yield put(setLoading({ pageLoading: false, errorMessage: ex.message }));
    return ex;
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchSuperAdminsWatcher(): Generator {
  yield takeLatest("FETCH_SUPER_ADMINS", fetchSuperAdminsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchUsersListWatcher(): Generator {
  yield takeLatest("FETCH_USERS_LIST", fetchUsersListWorker);
}

export { fetchSuperAdminsWatcher, fetchUsersListWatcher };
