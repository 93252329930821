import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { setLoading, updateMerchantList } from "../actions";
import axios from "../utilities/axiosWrapper";
import { Auth } from "../utilities/auth";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch merchant list
 * @return {*}  {Generator}
 */
export function* fetchMerchantListWorker(): Generator {
  yield put(setLoading({ pageLoading: true }));

  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "GET",
    path: `claims?type=merchant&limit=100&offset=0&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    let apiKeys: any = yield Auth.getAuthTokens();
    let response: any = yield call(axios, params, apiKeys);

    if (_.has(response, "data.fnResult")) {
      yield put(updateMerchantList(JSON.parse(response.data.fnResult)));
      yield put(setLoading({ pageLoading: false }));
    }
  } catch (err) {
    console.log("fetch merchant list err: ", err);
    yield put(setLoading({ pageLoading: false }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchMerchantListWatcher(): Generator {
  yield takeLatest("FETCH_MERCHANT_LIST", fetchMerchantListWorker);
}

export { fetchMerchantListWatcher };
