export const stepList: stepListType = {
    onboarding: [
      {
        indicator: 2,
        title: "PDV",
        step: "ONBOARDING-STEP_TWO-BHA",
      },
      {
        indicator: 3,
        title: "Corporate",
        step: "ONBOARDING-STEP_THREE-BHA",
      },
      {
        indicator: 4,
        title: "Contacts",
        step: "ONBOARDING-STEP_FOUR-BHA",
      },
      {
        indicator: 5,
        title: "Bank Account",
        step: "ONBOARDING-STEP_FIVE-BHA",
      },
      {
        indicator: 6,
        title: "Review",
        step: "ONBOARDING-STEP_SIX-BHA",
      },
    ],
    full: [
      {
        indicator: 1,
        title: "PDV",
        step: "FULL-STEP_ONE-BHA",
      },
      {
        indicator: 2,
        title: "Corporate",
        step: "FULL-STEP_TWO-BHA",
      },
      {
        indicator: 3,
        title: "Contacts",
        step: "FULL-STEP_THREE-BHA",
      },
      {
        indicator: 4,
        title: "Bank Account",
        step: "FULL-STEP_FOUR-BHA",
      },
      {
        indicator: 5,
        title: "Review",
        step: "FULL-STEP_FIVE-BHA",
      },
    ],
    quick: [
      {
        indicator: 1,
        title: "PDV",
        step: "FULL-STEP_ONE-BHA",
      },
      {
        indicator: 2,
        title: "Corporate",
        step: "FULL-STEP_TWO-BHA",
      },
      {
        indicator: 3,
        title: "Contacts",
        step: "FULL-STEP_THREE-BHA",
      },
      {
        indicator: 4,
        title: "Bank Account",
        step: "FULL-STEP_FOUR-BHA",
      },
      {
        indicator: 5,
        title: "Review",
        step: "FULL-STEP_FIVE-BHA",
      },
    ],
  };
  
  // Step list index type
  export type stepListIndexType = 'onboarding' | 'full' | 'quick';
  
  // Step list type
  export type stepListType = {
    onboarding: Array<stepListSubType>;
    full: Array<stepListSubType>;
    quick: Array<stepListSubType>;
  };
  
  // Step list sub type
  type stepListSubType = {
    indicator: number;
    title: string;
    step: String;
  };