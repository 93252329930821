import moment from "moment";
import { fetchClaimImages } from "../../actions";
import { transformClaimItems } from "./transformClaimItems";

// const dispatch = useDispatch();
export const transformReceivedClaim = async (receivedPayload: any) => {
  const { rawClaim, categorylist, tokens, dispatch } = receivedPayload;

  const receipt = rawClaim?.claimHealthReceipt[0];
  let receiptImages: any = [];

  const promises = receipt?.artifact?.map(async (item: any) => {
    let uri = "";
    if (item.documentUrl) {
      const tempUrl = item?.documentUrl?.split("/") || [];
      if (tempUrl?.length > 0) {
        uri = tempUrl[tempUrl.length - 1];
        // dispatch(fetchClaimImages(tempUrl[tempUrl.length - 1]));
        // uri = await fetchImage(tempUrl[tempUrl.length - 1], tokens);
      } else {
        uri = item.documentUrl;
      }
    }

    return {
      imageGuid: item.artifactGUID,
      uri,
      mimeType: item.documentMimeType,
      type: "link",
    };
  });

  receiptImages = promises ? await Promise.all(promises) : [];

  let claimItems: any = [];

  if (rawClaim?.claimHealthItem) {
    const claimItemsPromise = await transformClaimItems(
      rawClaim?.claimHealthItem,
      categorylist,
      tokens,
      dispatch
    );
    claimItems = await Promise.all(claimItemsPromise);
  }

  const prepareClaimData = {
    // oopReason: "Merchant Dosen't Accept MasterCard",
    amountPaid: rawClaim.claimAmount,
    amountToClaim: rawClaim.claimAmount,
    noOfItems: receipt.rtNumberOfItems,
    paymentDate: rawClaim.paymentDate
      ? moment.utc(rawClaim.paymentDate).format("YYYY-MM-DD")
      : rawClaim.paymentDate,
    // methodOfPayment: "Cash",
    dateOfTransaction: moment //submission date
      .utc(rawClaim.dateOfSubmission)
      .format("YYYY-MM-DD"),
    claimNumber: rawClaim.claimNumber,
    merchantInfo: {
      name: receipt.merchant.merchantName,
      isOnline: receipt.merchant.isOnline,
      website: receipt.merchant.website?.website || "",
      alternateName: receipt.merchant.merchantAlternateName,
      street1: receipt.merchant.address.streetOne,
      street2: receipt.merchant.address.streetTwo,
      city: receipt.merchant.address.city,
      province: receipt.merchant.address.province,
      postalCode: receipt.merchant.address.postalCode,
      phone: receipt.merchant.address.telephoneNumber,
    },
    dateOfPurchase: moment
      .utc(receipt.rtTransactionDate)
      .format("YYYY-MM-DD")
      .toString(),
    paymentType: rawClaim.claimPaymentType,
    claimItems,
    accessToken: "",
    receiptImages,
    claimStatus: rawClaim.claimStatus,
    approvedAmount: rawClaim.approvedAmount,
    claimType: rawClaim.claimCategory,
    claimHealthGUID: rawClaim.claimHealthGUID,
  };

  return prepareClaimData;
};
