import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { fetchClaims } from "../../../actions";
import history from "../../../history";
import { getMastercardClaims } from "../../../selectors";
import { COLOR_SCHEMES } from "../../../styles/colors/colors";
import { FONTS } from "../../../styles/fonts/fonts";
import { BOX_SHADOWS } from "../../../styles/other/boxShadow";
import { uuidv4Generator } from "../../../utilities/customFunctions";
import GradientText from "../../shared/GradientText/GradientText";
import GradientTextButton from "../../shared/GradientTextButton/GradientTextButton";
import MCClaimListItem from "./components/MCClaimListItem";
import MCConfirmation from "./components/MCConfirmation";
import {
  CLAIM_SUBMISSION_TYPES,
  REDIRECT_URL,
} from "./utilities/claimSubmission.constant";

const SelectClaimType = () => {
  const [claimType, toggleClaimType] = useState<string>(
    CLAIM_SUBMISSION_TYPES.MASTERCARD
  );
  const [requestedClaim, setRequestedClaim] = useState<any>(null);
  const { noReceiptModal, uploadReceipt, uploadReceiptMC, noReceiptModalMC } =
    REDIRECT_URL;
  const dispatch = useDispatch();

  const [mastercardClaims] = useSelector((state) => [
    getMastercardClaims(state),
  ]);

  useEffect(() => {
    if (mastercardClaims.claimsList === 0) {
      toggleClaimType(CLAIM_SUBMISSION_TYPES.OOP);
    } else {
      toggleClaimType(CLAIM_SUBMISSION_TYPES.MASTERCARD);
    }
  }, [mastercardClaims]);

  useEffect(() => {
    dispatch(fetchClaims({ mode: "pco" }));
  }, []);

  return (
    <DashboardContainer>
      <DashboardHeaderContainer>
        <Icon
          // className={styles.icon}
          name={"arrow left"}
          size={"big"}
          color={"grey"}
          onClick={() => history.goBack()}
          style={{
            cursor: "pointer",
            fontSize: 24,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        />
        <DashboardHeader>Submit Claim</DashboardHeader>
      </DashboardHeaderContainer>
      <ContentContainer>
        <ContentInner>
          {!requestedClaim ? (
            <>
              <ContentHeaderContainer>
                <ContentHeader>
                  Let's begin the claim submission process
                </ContentHeader>
                <ContentSubHeader>
                  Step 1: Please select the type of transaction
                </ContentSubHeader>
              </ContentHeaderContainer>

              <ActionContainer>
                {claimType !== CLAIM_SUBMISSION_TYPES.MASTERCARD && (
                  <StyledButton
                    basic
                    color="grey"
                    onClick={() => {
                      toggleClaimType(CLAIM_SUBMISSION_TYPES.MASTERCARD);
                    }}
                  >
                    Card Transaction
                  </StyledButton>
                )}

                {claimType === CLAIM_SUBMISSION_TYPES.MASTERCARD && (
                  <GradientTextButton
                    text={"Card Transaction"}
                    fontSize={"1rem"}
                    width={"16rem"}
                    disabled={false}
                    style={{ minWidth: "16rem" }}
                  />
                )}

                {claimType !== CLAIM_SUBMISSION_TYPES.OOP && (
                  <StyledButton
                    basic
                    color="grey"
                    onClick={() => {
                      toggleClaimType(CLAIM_SUBMISSION_TYPES.OOP);
                      history.push(REDIRECT_URL.submitExpenseModal);
                    }}
                    data-testid={"selectClaimOOP-button"}
                  >
                    Out of Pocket
                  </StyledButton>
                )}

                {claimType === CLAIM_SUBMISSION_TYPES.OOP && (
                  <GradientTextButton
                    text={"Out of Pocket"}
                    fontSize={"1rem"}
                    width={"16rem"}
                    disabled={false}
                    handleClick={() => {
                      toggleClaimType(CLAIM_SUBMISSION_TYPES.OOP);
                      history.push(REDIRECT_URL.submitExpenseModal);
                    }}
                  />
                )}
              </ActionContainer>

              {mastercardClaims.claimsList?.length > 0 && (
                <>
                  <DescriptionText>
                    The Card txn(s) below require more information. Some txn(s)
                    may take up to 3 business days to appear.
                  </DescriptionText>
                  <ClaimListContainer>
                    {mastercardClaims.claimsList.map((claim, i) => {
                      return (
                        <MCClaimListItem
                          claim={claim}
                          setRequestedClaim={setRequestedClaim}
                          key={uuidv4Generator()}
                          index={i}
                        />
                      );
                    })}
                  </ClaimListContainer>
                </>
              )}
            </>
          ) : (
            <MCConfirmation requestedClaim={requestedClaim} />
          )}

          {requestedClaim && (
            <ButtonContainer>
              <GradientTextButton
                text={"Add Receipt"}
                width={"100%"}
                fontSize={"1.25rem"}
                handleClick={() => history.push(uploadReceiptMC)}
                name={"addReceipt"}
              />
              <StyledNoReceipt
                onClick={() => history.push(noReceiptModalMC)}
                data-testid={"noReceipt-button"}
              >
                No Receipt?
              </StyledNoReceipt>
            </ButtonContainer>
          )}
        </ContentInner>

        <BottomText
          onClick={() =>
            window.open("mailto:support@ayacare.com?Subject=Support", "_blank")
          }
        >
          If you need help, please{" "}
          <GradientText
            fontSize={"1rem"}
            string="contact us"
            inlineBlock
            cursor="pointer"
          />
        </BottomText>
      </ContentContainer>
    </DashboardContainer>
  );
};

export default SelectClaimType;

const DashboardContainer = styled.div`
  width: 100%;
  background-color: ${COLOR_SCHEMES.DASHBOARD.BACKGROUND_COLOR};
  padding: 2rem 0 10rem;
`;

const DashboardHeaderContainer = styled.div`
  width: 80%;
  margin: 0 auto 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 700px) {
    width: 90%;
  }
`;

const DashboardHeader = styled.h1`
  color: ${COLOR_SCHEMES.BLACK};
  font-weight: 500;
  font-family: ${FONTS.FONT_1};
  margin-top: 0;
  margin-left: 12px;
`;

export const ContentContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: ${COLOR_SCHEMES.WHITE};
  -webkit-box-shadow: ${BOX_SHADOWS.BOX_SHADOW1};
  box-shadow: ${BOX_SHADOWS.BOX_SHADOW1};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3rem 6rem;
  position: relative;

  @media (max-width: 600px) {
    width: 90%;
    flex-direction: column;
    padding: 2rem 2rem 10rem;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;

  @media (min-width: 1200px) {
    max-width: 50%;
  }

  @media (min-width: 1500px) {
    max-width: 40%;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 35px !important;
  min-height: 3.65rem !important;
  min-width: 16rem;
  // margin: "auto" !important;
  /* margin-top: 20px !important; */
  font-size: 1rem !important;
  font-family: "Montserrat" !important;
  white-space: nowrap !important;
  cursor: pointer !important;
`;

export const ContentHeaderContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ContentHeader = styled.h2`
  font-family: ${FONTS.FONT_1};
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 400;
  /* width: 70%; */
`;

export const ContentSubHeader = styled.h3`
  font-family: ${FONTS.FONT_1};
  color: ${COLOR_SCHEMES.GREY};
  margin-top: 0;
  font-weight: 400;
  font-size: 1.25rem;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > :first-child {
    margin-right: 1rem;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    & > :first-child {
      margin-right: 0rem;
      margin-bottom: 1rem;
    }

    & > button {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
`;

const StyledNoReceipt = styled.div`
  color: ${COLOR_SCHEMES.GREY};
  font-family: ${FONTS.FONT_1};
  margin-top: 2rem;
  font-size: 1.25rem;
  cursor: pointer;
`;

const DescriptionText = styled.div`
  color: ${COLOR_SCHEMES.GREY};
  font-family: ${FONTS.FONT_1};
  margin: 3rem 0;
`;

const ClaimListContainer = styled.div`
  margin-bottom: 3rem;
  width: 100%;
`;

const BottomText = styled.div`
  position: absolute;
  bottom: 1rem;
  color: ${COLOR_SCHEMES.GREY};
  font-family: ${FONTS.FONT_1};
`;
