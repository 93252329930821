import React, { Component } from 'react';
import Button from '../../components/shared/button';
import NavBar from '../../components/shared/navBar';
import { Images } from '../../constants/Images';
import history from '../../history';
import styles from './Maintenance.module.scss';

export class Maintenance extends Component {
	render() {
		return (
			<div className={styles.container}>
				<NavBar shadow={true} noMenu={true} />
				<div className={styles.mainContent}>
					<img src={Images.teamMaintenance} className={styles.imageStyles} alt="Website under maintenance" />
					<div className={styles.textSection}>
						<p className={styles.boldText}>
							We are currently working on updating the app. <br /> We will be back shortly!
						</p>
						<p class={styles.lightText}>We apologize for any inconvenience.</p>
					</div>
					<Button onClick={() => history.push('/')} style="primary" name={'Try again'} />
				</div>
			</div>
		);
	}
}

export default Maintenance;
