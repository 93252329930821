// Npm modules
import styled from 'styled-components';
import { COLOR_SCHEMES } from '../../../designSystem/colors';

// Styled component wrapper
export const StyledPasswordContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 40%;
  min-width: 1.75rem;
  cursor: pointer;
`;

export const StyledForgetPasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: -1rem;
  cursor: pointer !important;
`;

export const ErrorMessage = styled.p`
  font-size: 1.25rem;
  font-family: Montserrat;
  margin-top: 1rem;
  color: red;
`;

export const DisabledText = styled.div`
  font-family: Montserrat;
  color: ${COLOR_SCHEMES.GREYS[700]};
  font-size: 16px;
`;

export const StyledGradientButton = styled.button`
  & {
    color: #313149;
    padding: 0.75rem 2rem;
    display: inline-block;
    position: relative;
    z-index: 0;
    width: ${(props: any) => props.width};
    text-align: center;
    cursor: pointer;
    min-width: 11rem;
    min-height: 3.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 35px;
    border: solid 2px transparent;
    background-image: none,
      linear-gradient(
        to right,
        ${(props: any) =>
          props.disabled ? COLOR_SCHEMES.GREYS[400] : props.primaryColor},
        ${(props: any) =>
          props.disabled ? COLOR_SCHEMES.GREYS[400] : props.secondaryColor}
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }
  @media (max-width: 900px) {
    min-width: 100%;
  }
`;
