export const CraHsaParser = (response: any): Array<any> => {
  const tdStartTag = '<td>';
  const tdEndTag = '</td>';
  const recordsMaxNumber = 1000;
  const tBodyStartTag = '<tbody>';
  const tBodyEndTag = '</tbody>';
  const trStartTag = '<tr>';
  const trEndTag = '</tr>';

  let startIndex = response.indexOf(tBodyStartTag) + tBodyStartTag.length;
  let endIndex = response.indexOf(tBodyEndTag);
  let mainTable = response.substring(startIndex, endIndex);

  let records = mainTable.split(trStartTag, recordsMaxNumber);
  let medicalExpenses: Array<any> = [];

  const extractPropertyValue = (htmlRecord: any) => {
    let propertyValue = htmlRecord.substring(0, htmlRecord.indexOf(tdEndTag));
    return propertyValue;
  };

  const getHyperlinkText = (aTag: any) => {
    let tokens = aTag.split(/<|>/);
    return tokens.length >= 3 ? tokens[2] : tokens[0];
  };

  const getMedicalExpense = (htmlRecord: any) => {
    if (htmlRecord === '') return;
    let records = htmlRecord.split(tdStartTag);
    let medicalExpense = {
      expenseName: getHyperlinkText(extractPropertyValue(records[1])),
      isEligible:
        extractPropertyValue(records[2]) === 'Eligible' ? true : false,
      prescriptionNeeded:
        extractPropertyValue(records[3]).toLowerCase() === 'yes' ? true : false,
      certificationNeeded:
        extractPropertyValue(records[4]).toLowerCase() === 'yes' ? true : false,
      formT2201Needed:
        extractPropertyValue(records[5]).toLowerCase() === 'yes' ? true : false,
    };
    return medicalExpense;
  };

  records.forEach((record: any) => {
    if (record === '') return;
    medicalExpenses.push(getMedicalExpense(record));
  });

  return medicalExpenses;
};

export const storeCRAInfoLocalStorageSuccess = (
  medicalExpenses: any,
  parsedAt: string
) => {
  window.localStorage.removeItem('AYA_HsaCRAMedicalList');
  const storedValue = {
    medicalExpenseList: medicalExpenses,
    parsedAt: parsedAt,
  };
  window.localStorage.setItem(
    'AYA_HsaCRAMedicalList',
    JSON.stringify(storedValue)
  );

  return true;
};
