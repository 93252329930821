export const TYPOGRAPHY: {
  [key: string]: any;
} = {
  fontFamily: {
    base: "Montserrat",
  },
  letterSpacings: {
    base: 0,
  },
  lineHeights: {
    "2xs": "1.5em",
    xs: "1.5em",
    sm: "1.5em",
    base: "1.5em",
    lg: "1.5em",
    "2xl": "1.5em",
    "3xl": "1.5em",
    "4xl": "1.25em",
    "5xl": "1.25em",
    "6xl": "1.25em",
    "7xl": "1.25em",
    "8xl": "1.25em",
    "9xl": "1.25em",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
    extrablack: 950,
  },
  fontSizes: {
    "2xs": "10px",
    xs: "12px",
    sm: "14px",
    base: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "22px",
    "3xl": "24px",
    "4xl": "28px",
    "5xl": "32px",
    "6xl": "36px",
    "7xl": "40px",
    "8xl": "48px",
  },
};

export default TYPOGRAPHY;
