// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchMerchantList = (): Action => ({
  type: "FETCH_MERCHANT_LIST",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateMerchantList = (data: any): Action => ({
  type: "UPDATE_MERCHANT_LIST",
  data,
});