// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchTpaSrs = (): Action => ({
  type: "FETCH_TPA_SRS",
});

/**
 * @return {*}  {Action}
 */
export const fetchFullTpaSrs = (): Action => ({
  type: "FETCH_FULL_TPA_SRS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateFullTpaSrs = (data: any): Action => ({
  type: "UPDATE_FULL_TPA_SRS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createTpaSr = (data: any): Action => ({
  type: "CREATE_TPA_SR",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editTpaSr = (data: any): Action => ({
  type: "EDIT_TPA_SR",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateTpaSr = (data: any): Action => ({
  type: "UPDATE_TPA_SR",
  data,
});

/**
 * @return {*}  {Action}
 */
export const getTpaSr = (): Action => ({
  type: "GET_TPA_SR",
});

/**
 * @return {*}  {Action}
 */
export const clearTpaSr = (): Action => ({
  type: "CLEAR_TPA_SR",
});
