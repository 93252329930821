// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchSuperAdmins = (): Action => ({
  type: "FETCH_SUPER_ADMINS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateSuperAdmins = (data: any): Action => ({
  type: "UPDATE_SUPER_ADMINS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchUsersList = (data: any): Action => ({
  type: "FETCH_USERS_LIST",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateUsersList = (data: any): Action => ({
  type: "UPDATE_USERS_LIST",
  data,
});
