import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchFullEmployees,
  setLoading,
  updateCompanyStats,
  updateDashboardStats,
} from "../actions";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { fetchFullEmployeesWorker } from "./employee";

//fetch General stats
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Update master card status
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* updateMasterCardStatusWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "put",
    path: `card-services/status`,
    data: action.data.body,
  };
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") === 400
    ) {
      throw { message: jsonResponse.data.fnMessage };
    }
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") === 200
    ) {
      yield call(fetchFullEmployeesWorker);
      return yield put(
        setLoading({
          loading: false,
          alertType: "success",
          successMessage: "SuccessFully Updated Card Status",
        })
      );
    }
  } catch (e) {
    console.log("error", e);
    yield put(
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: e.message,
      })
    );
  }
}

/**
 * @return {*}  {Generator}
 */
function* updateMasterCardStatusWatcher(): Generator {
  yield takeLatest("UPDATE_MASTERCARD_STATUS", updateMasterCardStatusWorker);
}

export { updateMasterCardStatusWatcher };
