// Parse
import Parse from "parse";

const {
  REACT_APP_PARSE_APP_ID,
  REACT_APP_PARSE_CLIENT_KEY,
  REACT_APP_PARSE_URL,
}: any = process.env;

Parse.initialize(REACT_APP_PARSE_APP_ID, REACT_APP_PARSE_CLIENT_KEY);
Parse.serverURL = REACT_APP_PARSE_URL;

/**
 * @desc Parse.initialize('myAppId', 'myJsKey', 'myMasterKey'); - Parse.serverURL = 'http://7e693b7a7fe8.ngrok.io/parse/';
 * @param {*} {username, password, personGuid, businessGuid, userRole}
 * @return {*}  {Promise<any>}
 */
const handleUserCreateOrLogin = async ({
  username,
  password,
  personGuid,
  businessGuid,
  userRole,
}: any): Promise<any> => {
  try {
    const user: any = await Parse.User.logIn(username, password);
    user.set("isOnline", true);
    await user.save();
    return user;
  } catch (e) {
    if (e.code === 101) {
      const userReg: any = new Parse.User();
      userReg.set("username", username);
      userReg.set("password", password);
      userReg.set("personGuid", personGuid);
      userReg.set("businessGuid", businessGuid);
      userReg.set("userRole", userRole);
      userReg.set("isOnline", true);
      return userReg.signUp();
    }
  }
};

/**
 * @return {*}  {Promise<any>}
 */
const handleUserLogout = async (): Promise<any> => Parse.User.logOut();

/**
 * @param {*} { businessGuid, userRole = 'PCO' }
 * @return {*}  {Promise<any>}
 */
const fetchAllLoggedInPcos = async ({
  businessGuid,
  userRole = "PCO",
}: any): Promise<any> => {
  const query = new Parse.Query(Parse.User);
  businessGuid && query.equalTo("businessGuid", businessGuid);
  query.equalTo("userRole", userRole);
  return query.count();
};

/**
 * @param {*} { businessGuid, userRole = 'PCO' }
 * @return {*}  {*}
 */
const getEmployeesQuery = ({ businessGuid, userRole = "PCO" }: any): any => {
  const query = new Parse.Query(Parse.User);
  businessGuid && query.equalTo("businessGuid", businessGuid);
  query.equalTo("userRole", userRole);
  return query;
};

// Export
export default {
  handleUserCreateOrLogin,
  handleUserLogout,
  fetchAllLoggedInPcos,
  ParseObject: Parse,
  getEmployeesQuery,
};
