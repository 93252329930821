import _, { get } from "lodash";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setLoading, updateCompanyStats, fetchCompanies } from "../actions";
import {
  authError,
  changeSubView,
  updateAuth,
  setRole,
  updatePreAuth,
  storeTerminateUser,
} from "../actions/auth";
import history from "../history";
import { deleteState } from "../store/localStorageService";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import ParseHelper from "../utilities/parse";
import store from "../store/configureStore";
import jwtDecode from "jwt-decode";
import data from "../components/dashboard/users/data";
import {
  AUTH_API_PATHS,
  AUTH_CHALLENGE_NAMES,
  AUTH_EVENT_TYPES,
  AUTH_FLOW,
  HTTP_METHODS,
} from "./types";
import { SENTRY_PAGES, SENTRY_ERRORS } from "./utilities/sentry-error.constant";
import externalAxios from "axios";
import { setSelfEnrolmentDetails } from "../actions";

//Self enrolment
const BASE_URL = `${process.env.REACT_APP_API_URL}/v1/`;
const HEADERS = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_API_KEY,
};

interface IParams {
  method: string;
  path: string;
  data: any;
}

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @return {*}
 */
/* async function getUserClaims() {
  let claims = await Auth.getUserClaims();
  return claims;
}

function increaseLoggedIn(): void {
  let state = store.getState();
  let newValue = state.companyReducer.companyStats.loggedInEmployees + 1;
  store.dispatch(updateCompanyStats({ loggedInEmployees: newValue }));
}

function decreaseLoggedIn(): void {
  console.log("user logged out");
  let state = store.getState();
  let newValue = state.companyReducer.companyStats.loggedInEmployees - 1;
  store.dispatch(updateCompanyStats({ loggedInEmployees: newValue }));
}

async function callSignIn(data: any) {
  console.log("in function: asdasd");
  // store.disptch(signIn(data));
} */

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* signIn(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: null }));
  let params: any = {
    method: "POST",
    path: "signin",
    data: action.data,
  };
  // create the new variable
  let isRedirectToChangeEmailView: boolean = false;
  // query string
  let queryString: string = "";
  // check redirect key
  if (_.has(action, "data.params.isRedirectToChangeEmailView")) {
    // get redirect to change email view key value
    isRedirectToChangeEmailView =
      action.data.params.isRedirectToChangeEmailView || false;
    // delete is redirect to change email view
    delete action.data.params.isRedirectToChangeEmailView;
  }
  // check query string key
  if (_.has(action, "data.params.queryString")) {
    // get redirect to change email view key value
    queryString = action.data.params.queryString || "";
    // delete is redirect to change email view
    delete action.data.params.queryString;
  }
  try {
    let response: any = yield call(axios, params);
    response = JSON.parse(response.data.body);
    yield put(setLoading({ loading: false }));
    const email: any = action.data.params.AuthParameters.USERNAME;
    if (
      _.has(response, "data.ChallengeName") &&
      response.data.ChallengeName === "NEW_PASSWORD_REQUIRED"
    ) {
      // history.push('/');
      history.push("/set-new-password", {
        view: "CONFIRM_NEW_PASSWORD",
        description: `For account ${email}`,
        primaryButtonName: "Set New Password",
        session: response.data.Session,
        userIdForSRP: response.data.ChallengeParameters.USER_ID_FOR_SRP,
        email,
        // modal: true
      });
      return;
    } else {
      if (!_.has(response, "data.AuthenticationResult")) {
        throw {
          message:
            "For security reasons, please reset your password by clicking 'Forgot Password'. If you've already reset it, ensure your username and password are correct.",
        };
      }
      const { AccessToken, IdToken, RefreshToken, ExpiresIn }: any =
        response.data.AuthenticationResult;
      let userRoles, parsedUserRoles, parsedUserRole, roles;
      if (IdToken) {
        const userIdToken: any = jwtDecode(IdToken);
        parsedUserRole = _.get(userIdToken, "userRole");
        userRoles = _.get(userIdToken, "roles");
        parsedUserRoles = userRoles && JSON.parse(userRoles);
        roles = parsedUserRoles;

        if (_.get(userIdToken, "userRole")) {
          const isPlanAdminRole: any =
            userIdToken.userRole === "planSponsorContact" ||
            userIdToken.userRole === "planSponsorAdministratorPrimary" ||
            userIdToken.userRole === "planSponsorAdministrator";

          const isSuperAdminRole: any = userIdToken.userRole === "superAdmin";
        }
      }
      yield put(
        updateAuth({
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          expiresInUTC: moment.utc().add(ExpiresIn, "seconds").utc(),
        })
      );

      localStorage.setItem("freshLogin", "true");

      yield put(setLoading({ loading: false }));
      const availableUserRoles: any = _.get(parsedUserRoles, "available");

      // if (availableUserRoles && availableUserRoles.length > 1) {
      //   yield put(
      //     setRole({
      //       idToken: IdToken,
      //       role: parsedUserRole,
      //       roles: userRoles,
      //       userRoles: parsedUserRoles,
      //     })
      //   );
      //   return history.push("/role/select");
      // }

      history.push(
        isRedirectToChangeEmailView
          ? `/set-new-email/${btoa(email)}`
          : "/dashboard"
      );
    }
  } catch (err) {
    const email: any = action?.data?.params?.AuthParameters?.USERNAME || "";
    Sentry.captureException(err.message, {
      tags: {
        page: SENTRY_PAGES.login,
        type: SENTRY_ERRORS.authFailed,
        email,
      },
    });
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          "Oops, an error has occurred. Please refresh and try again.",
      })
    );

    if (action.isSetNewPassword) {
      history.push("/set-new-password", {
        messageBarDisplayCSS: "flex",
        message:
          "Your email or verification code is incorrect. Please request a new code using the link in your verification email.",
        loading: false,
      });
      return;
    }

    history.push("/");

    // history.push("/login", {
    //   modal: true,
    //   messageBarDisplayCSS: "flex",
    //   message: err.message,
    //   loading: false,
    // });

    return;
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* renewVerificationCodeWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));

  let params: any = {
    method: "POST",
    path: "verification-code",
    data: action.data,
  };

  try {
    let response: any = yield call(axios, params);

    if (response.data.statusCode != 200) {
      throw response.data;
    }

    yield put(setLoading({ loading: false }));
    history.push("/");
    history.push("/renew-verification-code", {
      renewed: true,
      modal: true,
    });
    return;
  } catch (err) {
    console.log(err);
    yield put(setLoading({ loading: false }));
    history.push("/");
    history.push("/renew-verification-code", {
      message:
        "There was an error renewing your verification code. Please contact support.",
      modal: true,
    });
    return;
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* respondToAuthChallenge(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let params: any = {
    method: "POST",
    path: "signin",
    data: action.data,
  };

  try {
    let response: any = yield call(axios, params);
    response = JSON.parse(response.data.body);
    yield put(setLoading({ loading: false }));
    setTimeout(() => {
      history.push("/login", { modal: true });
    }, 500);
    history.push("/");
  } catch (err) {
    yield put(setLoading({ loading: false }));
    history.push(history.location.pathname, {
      message: "Unable to set new password. Please contact your administrator.",
      messageBarDisplayCSS: "flex",
      loading: false,
      // modal: true
    });
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* forgotPassword(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let params: any = {
    method: "POST",
    path: "forgot-password",
    data: action.data,
  };

  try {
    let response: any = yield call(axios, params);
    if (response.data == null) {
      throw "Error occurred fetching data.";
    }
    if (response.data.statusCode !== 200) {
      throw JSON.parse(response.data.body);
    }
    if (response.data.statusCode && response.data.statusCode === 200) {
      response = JSON.parse(response.data.body);
      let routeData: any = {
        view: "STEP_TWO",
      };

      yield put(setLoading({ ...routeData, loading: false }));
    }
  } catch (err) {
    console.log("forgetPassword saga err: ", err);
    yield put(
      setLoading({
        loading: false,
        errorMessage: err.data,
      })
    );
  }
}

export function* confirmForgotPasswordVerification(action: any): Generator {
  try {
    yield put(setLoading({ loading: true, errorMessage: "" }));
    let params: IParams = {
      method: HTTP_METHODS.POST,
      path: AUTH_API_PATHS.SIGN_IN,
      data: {
        eventName: AUTH_EVENT_TYPES.SIGN_IN,
        params: action.data,
      },
    };

    const response: any = yield call(axios, params);

    if (response.data && response.data.statusCode === 200) {
      yield put(setLoading({ loading: false, errorMessage: "" }));
    }

    if (response.data && response.data.statusCode === 400) {
      yield put(
        setLoading({
          loading: false,
          errorMessage:
            "Incorrect verification code. Has your code expired? Verification codes last for 24 hours.",
        })
      );
    }
  } catch (error) {
    yield put(setLoading({ loading: false, errorMessage: error }));
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* confirmForgotPassword(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let params: any = {
    method: "POST",
    path: "forgot-password",
    data: action.data,
  };

  try {
    let response: any = yield call(axios, params);
    if (response.data.statusCode !== 200) {
      throw JSON.parse(response.data.body);
    }

    response = JSON.parse(response.data.body);
    let routeData: any = { view: "STEP_THREE", loading: false };
    yield put(setLoading(routeData));
    history.push("/reset-password", { modal: true });
  } catch (err) {
    yield put(setLoading({ loading: false }));
    if (err.error) {
      yield put(
        setLoading({ loading: false, errorMessage: err.error?.message })
      );
    }
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* changePassword(action: any): Generator {
  let params: any = {
    method: "POST",
    path: "change-password",
    data: action.data,
  };

  try {
    yield put(setLoading({ loading: true }));
    const response: any = yield call(axios, params, action.authTokens);
    if (response.data.statusCode !== 200) {
      throw JSON.parse(response.data.body);
    }
    yield put(
      setLoading({
        successMessage: "Password successfully changed",
        loading: false,
      })
    );
  } catch (err) {
    yield put(
      setLoading({
        loading: false,
        error: true,
        errorMessage:
          "Old password incorrect. If you are experiencing technical difficulties, contact support@ayacare.com",
      })
    );
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* changeRole(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "POST",
    path: `change-role/?at=${selectedAuthReducer.accessToken}`,
    data: action.data,
  };

  try {
    const response: any = yield call(axios, params, action.authTokens);

    if (response.data.statusCode !== 200) {
      throw JSON.parse(response.data.body);
    }
    const responseBody: any = JSON.parse(response.data.body);
    const newIdToken: any = jwtDecode(responseBody.data);
    const newRole: any = _.get(newIdToken, "userRole");
    const roles: any = _.get(newIdToken, "roles");
    let routeData: any = { view: "CHANGE_ROLE_SUCCESS", loading: false };
    yield put(
      setLoading({ loading: false, pageLoading: false, errorMessage: "" })
    );
    // yield put(
    //   setRole({
    //     idToken: responseBody.data,
    //     role: action.data.params.RoleName,
    //     roles: roles,
    //     userRoles: JSON.parse(roles),
    //   })
    // );
    history.push("/dashboard");
  } catch (err) {
    yield put(
      setLoading({
        loading: false,
        pageLoading: false,
        errorMessage: "Failed to change role",
      })
    );
  }
}

/**
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* signout(action: any): Generator {
  let params: any = {
    method: "POST",
    path: "signout",
    data: action.data,
  };

  try {
    let selectedAuthReducer: any = yield select(selectAuthReducer);
    let userRole: any = selectedAuthReducer.userClaims.userRole;
    if (userRole) {
      if (userRole === "PCO") {
        yield ParseHelper.handleUserLogout();
      }
    }
    const response: any = yield call(axios, params, action.authTokens);
    deleteState();
  } catch (err) {
    history.push(history.location.pathname, {
      message: err,
    });
  }
}

export function* checkEmail(action: any): Generator {
  let params: IParams = {
    method: HTTP_METHODS.POST,
    path: AUTH_API_PATHS.SIGN_IN,
    data: {
      eventName: AUTH_EVENT_TYPES.SIGN_IN,
      params: action.data,
    },
  };

  try {
    yield put(
      setLoading({ loading: true, errorMessage: "", emailConfirmed: false })
    );
    let response: any = yield call(axios, params);

    if (response.status === 200 && response.data.body) {
      const parsedData = JSON.parse(response.data.body);

      if (parsedData?.error) {
        const { error } = parsedData;
        yield put(
          updatePreAuth({
            firstName: error.firstName,
            personStatus: error.personStatus,
            status: error.status,
            message: error.message,
            whiteLabel: error?.whiteLabel,
            email: action.data.AuthParameters.USERNAME,
          })
        );

        if (error.status === "FORCE_CHANGE_PASSWORD") {
          history.push("/set-verification", {
            email: action.data.AuthParameters.USERNAME,
          });
        }

        yield put(
          setLoading({ loading: false, errorMessage: "", emailConfirmed: true })
        );
      }
    }

    if (
      get(response.data, "errorMessage")?.includes("UserNotFoundException") ||
      get(response.data, "errorMessage")?.includes("User does not exist")
    ) {
      yield put(
        setLoading({
          loading: false,
          errorMessage:
            'Email not found. Please try again with an enroled email or contact <a href="mailto:support@ayacare.com">support@ayacare.com</a>',
        })
      );
    }
  } catch (error) {
    yield put(setLoading({ loading: false, errorMessage: error }));
  }
}

export function* resendVerification(action: any): Generator {
  try {
    yield put(setLoading({ loading: true, errorMessage: "" }));
    let params: IParams = {
      method: HTTP_METHODS.POST,
      path: AUTH_API_PATHS.VERIFICATION_CODE,
      data: {
        eventName: AUTH_EVENT_TYPES.RENEW_VERIFICATION_CODE,
        email: action.data,
        params: { Token: "mobile" },
      },
    };

    let response: any = yield call(axios, params);
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          'New code sent to your email. Please check inbox/spam folder or contact <a href="mailto:support@ayacare.com">support@ayacare.com</a>',
      })
    );
  } catch (error) {
    yield put(setLoading({ loading: false, errorMessage: error }));
  }
}

export function* confirmVerification(action: any): Generator {
  try {
    yield put(setLoading({ loading: true, errorMessage: "" }));
    let params: IParams = {
      method: HTTP_METHODS.POST,
      path: AUTH_API_PATHS.SIGN_IN,
      data: {
        eventName: AUTH_EVENT_TYPES.SIGN_IN,
        params: action.data,
      },
    };

    const response: any = yield call(axios, params);

    if (response.data && response.data.statusCode === 200) {
      const parsedResponse = JSON.parse(response.data.body);

      if (parsedResponse.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        history.push("/set-password", { data: parsedResponse.data });

        const selectedAuthReducer: any = yield select(selectAuthReducer);

        yield put(
          updatePreAuth({
            ...selectedAuthReducer.preAuthDetails,
            userSRPId: parsedResponse.data.ChallengeParameters.USER_ID_FOR_SRP,
            session: parsedResponse.data.Session,
          })
        );
      }
      yield put(setLoading({ loading: false, errorMessage: "" }));
    }

    if (response.data && response.data.statusCode === 400) {
      yield put(
        setLoading({
          loading: false,
          errorMessage:
            'Incorrect. Codes expiry after 24 hours. Please select resend or contact <a href="mailto:support@ayacare.com">support@ayacare.com</a>',
        })
      );
    }
  } catch (error) {
    yield put(setLoading({ loading: false, errorMessage: error }));
  }
}

export function* setNewPassword(action: any): Generator {
  try {
    yield put(setLoading({ loading: true, errorMessage: "" }));
    const { data: actionData } = action;

    let params: IParams = {
      method: HTTP_METHODS.POST,
      path: AUTH_API_PATHS.SIGN_IN,
      data: {
        eventName: AUTH_EVENT_TYPES.RESPOND_TO_AUTH_CHALLENGE,
        params: {
          ChallengeName: AUTH_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED,
          ChallengeResponses: {
            USERNAME: actionData.email,
            NEW_PASSWORD: actionData.password,
          },
          Session: actionData.session,
          UserIdForSRP: actionData.userSRPId,
        },
      },
    };
    const response: any = yield call(axios, params);
    const parsedData = JSON.parse(response.data.body);

    if (response.data.statusCode === 200 && response.data.body) {
      if (parsedData.message === "Success") {
        let data: any = {
          eventName: AUTH_EVENT_TYPES.SIGN_IN,
          params: {
            AuthFlow: AUTH_FLOW.ADMIN_NO_SRP_AUTH,
            AuthParameters: {
              USERNAME: actionData.email,
              PASSWORD: actionData.password,
            },
            ClientMetadata: {
              secretToBypassPreAuthTrigger: "WxYhd896hK%$j*hG!",
            },
          },
        };
        const signInResponse = yield put({ type: "SIGN_IN", data });
      }
      yield put(setLoading({ loading: false, errorMessage: "" }));
    }

    if (response.data.statusCode === 400 && parsedData.message === "Failure") {
      yield put(
        setLoading({
          loading: false,
          errorMessage:
            "There was a problem processing your request. Please try again.",
        })
      );
    }
  } catch (error) {
    yield put(
      setLoading({
        loading: false,
        errorMessage:
          "There was a problem processing your request. Please try again.",
      })
    );
  }
}

/**
 * @desc Patch Terminate Employee
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* setNewEmailWorker(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "POST",
    path: `persons/planmember/change-email`,
    data: action.payload,
  };
  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);
    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") !== 200
    ) {
      return yield put(
        setLoading({
          loading: false,
          errorMessage: jsonResponse.data.fnMessage,
        })
      );
    }
    yield put(
      setLoading({
        loading: false,
        alertType: "success",
        successMessage: "SuccessFully Updated Email",
      })
    );
    return history.push("/dashboard");
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

export function* fetchTerminatedUserWorker(): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  // let jsonResponse2: any = null;
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: `persons/planmember/terminate?at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);

    if (
      jsonResponse &&
      jsonResponse.status !== 200 &&
      jsonResponse.data.fnError
    ) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: jsonResponse.data.fnError,
        })
      );
    } else if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200
    ) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: jsonResponse.data.fnError,
        })
      );
    }

    yield put(storeTerminateUser(JSON.parse(jsonResponse.data.fnResult)));

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(setLoading({ loading: false, errorMessage: ex.message }));
    return ex;
  }
}

export function* triggerSelfEnrolmentVerificationCode(action: any): Generator {
  yield put(setLoading({ loading: true, errorMessage: "" }));
  yield put(setSelfEnrolmentDetails({ sendingVerificationCode: true }));
  let jsonResponse: any = null;
  let params: any = {
    method: "post",
    path: `${BASE_URL}businesses/selfEnrollment/verificationCode`,
    data: {
      email: action.data,
    },
    options: {
      headers: HEADERS,
    },
  };

  try {
    jsonResponse = yield externalAxios.post(
      params.path,
      params.data,
      params.options
    );

    if (
      jsonResponse &&
      jsonResponse.data &&
      jsonResponse.data.fnStatusCode !== 200 &&
      jsonResponse.data.fnStatusCode !== 201
    ) {
      throw jsonResponse.data.fnError ?? "";
    }

    yield put(
      setSelfEnrolmentDetails({
        verificationCodeSentSuccessfully: true,
        verificationCodeSentFailed: false,
        email: action.data,
        sendingVerificationCode: false,
      })
    );

    return yield put(setLoading({ loading: false }));
  } catch (ex) {
    yield put(
      setSelfEnrolmentDetails({
        verificationCodeSentSuccessfully: false,
        verificationCodeSentFailed: true,
        email: "",
        sendingVerificationCode: false,
      })
    );
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchTerminatedUserWatcher(): Generator {
  yield takeEvery("FETCH_TERMINATE_USER", fetchTerminatedUserWorker);
}

/**
 * @return {*}  {Generator}
 */
function* setNewEmailWatcher(): Generator {
  yield takeEvery("SET_NEW_EMAIL", setNewEmailWorker);
}

/**
 * @return {*}  {Generator}
 */
function* signInWatcher(): Generator {
  yield takeEvery("SIGN_IN", signIn);
}

/**
 * @return {*}  {Generator}
 */
function* renewVerificationCodeWatcher(): Generator {
  yield takeEvery("RENEW_VERIFICATION_CODE", renewVerificationCodeWorker);
}

/**
 * @return {*}  {Generator}
 */
function* respondToAuthChallengeWatcher(): Generator {
  yield takeEvery("RESPOND_TO_AUTH_CHALLENGE", respondToAuthChallenge);
}

/**
 * @return {*}  {Generator}
 */
function* forgotPasswordWatcher(): Generator {
  yield takeEvery("FORGOT_PASSWORD", forgotPassword);
}

/**
 * @return {*}  {Generator}
 */
function* confirmForgotPasswordWatcher(): Generator {
  yield takeEvery("CONFIRM_FORGOT_PASSWORD", confirmForgotPassword);
}

/**
 * @return {*}  {Generator}
 */
function* changePasswordWatcher(): Generator {
  yield takeEvery("CHANGE_PASSWORD", changePassword);
}

/**
 * @return {*}  {Generator}
 */
function* changeRoleWatcher(): Generator {
  yield takeEvery("CHANGE_ROLE", changeRole);
}

/**
 * @return {*}  {Generator}
 */
function* signoutWatcher(): Generator {
  yield takeEvery("SIGNOUT", signout);
}

function* checkEmailWatcher(): Generator {
  yield takeEvery("CHECK_EMAIL", checkEmail);
}

function* resendVerificationWatcher(): Generator {
  yield takeEvery("RESEND_VERIFICATION", resendVerification);
}

function* confirmVerificationWatcher(): Generator {
  yield takeEvery("CONFIRM_VERIFICATION", confirmVerification);
}

function* setNewPasswordWatcher(): Generator {
  yield takeEvery("SET_NEW_PASSWORD", setNewPassword);
}

function* confirmForgotPasswordVerificationWatcher(): Generator {
  yield takeEvery(
    "CONFIRM_FORGOT_PASSWORD_VERIFICATION",
    confirmForgotPasswordVerification
  );
}

function* triggerSelfEnrolmentVerificationCodeWatcher(): Generator {
  yield takeEvery(
    "TRIGGER_SELF_ENROLMENT_VERIFICATION_CODE",
    triggerSelfEnrolmentVerificationCode
  );
}

export {
  signInWatcher,
  signoutWatcher,
  changePasswordWatcher,
  respondToAuthChallengeWatcher,
  forgotPasswordWatcher,
  confirmForgotPasswordWatcher,
  renewVerificationCodeWatcher,
  changeRoleWatcher,
  checkEmailWatcher,
  resendVerificationWatcher,
  confirmVerificationWatcher,
  setNewPasswordWatcher,
  setNewEmailWatcher,
  fetchTerminatedUserWatcher,
  confirmForgotPasswordVerificationWatcher,
  triggerSelfEnrolmentVerificationCodeWatcher,
};
