import { IClaimUsageStats } from "./claims.interface";

export const CLAIM_USAGE_STATS_INITIAL_STATE: IClaimUsageStats = {
    limit: 0,
    offset: 0,
    claimsCount: 0,
    totalClaimsCount: 0,
    approvedClaims: {
        count: 0,
        amount: 0
    },
    awaitingAdjudicationClaims: {
        count: 0,
        amount: 0
    },
    declinedClaims: {
        count: 0,
        amount: 0
    }
};

export const SUBMITTED = "submitted"
export const AUTHORIZED = "authorized"