import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./auth";
import brokerReducer from "./broker";
import brokerageHouseReducer from "./brokerageHouse";
import claimsReducer from "./claims";
import companyReducer from "./company";
import dependentReducer from "./dependent";
import employeeReducer from "./employee";
import generalReducer from "./general";
import statsReducer from "./stats";
import tpaReducer from "./tpa";
import tpaSrReducer from "./tpaSr";
import paymentsReducer from "./payments";
import invoiceReducer from "./invoice";
import ledgerReducer from "./ledger";
import processorReducer from "./processor";
import merchantReducer from "./merchant";
import superAdminReducer from "./superAdmin";
import sensiBillReducer from "./sensiBill";
import slsReducer from "./sls";

const rootReducer = combineReducers({
  state: () => ({
    firstName: null,
  }),
  authReducer,
  form: formReducer,
  brokerageHouseReducer,
  tpaReducer,
  companyReducer,
  employeeReducer,
  dependentReducer,
  brokerReducer,
  tpaSrReducer,
  generalReducer,
  claimsReducer,
  statsReducer,
  paymentsReducer,
  invoiceReducer,
  ledgerReducer,
  processorReducer,
  merchantReducer,
  superAdminReducer,
  sensiBillReducer,
  slsReducer
});

export default rootReducer;
