// Types
import { Action } from "../actions/types";

// State Types
type State = {
  loading: boolean;
  hasErrors: boolean;
  error: string;
  responsePayload: any;
  sensiBillAccessToken: string;
  receiptID: string;
  expiresIn: string | number;
  rawImagesReceipt: Array<any>;
  rawImagesItem: Array<any>;
  itemIndex: any;
  data: any;
  tokenId: string;
};

// Initial state
const INITIAL_STATE: State = {
  loading: false,
  hasErrors: false,
  error: '',
  responsePayload: null,
  sensiBillAccessToken: "",
  receiptID: "",
  expiresIn: "",
  rawImagesReceipt: [],
  rawImagesItem: [],
  itemIndex: undefined,
  data: {},
  tokenId: ''
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
/*     case "REGISTER_SENSI_BILL":
      return { ...state }; */
    case "LOGIN_SENSI_BILL":
      return { ...state };
    case "CREATE_SENSI_BILL":
      return { ...state };
    case "POLLING_SENSI_BILL":
      return { ...state };
    case "GET_RECEIPT_SENSI_BILL":
      return { ...state };
    case "UPDATE_SENSI_BILL_DETAILS":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
