import React from "react";
import { Provider as AlertProvider } from "react-alert";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Alert from "./components/shared/alert";
import ScrollToTop from "./components/shared/scrollToTop";
import Maintenance from "./containers/Maintenance";
import RootScreen from "./containers/root";
import history from "./history";
import * as serviceWorker from "./serviceWorker";
import store from "./store/configureStore";
import styles from "./styles/fonts/index.module.css";
import ReactGA from "react-ga";
import CustomModal from "./components/shared/customModal";

// ReactGA.initialize( location.origin === 'https://www.app.ayacare.com' ? 'G-QYFHNYLFGH' : 'UA-146907781-1');
const trackingID = "G-QYFHNYLFGH"; //replace with code from env
ReactGA.initialize(trackingID, { testMode: process.env.NODE_ENV === "test" });
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_DEPLOY_ENVIRONMENT,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


const App = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === "on") {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Provider store={store}>
            <div className={styles.defaultFont}>
              <Maintenance />
            </div>
          </Provider>
        </ScrollToTop>
      </Router>
    );
  }
  return (
    <Router history={history}>
      <ScrollToTop>
        <Provider store={store}>
          <AlertProvider template={Alert}>
            <div className={styles.defaultFont}>
              <CustomModal />
              <RootScreen />
            </div>
          </AlertProvider>
        </Provider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
