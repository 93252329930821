import * as React from "react";
import { useSelector } from "react-redux";

const ArrowIconGradientRight: React.FunctionComponent = (props) => {
  const [whiteLabel] = useSelector((state: any) => [
    state.authReducer.preAuthDetails.whiteLabel,
  ]);
  const primary = whiteLabel?.primarycolor ?? "#606ac1";
  const secondary = whiteLabel?.secondarycolor ?? "#e300b1";

  return (
    <svg width={50} height={25} viewBox="27 20 10 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.591 22.378l8.039 8.21a1.31 1.31 0 010 1.824l-8.039 8.21a1.245 1.245 0 01-1.786 0 1.31 1.31 0 010-1.824l5.882-6.008H19v-2.58h19.687l-5.882-6.008a1.31 1.31 0 010-1.824 1.245 1.245 0 011.786 0z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.63 32.412a1.31 1.31 0 000-1.824l-8.039-8.21a1.245 1.245 0 00-1.786 0 1.31 1.31 0 000 1.824l5.882 6.008H19v2.58h19.687l-5.882 6.008a1.31 1.31 0 000 1.824 1.245 1.245 0 001.786 0l8.039-8.21z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={78.3196}
          y1={48.3077}
          x2={124.236}
          y2={46.0087}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB14D7" />
          <stop offset={1} stopColor="#2D42FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1={25.9278}
          y1={36.9808}
          x2={44.2669}
          y2={35.8209}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB14D7" />
          <stop offset={1} stopColor="#2D42FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1={25.9278}
          y1={36.9808}
          x2={44.2669}
          y2={35.8209}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ArrowIconGradientRight;
