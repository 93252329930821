import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  delay,
} from "redux-saga/effects";
import {
  setLoading,
  updateLedgerStats,
  fetchCollectionLedger,
  updateLedger,
  updateLedgerGeneral,
} from "../actions";
import axios from "../utilities/axiosWrapper";
import _ from "lodash";

// Auth reducer
export const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch ledger stats
 * @return {*}  {Generator}
 */
export function* fetchLedgerStats(): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResp: any = null;
  let params: any = {
    method: "GET",
    path: `ledger/overview?at=${selectedAuthReducer.accessToken}`,
  };

  try {
    jsonResp = yield call(axios, params, apiKeys);
    if (_.has(jsonResp, "data.fnResult")) {
      yield put(updateLedgerStats(JSON.parse(jsonResp.data.fnResult)));
    }
    yield put(setLoading({ loading: false }));
  } catch (err) {
    yield put(setLoading({ loading: false, errorMessage: err.message }));
  }
}

/**
 * @desc Fetch ledger
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* fetchLedger(action: any): Generator {
  yield put(
    updateLedgerGeneral({ type: "fetchLedgerListLoading", data: true })
  );
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResp: any = null;
  let params: any = {
    method: "POST",
    path: `ledger/list?at=${selectedAuthReducer.accessToken}`,
    data: {
      ledgerAccount: `${action.data.type}`,
      sortType: "asc",
      offset: 0,
      limit: 0,
    },
  };
  try {
    jsonResp = yield call(axios, params, apiKeys);
    if (_.has(jsonResp, "data.fnResult") && jsonResp.data.fnResult !== "") {
      yield put(
        updateLedger({
          key: action.data.type,
          data: JSON.parse(jsonResp.data.fnResult),
        })
      );
      yield put(
        updateLedgerGeneral({ type: "fetchLedgerListLoading", data: false })
      );
    }

    if (
      (jsonResp.data.fnStatusCode && jsonResp.data.fnStatusCode === 400) ||
      jsonResp.data.fnStatusCode === 500
    ) {
      yield put(
        updateLedgerGeneral({ type: "fetchLedgerListLoading", data: false })
      );
    }
  } catch (e) {
    yield put(updateLedgerGeneral({ type: "fetchLedgerListLoading", data: false }))
  }
}

/**
 * @desc Create memo transaction
 * @param {*} action
 * @return {*}  {Generator}
 */
export function* createMemoTransaction(action: any): Generator {
  yield put(
    updateLedgerGeneral({ type: "postTransactionLoading", data: true })
  );
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResp: any = null;
  let params: any = {
    method: "POST",
    path: `ledger/transaction?type=${action.data.transactionId.toLowerCase()}&at=${
      selectedAuthReducer.accessToken
    }`,
    data: action.data,
  };

  try {
    jsonResp = yield call(axios, params, apiKeys);
    if (_.has(jsonResp, "data.fnResult")) {
      yield delay(5000);
      yield put(
        updateLedgerGeneral({ type: "postTransactionLoading", data: false })
      );
      yield put(fetchCollectionLedger({ type: action.data.accountName }));
      yield put(
        updateLedgerGeneral({
          type: "successMessage",
          data: "Transaction successfully created!",
        })
      );
    }

    if (_.has(jsonResp, "data.fnMessage") && jsonResp.data.fnMessage !== "") {
      yield put(
        updateLedgerGeneral({ type: "errorMessage", data: `createMemoErr` })
      );
    }
  } catch (e) {
    yield put(
      updateLedgerGeneral({ type: "postTransactionLoading", data: false })
    );
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchLedgerStatsWatcher(): Generator {
  yield takeLatest("FETCH_LEDGER_STATS", fetchLedgerStats);
}

/**
 * @return {*}  {Generator}
 */
function* fetchLedgerWatcher(): Generator {
  yield takeEvery("FETCH_LEDGER", fetchLedger);
}

/**
 * @return {*}  {Generator}
 */
function* createMemoTransactionWatcher(): Generator {
  yield takeEvery("CREATE_MEMO_TRANSACTION", createMemoTransaction);
}

export {
  fetchLedgerStatsWatcher,
  fetchLedgerWatcher,
  createMemoTransactionWatcher,
};
