import React from "react";
import { useSelector } from "react-redux";
import { OptionItem, OptionText } from "../MenuOptionsBar.styled.components";
import { COLOR_SCHEMES } from "../../../../designSystem/colors";

const MenuOptionItem = ({
  name,
  navLink,
}: {
  name: string;
  navLink: string;
}) => {
  const [whiteLabel] = useSelector((state: any) => [
    state.authReducer.preAuthDetails.whiteLabel,
  ]);
  const primaryColor =
    whiteLabel?.primarycolor ?? COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE;
  const secondaryColor =
    whiteLabel?.secondarycolor ?? COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE;
  return (
    <OptionItem data-testid={`${name}-link`}>
      <OptionText
        exact
        to={navLink ?? "/dashboard"}
        primarycolor={primaryColor}
        secondarycolor={secondaryColor}
      >
        {name}
      </OptionText>
    </OptionItem>
  );
};

export default MenuOptionItem;
