import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import axios from "../utilities/axiosWrapper";
import { setLoading, updateInvoiceList } from "../actions";
import { Auth } from "../utilities/auth";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch invoices
 * @return {*}  {Generator}
 */
function* fetchInvoices(): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "GET",
    path: `invoice?info=overview&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      const parsedList: any = JSON.parse(jsonResponse.data.fnResult);
      yield put(updateInvoiceList(parsedList.list));
      yield put(setLoading({ loading: false }));
    }
  } catch (err) {
    return err;
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchInvoicesWatcher(): Generator {
  yield takeLatest("FETCH_INVOICES", fetchInvoices);
}

/**
 * @return {*}  {Generator}
 */
function* fetchInvoiceDetails(): Generator {
  yield call(console.log("in fetch invoices"));
}

/**
 * @return {*}  {Generator}
 */
function* fetchInvoiceDetailsWatcher(): Generator {
  yield takeLatest("FETCH_INVOICE_DETAILS", fetchInvoiceDetails);
}

/**
 * @return {*}  {Generator}
 */
function* sendInvoice(): Generator {
  yield call(console.log("in fetch invoices"));
}

/**
 * @return {*}  {Generator}
 */
function* sendInvoiceWatcher(): Generator {
  yield takeLatest("SEND_INVOICE", sendInvoice);
}

/**
 * @return {*}  {Generator}
 */
function* createJournal(): Generator {
  yield call(console.log("in fetch invoices"));
}

/**
 * @return {*}  {Generator}
 */
function* createJournalWatcher(): Generator {
  yield takeLatest("CREATE_JOURNAL", createJournal);
}

export {
  fetchInvoiceDetailsWatcher,
  fetchInvoicesWatcher,
  sendInvoiceWatcher,
  createJournalWatcher,
};
