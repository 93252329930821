// React
import { PureComponent, ReactElement, Fragment, MouseEvent } from "react";
// Redux
import { connect } from "react-redux";
// Constant
import { Images } from "../../../constants/Images";
// Scss
import styles from "./Button.module.scss";

// Props type
type Props = {
  style: string;
  mystyle: string;
  size: any;
  loading: boolean;
  disabled: boolean;
  fluid: string;
  nowrap: string;
  subtext: string;
  icon: any;
  children: JSX.Element | JSX.Element[];
  name: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};
// State type
type State = {
  buttonLoading: boolean;
};

/**
 * @class Button
 * @extends {PureComponent<Props, State>}
 */
class Button extends PureComponent<Props, State> {
  /**
   * Creates an instance of Button.
   * @param {*} props
   * @memberof Button
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      buttonLoading: false
    }
  }

  /**
   * @return {*}
   * @memberof Button
   */
  render(): ReactElement {
    const props: Props = this.props;
    const { buttonLoading }: State = this.state;
    return (
      <button
        className={`${styles[props.style]} ${styles[props.mystyle]} ${
          styles[props.size]
        } ${props.loading || this.props.disabled ? `${styles.disabled}` : ""} ${
          props.fluid ? `${styles.fluid}` : ""
        } ${props.nowrap ? `${styles.nowrap}` : ""} ${
          props.subtext ? `${styles.extraPadding}` : ""
        }`}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          this.setState({ buttonLoading: true });
          props.onClick(e);
        }}
        disabled={this.props.disabled || props.loading}
      >
        {buttonLoading && props.loading ? (
          <img src={Images.spinner} className={styles.loadingImage} />
        ) : (
          <Fragment>
            <Fragment>
              {props.icon && (
                <img src={Images[`${props.icon}`]} className={styles.image} />
              )}
              {props.name ? (
                props.name
              ) : props.subtext ? (
                <div className={styles.bold2}> {props.children}</div>
              ) : (
                props.children
              )}
            </Fragment>
            <Fragment>
              {props.subtext ? (
                <div className={styles.lightText}>{props.subtext}</div>
              ) : null}
            </Fragment>
          </Fragment>
        )}
      </button>
    );
  }
}

/**
 * @param {*} state
 * @param {*} ownProps
 */
const mapStateToProps = (state: any, ownProps: any) => ({
  loading: state.generalReducer.loading || ownProps.loading,
});

// Export with wrap HOC
export default connect(mapStateToProps, null)(Button);
