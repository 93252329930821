import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { editTpaSr, setLoading, updateFullTpaSrs } from "../actions";
import { roleBasedQueryResolver } from "../constants/roleApiResolver";
import history from "../history";
import { Auth } from "../utilities/auth";
import axios from "../utilities/axiosWrapper";
import { getRoute, getRouteData } from "./navigation/tpaSrNavigation";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;
//create TPA SR
const selectTpaSr = (state: any) => state.tpaSrReducer;

/**
 * @desc Create Tpa sr
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createTpaSrWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  let apikeys: any = null;
  let invite: any = null;
  let jsonResponse: any = null;
  let parsedJsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    invite = yield call(axios, action.data, apikeys);
    if (invite.data.fnResult) {
      invite = invite.data.fnResult.replace(/^"(.*)"$/, "$1");
    }
    params.path = `invites/${invite}?at=${selectedAuthReducer.accessToken}`;

    jsonResponse = yield call(axios, params, apikeys);
    if (jsonResponse.data.fnResult) {
      parsedJsonResponse = JSON.parse(jsonResponse.data.fnResult);
    }

    yield put(editTpaSr(parsedJsonResponse));
    let selectedTpaSr: any = yield select(selectTpaSr);
    let route: any = getRoute(selectedTpaSr.data);
    let routeData: any = getRouteData(selectedTpaSr.data);
    yield put(setLoading({ loading: false, ...routeData }));
    // return yield history.push(route, routeData);
    return yield history.push(route);
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @desc Fetch full tpa srs
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchFullTpaSrsWorker(action: any): Generator {
  yield put(setLoading({ loading: true, pageLoading: true, errorMessage: "" }));
  let selectedAuthReducer: any = yield select(selectAuthReducer);
  let userRole: any = selectedAuthReducer.userClaims.userRole;
  let queryStringRole: any = roleBasedQueryResolver(userRole);
  let jsonResponse: any = null;
  let apikeys: any = null;
  let params: any = {
    method: "get",
    path: "",
    data: {},
  };
  if (
    userRole === "tpaAdministrator" ||
    userRole === "tpaAdministratorPrimary" ||
    userRole === "superAdmin"
  ) {
    params.path = `lists/?type="${queryStringRole}tpasrviewlist"&limit=${
      _.get(action, "data.limit") || 20000
    }&offset=${_.get(action, "data.offset") || 0}&at=${
      selectedAuthReducer.accessToken
    }`;
  } else {
    params.path = `persons?type="includeOvw"&referenceType="tpaServiceRepresentative"&limit="100000"&at=${selectedAuthReducer.accessToken}`;
  }
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apikeys);
    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(setLoading({ loading: false, pageLoading: false }));
      return yield put(
        updateFullTpaSrs(JSON.parse(jsonResponse.data.fnResult))
      );
    }
  } catch (e) {
    yield put(setLoading({ loading: false, pageLoading: false }));
  }
}

/**
 * @desc Update Tpa sr
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateTpaSrWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  let apikeys: any = null;
  let jsonResponse: any = null;
  try {
    apikeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apikeys);

    if (jsonResponse.data.fnMessage) {
      yield put(editTpaSr(action.data.data));
      let parsedJsonResponse: any = action.data.data;
      let route: any = getRoute(parsedJsonResponse);
      let routeData: any = getRouteData(parsedJsonResponse);
      yield put(setLoading({ loading: false, ...routeData }));
      // return yield history.push(route, routeData);
      return yield history.push(route);
    } else {
      yield put(setLoading({ loading: false }));
    }
  } catch (ex) {
    yield put(setLoading({ loading: false }));
    return ex;
  }
}

/**
 * @return {*}  {Generator}
 */
function* createTpaSrWatcher(): Generator {
  yield takeLatest("CREATE_TPA_SR", createTpaSrWorker);
}

/**
 * @return {*}  {Generator}
 */
function* fetchFullTpaSrsWatcher(): Generator {
  yield takeLatest("FETCH_FULL_TPA_SRS", fetchFullTpaSrsWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateTpaSrWatcher(): Generator {
  yield takeLatest("UPDATE_TPA_SR", updateTpaSrWorker);
}

export { createTpaSrWatcher, updateTpaSrWatcher, fetchFullTpaSrsWatcher };
