// React
import { ReactElement, memo } from "react";
// History
import history from "../../../history";
// Constant
import { Images } from "../../../constants/Images";
// Scss
import styles from "./Footer.module.scss";

/**
 * @return {*}  {ReactElement}
 */
const Footer = (): ReactElement => (
  <footer className={styles.footer}>
    <div className={styles.footer__container}>
      <div className={styles.footer__main}>
        <img className={styles.footer__logo} src={Images.ayaLogo} alt="logo" />
        <div className={styles.text__sub}>Powered by Aya</div>
      </div>
    </div>

    <div className={styles.footer__info} id="contact">
      <div className={styles.footer__infoContainer}>
        <div className={styles.footer__left}>
          <p className={styles.text__header}>LINKS</p>
          <p
            onClick={() => history.push("/terms-of-service", { modal: true })}
            className={`${styles.text__sub} ${styles.text__link}`}
          >
            Terms of Service
          </p>
          <p
            onClick={() => history.push("/privacy-policy", { modal: true })}
            className={`${styles.text__sub} ${styles.text__link}`}
          >
            Privacy Policy
          </p>
          <p
            onClick={() => history.push("/esign-agreement", { modal: true })}
            className={`${styles.text__sub} ${styles.text__link}`}
          >
            eSign Agreement
          </p>
          <p
            onClick={() =>
              history.push("/card-member-agreement", { modal: true })
            }
            className={`${styles.text__sub} ${styles.text__link}`}
          >
            Cardholder Agreement
          </p>
        </div>

        <div className={styles.footer__right} id="contact">
          <p className={styles.text__header}>SUPPORT</p>
          <a className={styles.text__sub} href="tel:+1-888-427-6682">
            Tel: +1 (888) 427-6682
          </a>
          <a className={styles.text__sub} href="tel:+1-888-427-6682">
            Fax: +1 (647) 931-9311
          </a>
          <a
            className={styles.text__sub}
            href="mailto:support@ayacare.com?Subject=Hello%20again"
          >
            support@ayacare.com
          </a>
          <p className={`${styles.text__sub} ${styles.text__margin__sm}`}>
            Aya Payments
          </p>
          <p className={`${styles.text__sub} ${styles.text__margin__sm}`}>
            P.O. Box 60014
          </p>
          <p className={`${styles.text__sub} ${styles.text__margin__sm}`}>
            Thorncrest PO,
          </p>
          <p className={`${styles.text__sub} ${styles.text__margin__sm}`}>
            Etobicoke, ON., M9A 3L0
          </p>
          <div className={styles.text__sub}>©Aya Payments, Inc. 2020</div>
        </div>
      </div>

      {/* <div className={styles.footer__terms}>
        <p className={styles.text__sub}>
          The Aya Prepaid Mastercard® is issued by Peoples Trust Company under
          licence from Mastercard International Incorporated. Mastercard is a
          registered trademark, and the circles design is a trademark of
          Mastercard International Incorporated. This card may be used
          everywhere Mastercard is authorized to be accepted at Health &amp;
          Wellness merchants. Use of this card constitutes acceptance of the
          terms and conditions stated in the Cardholder Agreement.
        </p>
      </div> */}
    </div>
  </footer>
);

// Export
export default memo(Footer);
