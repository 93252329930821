// Type
import { Action } from "../actions/types";

// State type
export type State = {
  rowsDetails: {
    limit: number;
    loading: boolean;
    search: string;
    offset: number;
    rowsPerPage: number;
    rows: Array<any>;
    count: number;
  };
  rowsByIdDetails: {
    limit: number;
    loading: boolean;
    offset: number;
    rowsPerPage: number;
    rows: Array<any>;
    count: number;
    wallets: object;
    bankInfo: object;
    plan: object;
  };
  editRow: {
    loading: boolean;
  };
  paginationServer: boolean;
  activeIndex: number;
  errorMessage: string;
  successMessage: string;
  isRowClicked: boolean;
  selectedRow: object;
};

// Initial State
const INITIAL_STATE: State = {
  rowsDetails: {
    limit: 50,
    loading: false,
    search: "",
    offset: 1,
    rowsPerPage: 50,
    rows: [],
    count: 0,
  },
  rowsByIdDetails: {
    limit: 50,
    loading: false,
    offset: 1,
    rowsPerPage: 50,
    rows: [],
    count: 0,
    wallets: {},
    bankInfo: {},
    plan: {},
  },
  editRow: {
    loading: false,
  },
  paginationServer: true,
  errorMessage: "",
  successMessage: "",
  activeIndex: 0,
  isRowClicked: false,
  selectedRow: {},
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "UPDATE_SLS":
      return {
        ...state,
        ...action.data,
      };
    case "UPDATE_SLS_ROWS_DETAILS":
      return {
        ...state,
        rowsDetails: {
          ...state.rowsDetails,
          ...action.data,
        },
      };
    case "UPDATE_SLS_ROWS_BY_ID_DETAILS":
      return {
        ...state,
        rowsByIdDetails: {
          ...state.rowsByIdDetails,
          ...action.data,
        },
      };
    case "EDIT_SLS_ROWS":
      return {
        ...state,
        editRow: {
          ...state.editRow,
          ...action.data,
        },
      };
    case "CLEAR_SLS":
      return INITIAL_STATE;
    default:
      return state;
  }
};
