// React
import { PureComponent } from "react";
// Router
import { withRouter, RouteComponentProps } from "react-router-dom";

// Type props
type Props = {
  location: any;
};

type WithRouteProps = RouteComponentProps<Props>;

/**
 * @class ScrollToTop
 * @extends {PureComponent<Props>}
 */
class ScrollToTop extends PureComponent<WithRouteProps> {
  /**
   * @param {Props} prevProps
   * @memberof ScrollToTop
   */
  componentDidUpdate(prevProps: Props): void {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * @return {*}  {*}
   * @memberof ScrollToTop
   */
  render(): any {
    return this.props.children;
  }
}

// Export
export default withRouter(ScrollToTop);
