import _ from "lodash";
import moment from "moment";
import { takeEvery, call, put, select } from "redux-saga/effects";
import { setLoading, editBankInfo, updatePaymentsList } from "../actions";
import axios from "../utilities/axiosWrapper";
import { Auth } from "../utilities/auth";

// Auth reducer
const selectAuthReducer = (state: any) => state.authReducer;

/**
 * @desc Fetch payments List
 * @return {*}  {Generator}
 */
function* fetchPaymentsList(): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "GET",
    path: `payments?offset=0&limit=1000&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(updatePaymentsList(JSON.parse(jsonResponse.data.fnResult)));
      yield put(setLoading({ loading: false }));
    }
  } catch (err) {
    return err;
  }
}

/**
 * @desc Fetch single payment
 * @param {*} action
 * @return {*}  {Generator}
 */
function* fetchPayment(action: any): Generator {
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "GET",
    path: `payment/business/${action.paymentGuid}?at=${selectedAuthReducer.accessToken}`,
    data: action.data,
  };

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      return;
    }
  } catch (err) {
    return err;
  }
}

/**
 * @desc Update payment status
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updatePaymentStatus(action: any): Generator {
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "PUT",
    path: `payment/${action.paymentGuid}?at=${selectedAuthReducer.accessToken}`,
    data: action.data,
  };

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);
  } catch (err) {
    return err;
  }
}

/**
 * @desc Post new payment for BUSINESS
 * @param {*} action
 * @return {*}  {Generator}
 */
function* createNewPayment(action: any): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;

  try {
    apiKeys = yield Auth.getAuthTokens();
    let params: any = {
      method: "POST",
      path: `payments?at=${selectedAuthReducer.accessToken}`,
      data: {
        accessToken: apiKeys.accessToken,
        businessGuid: action.data.source,
        paymentAmount: Number(parseFloat(action.data.amount).toFixed(2)),
        paymentMethod: action.data.method,
        paymentReference: action.data.reference,
        paymentDate: moment.utc(action.data.date).utcOffset(0).format(),
        description: "",
        paymentStatus: "Completed",
        paymentStatusReason: "",
      },
    };
    jsonResponse = yield call(axios, params, apiKeys);

    if (_.has(jsonResponse, "data.fnResult")) {
      let updateListResponse: any = yield call(
        axios,
        {
          method: "GET",
          path: `payments?offset=0&limit=1000&at=${selectedAuthReducer.accessToken}`,
          data: {},
        },
        apiKeys
      );
      yield put(
        updatePaymentsList(JSON.parse(updateListResponse.data.fnResult))
      );
      yield put(setLoading({ loading: false }));
    }
  } catch (err) {
    return err;
  }
}

/**
 * @desc Fetch Bank Information
 * @return {*}  {Generator}
 */
function* fetchBankInfoWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  const { userRole } = selectedAuthReducer?.userClaims;
  const isAdmin: boolean = userRole === "superAdmin" || userRole === "finance";
  let apiKeys: any = null;
  let jsonResponse: any = null;
  let params: any = {
    method: "GET",
    path: action.isBusiness
      ? `bank?business=${action.data}&at=${selectedAuthReducer.accessToken}`
      : isAdmin
      ? `bank?referenceValue=${action.data}&at=${selectedAuthReducer.accessToken}`
      : `bank?referenceType=person&at=${selectedAuthReducer.accessToken}`,
    data: {},
  };

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, params, apiKeys);

    if (jsonResponse.data?.fnError) {
      return yield put(
        setLoading({
          loading: false,
          alertType: "error",
          errorMessage: "Banking information not provided",
        })
      );
    }

    if (_.has(jsonResponse, "data.fnResult")) {
      yield put(editBankInfo(JSON.parse(jsonResponse.data.fnResult)));
      return yield put(setLoading({ loading: false }));
    }
  } catch (err) {
    return err;
  }
}

/**
 * @desc Update Bank Information
 * @param {*} action
 * @return {*}  {Generator}
 */
function* updateBankInfoWorker(action: any): Generator {
  yield put(setLoading({ loading: true }));
  const selectedAuthReducer: any = yield select(selectAuthReducer);
  let apiKeys: any = null;
  let jsonResponse: any = null;

  try {
    apiKeys = yield Auth.getAuthTokens();
    jsonResponse = yield call(axios, action.data, apiKeys);

    if (
      _.get(jsonResponse.data, "fnStatusCode") &&
      _.get(jsonResponse.data, "fnStatusCode") !== 200
    ) {
      setLoading({
        loading: false,
        alertType: "error",
        errorMessage: _.get(jsonResponse.data, "fnError"),
      });
      throw { message: jsonResponse.data.fnError };
    }
    return yield put(
      setLoading({
        loading: false,
        alertType: "success",
        successMessage: "Banking information successfully updated",
      })
    );
  } catch (err) {
    return yield put(setLoading({ loading: false, errorMessage: err.message }));
  }
}

/**
 * @return {*}  {Generator}
 */
function* fetchPaymentsListWatcher(): Generator {
  yield takeEvery("FETCH_PAYMENTS_LIST", fetchPaymentsList);
}

/**
 * @return {*}  {Generator}
 */
function* fetchPaymentWatcher(): Generator {
  yield takeEvery("FETCH_PAYMENT", fetchPayment);
}

/**
 * @return {*}  {Generator}
 */
function* updatePaymentStatusWatcher(): Generator {
  yield takeEvery("UPDATE_PAYMENT_STATUS", updatePaymentStatus);
}

/**
 * @return {*}  {Generator}
 */
function* createNewPaymentWatcher(): Generator {
  yield takeEvery("POST_NEW_PAYMENT", createNewPayment);
}

/**
 * @return {*}  {Generator}
 */
function* fetchBankWatcher(): Generator {
  yield takeEvery("FETCH_BANK_INFO", fetchBankInfoWorker);
}

/**
 * @return {*}  {Generator}
 */
function* updateBankWatcher(): Generator {
  yield takeEvery("UPDATE_BANK_INFO", updateBankInfoWorker);
}

export {
  fetchPaymentsListWatcher,
  fetchPaymentWatcher,
  updatePaymentStatusWatcher,
  createNewPaymentWatcher,
  fetchBankWatcher,
  updateBankWatcher,
};
