// Npm dependencies
import moment from "moment";
// Types
import { Action } from "../actions/types";
import { COLOR_SCHEMES } from "../styles/colors/colors";
import { CLAIM_USAGE_STATS_INITIAL_STATE } from "./utilities/claims/claims.constant";
import { IClaimUsageStats } from "./utilities/claims/claims.interface";
// Get local storage state
// import { getState } from "../store/localStorageService";

// State Types
type State = {
  claims: Array<any>;
  cachedAllClaims: Array<any>;
  cached2kClaims: Array<any>;
  notes: Array<any>;
  claimCategories: Array<any>;
  data: any;
  count: number;
  recordsPerPage: number;
  sequentialClaimSubmission: string | null;
  userClaimsDetails: any;
  mastercardClaim: any;
  claimUsageStats: IClaimUsageStats;
  allClaimsCount: number;
};
// Initial state
const INITIAL_STATE: State = {
  claims: [],
  cachedAllClaims: [],
  cached2kClaims: [],
  notes: [],
  claimCategories: [],
  data: {},
  count: 0,
  recordsPerPage: 10,
  sequentialClaimSubmission: null,
  userClaimsDetails: {}, // getState('claims')
  mastercardClaim: {},
  claimUsageStats: CLAIM_USAGE_STATS_INITIAL_STATE,
  allClaimsCount: 0,
};

export const claimStatusMapper: { [index: string]: string } = {
  submittedtotpa: "unassigned",
  ireview: "ireview",
  treview: "treview",
  partial: "tpa partially approved",
  approved: "tpa fully approved",
  declined: "tpa declined",
  submitted: "submitted",
  draft: "draft",
  authorized: "authorized",
};

export const statusMapperForActivity = (status: string) => {
  switch (status) {
    case "authorized":
      return "Incomplete";
    case "draft":
      return "Draft";
    case "partial":
      return "Completed";
    case "approved":
      return "Completed";
    case "declined":
      return "Completed";
    default:
      return "Submitted";
  }
};

export const claimCompletedMapper = (status: string) => {
  switch (status) {
    case "partial":
      return "Partially Approved";
    case "approved":
      return "Approved";
    case "declined":
      return "Declined";
    case "draft":
      return "Incomplete";
    default:
      return "Submitted";
  }
};

export const claimCompletedColorMapper = (status: string) => {
  switch (status) {
    case "partial":
      return COLOR_SCHEMES.SUCCESS;
    case "approved":
      return COLOR_SCHEMES.SUCCESS;
    case "declined":
      return COLOR_SCHEMES.ERROR;
    default:
      return COLOR_SCHEMES.BLACK;
  }
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_CLAIM":
      return { ...state, data: { ...state.data, ...action.data } };
    case "EDIT_NOTES":
      return { ...state, notes: action.data };
    case "UPDATE_CLAIM_USAGE_STATS":
      return { ...state, claimUsageStats: action.data };
    case "UPDATE_CLAIMS":
      if (action.data?.localUpdate) {
        return { ...state, claims: action.data.claims };
      }
      if (action.data?.loadFromCache) {
        return {
          ...state,
          claims: action.data.claims,
        };
      }

      if (action.data?.updateCache) {
        if (
          action.data.cached2kClaims?.length > 0 &&
          action.data.cachedAllClaims?.length > 0
        ) {
          return {
            ...state,
            cached2kClaims: action.data.cached2kClaims,
            cachedAllClaims: action.data.cachedAllClaims,
          };
        }
        if (action.data.cached2kClaims?.length > 0) {
          return {
            ...state,
            cached2kClaims: action.data.cached2kClaims,
          };
        }
        if (action.data.cachedAllClaims?.length > 0) {
          return {
            ...state,
            cachedAllClaims: action.data.cachedAllClaims,
          };
        }
      }

      const formattedClaims = action.data.claims.map((data: any) => {
        return {
          paymentDate: data.paymentDate
            ? moment.utc(data.paymentDate).format("YYYY-MM-DD")
            : data.paymentDate,
          merchantName: data.merchantName || "----",
          adjudicationDate: moment
            .utc(data.adjudicationDate)
            .format("YYYY-MM-DD"),
          claimAmount: data.claimAmount,
          activityStatus: statusMapperForActivity(
            data.claimStatus.toLowerCase()
          ),
          activityDateOfSubmission: moment
            .utc(data.dateOfSubmission)
            .format("YYYY-MM-DD"),
          id: data.claimHealthGUID,
          claimFlag: data.claimStatus,
          claimId: data.claimNumber,
          dateOfSubmission: moment
            .utc(data.dateOfSubmission)
            .format("MMM Do YY"),
          claimType:
            data.claimType.toLowerCase() === "novahsa"
              ? "Aya Card"
              : data.claimType,
          claimPaymentType: data.claimPaymentType,
          certificateOwner: `${data.firstName} ${data.lastName}`,
          company: data.businessOperatedName,
          status: claimStatusMapper[data.claimStatus.toLowerCase()],
          assignedTo:
            data.assignedTo && data.assignedTo.trim().length < 1
              ? "unassigned"
              : `${data.assignedToFirstName} ${data.assignedToLastName}`,
          approvedAmount: data.approvedAmount,
        };
      });

      if (action.data?.isAllCache) {
        return {
          ...state,
          cachedAllClaims: formattedClaims,
          allClaimsCount: action.data.allClaimsCount,
        };
      }
      if (action.data?.is2kCache) {
        return {
          ...state,
          cached2kClaims: formattedClaims,
          allClaimsCount: action.data.allClaimsCount,
        };
      }

      return { ...state, claims: formattedClaims, count: action.data.count };
    case "UPDATE_CLAIM_CATEGORIES":
      return { ...state, claimCategories: { ...action.data } };
    case "CLEAR_CLAIMS_LIST":
      return {
        ...state,
        claims: INITIAL_STATE.claims,
        cachedAllClaims: INITIAL_STATE.claims,
        cached2kClaims: INITIAL_STATE.claims,
      };
    case "CLEAR_CLAIM":
      return { ...state, data: INITIAL_STATE.data };
    case "CLEAR_NOTES":
      return { ...state, notes: INITIAL_STATE.notes };
    case "UPLOAD_CLAIM_IMAGE":
      return { ...state };
    case "SEQUENTIAL_CLAIMS":
      return {
        ...state,
        sequentialClaimSubmission:
          action.data !== "doneClaims"
            ? action.data
            : INITIAL_STATE.sequentialClaimSubmission,
      };
    case "USER_CLAIMS_DETAILS":
      return {
        ...state,
        userClaimsDetails: { ...state.userClaimsDetails, ...action.data },
      };
    case "UPDATE_CLAIM_BALANCE":
      return {
        ...state,
        userClaimsDetails: { ...state.userClaimsDetails, ...action.data },
      };
    case "UPDATE_MASTERCARD_CLAIM":
      return {
        ...state,
        mastercardClaim: { ...action.data },
      };
    case "CLEAR_USER_CLAIMS_DETAILS":
      return { ...state, userClaimsDetails: {} };
    case "CLEAR_MASTERCARD_CLAIM":
      return { ...state, mastercardClaim: {} };
    default:
      return state;
  }
};
