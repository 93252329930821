// Types
import { stat } from "fs";
import { Action } from "../actions/types";

type reportsData = {
  fileData: string;
  reportGenerated: boolean;
  reportType?: string;
};

type IClasses = {
  classList: Array<any>;
  responseStatus: {
    classAddedSuccessfully: boolean;
    classAddedFailed: boolean;
    classUpdatedSuccessfully: boolean;
    classUpdatedFailed: boolean;
    classFetchedSuccessfully: boolean;
    classFetchedFailed: boolean;
    response: any;
  };
};

// State type
type State = {
  companies: Array<any>;
  companiesFull: Array<any>;
  industries: Array<any>;
  pcoTPA: Array<any>;
  productCodes: Array<any>;
  generalInformation: any;
  data: any;
  newPlanAdmin: any;
  planSponsorAuthorizedSigner: any;
  planSponsorAdminPrimary: any;
  planSponsorAdmin: any;
  planSponsorContact: any;
  planSponsorDirector: any;
  planSponsorAccountant: any;
  planSponsorBeneficiary: any;
  ayaSalesReps: any;
  companyStats: any;
  companyAllInfo: any;
  invoiceFile: any;
  invoiceList: any;
  reports: reportsData;
  redirectedCompanyReport: string;
  balances: {};
  selfEnrolment: any;
  pcoEnrolment: any;
  classes: IClasses;
};

// Initial state
const INITIAL_STATE: State = {
  companies: [],
  companiesFull: [],
  industries: [],
  pcoTPA: [],
  productCodes: [],
  generalInformation: {},
  data: {},
  newPlanAdmin: {},
  planSponsorAuthorizedSigner: {},
  planSponsorAdminPrimary: {},
  planSponsorAdmin: {},
  planSponsorContact: {},
  planSponsorDirector: {},
  planSponsorAccountant: {},
  planSponsorBeneficiary: {},
  ayaSalesReps: {},
  companyStats: {},
  companyAllInfo: {},
  invoiceFile: "",
  invoiceList: [],
  reports: {
    fileData: "",
    reportGenerated: false,
    reportType: "",
  },
  redirectedCompanyReport: "",
  balances: {
    cash: {
      availableBalance: 0,
      holdBalance: 0,
      returnBalance: 0,
    },
  },
  selfEnrolment: {
    sendingVerificationCode: false,
    verificationCodeSentSuccessfully: false,
    verificationCodeSentFailed: false,
    email: "",
    verificationCode: "",
    gettingSelfEnrolmentUserData: false,
    getSelfEnrolmentUserDataSuccess: false,
    getSelfEnrolmentUserDataFailed: false,
    userData: {},
    selectedCompanyGuid: "",
    gettingSelfEnrolmentCompanyData: false,
    getSelfEnrolmentCompanyDataSuccess: false,
    getSelfEnrolmentCompanyDataFailed: false,
    companyData: {},
    routeData: {},
    businessLegalName: "",
    enrolmentStatus: "",
    submitSelfEnrolmentCompanyDataSuccess: false,
    selfEnroledCompanies: [],
    companySelfEnrolmentDeclined: false,
    companySelfEnrolmentDeclineFailed: false,
    companySelfEnrolmentApproved: false,
    companySelfEnrolmentApprovalFailed: false,
    updatedOn: "",
  },
  pcoEnrolment: {
    pcoDataFull: [],
    newFileUploaded: false,
    pcoListUpdated: false,
  },
  classes: {
    classList: [],
    responseStatus: {
      classAddedSuccessfully: false,
      classAddedFailed: false,
      classUpdatedSuccessfully: false,
      classUpdatedFailed: false,
      classFetchedSuccessfully: false,
      classFetchedFailed: false,
      response: "",
    },
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_COMPANY":
      return { ...state, data: { ...state.data, ...action.data } };
    case "EDIT_NEW_PLAN_ADMIN":
      return {
        ...state,
        newPlanAdmin: { ...state.newPlanAdmin, ...action.data },
      };
    case "UPDATE_PRODUCT_CODES":
      return { ...state, productCodes: { ...action.data } };
    case "UPDATE_COMPANY_GENERAL_INFORMATION":
      return { ...state, generalInformation: { ...action.data } };
    case "UPDATE_PLAN_SPONSOR_ADMIN_PRIMARY":
      return { ...state, planSponsorAdminPrimary: { ...action.data } };
    case "UPDATE_PLAN_SPONSOR_ADMIN":
      return { ...state, planSponsorAdmin: { ...action.data } };
    case "UPDATE_PLAN_SPONSOR_CONTACT":
      return { ...state, planSponsorContact: { ...action.data } };
    case "UPDATE_ACCOUNTANTS":
      return { ...state, planSponsorAccountant: { ...action.data } };
    case "UPDATE_AYA_SALES_REPS":
      return { ...state, ayaSalesReps: { ...action.data } };
    case "UPDATE_BENEFICIARY_OWNERS":
      return { ...state, planSponsorBeneficiary: { ...action.data } };
    case "UPDATE_AUTHORIZED_SIGNERS":
      return { ...state, planSponsorAuthorizedSigner: { ...action.data } };
    case "UPDATE_DIRECTORS":
      return { ...state, planSponsorDirector: { ...action.data } };
    case "UPDATE_COMPANIES":
      return { ...state, companies: { ...action.data } };
    case "UPDATE_FULL_COMPANIES":
      return { ...state, companiesFull: { ...action.data } };
    case "UPDATE_INDUSTRIES":
      return { ...state, industries: { ...action.data } };
    case "UPDATE_PCO_TPA":
      return { ...state, pcoTPA: { ...action.data } };
    case "UPDATE_COMPANY_STATS":
      return {
        ...state,
        companyStats: { ...state.companyStats, ...action.data },
      };
    case "CLEAR_COMPANY":
      return {
        ...state,
        data: INITIAL_STATE.data,
        companies: INITIAL_STATE.companies,
        newPlanAdmin: INITIAL_STATE.newPlanAdmin,
        companyAllInfo: {},
        invoiceList: [],
        invoiceFile: "",
      };
    case "UPDATE_COMPANY_ALL_INFO":
      return {
        ...state,
        companyAllInfo: { ...state.companyAllInfo, ...action.data },
      };
    case "UPDATE_COMPANY_INVOICE_FILE":
      return {
        ...state,
        invoiceFile: { ...action.data },
      };
    case "CLEAR_COMPANY_INVOICE_FILE":
      return {
        ...state,
        invoiceFile: "",
      };
    case "UPDATE_COMPANY_INVOICE_LIST":
      return {
        ...state,
        invoiceList: [...action.data],
      };
    case "UPDATE_REPORT":
      return {
        ...state,
        reports: {
          fileData: action.data.fileData,
          reportType: action.data.reportType,
          reportGenerated: true,
        },
      };
    case "CLEAR_REPORT":
      return {
        ...state,
        reports: {
          fileData: "",
          reportType: "",
          reportGenerated: false,
        },
      };
    case "UPDATE_REDIRECTED_COMPANY_REPORT":
      return {
        ...state,
        redirectedCompanyReport: action.data,
      };
    case "CLEAR_REDIRECTED_COMPANY_REPORT":
      return {
        ...state,
        redirectedCompanyReport: "",
      };
    case "UPDATE_BALANCES":
      return {
        ...state,
        balances: {
          [action.data.type.toLowerCase()]: {
            availableBalance: action.data.availableBalance,
            holdBalance: action.data.holdBalance,
            returnBalance: action.data.returnBalance,
          },
        },
      };
    case "CLEAR_BALANCES":
      return {
        ...state,
        balances: INITIAL_STATE.balances,
      };
    case "SET_SELF_ENROLMENT_DETAILS":
      return {
        ...state,
        selfEnrolment: {
          ...state.selfEnrolment,
          ...action.data,
        },
      };
    case "SET_PCO_ENROLMENT_DETAILS":
      return {
        ...state,
        pcoEnrolment: {
          ...state.pcoEnrolment,
          ...action.data,
        },
      };
    case "UPDATE_PCO_ENROLMENT_DETAILS":
      action.data.forEach((actionData: any) => {
        if (actionData.email) {
          state.pcoEnrolment.pcoDataFull.forEach((element: any) => {
            if (element.email === actionData.email) {
              element.status = actionData.status;
              element.errors = actionData.errors ? actionData.errors : {};
            }
          });
        }
      });
      return {
        ...state,
        pcoEnrolment: {
          ...state.pcoEnrolment,
          pcoDataFull: [...state.pcoEnrolment.pcoDataFull],
          pcoListUpdated: true,
        },
      };
    case "CLEAR_PCO_ENROLMENT_DETAILS":
      return {
        ...state,
        pcoEnrolment: INITIAL_STATE.pcoEnrolment,
      };
    case "UPDATE_CLASS_IN_STORE":
      return {
        ...state,
        classes: {
          ...state.classes,
          ...action.data,
        },
      };
    case "CLEAR_CLASS":
      return {
        ...state,
        classes: INITIAL_STATE.classes,
      };
    case "CLEAR_CLASS_RESPONSE":
      return {
        ...state,
        classes: {
          ...state.classes,
          responseStatus: INITIAL_STATE.classes.responseStatus,
        },
      };
    default:
      return state;
  }
};
