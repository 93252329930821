import { all } from "redux-saga/effects";
import {
  changePasswordWatcher,
  confirmForgotPasswordWatcher,
  forgotPasswordWatcher,
  renewVerificationCodeWatcher,
  respondToAuthChallengeWatcher,
  signInWatcher,
  signoutWatcher,
  changeRoleWatcher,
  checkEmailWatcher,
  resendVerificationWatcher,
  confirmVerificationWatcher,
  setNewPasswordWatcher,
  setNewEmailWatcher,
  fetchTerminatedUserWatcher,
  confirmForgotPasswordVerificationWatcher,
  triggerSelfEnrolmentVerificationCodeWatcher,
} from "./auth";
import {
  createBrokerWatcher,
  fetchBhaBrokersWatcher,
  fetchFullBrokersWatcher,
  updateBrokerWatcher,
} from "./broker";
import {
  createBrokerageHouseWatcher,
  fetchBanksWatcher,
  fetchBrokerageHouseInfoWatcher,
  fetchBrokerageHousesWatcher,
  updateBrokerageHouseWatcher,
} from "./brokerageHouse";
import {
  fetchClaimCategoriesWatcher,
  fetchClaimsWatcher,
  fetchNotesWatcher,
  getClaimWatcher,
  updateClaimItemWatcher,
  updateClaimWatcher,
  updateNotesWatcher,
  uploadClaimImageWatcher,
  createClaimWatcher,
  submitClaimWatcher,
  batchClaimImageUploadWatcher,
  createAndSubmitClaimWatcher,
  fetchMastercardClaimWatcher,
  fetchClaimImagesWatcher,
  submitMastercardClaimWatcher,
  fetchClaimUsageStatsWatcher,
} from "./claims";
import {
  createCompanyWatcher,
  createNewPlanAdminWatcher,
  fetchAccountantsWatcher,
  fetchAuthorizedSignersWatcher,
  fetchAyaSalesRepsWatcher,
  fetchBeneficiaryOwnersWatcher,
  fetchCompaniesWatcher,
  fetchDirectorsWatcher,
  fetchFullCompaniesWatcher,
  fetchIndustriesWatcher,
  fetchPcoTpaWatcher,
  fetchPlanSponsorAdministratorPrimaryWatcher,
  fetchPlanSponsorAdministratorWatcher,
  fetchPlanSponsorContactWatcher,
  fetchProductCodesWatcher,
  getCompanyDetailsWatcher,
  getCompanyWatcher,
  getNewPlanAdminWatcher,
  updateCompanyWatcher,
  updateNewPlanAdminWatcher,
  fetchCompanyAllInfoWatcher,
  getCompanyInvoiceFileWatcher,
  getInvoiceListWatcher,
  getReportwatcher,
  getBalanceswatcher,
  getSelfEnrolmentUserDataWatcher,
  getSelfEnrolmentCompanyDataWatcher,
  saveSelfEnrolmentCompanyDataWatcher,
  createSelfEnrolmentCompanyWatcher,
  submitSelfEnrolmentCompanyDataWatcher,
  getAllSelfEnroledCompaniesWatcher,
  getSelfEnroledCompanyDetailsWatcher,
  setApproveSelfEnroledCompanyWatcher,
  setDeclineSelfEnroledCompanyWatcher,
  saveReviewedSelfEnrolmentCompanyDataWatcher,
  triggerPcoEnrolmentWatcher,
  addClassWatcher,
  updateClassWatcher,
  fetchClassesWatcher,
} from "./company";
import {
  createDependentWatcher,
  updateDependentsWatcher,
  fetchDependentWatcher,
} from "./dependent";
import {
  patchEmployeeWatcher,
  terminateEmployeeWatcher,
  createEmployeeWatcher,
  fetchEmployeesWatcher,
  fetchFullEmployeesWatcher,
  updateEmployeeWatcher,
  fetchPlanHSAWSAWatcher,
  fetchPlanDetailsWatcher,
  resendEmployeeEmailWatcher,
  fetchEmployeeDetailsWatcher,
  updateEmployeeDetailsWatcher,
  updateEmployeeCardDetailsWatcher,
  fetchEmployeeInviteDataWatcher,
  fetchEmployeeCardsWatcher,
  activateEmployeeCardWatcher,
  fetchEmployeeCraHsaInfoWatcher,
  updateEmployeeCardPinWatcher,
  triggerPinVerificationWatcher,
  fetchAccountDeletionInfoWatcher,
  triggerAccountDeletionWatcher,
  triggerAccDelVerificationCodeWatcher,
  accountDeletionCancellationWatcher,
  updateFlexAllocationWatcher,
  updateContributionWatcher,
  updateEmployeeAllocationWatcher,
} from "./employee";
import { fetchAgreementsWatcher, setModalWatcher } from "./general";
import { fetchDashboardStatsWatcher, fetchCompanyStatsWatcher } from "./stats";
import { createTPAWatcher, fetchTPASWatcher, updateTPAWatcher } from "./tpa";
import {
  createTpaSrWatcher,
  fetchFullTpaSrsWatcher,
  updateTpaSrWatcher,
} from "./tpaSr";
import {
  fetchPaymentsListWatcher,
  fetchPaymentWatcher,
  updatePaymentStatusWatcher,
  createNewPaymentWatcher,
  fetchBankWatcher,
  updateBankWatcher,
} from "./payments";
import {
  fetchInvoiceDetailsWatcher,
  fetchInvoicesWatcher,
  sendInvoiceWatcher,
  createJournalWatcher,
} from "./invoice";
import {
  fetchLedgerStatsWatcher,
  fetchLedgerWatcher,
  createMemoTransactionWatcher,
} from "./ledger";
import { updateMasterCardStatusWatcher } from "./mastercard";
import { fetchCartaReportsWatcher, fetchCarta04Watcher } from "./processor";
import { fetchMerchantListWatcher } from "./merchant";
import { fetchSuperAdminsWatcher, fetchUsersListWatcher } from "./superAdmin";
import { registerSensiBillWatcher, createSensiBillWatcher } from "./sensiBill";
import { getSlsWatcher, getSlsByIdWatcher, editSlsByIdWatcher } from "./sls";

export default function* root() {
  yield all([
    fetchTerminatedUserWatcher(),
    setNewEmailWatcher(),
    signInWatcher(),
    fetchAgreementsWatcher(),
    renewVerificationCodeWatcher(),
    signoutWatcher(),
    changePasswordWatcher(),
    changeRoleWatcher(),
    respondToAuthChallengeWatcher(),
    forgotPasswordWatcher(),
    confirmForgotPasswordWatcher(),
    createBrokerWatcher(),
    updateBrokerWatcher(),
    fetchFullBrokersWatcher(),
    fetchBhaBrokersWatcher(),
    fetchFullTpaSrsWatcher(),
    createTpaSrWatcher(),
    updateTpaSrWatcher(),
    fetchBrokerageHousesWatcher(),
    fetchBanksWatcher(),
    createBrokerageHouseWatcher(),
    updateBrokerageHouseWatcher(),
    fetchTPASWatcher(),
    createTPAWatcher(),
    updateTPAWatcher(),
    fetchPlanSponsorContactWatcher(),
    fetchPlanSponsorAdministratorPrimaryWatcher(),
    fetchPlanSponsorAdministratorWatcher(),
    getCompanyWatcher(),
    getCompanyDetailsWatcher(),
    fetchCompaniesWatcher(),
    fetchProductCodesWatcher(),
    fetchFullCompaniesWatcher(),
    createNewPlanAdminWatcher(),
    updateNewPlanAdminWatcher(),
    getNewPlanAdminWatcher(),
    fetchPlanHSAWSAWatcher(),
    fetchPlanDetailsWatcher(),
    resendEmployeeEmailWatcher(),
    createCompanyWatcher(),
    patchEmployeeWatcher(),
    terminateEmployeeWatcher(),
    updateCompanyWatcher(),
    fetchEmployeesWatcher(),
    createEmployeeWatcher(),
    updateEmployeeWatcher(),
    createDependentWatcher(),
    updateDependentsWatcher(),
    fetchDependentWatcher(),
    fetchClaimCategoriesWatcher(),
    fetchIndustriesWatcher(),
    fetchPcoTpaWatcher(),
    fetchNotesWatcher(),
    updateNotesWatcher(),
    fetchClaimsWatcher(),
    getClaimWatcher(),
    updateClaimWatcher(),
    updateClaimItemWatcher(),
    createClaimWatcher(),
    submitClaimWatcher(),
    batchClaimImageUploadWatcher(),
    fetchCompanyStatsWatcher(),
    fetchDashboardStatsWatcher(),
    fetchAuthorizedSignersWatcher(),
    fetchDirectorsWatcher(),
    fetchBeneficiaryOwnersWatcher(),
    fetchAccountantsWatcher(),
    fetchAyaSalesRepsWatcher(),
    fetchFullEmployeesWatcher(),
    fetchPaymentsListWatcher(),
    fetchPaymentWatcher(),
    updatePaymentStatusWatcher(),
    createNewPaymentWatcher(),
    fetchBankWatcher(),
    updateBankWatcher(),
    fetchInvoiceDetailsWatcher(),
    fetchInvoicesWatcher(),
    sendInvoiceWatcher(),
    createJournalWatcher(),
    fetchLedgerStatsWatcher(),
    updateMasterCardStatusWatcher(),
    uploadClaimImageWatcher(),
    fetchLedgerWatcher(),
    createMemoTransactionWatcher(),
    fetchCartaReportsWatcher(),
    fetchMerchantListWatcher(),
    fetchCarta04Watcher(),
    fetchSuperAdminsWatcher(),
    fetchBrokerageHouseInfoWatcher(),
    setModalWatcher(),
    fetchUsersListWatcher(),
    setModalWatcher(),
    fetchEmployeeDetailsWatcher(),
    updateEmployeeDetailsWatcher(),
    updateEmployeeCardDetailsWatcher(),
    updateEmployeeCardPinWatcher(),
    triggerPinVerificationWatcher(),
    fetchEmployeeInviteDataWatcher(),
    checkEmailWatcher(),
    resendVerificationWatcher(),
    confirmVerificationWatcher(),
    setNewPasswordWatcher(),
    confirmForgotPasswordVerificationWatcher(),
    registerSensiBillWatcher(),
    createSensiBillWatcher(),
    createAndSubmitClaimWatcher(),
    getSlsWatcher(),
    getSlsByIdWatcher(),
    editSlsByIdWatcher(),
    fetchClaimUsageStatsWatcher(),
    fetchMastercardClaimWatcher(),
    fetchClaimImagesWatcher(),
    submitMastercardClaimWatcher(),
    fetchCompanyAllInfoWatcher(),
    fetchEmployeeCardsWatcher(),
    activateEmployeeCardWatcher(),
    fetchEmployeeCraHsaInfoWatcher(),
    getCompanyInvoiceFileWatcher(),
    getInvoiceListWatcher(),
    getReportwatcher(),
    getBalanceswatcher(),
    fetchAccountDeletionInfoWatcher(),
    triggerAccountDeletionWatcher(),
    triggerAccDelVerificationCodeWatcher(),
    accountDeletionCancellationWatcher(),
    triggerSelfEnrolmentVerificationCodeWatcher(),
    getSelfEnrolmentUserDataWatcher(),
    getSelfEnrolmentCompanyDataWatcher(),
    saveSelfEnrolmentCompanyDataWatcher(),
    createSelfEnrolmentCompanyWatcher(),
    submitSelfEnrolmentCompanyDataWatcher(),
    getAllSelfEnroledCompaniesWatcher(),
    getSelfEnroledCompanyDetailsWatcher(),
    setApproveSelfEnroledCompanyWatcher(),
    setDeclineSelfEnroledCompanyWatcher(),
    saveReviewedSelfEnrolmentCompanyDataWatcher(),
    triggerPcoEnrolmentWatcher(),
    updateFlexAllocationWatcher(),
    updateContributionWatcher(),
    updateEmployeeAllocationWatcher(),
    addClassWatcher(),
    updateClassWatcher(),
    fetchClassesWatcher(),
  ]);
}
