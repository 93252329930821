import { IEmployeeBalance, IEmployeePlanInfo, IEmployeeStats } from "./employee.interface";

export const EMPLOYEE_STATS:IEmployeeStats={
    activeEmployeesCount: 0,
    employeesCount: 0,
    fullyTerminatedEmployeesCount: 0,
    invitedEmployeesCount: 0,
    limit: 0,
    offset: 0,
    terminatedActiveEmployeesCount: 0,
    totalEmployeesCount: 0,
  };

export const EMPLOYEE_PLAN:IEmployeePlanInfo={
    classGuid: '',
	className: '',
	classType: '',
	programType: '',
	waitingPeriod: 0,
	annualContribution: 0,
	annualContributionPlus1: 0,
	annualContributionPlus2: 0,
	persongGuid: '',
	firstDateSubmitClaim: '',
	lastDateSubmitClaim: '',
	flexAllocation: false
}

export const EMPLOYEE_BALANCES: IEmployeeBalance[]=[{
    available: false,
    balanceGuid: '',
    startDate: '',
    endDate: '',
    amount: 0,
    bucketName: '',
    productName: '',
    contributionAmount: '',
    contributionDate: '',
}]