// React
import { memo, FC, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../../selectors";
import DashboardFooter from "../../dashboardFooter";
// Components
import MenuOptionsBar from "../../MenuOptionsBar";
import NavBar from "../../navBar";
import PusherMenu from "../../pusherMenu";
// Scss
import "./CommonLayout.scss";

// types
type Props = {
  children: JSX.Element | JSX.Element[];
  layoutProps: object;
};

/**
 * @author Aya
 * @desc handle the layout things like navbar, sidebar, footer, and main container
 * @param {React} { children }
 * @return element
 */
const CommonLayout: FC<Props> = (props: Props): ReactElement => {
  const [userRole] = useSelector((store) => [getUserRole(store)]);
  const v2Allowed = ["PCO", "planSponsorAdministratorPrimary", "planSponsorAdministrator","planSponsorContact"];
  const updatedStyleGuide = v2Allowed.includes(userRole);

  return (
    <div className="container">
      <div className="container__navbar">
        <NavBar {...props.layoutProps} />
        {updatedStyleGuide && <MenuOptionsBar />}
      </div>
      <div className="container__inner">
        {!updatedStyleGuide ? (
          <PusherMenu>{props.children}</PusherMenu>
        ) : (
          props.children
        )}
      </div>
      {updatedStyleGuide && userRole === "PCO" && (
        <div className="container__footer">
          <DashboardFooter />
        </div>
      )}
    </div>
  );
};

// Default export
export default memo(CommonLayout);
