import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Icon, Popup } from 'semantic-ui-react';
import HamburgerMenu from 'react-hamburger-menu';
import styled from 'styled-components';
import { isMenuOpen as openMenuBar, setLoading } from '../../../actions';
import { Images } from '../../../constants';
import history from '../../../history';
import { deleteState } from '../../../store/localStorageService';
import { getErrorMessage, getSuccessMessage } from '../../../selectors';
import { positions, useAlert } from 'react-alert';

const NavBar: React.FunctionComponent = () => {
  const [menuOpen, toggleMenuOpen] = useState<boolean>(false);
  const [
    whiteLabel,
    userInfo,
    authInfo,
    isMenuOpen,
    errorMessage,
    successMessage,
  ] = useSelector((state: any) => [
    state.authReducer.preAuthDetails?.whiteLabel,
    state.authReducer.userClaims,
    state.authReducer,
    state.generalReducer.isMenuOpen,
    getErrorMessage(state),
    getSuccessMessage(state),
  ]);
  const dispatch = useDispatch();
  const userFullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const isLoggedIn = authInfo.accessToken && authInfo.idToken;
  const alert = useAlert();

  useEffect(() => {
    if (successMessage) {
      alert.show(
        { rawMessage: successMessage },
        {
          position: positions.TOP_CENTER,
          type: 'success',
          timeout: 3000,
          onClose: () => {
            dispatch(
              setLoading({
                error: false,
                errorMessage: null,
                success: false,
                successMessage: null,
              })
            );
          },
        }
      );
    } else {
      alert.removeAll();
    }
  }, [successMessage]);

  const handleLogout = () => {
    setTimeout(() => {
      history.push('/logout', { modal: true });
    }, 500);
    deleteState();
    history.push('/');
  };

  return (
    <StyledNavBar data-testid={'navBar'}>
      <LeftContent>
        {isLoggedIn && (
          <HambugerMenuContainer>
            <HamburgerMenu
              isOpen={isMenuOpen}
              menuClicked={() => dispatch(openMenuBar())}
              width={27}
              height={20}
              color={'#5f6061'}
              strokeWidth={3}
            />
          </HambugerMenuContainer>
        )}
        <div onClick={() => history.push('/dashboard')}>
          <AyaLogoImg
            src={Images.ayaLogo}
            alt={'Company Logo'}
            data-testid={'ayaCareLogo'}
          />
        </div>
        {whiteLabel?.logo && (
          <CompanyLogoImg
            src={whiteLabel.logo}
            alt={'Company Logo'}
            data-testid={'companyLogo'}
          />
        )}
        <CompanyName>{userInfo.businessOperatedName}</CompanyName>
      </LeftContent>
      {isLoggedIn && (
        <RightContent>
          <Dropdown
            icon={
              <RightMenu>
                <UserName>{userFullName}</UserName>
                {menuOpen ? (
                  <Icon name={'caret down'} size={'large'} color={'grey'} />
                ) : (
                  <Icon name={'caret right'} size={'large'} color={'grey'} />
                )}
              </RightMenu>
            }
            onOpen={() => toggleMenuOpen(true)}
            onClose={() => toggleMenuOpen(false)}
          >
            <Dropdown.Menu style={{ marginTop: '1rem', width: '100%' }}>
              <Dropdown.Item
                content={
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </RightContent>
      )}
    </StyledNavBar>
  );
};

export default NavBar;

const StyledNavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e6e6;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HambugerMenuContainer = styled.div`
  display: none;
  margin-right: 0.5rem;

  @media only screen and (max-width: 600px) {
    & {
      display: block;
    }
  }
`;

const RightContent = styled.div``;

const AyaLogoImg = styled.img`
  max-width: 40px;
  margin-right: 0.5rem;
`;

const CompanyLogoImg = styled.img`
  max-width: 90px;
  margin-right: 0.5rem;
`;

const CompanyName = styled.h1`
  margin: 0;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.3rem;
  display: block;
  text-transform: capitalize;

  @media only screen and (max-width: 600px) {
    & {
      display: none;
    }
  }
`;

const RightMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const UserName = styled.h1`
  margin: 0;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 0.75rem;
  text-transform: capitalize;
`;

const DropdownItem = styled.h3`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1rem;
  color: black;
`;
