/**
 * @desc get route data
 * @param {*} data
 * @return {*}  {*}
 */
export const getRouteData = (data: any): any => {
  let step: string = data.inviteState ? data.inviteState.step : data.inviteStep;
  let firstName: any = data.person && data.person.firstName;
  let lastName: any = data.person && data.person.lastName;
  let userName: string = `${firstName} ${lastName}`;
  switch (step) {
    case "QUICK-STEP_ONE-EMPLOYEE":
      return {
        view: "STEP_ONE",
        largeHeader: "Add Employee",
        largeSubHeader: "",
      };
    case "QUICK-STEP_TWO-EMPLOYEE":
      return {
        view: "STEP_TWO",
        largeHeader: "",
        largeSubHeader: "",
      };
    case "FULL-STEP_ONE-EMPLOYEE":
      return {
        view: "STEP_ONE",
        largeHeader: "Add Employee",
        largeSubHeader: "",
      };
    case "FULL-STEP_TWO-EMPLOYEE":
      return {
        view: "STEP_TWO",
        largeHeader: "Add Employee",
        largeSubHeader: `${userName}`,
      };
    case "FULL-STEP_THREE-EMPLOYEE":
      return {
        view: "STEP_THREE",
        largeHeader: "Add Employee",
        largeSubHeader: `${userName}`,
      };
    case "FULL-STEP_FOUR-EMPLOYEE":
      return {
        view: "STEP_FOUR",
        largeHeader: "",
        largeSubHeader: "",
      };
    case "ONBOARDING-STEP_ONE-EMPLOYEE":
      return {
        view: "STEP_ONE",
        largeHeader: `Enrolment of ${userName}`,
        largeSubHeader: "Primary Certificate Owner",
      };
    case "ONBOARDING-STEP_TWO-EMPLOYEE":
      return {
        view: "STEP_TWO",
        largeHeader: `Enrolment of ${userName}`,
        largeSubHeader: "Primary Certificate Owner",
      };
    case "ONBOARDING-STEP_THREE-EMPLOYEE":
      return {
        view: "STEP_THREE",
        largeHeader: `Enrolment of ${userName}`,
        largeSubHeader: "Primary Certificate Owner",
      };
    case "ONBOARDING-STEP_FOUR-EMPLOYEE":
      return {
        view: "STEP_FOUR",
        largeHeader: `Enrolment of ${userName}`,
        largeSubHeader: "Primary Certificate Owner",
      };
    case "COMPLETE-STEP_FOUR-EMPLOYEE":
      return {
        view: "STEP_FOUR",
        largeHeader: "",
        largeSubHeader: "",
      };
    default:
      return {
        view: "STEP_ONE",
        largeHeader: "Add Employee",
        largeSubHeader: "",
      };
  }
};

/**
 * @desc get route
 * @param {*} data
 * @return {*}  {*}
 */
export const getRoute = (data: any): any => {
  let route: string = data.inviteState
    ? data.inviteState.step.split("-")[0]
    : "";

  switch (route) {
    case "QUICK":
      return "/employees/add";
    case "FULL":
      return "/employees/add/full";
    case "ONBOARDING":
      return "/employee/onboarding";
    case "COMPLETE":
      return "/dashboard";
    default:
      break;
  }
};
