// Types
import type { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchDashboardStats = (): Action => ({
  type: "FETCH_DASHBOARD_STATS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateDashboardStats = (data: any): Action => ({
  type: "UPDATE_DASHBOARD_STATS",
  data,
});

/**
 * @return {*}  {Action}
 */
export const fetchCompanyStats = (): Action => ({
  type: "FETCH_COMPANY_STATS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCompanyStats = (data: any): Action => ({
  type: "UPDATE_COMPANY_STATS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateReportsCache = (data: any): Action => ({
  type: "UPDATE_REPORT_CACHE",
  data,
});
