// Reselect
import { createSelector } from "reselect";
// Moment
import moment from "moment";
// Lodash
import { get, size, forIn, filter } from "lodash";
// Utilities
import {
  cleanUpMeta,
  flattenObject,
  uuidv4Generator,
} from "../utilities/customFunctions";

// States
const employee = (state: any) => state.employeeReducer;

// Employee list
export const getEmployeeList = createSelector(employee, (employee) => {
  const employeesFull: any = employee.employeesFull;
  let newEmployees: Array<any> = [];
  if (get(employeesFull, "listData") && size(employeesFull.listData) > 0) {
    let employeeValues: any = get(employeesFull, "listData");
    Array.isArray(employeeValues) &&
      employeeValues.forEach((data) => {
        data &&
          newEmployees.push({
            employeeName: data.employeeName,
            employeeEmail: data.employeeEmail,
            numDependents: data.numDependents || 0,
            approvedClaims: data.approvedClaims || 0,
            declinedClaims: data.declinedClaims || 0,
            totalClaims: data.numClaims || 0,
            employeeStatus: data.personStatus,
            mastercardStatus:
              data.programPaymentType === "pos" || data.mcAccountStatus === ""
                ? ""
                : data.mcAccountStatus,
            numberOfDaysSincePersonStatus: data.numberOfDaysSincePersonStatus,
            numberOfDaysSinceMasterCardStatus:
              data.numberOfDaysSinceMasterCardStatus,
            lastActivity: moment.utc(data.lastActivity).format("ll"),
            availableBalance: data.availableBalance || 0,
            carryover: data.carryover,
            id: uuidv4Generator(),
            companyName: data.companyName,
            thirdPartyAdministratorName: data.thirdPartyAdministratorName,
            product: `${
              data.programType === "hsa+wsa" ? "HWSA" : data.programType
            }-${
              data.programPaymentType === "pos"
                ? "POST"
                : data.programPaymentType
            }-${data.purseType === "split" ? "SINGLE" : "DUAL"}`,
            employeeGuid: data.employeeGuid,
            carryOverYear1: data.carryOverYear1 || 0,
            carryOverYear2: data.carryOverYear2 || 0,
            terminationDate: data.terminationDate ?? "",
            gracePeriod: data.gracePeriod ?? 0,
            terminationGracePeriod: data.terminationGracePeriod ?? 0,
            graceBalance: data.graceBalance ?? 0,
            lastDayToSubmitClaim: data.lastDayToSubmitClaim ?? "",
            hsaAmount: data.hsaAmount || 0,
            wsaAmount: data.wsaAmount || 0,
            flexAmount: data.flexAmount || 0,
            className: data.className ?? "",
            companyGuid: data.companyGuid,
            familyType: data.familyType,
            programType: data.programType,
          });
      });
  }
  return newEmployees;
});

// Hsa wsa details
export const getEmployeeHsaWsa = createSelector(employee, (employee) => {
  // Default false
  let isSuspended: boolean = true;
  // Get the hsa
  const hsaWsa: object = employee.hsaWsa;
  // Check the length of hsa
  if (size(hsaWsa) > 0) {
    // loop over the hsa
    forIn(hsaWsa, (person: { [index: string]: any }) => {
      // check person status is suspended then update the isSuspended variable into true
      if (
        person?.personStatus &&
        person?.personStatus.toLowerCase() === "suspended"
      ) {
        isSuspended = true;
      } else {
        isSuspended = false;
      }
    });
  }
  return isSuspended;
});

// Plan Details
export const getPlanDetails = createSelector(
  employee,
  (employee) => employee.planDetails
);

// Employee meta data
export const getEmployeeMetaData = createSelector(employee, (employee) =>
  cleanUpMeta(employee.data.person.metaInformation)
);

// Plan details values
export const getPlanDetailValues = createSelector(employee, (employee) =>
  flattenObject(employee.planDetails)
);

// Hsa details
export const getHSADetails = createSelector(
  employee,
  (employee) => employee.hsaDetails
);

// Wsa details
export const getWSADetails = createSelector(
  employee,
  (employee) => employee.wsaDetails
);

// Flex details
export const getFlexDetails = createSelector(
  employee,
  (employee) => employee.flexDetails
);

// Hsa Wsa
export const getHsaWsa = createSelector(
  employee,
  (employee) => employee.hsaWsa
);

// Employee cards
export const getEmployeeCards = createSelector(
  employee,
  (employee) => employee.employeeCards
);

// Employee cards
export const getPinResetCompleted = createSelector(
  employee,
  (employee) => employee.pinResetCompleted
);

//Cra Hsa medical expenses info
export const getEmployeeCraHsaInfo = createSelector(
  employee,
  (employee) => employee.employeeCraHsaInfo.medicalExpenseList
);

//Cra Hsa Parsed at date
export const getParsedAtDate = createSelector(
  employee,
  (employee) => employee.employeeCraHsaInfo.employeeCraParsedAt
);

// Is prepay
export const getIsPrepay = createSelector(employee, (employee) => {
  return employee.hsaDetails.prepaid ?? employee.wsaDetails.prepaid;
});

export const getEmployeeDashboardStats = createSelector(
  employee,
  (employee) => {
    const dashboardStats = {
      active: employee.employeeStats.activeEmployeesCount,
      invited: employee.employeeStats.invitedEmployeesCount,
      terminatedWithBenefits:
        employee.employeeStats.terminatedActiveEmployeesCount,
      terminated: employee.employeeStats.fullyTerminatedEmployeesCount,
    };
    return dashboardStats;
  }
);

export const getEmployeeFullList = createSelector(employee, (employee) => {
  return employee.employeesFull;
});

export const getEmployeeCurrentFundsLoadedStatus = createSelector(
  employee,
  (employee) => {
    // get status of loaded funds for the current plan year
    const balances = employee?.planDetails?.balances || [];
    let currentYearFundsLoaded = false;
    Object.values(balances).forEach((balance: any) => {
      if (balance.ContributionYear > 0) {
        currentYearFundsLoaded = true;
      }
    });
    return currentYearFundsLoaded;
  }
);

// Termination statuses
export const getEmployeeTerminationStats = createSelector(
  employee,
  (employee) => employee.employeeTerminationStats
);

export const getEmployeeAccountDeletion = createSelector(
  employee,
  (employee) => employee.accountDeletion
);

export const getFlexAllocationAPIResponse = createSelector(
  employee,
  (employee) => employee.flexAllocationInfo?.apiResponse
);
