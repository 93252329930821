// React
import { useState, useEffect, memo, FC, ReactElement } from "react";
// Router
import { RouteComponentProps, withRouter } from "react-router";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Semantic UI
import { Sidebar, Menu, Segment } from "semantic-ui-react";
// Helper
import { useWindowSize } from "../../../utilities/reactHelpers";
// History
import history from "../../../history";
// Components
import MenuBar from "../menuBar";
// Actions
import { isMenuOpen as openMenuBar } from "../../../actions";
// Selectors
import {
  getIsMenuOpen,
  getBusinessOperatedName,
  getUserRole,
} from "../../../selectors";
// Scss
import styles from "./styles.module.scss";

// Interface props
interface IProps extends RouteComponentProps {
  children: JSX.Element | JSX.Element[];
}

/**
 * @param {IProps} props
 * @return {*}  {ReactElement}
 */
const PusherMenu: FC<IProps> = (props: IProps): ReactElement => {
  // local state
  const [mobile, setMobile] = useState(false);

  // selectors
  const [isMenuOpen, userRole, businessOperatedName] = useSelector((store) => [
    getIsMenuOpen(store),
    getUserRole(store),
    getBusinessOperatedName(store),
  ]);

  // dispatch
  const dispatch = useDispatch();

  // Window scroll
  let [width] = useWindowSize();

  // Check if screen is mobile
  useEffect(() => {
    width <= 700 ? setMobile(true) : setMobile(false);
  });

  // Watches for screen re-sizing and opens/closes PusherMenu as needed
  useEffect(() => {
    if (window.innerWidth >= 700) {
      dispatch(openMenuBar({ override: true }));
    }
    if (window.innerWidth < 700) {
      dispatch(openMenuBar({ override: false }));
    }

    if (userRole === "PCO" && window.innerWidth >= 700) {
      dispatch(openMenuBar({ override: false }));
    }
  }, [window.innerWidth > 700]);

  // Closes pusherMenu on mobile when navigating to different screens
  useEffect(() => {
    if (mobile && history.action === "PUSH") {
      dispatch(openMenuBar({ override: false }));
    }
  }, [props.location.pathname]);

  return (
    <Sidebar.Pushable as={Segment} style={{ backgroundColor: "#fbfbfb" }}>
      <Sidebar
        as={Menu}
        animation={mobile ? "overlay" : "push"}
        vertical
        visible={isMenuOpen}
        onHide={() => mobile && dispatch(openMenuBar({ override: false }))}
      >
        <MenuBar
          businessOperatedName={businessOperatedName}
          userRole={userRole}
          history={history}
        />
      </Sidebar>
      <Sidebar.Pusher dimmed={mobile && isMenuOpen ? true : false}>
        <div
          className={styles.flexContainer}
          style={{
            width: isMenuOpen && !mobile ? `${width - 270}px` : "100%",
          }}
        >
          {props.children}
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default withRouter(memo(PusherMenu));
