// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchLedgerStats = (): Action => ({
  type: "FETCH_LEDGER_STATS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateLedgerStats = (data: any): Action => ({
  type: "UPDATE_LEDGER_STATS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchCollectionLedger = (data: any): Action => ({
  type: "FETCH_LEDGER",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateLedger = (data: any): Action => ({
  type: "UPDATE_LEDGER",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateCollectionLedger = (data: any): Action => ({
  type: "UPDATE_COLLECTION_LEDGER",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createMemoTransaction = (data: any): Action => ({
  type: "CREATE_MEMO_TRANSACTION",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateLedgerGeneral = (data: any): Action => ({
  type: "UPDATE_GENERAL",
  data,
});
