export const SENTRY_PAGES = {
  login: 'LoginPage',
  claimDetails: 'ClaimDetailsPage',
  hsaCoverage: 'HsaCraCoverage',
};

export const SENTRY_ERRORS = {
  authFailed: 'AuthFailed',
  claimSubmissionFailed: 'ClaimSubmissionFailed',
  claimLoadFailed: 'ClaimLoadFailed',
  hsaCoverageCraFailed: 'HsaCoverageCraFailed',
};
