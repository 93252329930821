// Moment
import moment from "moment";
// History
import history from "../../history";
// Redux store
import store from "../../store/configureStore";
// Services
import { deleteState, getAuthData } from "../../store/localStorageService";

/**
 * @desc Use this function to check the current authentication status of the signed in user
 * @return {*}  {boolean} returns true or false
 */
export const checkAuth = (): boolean => {
  const { authReducer = {} }: any = store.getState();
  const { expiresInUTC = undefined }: any = authReducer;
  let expiresInUTCLocal: any = undefined;
  if (getAuthData()) {
    let { expiresInUTC: expiresInUTCLocal }: any = getAuthData();
  }
  const expiryDate: Date = expiresInUTC || expiresInUTCLocal;
  if (expiryDate) {
    if (moment.utc(moment.utc(expiryDate)).isAfter(moment.utc().utc())) {
      return true;
    }
    deleteState();
    return false;
  } else {
    deleteState();
    return false;
  }
};

/**
 * @desc Get auth tokens
 * @return {*}  {Promise<object>}
 */
const getAuthTokens = async (): Promise<object> => {
  const { accessToken, idToken, refreshToken }: any =
    store.getState().authReducer;
  return {
    accessToken,
    idToken,
    refreshToken,
  };
};

export const handleLogout = (): void => {
  setTimeout(() => {
    history.push("/logout", { modal: true });
  }, 500);
  deleteState();
  history.push("/");
};

/**
 * @return {*}  {Promise<any>}
 */
const getUserClaims = async (): Promise<any> => {
  return store.getState().authReducer.userClaims;
};

export const deleteAuthState = (): void => {
  deleteState();
};

/** @type {*} */
const AuthWrapper: object = {
  getAuthTokens,
  getUserClaims,
};

export { AuthWrapper as Auth };
