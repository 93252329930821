import React from 'react';
import styles from './views.module.scss';

const PrivacyPolicy = () => {
	return (
		<div className={ styles.terms }>
			<h2><strong>Aya Payments, Inc. - Privacy Policy</strong></h2>
			<p>Welcome to the Aya Payments Inc., (hereinafter &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;) Privacy Policy. This Privacy Policy demonstrates the commitment from us to your privacy and discloses the information - gathering and privacy practices employed by us in connection with providing you with your use of the website, <a href="http://www.ayacare.com">www.ayacare.com</a> (the &ldquo;Site&rdquo;), and the Aya Prepaid Mastercard&reg; (collectively known as the &ldquo;Services&rdquo;). By visiting the Site, you agree to be bound by the terms of this Privacy Policy. If you feel that we are not abiding by this posted Privacy Policy, you should contact us by using the methods listed below under the heading CONTACTING US at the Site.</p>
			<h2>Information Collection and Purpose Specification</h2>
			<p>We collect the data that you may volunteer to share with us while using our Services, including without limitation, personal and business data when you register for Services, information about your transactions with us, alternate contact information, a password which is used to protect your account from unauthorized access and other information from your interaction with the Site and transactions while utilizing our Services.</p>
			<p>For business plan administrators, personal information, business information, financial information, and financial data will be collected as a prerequisite to registered users being authorized to use the Services to open an account. The collection of business information may include, but is not limited to, your business name, business identification number (BIN), business address, business telephone number, business related documentation, and telephone number, or email address. In addition, you will be required to provide personal information including, but not limited to: name, personal address, date of birth, and other similar information. Additional information will be requested at account opening and may be requested throughout the life of the account relationship to meet regulatory and legal requirements. If you choose to provide us with personal information, business information, financial information, and financial data, you consent to the transfer and storage of that information on our servers located within Canada. Information disclosures, which are mandatory in order for you to use Services from us, will be designated as such.</p>
			<p>We collect, use, and otherwise process personal information, business information and financial data about you for the following purposes: (i) to enable you to access and use the Site; (ii) to comply with regulatory requirements; (iii) to maintain your Aya Mastercard Account; (iv) to process your fund transfers and transactions; (v) to enable us to provide any other Services which you have requested through use of the Site; (vi) to personalize aspects of our overall service to you; (vii) to provide customer support that you request, resolve disputes, collect fees and troubleshoot problems; (viii) to prevent potentially prohibited or illegal activities and enforce the applicable member Services Agreement; (ix) to measure and improve our Services, content, and advertising; and (x) any other reasonable need to use such information for the purposes of managing the Aya Mastercard program and provide related Services.</p>
			<h2>Important Information for Opening an Account</h2>
			<p>To help the government fight the funding of terrorism and money laundering activities, the Department of Justice of Canada, a Federal branch, requires all financial institutions to obtain, verify, and record information that identifies each business that opens an account and the personal information of its owners or representatives. When applying for an account, Aya Mastercard we will collect and store details about the personally identifiable information about the business owner and/or administrators and/or authorized users such as: name, address, date of birth, and other information that will allow Aya Mastercard to confirm identity. Aya Mastercard may also ask for details about the business owner&rsquo;s, administrators&rsquo; and/or authorized user&rsquo;s identifying information.</p>
			<h2>Use of &ldquo;Cookies&rdquo;</h2>
			<p>&ldquo;Cookies&rdquo; are small pieces of information that are stored by your browser on your computer&rsquo;s hard drive, that allow us to identify you and link you to your Aya Mastercard Account. If you choose not to accept our cookie, we will not be able to store your Aya Mastercard username, session data or other information for subsequent log-ins or future use.</p>
			<h2>Providing visitors with anonymous access</h2>
			<p>You can access the Site home pages and browse the Site home pages without disclosing your personal information.</p>
			<h2>Sharing of Information</h2>
			<p>The information we collect may be shared on an aggregate and statistical and/or personally identifiable basis with Authorized Third Parties (credible third parties collaborating with us, who have been presented with and agreed to be bound by our Privacy Policy). To the best of our knowledge, Authorized Third Parties do not release this information, other than for transactional purposes, or utilize it for any promotional activities. We utilize the services of Authorized Third Parties, for example, the Aya Prepaid Mastercard Issuing financial institution. It may be necessary to disclose customer information to such Authorized Third Parties in order to provide the Services.</p>
			<p>Aside from sharing your information with Authorized Third Parties in order to provide the Services, as discussed above, we do not provide your personal information to any third party without your permission. Additionally, we do not share your individual buying habits with third parties. We may provide general statistics to third parties in order to assess the market for the Aya Mastercard.</p>
			<p>We have no responsibility for the privacy policies and other aspects of websites that we link to, including the content of those websites and any cookies those websites might deposit on your hard drive. Third parties have their own privacy policies over which we exercise no control. Before providing personal information to third parties, you should refer to each third party&rsquo;s privacy policy for complete information about the collection and use of personal information on their website. Each third party&rsquo;s privacy policy, and not this Privacy Policy, governs how information that you provide directly to them is used.</p>
			<p>In the event you enter your personal information into fields requesting additional information or offers or otherwise affirmatively elect to receive such information or offers, you agree to and authorize the delivery of automatic updates and/or marketing materials from Aya Mastercard and/or Authorized Third Parties to you. In the event you wish to later opt out of receiving such information or offers, please contact us using the methods listed below under the heading CONTACTING US. Please allow several weeks for your opt-out request to take effect. Please understand that even if you choose to opt-out, we may continue to share your non-public personal information with Authorized Third Parties and certain non-affiliated third parties as permitted by applicable laws and regulations.</p>
			<h2>Forced Disclosure</h2>
			<p>Your personal data will only be disclosed (i) to government agencies and authorities where required by applicable laws or other regulations; (ii) to enforce our Aya Mastercard Account or the terms of this Privacy Policy; (iii) to detect, prevent or address issues relating to fraud, security breaches or other technical issues; or (iv) to protect against imminent harm to the rights, property or safety of Aya Mastercard or other parties as may be required or permitted by applicable laws or regulations.</p>
			<h2>Confidentiality and Security</h2>
			<p>Keeping your personal information secure is very important to us. All of our employees, data processors and co-hosting facilities who have access to and are associated with the processing of unencrypted personal data are obligated to take reasonable efforts to respect the confidentiality of your personal data and have agreed to be bound by the terms of the Privacy Policy and such other confidentiality and security measures as we determine necessary. We will take appropriate and up to date measures to the best of our abilities to protect your personal information. However, no data transmission over the Internet can be guaranteed to be totally secure, and third parties may unlawfully intercept or access transmissions or private communications. As a result, while we strive to protect your personal information, we cannot ensure or warrant the security of any information that you send to us, and therefore you do so at your own risk.</p>
			<h2>Indemnification</h2>
			<p>You are entirely responsible for maintaining the confidentiality of your username(s), passwords(s), and your account(s), as well as all activities that occur under your account(s). You hereby agree to indemnify, defend, and hold us, our agents, representatives and other authorized users, and each of the foregoing entities&rsquo; respective officers, directors, owners, employees, agents, representatives and assigns (collectively the &ldquo;Indemnified Parties&rdquo;) harmless from and against any and all losses, damages, liabilities and costs incurred by the Indemnified Parties in connection with any claim arising out of any breach by you of this Privacy Policy, the Aya Mastercard account and the Services Agreement (if applicable), or claims arising from your Aya Mastercard account(s). You agree to use your best efforts to cooperate with us in the defense of any claim. We reserve the right, at our own expense, to employ separate counsel and assume the exclusive defense and control of any matter otherwise subject to indemnification by you.</p>
			<h2>Privacy Practices of Third Parties, Advertisers, Vendors and Others</h2>
			<p>This website may contain advertisements and links to other sites. Aya Mastercard is not responsible for the content or privacy policies or practices of any advertisers or linked sites or for the content, privacy policies or practices of any third parties, including without limitation the Aya Mastercard issuing financial institution. To the extent that you disclose personal information to any advertisers, linked sites or other third parties, which are featured on our site, different rules may apply to their use or disclosure of personal information, and we encourage you to review their privacy policies before disclosing personal information to them.</p>
			<h2>Changes to this Privacy Policy</h2>
			<p>Our Site and the Aya Mastercard features and Services may change over time and our information-gathering practices and policies may also change. While our philosophy of protecting user information from inappropriate uses and disclosures will not change, this Privacy Policy will be updated occasionally to include any change that materially affects the collection, maintenance, use, or disclosure of personal information.</p>
			<p>Other changes to this Privacy Policy that reflect refinements, improvements, updates, and new features will be made as appropriate and posted as soon as possible. Whenever the Privacy Policy changes, we may modify the date of the Privacy Policy to reflect that a change was made.</p>
			<h2>Access or Corrections to your Personal Information</h2>
			<p>You may request in writing any personal information about you that we hold, or correct or amend information about you if it is inaccurate, using the methods listed below under the heading</p>
			<h2>Children</h2>
			<p>This website is intended for use by adults only. Aya Mastercard does not knowingly collect or use information from children.</p>
			<h2>Former Customers</h2>
			<p>We follow the same privacy policies and practices for our former customers as are described in this Privacy Policy.</p>
			<h2>Contacting Us</h2>
			<h3>Customer Service</h3>
			<p>For all customer service information regarding the Aya Prepaid Mastercard, please contact:</p>
			<p>+1 (888) 427-6682</p>
			<p>Or</p>
			<p><a href="mailto:privacy@ayacare.com">privacy@ayacare.com</a></p>
			<p>Or</p>
			<p>Aya Payments, Inc.,&nbsp;</p>
			<p>P.O. Box 60014</p>
			<p>Thorncrest PO,&nbsp;</p>
			<p>Etobicoke, ON., M9A 3L0</p>
			<p>This Privacy Policy is effective September 25, 2020 (PCS ID 9628). <em>&nbsp;Aya Prepaid Mastercard&reg; is issued by Peoples Trust Company under licence from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.</em></p>
		</div>
	);
};

export default PrivacyPolicy;
