// Types
import type { Action } from "./types";

/**
 * @param {string} idToken
 * @param {string} email
 * @return {*}  {Action}
 */
export const authorizationSensiBill = (idToken: string): Action => ({
  type: "AUTHORIZATION_SENSI_BILL",
  data: { idToken },
});

/**
 * @param {string} idToken
 * @return {*}  {Action}
 */
export const loginSensiBill = (idToken: string): Action => ({
  type: "LOGIN_SENSI_BILL",
  data: { idToken },
});

/**
 * @param {string} sensiBillAccessToken
 * @param {string} imageDetails
 * @return {*}  {Action}
 */
export const createReceipt = (
  sensiBillAccessToken: string,
  imageDetails: string[],
  whereRedirect: string
): Action => ({
  type: "CREATE_SENSI_BILL",
  data: { sensiBillAccessToken, imageDetails, whereRedirect },
});

/**
 * @param {string} transactionID
 * @param {string} sensiBillAccessToken
 * @return {*}  {Action}
 */
export const polling = (
  transactionID: string,
  sensiBillAccessToken: string
): Action => ({
  type: "POLLING_SENSI_BILL",
  data: { transactionID, sensiBillAccessToken },
});

/**
 * @param {string} receiptID
 * @param {string} sensiBillAccessToken
 * @return {*}  {Action}
 */
export const getReceipt = (
  receiptID: string,
  sensiBillAccessToken: string
): Action => ({
  type: "GET_RECEIPT_SENSI_BILL",
  data: { receiptID, sensiBillAccessToken },
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateSensiBill = (data: any): Action => ({
  type: "UPDATE_SENSI_BILL_DETAILS",
  data
})
