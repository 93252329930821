//This contains helpers that utilize react
import React, { useLayoutEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import NumberFormat from "react-number-format";

export const truncateStrings = (str, length = 20) => {
  if (str && str.length > length - 3) {
    return (
      <Popup
        content={str}
        position={"top center"}
        inverted
        trigger={<span> {`${str.substring(0, length - 3)}...`} </span>}
      />
    );
  }
  return str;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const renderCurrency = (value) => (
  <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator
    prefix="$"
    decimalScale={2}
    fixedDecimalScale
    renderText={(formattedValue) => formattedValue}
  />
);
