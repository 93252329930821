// Reselect
import _, { get } from "lodash";
import { createSelector } from "reselect";
import {
  buildPDVArray,
  restorePercentageValue,
  transformClassData,
} from "../utilities/customFunctions";
import { classList } from "../components/dashboard/selfEnrolment/sampleData";

// States
const company = (state: any) => state.companyReducer;

// Company stats
export const getCompanyStats = createSelector(
  company,
  (company) => company.companyStats
);

// Employee count
export const getEmployeeCount = createSelector(
  company,
  (company) => company.companyStats.employeeCount
);

// Company info
export const getCompanyInfo = createSelector(
  company,
  (company) => company.data
);

// Company list
export const getCompanyList = createSelector(
  company,
  (company) => company.companiesFull
);

export const getSelfEnrolmentData = createSelector(
  company,
  (company) => company.selfEnrolment
);

export const getPcoEnrolmentData = createSelector(
  company,
  (company) => company.pcoEnrolment
);

// Company guid
export const getDefaultCompanyGuidForReport = createSelector(
  company,
  (company) => company.redirectedCompanyReport
);

// Company invoice file
export const getInvoiceFile = createSelector(
  company,
  (company) => company.invoiceFile
);

//Invoice list
export const fetchInvoiceList = createSelector(
  company,
  (company) => company.invoiceList
);

//Report file
export const fetchReportFile = createSelector(
  company,
  (company) => company.reports.fileData
);

//Report type
export const fetchReportType = createSelector(
  company,
  (company) => company.reports.reportType
);

//Report file is generated or not
export const fetchIsReportGenerated = createSelector(
  company,
  (company) => company.reports.reportGenerated
);

//Return balances
export const fetchCompanyCashBalances = createSelector(
  company,
  (company) => company.balances.cash
);

// Plan Admin Dashboard
export const getCompanyDashboardInfo = createSelector(company, (company) => {
  const {
    data,
    planSponsorContact = {},
    planSponsorAdminPrimary = {},
    planSponsorAccountant = {},
    classes = [],
  } = company;

  //Get contacts
  let billingContact;
  let planSponsorAdministratorPrimary;
  let planContact;
  billingContact =
    _.size(planSponsorAccountant) > 0
      ? Object.values(planSponsorAccountant)[0]
      : [];
  planSponsorAdministratorPrimary =
    _.size(planSponsorAdminPrimary) > 0
      ? Object.values(planSponsorAdminPrimary)[0]
      : [];
  planContact =
    _.size(planSponsorContact) > 0 ? Object.values(planSponsorContact)[0] : [];

  //get programDefinedVariables
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  //get employeeClass
  let employeeClass = [];
  if (_.size(programDefinedVariables) > 0) {
    employeeClass = _.get(programDefinedVariables, "contractTerm")
      ? buildPDVArray(
          programDefinedVariables.contractTerm,
          programDefinedVariables.contractTerm.endDateYear1,
          programDefinedVariables.contractTerm.startDateYear1,
          programDefinedVariables.contractTerm.prorateYear1,
          programDefinedVariables.contractTerm.paymentSchedule
        )
      : buildPDVArray(
          programDefinedVariables,
          programDefinedVariables.endDateYear1,
          programDefinedVariables.startDateYear1,
          programDefinedVariables.prorateYear1,
          programDefinedVariables.paymentSchedule
        );
  } else {
    employeeClass = buildPDVArray(
      _.get(data, "business.programDefinedVariables.contractTerm"),
      _.get(data, "business.programDefinedVariables.contractTerm,endDateYear1"),
      _.get(
        data,
        "business.programDefinedVariables.contractTerm,startDateYear1"
      ),
      _.get(data, "business.programDefinedVariables.contractTerm,prorateYear1"),
      _.get(
        data,
        "business.programDefinedVariables.contractTerm,paymentSchedule"
      )
    );
  }

  return {
    companyGuid: get(data, "business.businessGUID"),
    programDefinedVariables,
    // userName: `${userClaims.firstName}  ${userClaims.lastName}`,
    businessName: _.get(data, "business.businessOperatedName"),
    year1StartDate: _.get(programDefinedVariables, "year1StartDate"),
    year1TermDate: _.get(programDefinedVariables, "year1endDate"),
    planType: _.get(programDefinedVariables, "programType"),
    purseType: _.get(programDefinedVariables, "purseType"),
    paymentType:
      _.get(programDefinedVariables, "programPaymentType") ===
      "prepaymentmastercard"
        ? "PrePay"
        : "PostPay",
    paymentSchedule: get(programDefinedVariables, "paymentSchedule"),
    postpaymentFundsPullingRate: _.get(
      programDefinedVariables,
      "postpaymentFundsPullingRate"
    ),
    isCompanyFloat:
      _.get(programDefinedVariables, "floatDesiredBalance") &&
      parseFloat(programDefinedVariables?.floatDesiredBalance) > 0
        ? true
        : false,
    dependentsEligible:
      _.get(programDefinedVariables, "allow") === "true" ? "Yes" : "No",
    lastDayToSubmitPrevYear: _.get(
      programDefinedVariables,
      "daysToSubmitClaimPreviousYear"
    ),
    lastDayToSubmitTermination: _.get(
      programDefinedVariables,
      "daysToSubmitClaimTermination"
    ),

    administrationFee: restorePercentageValue(
      _.get(programDefinedVariables, "administrationFee") || 0
    ),
    claimProcessingFee: restorePercentageValue(
      _.get(programDefinedVariables, "claimProcessingFee") || 0
    ),
    monthlyEmployeeFeeTier1:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier1") || 0,
    monthlyEmployeeFeeTier2:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier2") || 0,
    setupFee: _.get(programDefinedVariables, "setupFee") || 0,
    employeeSetupFee: _.get(programDefinedVariables, "employeeSetupFee") || 0,
    cardIssuanceFee: _.get(programDefinedVariables, "cardIssuanceFee") || 0,
    employeeMonthlyFee:
      _.get(programDefinedVariables, "monthlyEmployeeFee") || 0,
    earlyTerminationFee:
      _.get(programDefinedVariables, "earlyTerminationFee") || 0,
    year1StartDate: _.get(programDefinedVariables, "startDateYear1"),
    year1EndDate: _.get(programDefinedVariables, "endDateYear1"),
    pepmFee: _.get(programDefinedVariables, "pepmFee") || 0,
    pepmFrequency: _.get(programDefinedVariables, "pepmFrequency") || "-",
    pepmYearlyDiscount:
      _.get(programDefinedVariables, "pepmYearlyDiscount") || 0,
    companyContactFirstName: get(planContact, "firstName") || "-",
    companyContactLastName: get(planContact, "lastName") || "-",
    companyContactEmail: get(planContact, "email") || "-",
    companyContactWorkNumber: get(planContact, "workNumber") || "-",
    companyContactMobileNumber: get(planContact, "mobileNumber") || "-",
    planAdminFirstName:
      get(planSponsorAdministratorPrimary, "firstName") ||
      get(planContact, "firstName") ||
      "-",
    planAdminLastName:
      get(planSponsorAdministratorPrimary, "lastName") ||
      get(planContact, "lastName") ||
      "-",
    planAdminEmail:
      get(planSponsorAdministratorPrimary, "email") ||
      get(planContact, "email") ||
      "-",
    planAdminWorkNumber: get(planSponsorAdministratorPrimary, "workNumber")
      ? get(planSponsorAdministratorPrimary, "workNumber")
      : !get(planSponsorAdministratorPrimary, "email")
      ? get(planContact, "workNumber") || "-"
      : "-",
    planAdminMobileNumber: get(planSponsorAdministratorPrimary, "mobileNumber")
      ? get(planSponsorAdministratorPrimary, "mobileNumber")
      : !get(planSponsorAdministratorPrimary, "email")
      ? get(planContact, "mobileNumber") || "-"
      : "-",
    billingContactFirstName:
      get(billingContact, "firstName") || get(planContact, "firstName") || "-",
    billingContactLastName:
      get(billingContact, "lastName") || get(planContact, "lastName") || "-",
    billingContactEmail:
      get(billingContact, "email") || get(planContact, "email") || "-",
    billingContactWorkNumber: get(billingContact, "workNumber")
      ? get(billingContact, "workNumber")
      : !get(billingContact, "email")
      ? get(planContact, "workNumber") || "-"
      : "-",
    billingContactMobileNumber: get(billingContact, "mobileNumber")
      ? get(billingContact, "mobileNumber")
      : !get(billingContact, "email")
      ? get(planContact, "mobileNumber") || "-"
      : "-",
    defaultPaymentMethod: get(data, "business.defaultPaymentMethod"),
    financialInstitution: get(data, "business.bankAccount.bankName") || "-",
    accountNumber: get(data, "business.bankAccount.bankAccountNumber"),
    classes: employeeClass,
    classList: transformClassData(classes.classList),
    prorateYear1:
      _.get(programDefinedVariables, "prorateYear1") === "true"
        ? "true"
        : "false",
  };
});

export const getCompanyGenInfoSummary = createSelector(company, (company) => {
  const {
    generalInformation,
    planSponsorContact = {},
    planSponsorAdminPrimary = {},
    planSponsorAccountant = {},
  } = company;

  //Get contacts
  let billingContact;
  let planSponsorAdministratorPrimary;
  let planContact;
  billingContact =
    _.size(planSponsorAccountant) > 0
      ? Object.values(planSponsorAccountant)[0]
      : [];
  planSponsorAdministratorPrimary =
    _.size(planSponsorAdminPrimary) > 0
      ? Object.values(planSponsorAdminPrimary)[0]
      : [];
  planContact =
    _.size(planSponsorContact) > 0 ? Object.values(planSponsorContact)[0] : [];

  //get programDefinedVariables
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(generalInformation, "business.programDefinedVariables") &&
    typeof generalInformation.business.programDefinedVariables !== null &&
    typeof generalInformation.business.programDefinedVariables.forEach ===
      "function"
  ) {
    generalInformation.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(generalInformation, "business.programDefinedVariables")) {
    for (let item in generalInformation.business.programDefinedVariables) {
      if (
        _.get(
          generalInformation,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          generalInformation.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          generalInformation.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        generalInformation.business.programDefinedVariables[item];
    }
  }

  //get employeeClass
  let employeeClass = [];
  if (_.size(programDefinedVariables) > 0) {
    employeeClass = _.get(programDefinedVariables, "contractTerm")
      ? buildPDVArray(
          programDefinedVariables.contractTerm,
          programDefinedVariables.contractTerm.endDateYear1,
          programDefinedVariables.contractTerm.startDateYear1,
          programDefinedVariables.contractTerm.prorateYear1,
          programDefinedVariables.contractTerm.paymentSchedule
        )
      : buildPDVArray(
          programDefinedVariables,
          programDefinedVariables.endDateYear1,
          programDefinedVariables.startDateYear1,
          programDefinedVariables.prorateYear1,
          programDefinedVariables.paymentSchedule
        );
  } else {
    employeeClass = buildPDVArray(
      _.get(
        generalInformation,
        "business.programDefinedVariables.contractTerm"
      ),
      _.get(
        generalInformation,
        "business.programDefinedVariables.contractTerm,endDateYear1"
      ),
      _.get(
        generalInformation,
        "business.programDefinedVariables.contractTerm,startDateYear1"
      ),
      _.get(
        generalInformation,
        "business.programDefinedVariables.contractTerm,prorateYear1"
      ),
      _.get(
        generalInformation,
        "business.programDefinedVariables.contractTerm,paymentSchedule"
      )
    );
  }

  return {
    companyGuid: get(generalInformation, "business.businessGUID"),
    programDefinedVariables,
    // userName: `${userClaims.firstName}  ${userClaims.lastName}`,
    businessName: _.get(generalInformation, "business.businessOperatedName"),
    year1StartDate: _.get(programDefinedVariables, "year1StartDate"),
    year1TermDate: _.get(programDefinedVariables, "year1endDate"),
    planType: _.get(programDefinedVariables, "programType"),
    purseType: _.get(programDefinedVariables, "purseType"),
    paymentType:
      _.get(programDefinedVariables, "programPaymentType") ===
      "prepaymentmastercard"
        ? "PrePay"
        : "PostPay",
    paymentSchedule: get(programDefinedVariables, "paymentSchedule"),
    postpaymentFundsPullingRate: _.get(
      programDefinedVariables,
      "postpaymentFundsPullingRate"
    ),
    isCompanyFloat:
      _.get(programDefinedVariables, "floatDesiredBalance") &&
      parseFloat(programDefinedVariables?.floatDesiredBalance) > 0
        ? true
        : false,
    dependentsEligible:
      _.get(programDefinedVariables, "allow") === "true" ? "Yes" : "No",
    lastDayToSubmitPrevYear: _.get(
      programDefinedVariables,
      "daysToSubmitClaimPreviousYear"
    ),
    lastDayToSubmitTermination: _.get(
      programDefinedVariables,
      "daysToSubmitClaimTermination"
    ),

    administrationFee: restorePercentageValue(
      _.get(programDefinedVariables, "administrationFee") || 0
    ),
    claimProcessingFee: restorePercentageValue(
      _.get(programDefinedVariables, "claimProcessingFee") || 0
    ),
    monthlyEmployeeFeeTier1:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier1") || 0,
    monthlyEmployeeFeeTier2:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier2") || 0,
    setupFee: _.get(programDefinedVariables, "setupFee") || 0,
    employeeSetupFee: _.get(programDefinedVariables, "employeeSetupFee") || 0,
    cardIssuanceFee: _.get(programDefinedVariables, "cardIssuanceFee") || 0,
    employeeMonthlyFee:
      _.get(programDefinedVariables, "monthlyEmployeeFee") || 0,
    earlyTerminationFee:
      _.get(programDefinedVariables, "earlyTerminationFee") || 0,
    year1StartDate: _.get(programDefinedVariables, "startDateYear1"),
    year1EndDate: _.get(programDefinedVariables, "endDateYear1"),
    pepmFee: _.get(programDefinedVariables, "pepmFee") || 0,
    pepmFrequency: _.get(programDefinedVariables, "pepmFrequency") || "-",
    pepmYearlyDiscount:
      _.get(programDefinedVariables, "pepmYearlyDiscount") || 0,
    companyContactFirstName: get(planContact, "firstName") || "-",
    companyContactLastName: get(planContact, "lastName") || "-",
    companyContactEmail: get(planContact, "email") || "-",
    companyContactWorkNumber: get(planContact, "workNumber") || "-",
    companyContactMobileNumber: get(planContact, "mobileNumber") || "-",
    planAdminFirstName:
      get(planSponsorAdministratorPrimary, "firstName") ||
      get(planContact, "firstName") ||
      "-",
    planAdminLastName:
      get(planSponsorAdministratorPrimary, "lastName") ||
      get(planContact, "lastName") ||
      "-",
    planAdminEmail:
      get(planSponsorAdministratorPrimary, "email") ||
      get(planContact, "email") ||
      "-",
    planAdminWorkNumber: get(planSponsorAdministratorPrimary, "workNumber")
      ? get(planSponsorAdministratorPrimary, "workNumber")
      : !get(planSponsorAdministratorPrimary, "email")
      ? get(planContact, "workNumber") || "-"
      : "-",
    planAdminMobileNumber: get(planSponsorAdministratorPrimary, "mobileNumber")
      ? get(planSponsorAdministratorPrimary, "mobileNumber")
      : !get(planSponsorAdministratorPrimary, "email")
      ? get(planContact, "mobileNumber") || "-"
      : "-",
    billingContactFirstName:
      get(billingContact, "firstName") || get(planContact, "firstName") || "-",
    billingContactLastName:
      get(billingContact, "lastName") || get(planContact, "lastName") || "-",
    billingContactEmail:
      get(billingContact, "email") || get(planContact, "email") || "-",
    billingContactWorkNumber: get(billingContact, "workNumber")
      ? get(billingContact, "workNumber")
      : !get(billingContact, "email")
      ? get(planContact, "workNumber") || "-"
      : "-",
    billingContactMobileNumber: get(billingContact, "mobileNumber")
      ? get(billingContact, "mobileNumber")
      : !get(billingContact, "email")
      ? get(planContact, "mobileNumber") || "-"
      : "-",
    defaultPaymentMethod: get(
      generalInformation,
      "business.defaultPaymentMethod"
    ),
    financialInstitution:
      get(generalInformation, "business.bankAccount.bankName") || "-",
    accountNumber: get(
      generalInformation,
      "business.bankAccount.bankAccountNumber"
    ),
    classes: employeeClass,
  };
});
