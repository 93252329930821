// Types
import { Action } from "./types";

/**
 * @return {*}  {Action}
 */
export const fetchTPAS = (): Action => ({
  type: "FETCH_TPAS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateTPAS = (data: any): Action => ({
  type: "UPDATE_TPAS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createTPA = (data: any): Action => ({
  type: "CREATE_TPA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const newTPA = (data: any): Action => ({
  type: "NEW_TPA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editTPA = (data: any): Action => ({
  type: "EDIT_TPA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateTPA = (data: any): Action => ({
  type: "UPDATE_TPA",
  data,
});

/**
 * @return {*}  {Action}
 */
export const getTPA = (): Action => ({
  type: "GET_TPA",
});

/**
 * @return {*}  {Action}
 */
export const showTPA = (): Action => ({
  type: "SHOW_TPA",
});

/**
 * @return {*}  {Action}
 */
export const clearTPA = (): Action => ({
  type: "CLEAR_TPA",
});
