// React
import { memo, ReactNode, FC, ReactElement } from "react";
// Components
import NavBar from "../../navBar";

// types
type Props = {
  children: ReactNode;
  layoutProps: object;
};

/**
 * @author Aya
 * @desc handle the layout things like navbar, sidebar, footer, and main container
 * @param {React.ReactNode} { children }
 * @return element
 */
const LoginLayout: FC<Props> = (props: Props): ReactElement => (
  <div>
    <NavBar {...props.layoutProps} />
    {props.children}
  </div>
);

// Default export
export default memo(LoginLayout);
