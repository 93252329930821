import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'qs'
import { withRouter } from 'react-router-dom';
import { Modal, Header, Button, Image } from 'semantic-ui-react';
import { setModal } from '../../../actions/general';
import TermsOfService from './views/termsOfService';
import CardMemberAgreement from './views/cardMemberAgreement';
import PADAgreement from './views/pad';
import ESignAgreement from './views/eSignAgreement';
import PrivacyPolicy from './views/privacyPolicy';
import styles from './customModal.module.scss';
import { Images } from '../../../constants';
import { getTitle } from './helpers';
interface IProps {
    location: any,
    isOpen: boolean,
    searchQuery: string,
    setModal: (args: setModalArgs) => void,
};

interface setModalArgs {
    modal: { 
        isOpen: boolean, 
        searchQuery: string,
        locationData: any,
    },
};

const CustomModal: React.FunctionComponent<IProps> = ({
    isOpen,
    setModal,
    location,
    searchQuery,
}) => {
    let createModal = qs.parse(location?.search, { ignoreQueryPrefix: true });
    let view = null;
    let title = getTitle[createModal.gref];

    const handleClose = () => {
        setModal({ 
            modal: { 
                isOpen: false, 
                searchQuery: '',
                locationData: null 
            }
        });
    };

    if(createModal.gref) {
        switch(createModal.gref){
            case 'terms-of-service':
                view = <TermsOfService />
                break;
            case 'pre-authorized-debit':
                view = <PADAgreement />
                break;
            case 'card-member-agreement':
                view = <CardMemberAgreement />
                break;
            case 'esign-agreement':
                view = <ESignAgreement />
                break;
            case 'privacy-policy':
                view = <PrivacyPolicy />
                break;   
            default:
                handleClose();
                break;
        };
    };

    return(
        <Modal
            onClose={ handleClose }
            open={ isOpen }
            // onOpen={() => setOpen(true)}
        >
            <Modal.Header>
                <div className={ styles.modal__header }>
                    <div>{ title ? title : '' }</div>
                    <div
                        style={ { backgroundImage: `url(${ Images.close })`, backgroundRepeat: `no-repeat` } }
                        className={ styles.rightSubHeader }
                        onClick={ handleClose }
                    />
                </div>
            </Modal.Header>

            <Modal.Content>
                { view }
            </Modal.Content>
      </Modal>
    );
};

const mapStateToProps = (state: any) => {
    const { modal } = state.generalReducer;

    return {
        isOpen: modal?.isOpen,
        searchQuery: modal?.searchQuery,
        locationData: modal?.locationData
    }
};

export default connect(mapStateToProps, { setModal })(withRouter(CustomModal));
