// JWt
import jwtDecode from "jwt-decode";
// Type
import { Action } from "../actions/types";

// State type
export type State = {
  idToken: any;
  accessToken: any;
  refreshToken: any;
  errorMessage: any;
  userClaims: any;
  showCodeInput: any;
  preAuthDetails: {
    firstName: string;
    personStatus: string;
    status: string;
    message: string;
    whiteLabel: {
      businessguid: string;
      logo: string;
      primarycolor: string;
      secondarycolor: string;
      sysutcinsertedat: string;
      sysutcupdatedat: string;
      whitelabelguid: string;
    };
  };
  terminateUser: {
    hsaAccountBalance: number | string;
    lastEligibleDate: string;
  };
};

// Initial State
const INITIAL_STATE: State = {
  idToken: null,
  accessToken: null,
  refreshToken: null,
  errorMessage: null,
  userClaims: {
    firstName: "",
    lastName: "",
    userRole: "",
    personGuid: "",
    businessOperatedName: "",
    roles: [],
  },
  showCodeInput: false,
  preAuthDetails: {
    firstName: "",
    personStatus: "",
    status: "",
    message: "",
    whiteLabel: {
      businessguid: "",
      logo: "",
      primarycolor: "#606ac1",
      secondarycolor: "#e300b1",
      sysutcinsertedat: "",
      sysutcupdatedat: "",
      whitelabelguid: "",
    },
  },
  terminateUser: {
    hsaAccountBalance: "",
    lastEligibleDate: "",
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "UPDATE_AUTH":
      const userClaims = jwtDecode(action.data.idToken);
      return {
        ...state,
        ...action.data,
        userClaims: {
          ...userClaims,
        },
      };
    case "SIGN_OUT":
      return INITIAL_STATE;
    case "AUTH_ERROR":
      return {
        ...state,
        errorMessage: action.payload,
      };
    case "CHANGE_SUB_VIEW":
      return {
        ...state,
        showCodeInput: action.payload,
      };
    case "SET_ROLE":
      const oldUserClaims = state.userClaims;
      const { role, idToken, userRoles, roles } = action.data || {};
      return {
        ...state,
        idToken,
        userClaims: {
          ...oldUserClaims,
          userRole: role,
          userRoles,
          roles,
        },
      };
    case "CHECK_EMAIL":
      return state;
    case "UPDATE_PREAUTH":
      return {
        ...state,
        preAuthDetails: {
          ...action.data,
        },
      };
    case "STORE_TERMINATE_USER":
      return {
        ...state,
        terminateUser: action.payload,
      };
    default:
      return state;
  }
};
