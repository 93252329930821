// Types
import { Action } from "../actions/types";

// State types
type State = {
  superAdmins: Array<any>;
  users: {
    count: number;
    summaryCount: any;
    listData: Array<any>;
  };
};

// Initial state
const INITIAL_STATE: State = {
  superAdmins: [],
  users: {
    count: 0,
    summaryCount: {},
    listData: [],
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "UPDATE_SUPER_ADMINS":
      return {
        ...state,
        superAdmins: [...action.data],
      };
    case "UPDATE_USERS_LIST":
      return {
        ...state,
        users: {
          count: action.data.count,
          summaryCount: { ...action.data.listData.summaryCount },
          listData: [...action.data.listData.list],
        },
      };
    default:
      return state;
  }
};
