/**
 * @param {any} type
 * @return {*}  {string}
 */
export const errorMappings = (type: any): string => {
  if (type.rawMessage) {
    return type.rawMessage;
  }

  if (type.includes("create.business.businessLegalName")) {
    return `Oops! This business name already exist: ${extractString(type)}`;
  } else if (type.includes("create.person.email")) {
    return `Oops! This email is already in use: ${extractString(type)}`;
  } else if (type.includes("404")) {
    return `Oops! Failed to create invite`;
  } else if (type.includes("createMemoErr")) {
    return "failed to create memo transaction";
  } else {
    return "Sorry, an unexpected error has occurred.";
  }
};

/**
 * @param {*} str
 * @return {*}  {string}
 */
const extractString = (str: any): string => {
  return str.match(/\[(.*?)\]/)
    ? str.match(/\[(.*?)\]/)[0].replace(/[\[\]']+/g, "")
    : "";
};
