// Types
import { Action } from "../actions/types";

// State type
type State = {
  merchantList: Array<any>;
};

// Initial state
const INITIAL_STATE: State = {
  merchantList: [],
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "FETCH_MERCHANT_LIST":
      return state;
    case "UPDATE_MERCHANT_LIST":
      return { ...state, merchantList: action.data };
    default:
      return state;
  }
};
