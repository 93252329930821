// Types
import type { Action } from "./types";

/**
 * @param {({ accessToken: string, path: string, limit: number | string, offset: number | string, search: string, rowPerPage: string | number})} details
 * @return {*}  {Action}
 */
export const getSls = (details: {
  accessToken: string;
  path: string;
  limit: number | string;
  offset: number | string;
  search: string;
  rowsPerPage: number | string;
  activeIndex: number;
}): Action => ({
  type: "GET_SLS",
  data: { ...details },
});

/**
 * @param {{
 *   accessToken: string,
 *     path: string,
 *     row: any,
 *     rowClicked: boolean
 * }} details
 * @return {*}  {Action}
 */
export const getSlsById = (details: {
  accessToken: string;
  path: string;
  row: any;
  rowClicked: boolean;
  limit: number;
  offset: number;
}): Action => ({
  type: "GET_SLS_BY_ID",
  data: { ...details },
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateSls = (data: any, type: any = "UPDATE_SLS"): Action => ({
  type,
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editSls = (data: any): Action => ({
  type: "EDIT_SLS",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearSls = (): Action => ({ type: "CLEAR_SLS" });
