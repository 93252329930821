export const AUTH_EVENT_TYPES = {
  RESPOND_TO_AUTH_CHALLENGE: "RESPOND_TO_AUTH_CHALLENGE",
  RENEW_VERIFICATION_CODE: "RENEW_VERIFICATION_CODE",
  SIGN_IN: "SIGN_IN",
};

export const AUTH_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
};

export const AUTH_API_PATHS = {
  SIGN_IN: "signin",
  VERIFICATION_CODE: "verification-code",
};

export const AUTH_FLOW = {
  ADMIN_NO_SRP_AUTH: "ADMIN_NO_SRP_AUTH",
};

export const HTTP_METHODS = {
  POST: "POST",
  GET: "GET",
};
