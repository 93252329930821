// Reselect
import { createSelector } from "reselect";
// User role mapper
import userRoleMapper from "../containers/dashboard/userRoleMapper";

// States
const auth = (state: any) => state.authReducer;

// Id Token
export const getIdToken = createSelector(auth, (auth) => auth.idToken);

// Access Token
export const getAccessToken = createSelector(auth, (auth) => auth.accessToken);

// BusinessOperatedName
export const getBusinessOperatedName = createSelector(
  auth,
  (auth) => auth.userClaims.businessOperatedName
);

// UserRole
export const getUserRole = createSelector(
  auth,
  (auth) => auth.userClaims.userRole
);

// ProperUserRole
export const properUserRole = createSelector(
  auth,
  (auth) => userRoleMapper[auth.userClaims.userRole]
);

// PreAuthDetails
export const getPreAuthDetails = createSelector(
  auth,
  (auth) => auth.preAuthDetails
);

// White label
export const getWhiteLabel = createSelector(
  auth,
  (auth) => auth.preAuthDetails.whiteLabel
);

// Get terminate user details
export const getTerminateUser = createSelector(
  auth,
  (auth) => auth.terminateUser
);

// Get user's name
export const getUsersName = createSelector(
  auth,
  (auth) => `${auth.userClaims.firstName} ${auth.userClaims.lastName}`
);

export const getUserClaims = createSelector(auth, (auth) => auth.userClaims);

export const getPersonGuid = createSelector(
  auth,
  (auth) => auth.userClaims.personGuid
);
