// Interface
import { IConstantObject } from "./Constant.interface";

/** @type {IConstantObject} */
const Images: IConstantObject<string> = {
  mapPin: "/images/placeholder.svg",
  uploadIcon: "/images/cloud-computing.svg",
  paperPlane: "/images/paper-plane.svg",
  phone: "/images/phone.svg",
  user: "/images/user.svg",
  calendar: "/images/calendar.svg",
  calendar1: "/images/calendar1.svg",
  tick: "/images/tick.svg",
  successImage: "/images/primary-check-circular.png",
  dropDownArrow: "/images/drop-down-arrow.svg",
  clear: "/images/clear.svg",
  addIconFilled: "/images/filled-add-icon.svg",
  openEyeIcon: "/images/open-eye-icon.svg",
  closedEyeIcon: "/images/eye-line.svg",
  greenCheckCircle: "/images/check-circle-2.svg",
  checkMark: "/images/check-mark.svg",
  downCarrot: "/images/down-carrot.svg",
  close: "/images/close.svg",
  spinner: "/images/spinner1.svg",
  avatar: "/images/profile_avatar.svg",
  clearOutline: "/images/clear-outline.svg",
  arrows: "/images/Aya_arrows.jpg",
  lock: "/images/Aya_Lock.jpg",
  star: "/images/Aya_star.jpg",
  logo: "/images/ayalogo.png",
  profile: "/images/person-2.svg",
  businesses: "/images/businesses.svg",
  applestore: "/images/appstore.svg",
  googlestore: "/images/googlestore.svg",
  claimWarning: "/images/claimWarning.svg",
  claimGood: "/images/claimGood.svg",
  claimNormal: "/images/claimNormal.svg",
  claimDanger: "/images/claimDanger.svg",
  outOfPocket: "/images/outOfPocket.svg",
  novaCard: "/images/novaCard.svg",
  receiptPlaceHolder: "/images/receiptPlaceHolder.jpg",
  alertCircle: "/images/alertCircle.svg",
  basket: "/images/basket.svg",
  fileCheck: "/images/fileCheck.svg",
  greyFlag: "/images/greyFlag.svg",
  hourglass: "/images/hourglass.svg",
  orangeRightCarret: "/images/orangeRightCarret.svg",
  pencil: "/images/pencil.svg",
  receipt: "/images/receipt.svg",
  redFlag: "/images/redFlag.svg",
  orangeFlag: "/images/orangeFlag.svg",
  whiteFlag: "/images/whiteFlag.svg",
  redFlagCircle: "/images/redFlagCircle.svg",
  store: "/images/store.svg",
  person: "/images/person.svg",
  receiptLarge: "/images/receiptLarge.png",
  missingDocument: "/images/missingDocument.svg",
  search: "/images/search.svg",
  whiteFilledCarretDown: "/images/whiteFilledCarretDown.svg",
  missing: "/images/missing.svg",
  ayaLogo: "/images/aya-logo.png",
  teamMaintenance: "/images/teamMaintenance.svg",
  question: "/images/question.svg",
  cheque: "/images/cheque.png",
  accountNumber: "/images/accountNumber.png",
  institutionNumber: "/images/institutionNumber.png",
  branchNumber: "/images/branchNumber.png",
  briefcase: "/images/briefcase.svg",
  house: "/images/house.svg",
  elipses: "/images/elipses.svg",
  arrowDown: "./images/arrow-down.svg",
  info: "./images/info.svg",
  claimSubmissionCheque: "./images/claimSubmissionCheque.jpg",
  closeOutline: "./images/close-outline.svg",
  masterCardLogo: "./images/mastercard.png",
  arrowBackImage: "./images/arrow-left.svg",
  notificationWithCheckImage: "./images/notification-with-check.png",
  empty: "./images/Empty.svg",
  cardSet: "./images/cardActivated.png",
  blankCard: "./images/blankCard.png",
  cardDetails: "./images/cardDetails.png",
  cardInTransit: "./images/cardInTransit.png",
  xClose: "./images/x.svg",
  gradientCheckmark: "./images/checkmark-pink.svg",
  externalLink: "./images/externalLink.svg",
  receiptImg: "./images/receiptImage.png",
  uploadCircle: "./images/upload-circle.png",
  submittedCircle: "./images/submitted.svg",
  infoIcon: "./images/infoIcon.svg",
  pdf: "./images/pdfImg.png",
  arrowRight: "./images/arrow-right.svg",
};

// Export
export { Images };
