import React, { useEffect, useState, memo, ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import GradientButton from "../../../auth/login/components/GradientButton";
import { Modal } from "semantic-ui-react";
import { triggerAccountDeletionCancellation } from "../../../../actions";
import {
  getPersonGuid,
  getEmployeeAccountDeletion,
} from "../../../../selectors";
import DeletionInProgressContent from "./DeletionInProgressContent";
import { useAlert } from "react-alert";
import history from "../../../../history";
import { deleteState } from "../../../../store/localStorageService";
import { COLOR_SCHEMES } from "../../../../styles/colors/colors";

const styleClasses = {
  modal: {
    padding: "10px",
    fontFamily: "Montserrat",
    borderRadius: "50px",
    border: "2px solid",
    borderColor: COLOR_SCHEMES.PRIMARY_GRADIENT.GREY,
  },
  cancellationFailedMsg: {
    textAlign: "center",
    color: COLOR_SCHEMES.ERROR,
    fontFamily: "Montserrat",
    fontSize: "14px",
    marginTop: "20px",
  },
  buttonFooter: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
};

interface IAccDeletionCancellationModal {
  isOpen: any;
  onClose: () => void;
}

const AccDeletionCancellationModal = ({
  isOpen,
}: IAccDeletionCancellationModal): ReactElement => {
  const alert = useAlert();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const closeModal = () => {
    setTimeout(() => {
      history.push("/logout", { modal: true });
    });
    deleteState();
    history.push("/");
  };
  const [hasCancellationFailed, setHasCancellationFailed] = useState(false);

  const [personGuid, cancelVerificationFailed, cancelVerificationSuccess] =
    useSelector((state: any) => [
      getPersonGuid(state),
      getEmployeeAccountDeletion(state).cancelVerificationFailed,
      getEmployeeAccountDeletion(state).cancelVerificationSuccess,
    ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, closeModal]);

  useEffect(() => {
    if (cancelVerificationSuccess) {
      setIsModalOpen(false);
    }
  }, [cancelVerificationSuccess]);

  useEffect(() => {
    if (cancelVerificationFailed) {
      setHasCancellationFailed(true);
    }
  }, [cancelVerificationFailed]);

  const onCancelDeletion = () => {
    dispatch(triggerAccountDeletionCancellation({ personGuid }));
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        size="small"
        className="modal-style"
        closeOnDimmerClick={true}
        onClose={() => closeModal()}
        style={styleClasses.modal}
      >
        <div
          style={{
            marginTop: "50px",
          }}
        >
          <Modal.Content>
            <DeletionInProgressContent />

            {hasCancellationFailed && (
              <div style={styleClasses.cancellationFailedMsg}>
                <p>
                  Could not cancel account deletion. Please try again later or
                  contact support.
                </p>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <div style={styleClasses.buttonFooter}>
              <GradientButton
                style={{ width: 250, margin: "auto", cursor: "pointer" }}
                label="Close"
                handleClick={() => closeModal()}
                isLoading={false}
                disabled={false}
              />
              <GradientButton
                style={{ width: 250, margin: "auto", cursor: "pointer" }}
                label="Cancel Deletion"
                handleClick={onCancelDeletion}
                isLoading={false}
                disabled={false}
              />
            </div>
          </Modal.Actions>
        </div>
      </Modal>
    </>
  );
};

export default AccDeletionCancellationModal;
