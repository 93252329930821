// Export
export * from "./CountryCodes";
export * from "./Generic";
export * from "./Images";

export /** @type {*} */
const SEQUENTIAL_CLAIMS: {
  UPDATE_CLAIMS_ITEMS: string;
  UPDATE_CLAIMS: string;
  DO_CLAIMS: string;
  DONE_CLAIMS: string;
} = {
  UPDATE_CLAIMS_ITEMS: "updateClaimsItems",
  UPDATE_CLAIMS: "updateClaims",
  DO_CLAIMS: "doClaims",
  DONE_CLAIMS: "doneClaims"
};
