import React from 'react';
import styles from './views.module.scss';

const CardMemberAgreement = () => {
    return (
        <div className={ styles.terms }>
            <h2><strong>Aya Prepaid Mastercard&reg; Card Cardholder Agreement</strong></h2>
            <p><strong>Please read this Agreement carefully and retain a copy for your records.</strong></p>
            <p><em>The following terms and conditions apply to your use of the Aya Prepaid Mastercard Card.</em></p>
            <p><em>By signing and/or using the Card, you are agreeing to these terms and conditions and fees outlined below.&nbsp;</em></p>
            <p><strong>Information disclosure summary</strong> (detailed terms and conditions will follow):</p>
            <p><strong>Card issuer: </strong>This card is issued by Peoples Trust Company under licence by Mastercard International Incorporated.</p>
            <p><strong>Card information and balance:</strong> To obtain the expiry date of the Card, if you have questions regarding the Card Balance, or to log a complaint, you may call customer service at <strong>1-888-427-6682</strong> or visit <a href="http://www.ayacare.com"><strong>www.ayacare.com</strong></a>&nbsp;for free.</p>
            <p><strong>Card restrictions:&nbsp;</strong></p>
            <ul>
            <li><strong>Cardholders are restricted to one Card per person.</strong></li>
            </ul>
            <ul>
            <li>Use of the Card in certain countries may be restricted by law.</li>
            </ul>
            <ul>
            <li>You may not use your Card to commit or facilitate illegal activity.</li>
            <li>You can only use your Card at Health &amp; Wellness Merchants that accepts Mastercard<em>.</em></li>
            <li>The Card does not have any ATM or cash-back at POS functionality.</li>
            <li>Only the Program Sponsor may add funds to the Card.</li>
            <li>You do not have the right to stop the payment of any transaction you conduct with the Card.</li>
            <li>The Card may not be cancelled by you unless otherwise directed in this Agreement or by Applicable Law.</li>
            <li>Regular pre-authorized debit (PAD) transactions, where you authorize a company or organization to withdraw funds from the Card, are not permitted. All PAD transactions will be rejected and Peoples Trust Company will not be liable for any costs incurred by you as a result.</li>
            <li>The Card is subject to certain limits, as set out below. Peoples Trust Company may change these limits in accordance with Applicable Law and will post notice on <strong>www.ayacare.com</strong> at least thirty (30) days in advance of the date such change is to come into effect. The change will take effect on the date indicated in the notice. Your continued use of the Card, after the change to the limits has come into effect, will be taken as your acceptance of that change.</li>
            </ul>
            <table>
            <tbody>
            <tr>
            <td><br />
            <table>
            <tbody>
            <tr>
            <td>
            <p><strong>Limits</strong></p>
            </td>
            </tr>
            <tr>
            <td>
            <p>Maximum Card Balance</p>
            </td>
            <td>
            <p>$250,000.00</p>
            </td>
            </tr>
            <tr>
            <td>
            <p>Maximum daily spend at point-of-sale</p>
            </td>
            <td>
            <p>$10,000.00</p>
            </td>
            </tr>
            <tr>
            <td>
            <p>Maximum number of point-of-sale- transactions / day</p>
            </td>
            <td>
            <p>100</p>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <p>Please also note: The Card may be deactivated at any time if fraud, related to the Card or use of the Card, is suspected.</p>
            <p><strong>Card expiry and access to funds:</strong> The Card has an expiry date printed on the front. Funds will expire on the last calendar day of the printed month shown on the Card. After expiry, the Card will be void. All remaining funds will automatically transfer to your newly issued card.</p>
            <p><strong>Fees:</strong> The table below sets out the fees that may be imposed upon the Card. You acknowledge being advised of the fees and agree to pay all fees charged under this Agreement.</p>
            <table>
            <tbody>
            <tr>
            <td><br />
            <table>
            <tbody>
            <tr>
            <td colSpan="2">
            <p><strong>Fees</strong>&dagger;</p>
            </td>
            </tr>
            <tr>
            <td>
            <p>Foreign exchange charge</p>
            </td>
            <td>
            <p>A minimum of $5.00 or 3%</p>
            </td>
            </tr>
            <tr>
            <td>
            <p>Out of Pocket Repayment&ndash; Payable by Bank to Bank Transfer</p>
            </td>
            <td>
            <p>$2.99 per transfer</p>
            </td>
            </tr>
            <tr>
            <td colSpan="2">
            <p><em>&dagger;</em><em> Subject to change. See terms and conditions below for details.</em></p>
            <p><strong>Funds loaded onto the Card are not insured by the Canada Deposit Insurance Corporation (CDIC).</strong></p>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <p><strong>Lost or stolen Card or PIN: </strong>Your card may have requirements for a Personal Identification Number (PIN). You can set your PIN within the Aya mobile application. You must take all reasonable steps to protect the Card and/or PIN against loss, theft, or unauthorized use. You should not maintain a written record of, or disclose the PIN to a third party, including family members and friends<strong>. If you lose the Card and/or PIN or you become aware that the PIN may have become known to someone else, you must call customer service immediately at 1-888-427-6682</strong> Avoid PIN combinations that may be easily guessed by others. All transactions carried out on the Card before you notify us will be considered to have been made by you.</p>
            <p>If your card has been disabled, please call customer service for assistance.</p>
            <p><strong>Split tender transactions: </strong>If the Balance on the Card is insufficient to cover the full Transaction Amount, you may request the merchant to conduct a split tender transaction, which is where you use the Card as partial payment of the Transaction Amount and then pay the remainder of the amount with another form of payment (e.g. cash, cheque, credit or debit). If you fail to inform the merchant that you would like to complete a split tender transaction prior to swiping the Card, the Card may be declined. Some merchants may require payment for the remaining Balance in cash. Merchants do not have to and may not agree to accept split tender transactions.</p>
            <p><strong>Personal Information: </strong>By accepting the Card, you consent to the collection, use, disclosure and retention of your personal information by Peoples Trust Company and its service providers as described below. The collection of that information is necessary for the entering into and performance of this Agreement. Therefore, if you do not consent to the collection, use, disclosure and retention of your personal information, you may not accept or use a Card.</p>
            <table>
            <tbody>
            <tr>
            <td>
            <p><strong>Key Cardholder Responsibilities under this Agreement:</strong></p>
            <ul>
            <li><strong>You must take all reasonable steps to protect the Card (and PIN, if applicable) against loss, theft, or unauthorized use. If you lose the Card (or PIN), you must call customer service immediately.</strong></li>
            <li><strong>You must activate and/or sign the Card as instructed upon receipt of the Card.</strong></li>
            <li><strong>You must surrender the Card to us immediately upon request by us.</strong></li>
            <li><strong>You must ensure that there is a sufficient Balance on the Card to cover the full amount of transactions made with the Card.</strong></li>
            <li><strong>If your information, associated with the Card, changes, you must notify us of the change(s).</strong></li>
            <li><strong>If you become aware that your information, associated with the Card, is incorrect, you must notify us of the correct information.</strong></li>
            <li><strong>If you find an error in any transaction record, you must communicate the error to the merchant with whom you made the transaction.</strong></li>
            <li><strong>If you wish to dispute a transaction on your Card, you must notify us in writing of your dispute within sixty (60) days of the transaction date.</strong></li>
            <li><strong>You must only use our online resources as set out in &lsquo;Website and Availability&rsquo;, below.</strong></li>
            </ul>
            </td>
            </tr>
            </tbody>
            </table>
            <p><strong>Detailed Terms and Conditions:&nbsp;</strong></p>
            <p><strong>Definitions:</strong></p>
            <ul>
            <li><strong>&lsquo;Agreement&rsquo;</strong> means this Aya Prepaid Mastercard Cardholder Agreement between Peoples Trust Company and the Cardholder and all documents that are expressly referred to herein, which govern your use of the Aya Prepaid Mastercard.</li>
            <li><strong>&lsquo;Amendment&rsquo; </strong>refers to any change to a term or condition of this Agreement or to the addition of a new term or condition, including increasing or adding new fees.</li>
            <li><strong>&lsquo;Applicable Law&rsquo; </strong>means the Trust and Loan Companies Act (Canada), the Personal Information Protection and Electronic Documents Act (Canada), the Proceeds of Crime (Money Laundering) and Terrorist Financing Act (PCMLTFA), Canada&rsquo;s Anti-Spam Legislation (CASL), PCI DSS or any other statute, regulation or operating rule of any Governmental Authority or any other regulatory authority that Peoples Trust Company and the Program Sponsor are subject to, or any bylaw, operating rule or regulation of Mastercard.</li>
            <li><strong>&lsquo;Balance&rsquo;</strong> means the amount of the funds that are loaded onto the Card.</li>
            <li><strong>&lsquo;Canada&rsquo;s Anti-Spam Legislation (CASL)&rsquo;</strong> means An Act to promote the efficiency and adaptability of the Canadian economy by regulating certain activities that discourage reliance on electronic means of carrying out commercial activities, and to amend the Canadian Radio-television and Telecommunications Commission Act, the Competition Act, the Personal Information Protection and Electronic Documents Act and the Telecommunications Act (Canada), and its regulations, as may be amended from time to time.</li>
            <li><strong>&lsquo;Card&rsquo; </strong>refers to the Aya Prepaid Mastercard Card activated, signed, or used by the Cardholder.</li>
            <li><strong>&lsquo;Cardholder&rsquo;</strong> means you or any other individual who has received, activated, signed or used the Card.</li>
            <li><strong>&lsquo;Foreign exchange charge&rsquo;</strong> means the foreign exchange service charge of 3%.</li>
            <li><strong>&lsquo;Governmental Authority&rsquo;</strong> means any federal, provincial, territorial, regional, municipal or local governmental authority, quasi-governmental authority (including the Office of the Superintendent of Financial Institutions), government organization, court, commission, board, professional agency, tribunal, organization, or any regulatory, administrative or other agency, or any political or other subdivision, department, or branch of any of the foregoing, in each case to the extent it has jurisdiction over Peoples Trust Company and/or the Program Sponsor or any Person, property, transaction, activity, event or other matter related to this Agreement. The above definition is deemed to include any interim or permanent transferee or successor of a Government Authority&rsquo;s underlying mandate, function or activity.</li>
            <li><strong>&lsquo;Health &amp; Wellness Merchants&rsquo;</strong> means those merchants that are labelled by Mastercard under their merchant commodity code as a provider of goods or services for health and wellness items.</li>
            <li><strong>&lsquo;Mastercard&rsquo; </strong>means Mastercard Incorporated, and its successors and assigns.</li>
            <li><strong>&lsquo;Mastercard Conversion Rate&rsquo; </strong>means the rate that we pay to Mastercard to convert foreign currency to Canadian currency plus the Foreign exchange charge<strong>.</strong></li>
            <li><strong>&lsquo;Mobile Application&rsquo;</strong> refers to the Aya application available through iOS or Android devices.</li>
            </ul>
            <ul>
            <li><strong>&lsquo;Aya for health spending account (HSA)&rsquo;</strong> is a tax-free Defined Contribution offered to you by your employer and adheres to Canadian Revenue Agency guidelines. All transactions will be audited for accuracy, and any items found to be non-eligible will require that you reimburse your account directly (bill pay) or indirectly (future monthly contributions will cover past transactions that were deemed to be non-eligible).</li>
            <li><strong>&lsquo;Aya for wellness spending account (WSA)&rsquo;</strong> is a Defined Contribution offered to you by your employer and adheres to Canadian Revenue Agency guidelines in accordance with taxable benefits. All transactions will be audited for accuracy, and any items found to be non-eligible will require that you reimburse your account directly (bill pay) or indirectly (future monthly contributions will cover past transactions that were deemed to be non-eligible).</li>
            <li><strong>&lsquo;PCI DSS&rsquo;</strong> means a multifaceted security standard defined by Payment Card Industry Security Standards Council and includes requirements for security management, policies, procedures, network architecture, software design and other critical protective measures.</li>
            <li><strong>&lsquo;Plan Administrator&rsquo; </strong>means the individual of a company authorized to administer Aya. The administration includes (but not limited) to adding or removing employees, paying invoices, looking up health and wellness merchants in a particular geographical area.</li>
            <li><strong>&lsquo;POS&rsquo;</strong> means point of sale, where you can use the Card to purchase goods or services from a merchant.</li>
            <li><strong>&lsquo;Transaction Amount&rsquo;</strong> is the amount that is debited from the Balance in connection with the Cardholder&rsquo;s use of the Card to purchase goods or services, which includes the amount of the Balance to be transferred, the Card service charges and the taxes imposed to complete the transaction.&nbsp;</li>
            <li><strong>&lsquo;we&rsquo;</strong>, <strong>&lsquo;us&rsquo;</strong>, <strong>&lsquo;our&rsquo; </strong>and<strong> &lsquo;Peoples Trust&rsquo;</strong> mean Peoples Trust Company, and our successors, subsidiaries, affiliates or assignees.&nbsp;</li>
            <li><strong>&lsquo;Website&rsquo;</strong> means <strong>www.ayacare.com</strong></li>
            <li><strong>&lsquo;you&rsquo;</strong>, <strong>&lsquo;your&rsquo;</strong>, and <strong>&lsquo;yours&rsquo;</strong> each mean the Cardholder.</li>
            </ul>
            <p><strong>Acceptance:</strong> This Agreement constitutes a binding agreement between you and us with respect to the terms of use of the Card.</p>
            <p><strong>The Prepaid Mastercard Card: </strong>The Card is a prepaid Mastercard that can be used only at Health &amp; Wellness Merchants that accept Mastercard, including mail order, online, telephone and point of sale retail merchants, subject to the terms of this Agreement.</p>
            <p><strong>Activating the Card:</strong><strong>The Card cannot be used for any purpose until it has been activated, and signed by you on the back of the Card where indicated</strong>. Whether or not the Card has an activation sticker adhered to the Card face, <strong>you must sign the back of the Card</strong>.</p>
            <p><strong>Ownership and Use of the Card:</strong> The Card can be used to pay the full amount of the purchase and applicable taxes, so long as the Balance remaining on the Card is sufficient. The Card is, and will remain, our property. The Card is not a credit card, charge card, or debit card and its usage will not enhance nor improve your credit rating. No interest dividends or other earnings or returns will be paid on the Card. Neither the Card nor the Balance is a deposit account. You have no right to write cheques on, or demand repayment of, the outstanding Balance on the Card, but are strictly limited to the right to use the Card, in accordance with this Agreement, as payment for goods and services from merchants who accept Mastercard.</p>
            <p>When you use the Card to make a purchase, the amount of the purchase, plus any applicable fees and taxes, will be deducted from the available Balance associated with the Card. If you use the Card for card-not-present transactions (such as online, mail or telephone purchases), the legal effect is the same as if you used the physical Card.</p>
            <p>You will be solely and completely responsible for the possession, use and control of the Card. <strong>You must surrender the Card to us immediately upon request by us.</strong> The Card is provided to you, the Cardholder, only. You may not sell or assign the Card to a third party. If you authorize another person to use the Card you agree, to the extent permitted by law, that you will be liable for all transactions arising from use of the Card by such person.</p>
            <p>You agree that we are not required to verify the signature on any sales draft prepared in connection with a transaction on the Card, and we may authorize and process a transaction even if the signature on the sales draft is different than the signature on the Card. You do not have the right to stop the payment of any transaction you conduct with the Card. We are not liable to you for declining authorization for any particular transaction, regardless of our reason. We may, in our sole discretion, cancel or suspend any features or services of the Card at any time, with or without cause, with thirty (30) days&rsquo; notice to you or as otherwise required by Applicable Law.</p>
            <p><strong>Information About Balance</strong>: <strong>It is your responsibility to ensure that there is a sufficient Balance on the Card to cover transactions.</strong> To obtain the current Balance amount, or the transaction history, you may call customer service toll-free at <strong>1-888-427-6682</strong> or visit the Website or by downloading the Aya Mobile Application on iOS or Android. The Card Balance will reflect all transactions that have been posted to our system. You are not allowed to exceed the Balance available on your Card for any transaction.</p>
            <p>If you attempt to use the Card when there is insufficient Balance available to cover the full Transaction Amount, the transaction in most instances will be declined. However, if due to a systems malfunction or for any reason whatsoever, a transaction occurs despite insufficient Balance on the Card, creating a negative amount, you agree to reimburse us, upon request, for the amount of the Transaction Amount in excess of the Balance.</p>
            <p><strong>Transactions Made in Foreign Currencies:</strong> We convert transactions, made in a foreign currency, to Canadian dollars using the Mastercard Conversion Rate in effect on the day the transaction is posted to the Card. The Mastercard Conversion Rate in effect on the posting date may differ from the rate in effect on the date of the transaction.&nbsp;</p>
            <p>However, if a foreign currency transaction is refunded to the Card, the Mastercard Conversion Rate used to convert your refund to Canadian dollars for the Card is the rate that we pay to Mastercard minus the Foreign exchange charge. Additionally, the rate that we pay to Mastercard may not be the same as the rate that existed on the date the transaction was refunded. For these reasons, the amount that is credited to the Card for a refund of a foreign currency transaction will, in most cases, be less than the amount that was originally charged to the Card for that transaction.</p>
            <p><strong>Protection Against Loss, theft, or Unauthorized Use:</strong> If the Card is lost or stolen, you can immediately suspend your Card using the Website or Mobile Application. You can also call the customer service toll-free number at <strong>1-888-427-6682</strong> during regular business hours from 9am ET &ndash; 5pm ET Monday to Friday to report your card lost or stolen or has unauthorized usage. You will be asked to provide us with the name on the Card and the Card number, and to answer an identifying question drawn from your personal information. If you lose the Card, someone might be able to use the Balance on the Card. The Card can be used without a PIN to make purchases online.</p>
            <p>We will transfer any remaining Balance onto a replacement card after we have processed all transactions completed before we had an opportunity to act on your information.&nbsp;</p>
            <p>A replacement Card with any remaining Balance will be issued within ten (10) days after you report the Card lost or stolen to ensure that all transactions have been processed properly.</p>
            <p>You agree, to the extent permitted by Applicable Law, to cooperate with us in our attempts to recover from unauthorized users and to assist in their prosecution.</p>
            <p>The MasterCard Zero Liability Policy applies to purchases made in-store, via telephone, online, or from a mobile device. As a cardholder, you will not be held responsible for unauthorized transactions if:&nbsp;</p>
            <ol>
            <li>you have exercised reasonable care in protecting the Card from loss or theft, and&nbsp;</li>
            <li>you promptly reported the loss or theft of the Card to Peoples Trust.&nbsp;</li>
            </ol>
            <p>If these conditions are not met you will be liable for all unauthorized transactions completed before you reported the loss of theft of the Card to Peoples Trust. Verification of a Zero Liability claim can take up to one hundred and twenty (120) days once all the required forms and/or documents have been received and confirmed by Peoples Trust, and may require a police investigation.</p>
            <p><strong>Notification and Changes to Terms:</strong> Subject to the limitations of Applicable Law, we may from time to time amend any term or condition of this Agreement or add a new term or condition, including increasing or adding new fees. As required by Applicable Law, notice of any Amendments will be sent to you at the most recent mailing or email address that we have on record for you. We must, at least thirty (30) days before the Amendment comes into force, send you a written notice drawn up clearly and legibly, setting out the new clause(s) only, or the amended clause(s) and the clause(s) as it (they) read formerly, the date of the coming into force of the Amendment and your rights set forth below.</p>
            <p>You may refuse the Amendment and rescind this Agreement without cost, penalty or cancellation indemnity by sending us a notice to that effect no later than thirty (30) days after the Amendment comes into force, if the Amendment entails an increase in your obligations or a reduction in our obligations. If you choose to rescind this Agreement, the Cancellation section of this Agreement will apply. Notification of any Amendment will also be posted on the Website at least sixty (60) days in advance of the effective date of the Amendment, unless otherwise required by Applicable Law. The change will take effect on the date indicated in the notice. <strong>You are responsible for (i) informing us of any change in your mailing or email address, by contacting customer service at 1-888-427-6682 </strong>or by updating your personal information within the website or Aya mobile app, and (ii) for checking the Website for Amendment details. Notice will be deemed to be received by you the next business day after electronic mail.</p>
            <p>You may notify us by delivering notice to the Program Sponsor or sending notice to us at the Website (other than notification of a lost or stolen Card, which may only be done by telephone as set out above). Notice will be deemed to be received on the date of delivery of notice to us or the Program Sponsor, as applicable, and the next business day after electronic mail.</p>
            <p><strong>Personal Information Consent:</strong> By accepting the card from your employer, you consent to the collection, use, disclosure and retention of your personal information by us and our service providers for purposes relating to your application for a Card and your use of a Card (if issued to you) and as otherwise described below. The collection of that information is necessary for the entering into and performance of this Agreement. Therefore, if you do not consent to the collection, use, disclosure and retention of your personal information, you may not apply for or use a Card. As explained below, you may withdraw your consent at any time by cancelling your Card and all related services from us. The restrictions and requirements described herein do not apply to information that is aggregated or otherwise de-personalized and does not identify you.</p>
            <p><strong>Safeguarding Your Personal Information: </strong>We protect personal information in our possession or control from loss, theft, alteration and misuse. The safeguards employed by us to protect your personal information depend on the sensitivity, amount, distribution, format and storage of the personal information. Although technologies can make it easier for fraud to occur, we employ around the clock monitoring systems and controls to detect and prevent fraudulent activity. We also build fraud prevention measures into our due diligence processes and regularly update our fraud detection/prevention methods. While we take precautions to protect your personal information from loss, theft, alteration, or misuse, no system or security measure is completely secure. Any transmission of your personal data is at your own risk and we expect that you will use appropriate measures to protect your personal information as well.</p>
            <p><strong>Collecting your Personal Information</strong><strong><em>:</em></strong> We and our service providers will collect information about you (e.g. your name, address, telephone number and date of birth) when you apply for a Card and, if a Card is issued to you, we and our service providers will collect information about you and your use of the Card and related services, including information about your Card transactions (e.g. the date, amount and place of each transaction) (all collectively &ldquo;Cardholder Information&rdquo;). We and our service providers will collect your Cardholder Information directly from you and from other sources, including the Program Sponsor and third party providers of identity verification, demographic and fraud prevention services.</p>
            <p><strong>How We Use, Retain, and Disclose Your Personal Information</strong><strong><em>:</em></strong> We and our service providers will use, disclose and retain your Cardholder Information to process your application for a Card (including to verify your identity) and, if a Card is issued to you, to provide you with services relating to your Card (including to administer your Card and to process your Card transactions), to protect against fraud and for legal compliance purposes, to perform and enforce this Agreement, to protect and enforce our legal rights and for other purposes required or permitted by Applicable Law. We will disclose your Cardholder Information to our service providers to assist us to provide services to you and to provide related services to us.</p>
            <p>We maintain physical, electronic and procedural security measures that comply with Canadian regulations to safeguard Cardholder Information.</p>
            <p>We and our service providers may use and store your Cardholder Information at facilities in various countries (including Canada and the United States of America). The personal information protection laws of those countries might be different from the laws of the jurisdiction in which you are located, and might permit courts, government, law enforcement and regulatory agencies and security authorities to access your Cardholder Information without notice. The laws on data protection in other jurisdictions, to which we may transfer your information, may differ from those in your jurisdiction and any personal information transferred to another jurisdiction will be subject to law enforcement and national security authorities in that jurisdiction. Subject to these laws, we will use reasonable measures to maintain protections of your personal information that are equivalent to those that apply in your jurisdiction. You hereby give your consent to such cross-border transfers (including to the United States) of such personal information to third parties for the purpose set out above.</p>
            <p>We will use and rely on your Cardholder Information to issue and administer your Card and provide related services. We and our service providers will rely on you to ensure that your Cardholder Information is accurate, complete and up to date. <strong>You will promptly inform us (by contacting Customer Service at 1-888-427-6682</strong> of any changes to your Cardholder Information or if you discover any errors in yo<strong>ur Cardholder Information.</strong> You may communicate with us through our customer service number [or the Website] with regards to requests to access information related to you that we have obtained. If such information is obtained from providers of identity verification data and demographic information, we will inform you of your right of access and rectification in relation to the file held by the personal information agent and will indicate to you the manner in which and the place where you may have access to the reports or recommendations and cause them to be rectified, where necessary.</p>
            <p>We and our service providers may use your Cardholder Information (including your telephone and mobile phone numbers and your email addresses) to contact you, including by regular and electronic mail, telephone call (including by pre-recorded or artificial voice messages and automatic telephone dialling systems) and instant messaging, regarding your Card and related matters, regardless of whether you incur any long distance or usage charges as a result.</p>
            <p>We and our service providers may monitor and record their communications and correspondence with you (including emails, online chats and telephone calls) for quality assurance, staff training and legal compliance purposes.</p>
            <p><strong>Other Uses of Your Personal Information:</strong> In addition to the foregoing, if you consent to a Program Sponsor or other third party collecting and using your personal information (including Cardholder Information) for their own purposes (not as our service provider), including to send marketing and promotional messages to you, then we will not have any control over, and will not be responsible or liable for, the collection, use, disclosure and retention of your personal information by the Program Sponsor or third party, the marketing or promotional messages that they send to you, or any other wrongful act or omission by the Program Sponsor or third party.</p>
            <p><strong>Your Right to Access Your Personal Information</strong>: You may obtain access to the Cardholder Information we hold about you at any time and review its content and accuracy, and have it amended as appropriate; however, access may be restricted as permitted or required by law. To request access to such information, to ask questions about our privacy policies or to withdraw your consent to the collection, use and disclosure of your Cardholder Information and to cancel your Card and all related services from us, contact Customer Service at <strong>1-888-427-6682</strong>. If you withdraw your consent, we will continue to collect, use, disclose and retain your Cardholder Information for as long as may be reasonably required to perform services relating to the cancellation of your Card, to protect against fraud and for legal compliance purposes, to perform and enforce this Agreement, to protect and enforce our legal rights and for other purposes required or permitted by Applicable Law.</p>
            <p><strong>Peoples Trust Company&rsquo;s Privacy Policies:</strong> Our general personal information practices are described in our privacy policy, as amended from time to time, available online at <a href="http://www.peoplestrust.com/en/legal/privacy-security/privacy/"><strong>http://www.peoplestrust.com/en/legal/privacy-security/privacy/</strong></a>.&nbsp;</p>
            <p><strong>Disputes</strong>: <strong>If you believe a transaction on your Card account is incorrect, you must notify us of your dispute within sixty (60) days of the transaction date.</strong> Notification can be completed via email at <a href="mailto:disputes@ayacare.com">disputes@ayacare.com</a> , or by calling <strong>1-888-427-6682</strong>, or by sending us written correspondence to&nbsp;</p>
            <p>Aya Customer Service</p>
            <p>PO BOX 60014 Thorncrest PO</p>
            <p>Etobicoke, ON., M9A 3L0</p>
            <p>Following your notification, a dispute form will be sent to you via email. You will need to complete and submit the dispute form within ninety (90) days of the original transaction date. Completed dispute forms may be returned via email to <a href="mailto:disputes@ayacare.com">disputes@ayacare.com</a> or by mail to the address above.<strong> Please note that this form must be received within ninety (90) days of the date of the disputed transaction or you will have been deemed to have accepted such transaction.</strong></p>
            <p><strong>If you identify an error in any transaction record, you must address such error with the applicable merchant.</strong> If there is any dispute in regard to purchases you make using the Card, you agree to settle such disputes with the merchant from whom the purchase was made. Please ask the merchant for any return policy that may apply to purchases made with the Card. We are not responsible for any problems you may have with any goods or services that you purchase with your Card, whether with regard to quality, safety, legality, or any other aspect of your purchase. If you are entitled to a refund for any reason for goods or services obtained with the Card, you agree to accept credits to the Balance on your Card in place of cash.</p>
            <p><strong>Arbitration:</strong><strong>Subject to all other terms of this Agreement, and to the extent not prohibited by Applicable Law, you agree that any claim of any kind against us, the Program Manager, the Program Sponsor or Mastercard arising from or related to this Agreement or the use of the Card (i) shall be resolved by final and binding arbitration before a single arbitrator at Vancouver, British Columbia and (ii) shall not be brought through class or individual litigation proceedings. If such a claim is advanced by class proceeding by any other person on your behalf, you will opt out of, or not opt into, such proceedings as circumstances dictate.</strong></p>
            <p><strong>Complaints:</strong> If you have a complaint or inquiry about any aspect of your Card, you may first attempt to resolve the complaint or inquiry by calling our toll-free customer service number at <strong>1-888-427-6682</strong>. If customer service is unable to resolve the complaint or inquiry to your satisfaction, you may call us at <strong>1-855-694-6214</strong> or submit your complaint or inquiry through the form found on the Website (<a href="http://www.peoplestrust.com/en/about-us/contact/"><strong>http://www.peoplestrust.com/en/about-us/contact/</strong></a>). We will do our best to resolve your complaint or inquiry.</p>
            <p>If for some reason we are unable to resolve the issue to your satisfaction, you may refer your inquiry or complaint to the Ombudsman for Banking Services and Investments at <strong>1-888-451-4519</strong> for resolution. If the Cardholder has a concern regarding a potential violation of a consumer protection law, a public commitment, or an industry code of conduct, the concern may be communicated at any time to the Financial Consumer Agency of Canada, either in person, by letter, by telephone, or through its website at:</p>
            <p>Financial Consumer Agency of Canada</p>
            <p>427 Laurier Avenue West, 6th Floor</p>
            <p>Ottawa, ON, K1R 1B9</p>
            <p>Telephone: <strong>1-866-461-3222</strong></p>
            <p><a href="http://www.fcac-acfc.gc.ca"><strong>www.fcac-acfc.gc.ca</strong></a></p>
            <p>Our complaints policy can be found online at: <a href="http://www.peoplestrust.com/en/about-us/resolving-your-concerns/"><strong>http://www.peoplestrust.com/en/about-us/resolving-your-concerns/</strong></a>.&nbsp;</p>
            <p><strong>Cancellation:</strong> As an employee that has received the Aya Prepaid Mastercard, you may elect to withdraw from the Card program by calling <strong>1-888-427-6682</strong> and/or by notifying your employer plan administrator. Upon receipt of your request (and that of your employer), your prepaid Card and account will be terminated and all unspent funds will be returned to the employer. The employer continues to manage your health and wellness plan.&nbsp;</p>
            <p><strong>We may terminate this Agreement at any time, at which time you must immediately return the Card to us or as we direct</strong>, provided that if you are not in default of your obligations under this Agreement, we will notify you in writing at least sixty (60) days before the date of termination. We or our Program Sponsor will reimburse you any remaining Balance on the Card less outstanding fees. <strong>Despite any termination of this Agreement, you must fulfil all of your obligations under this Agreement.</strong></p>
            <p><strong>No Warranty of Availability or Uninterrupted Use:</strong><strong>FROM TIME TO TIME CARD SERVICES MAY BE INOPERATIVE, AND WHEN THIS HAPPENS, YOU MAY BE UNABLE TO USE YOUR CARD OR OBTAIN INFORMATION ABOUT THE BALANCE ON YOUR CARD. PLEASE NOTIFY US IF YOU HAVE ANY PROBLEMS USING YOUR CARD. YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY INTERRUPTION OF SERVICE.</strong></p>
            <p><strong>Third Party Claims</strong>: In the event we reimburse you for a refund claim you have made, or if we otherwise provide you with a credit or payment with respect to any problem arising out of any transaction made with the Card, you are automatically deemed to assign and transfer to us any rights and claims (excluding tort claims) that you have, had or may have against any third party for an amount equal to the amount we have paid to you or credited to your Card. You agree that you will not pursue any claim against or reimbursement from such third party for the amount that we paid or credited to your Card, and that you will cooperate with us if we decide to pursue the third party for the amount paid or credited to you. If we do not exercise our rights under this section, we do not give up our rights to exercise them in the future.</p>
            <p><strong>Disclaimer of Warranties</strong>: <strong>EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN THIS AGREEMENT, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND TO YOU, WHETHER EXPRESS OR IMPLIED, REGARDING ANY SUBJECT MATTER OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE.</strong></p>
            <p><strong>Limitation of Liability: </strong><strong>EXCEPT AS EXPRESSLY REQUIRED BY THIS AGREEMENT OR APPLICABLE LAW, WE WILL NOT BE LIABLE TO YOU FOR PERFORMING OR FAILING TO PERFORM ANY OBLIGATION UNDER THIS AGREEMENT UNLESS WE HAVE ACTED IN BAD FAITH. WITHOUT LIMITING THE FOREGOING, WE WILL NOT BE LIABLE TO YOU FOR DELAYS OR MISTAKES RESULTING FROM ANY CIRCUMSTANCES BEYOND OUR CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF GOVERNMENTAL AUTHORITIES, NATIONAL EMERGENCIES, INSURRECTION, WAR, RIOTS, FAILURE OF MERCHANTS TO PERFORM OR PROVIDE SERVICES, FAILURE OF COMMUNICATION SYSTEMS, OR FAILURES OF OR DIFFICULTIES WITH OUR EQUIPMENT OR SYSTEMS. ALSO WITHOUT LIMITING THE FOREGOING, WE WILL NOT BE LIABLE TO YOU FOR ANY DELAY, FAILURE OR MALFUNCTION ATTRIBUTABLE TO YOUR EQUIPMENT, ANY INTERNET SERVICE, ANY PAYMENT SYSTEM OR ANY CUSTOMER SERVICE FUNCTION. IN THE EVENT THAT WE ARE HELD LIABLE TO YOU, YOU WILL ONLY BE ENTITLED TO RECOVER YOUR ACTUAL AND DIRECT DAMAGES. IN NO EVENT WILL YOU BE ENTITLED TO RECOVER ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY OR SPECIAL DAMAGES (WHETHER IN CONTRACT, TORT, OR OTHERWISE), EVEN IF YOU HAVE ADVISED US OF THE POSSIBILITY OF SUCH DAMAGES.</strong></p>
            <p><strong>Website and Availability: </strong>Although considerable effort is made to ensure that our Website and other operational and communications channels available around the clock, we do not warrant these channels to be available and error free at all times. You agree that we will not be responsible for temporary interruptions in service due to maintenance, website changes, or failures, nor will we be liable for extended interruptions due to failures beyond our control, including but not limited to the failure of interconnecting and operating systems, computer viruses, forces of nature, labour disputes and armed conflicts. We will not bear any liability, whatsoever, for any damage or interruptions caused by any computer viruses that may affect your computer or other equipment. You agree to act responsibly with regard to the Website and its use. <strong>You will not violate any laws, interfere or disrupt computer networks, impersonate another person or entity, violate the rights of any third party, stalk, threaten or harass anyone, gain any unauthorized entry, or interfere with the Website&rsquo;s systems and integrity.</strong></p>
            <p><strong>Assignment: </strong>At our sole discretion, we may assign our rights and responsibilities under this Agreement at any time and without notice to you. If we do make such an assignment, then this Agreement will remain binding on you and your respective executors, administrators, successors, representatives and permitted assigns.&nbsp;</p>
            <p><strong>Entire Agreement: </strong>This Agreement sets forth the entire understanding and Agreement between you and us, whether written or oral, with respect to the subject matter hereof and supersedes any prior or contemporaneous understandings or Agreements with respect to such subject matter.</p>
            <p><strong>Governing Law: </strong>The parties agree that any claim or action brought pursuant to this Agreement will be brought in the exclusive jurisdiction of the courts of British Columbia and this Agreement will be construed in accordance with and governed by the laws of the Province of British Columbia and the laws of Canada applicable therein.</p>
            <p><strong>Section Headings:</strong> Section headings in this Agreement are for convenience of reference only, and will not govern the interpretation of any provision of this Agreement.</p>
            <p><strong>Severability:</strong> If any of the terms of this Agreement are invalid, changed by Applicable Law or declared invalid by order of court or regulatory authority, the remaining terms of this Agreement will not be affected, and this Agreement will be interpreted as if the invalid terms had not been included in this Agreement.</p>
            <p><strong>Contact Information:</strong> If you have questions regarding the Card, or need to report a lost or stolen Card, you may call customer service at <strong>1-888-427-6682</strong> or write to:&nbsp;</p>
            <p>Aya Customer Service</p>
            <p>PO BOX 60014 Thorncrest PO</p>
            <p>Etobicoke, ON., M9A 3L0</p>
            <p>Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.</p>
            <p>Updated: September 25, 2020 (PCS ID 10090)</p>
        </div>
    );
};

export default CardMemberAgreement;