// Interface
import {
  ICategoryType,
  IStoreTypes,
  IClaimTypes,
  IClaimSubmissionTypes,
} from "./claimSubmission.interface";

/** @type {*} */
export const STORE_TYPES: IStoreTypes = {
  ONLINE: "Online",
  PHYSICAL: "Physical",
};

export const CLAIM_TYPES: IClaimTypes = {
  HEALTH: "Health",
  WELLNESS: "Wellness",
};

export const CLAIM_SUBMISSION_TYPES: IClaimSubmissionTypes = {
  MASTERCARD: "mc",
  OOP: "oop",
};

/**
 * @param {*} hsa
 * @param {*} wsa
 * @return {*}  {{ HEALTH: boolean; WELLNESS: boolean }}
 */
export const CLAIM_TYPES_BALANCE = (
  hsa: any,
  wsa: any,
  equalCondition: boolean = false
): any => ({
  Health: equalCondition ? parseFloat(hsa) === 0 : parseFloat(hsa) !== 0,
  Wellness: equalCondition ? parseFloat(wsa) === 0 : parseFloat(wsa) !== 0,
});

/** @type {*} */
export const ACCOUNT_GUID = (hsa: any, wsa: any): IClaimTypes => ({
  HEALTH: hsa !== null ? true : false,
  WELLNESS: wsa !== null ? true : false,
});

/** @type {*} */
export const categoryType: ICategoryType = {
  major: { name: "Major", key: "majorCategory", nextKey: "minor" },
  minor: { name: "Minor", key: "minorCategory", nextKey: "minorSub" },
  minorSub: { name: "Minor Sub", key: "minorSubCategory", nextKey: "" },
};

// Default category parent id
export const defaultCategoryParentId: string =
  "00000000-0000-0000-0000-000000000000";
export const oopReason: string = "Merchant Doesn't Accept Card";
export const methodOfPayment: string = "Cash";

// Redirect URLs
export const REDIRECT_URL = {
  dashboard: "/dashboard",
  claimSubmission: "/claim-submission?comp=ClaimDetails",
  addReceipt: "/claim-submission?comp=AddReceiptDetails",
  submitExpenseModal: "/claim-submission?comp=SubmitExpenseModal",
  uploadReceipt: "/claim-submission?comp=UploadReceipt",
  noReceiptModal: "/claim-submission?comp=NoReceiptInfoModal",
  claimSubmitted: "/claim-submission?comp=ClaimSubmitted",
  uploadReceiptMC: "/claim-submission?comp=UploadReceipt&type=mc",
  noReceiptModalMC: "/claim-submission?comp=NoReceiptInfoModal&type=mc",
  claimSubmissionMC: "/claim-submission?comp=ClaimDetails&type=mc",
  addReceiptMC: "/claim-submission?comp=AddReceiptDetails&type=mc",
  claimSubmittedMC: "/claim-submission?comp=ClaimSubmitted&type=mc",
  selectClaimType: "/select-claim-type",
};

/**
 * @param {string} firstName
 * @param {string} lastName
 * @return {*}  {Array<string>}
 */
export const dependant = (
  firstName: string,
  lastName: string
): Array<string> => [
  `${firstName} ${lastName}`,
  "Dependent",
  "Dependent not listed",
];

/** @type {*} */
export const categoryDetails: {
  [index: string]: { category: string; subCategory: string };
} = {
  wellNess: {
    category: "wellness",
    subCategory: "Wellness Spending",
  },
  prescription: {
    category: "Prescription Drugs",
    subCategory: "Prescription drugs and medications",
  },
};
