// Types
import { Action } from "../actions/types";

// State types
type State = {
  data: any;
  recentReports: Array<any>;
};

// Initial state
const INITIAL_STATE: State = {
  data: {},
  recentReports: [],
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "UPDATE_DASHBOARD_STATS":
      return { ...state, data: { ...state.data, ...action.data } };
    case "UPDATE_REPORT_CACHE":
      if (state.recentReports?.length === 5) {
        state.recentReports.shift();
      }
      return {
        ...state,
        recentReports:
          state.recentReports && state.recentReports.length > 0
            ? [...state.recentReports, ...action.data.recentReports]
            : action.data.recentReports,
      };
    default:
      return state;
  }
};
